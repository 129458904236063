import { createDate } from '@resnet/client-common/date/utils/create-date'

export type ComparatorT<ValueT> = (left: ValueT, right: ValueT) => number

type SelectorT<InputT, OutputT> = (input: InputT) => OutputT

export const composeComparators =
  <ValueT>(...comparators: ComparatorT<ValueT>[]): ComparatorT<ValueT> =>
  (left, right) => {
    for (const comparator of comparators) {
      const result = comparator(left, right)

      if (result !== 0) {
        return result
      }
    }

    return 0
  }

export const compareBy =
  <InputT, OutputT>(selector: SelectorT<InputT, OutputT>, comparator: ComparatorT<OutputT>): ComparatorT<InputT> =>
  (left, right) =>
    comparator(selector(left), selector(right))

export const compareStrings: ComparatorT<string> = (left, right) => left.localeCompare(right)

export const compareDates: ComparatorT<undefined | null | number | string | Date> = (left, right) => {
  if (left === undefined || left === null) {
    return -1
  }

  if (right === undefined || right === null) {
    return 1
  }

  return Number(createDate(left)) - Number(createDate(right))
}

export const compareStringsBy = <InputT>(selector: SelectorT<InputT, string>): ComparatorT<InputT> =>
  compareBy(selector, compareStrings)

export const compareStringsDesc: ComparatorT<string> = (left, right) => right.localeCompare(left)

export const compareStringsDescBy = <InputT>(selector: SelectorT<InputT, string>): ComparatorT<InputT> =>
  compareBy(selector, compareStringsDesc)

export const compareNumbers: ComparatorT<number> = (left, right) => Number(left) - Number(right)

export const compareNumbersBy = <InputT>(selector: SelectorT<InputT, number>): ComparatorT<InputT> =>
  compareBy(selector, compareNumbers)

export const compareNumbersDesc: ComparatorT<number> = (left, right) => Number(right) - Number(left)

export const compareNumbersDescBy = <InputT>(selector: SelectorT<InputT, number>): ComparatorT<InputT> =>
  compareBy(selector, compareNumbersDesc)
