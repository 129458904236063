import { transform } from '@resnet/client-common/common/utils/object/transform'
import { createHookElementsContainer } from '@resnet/client-common/react/utils/create-hook-container'

import { useInfiniteResourceObjectTypesQuery } from '@resnet/client-api/api'

import { createUseSelectDropdown } from '@resnet/client-shared-web/shared/common/factories/create-use-select-dropdown'

import { useResourceObjectTypeOptionActions } from './use-resource-object-type-option-actions'

export const useResourceObjectTypeSelectDropdown = createUseSelectDropdown({
  mapOptionsQueryDataToOptions: (data) => data.listResourceObjectTypes.items,
  useOptionsQuery: useInfiniteResourceObjectTypesQuery,
  withSearch: (input, { search }) =>
    transform(input ?? {}, { filter: (filter) => transform(filter ?? {}, { name: () => ({ includes: search }) }) }),
})

export const useResourceObjectTypeSelectWithActionsOptionsDropdown = createUseSelectDropdown({
  mapOptionsQueryDataToOptions: (data) => data.listResourceObjectTypes.items,
  useGetOptionActions: useResourceObjectTypeOptionActions,
  useOptionsQuery: useInfiniteResourceObjectTypesQuery,
  withSearch: (input, { search }) =>
    transform(input ?? {}, { filter: (filter) => transform(filter ?? {}, { name: () => ({ includes: search }) }) }),
})

export const ResourceObjectTypeSelectDropdownContainer = createHookElementsContainer(
  useResourceObjectTypeSelectDropdown,
)

export const ResourceObjectTypeSelectWithActionsDropdownContainer = createHookElementsContainer(
  useResourceObjectTypeSelectWithActionsOptionsDropdown,
)
