import { Box } from '@mui/material'
import { DateCalendar, DigitalClock, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { useState } from 'react'

import { useEventCallback } from '@resnet/client-common/react/hooks/use-event-callback'
import { foldElement } from '@resnet/client-common/react/utils/fold-element'

import { ClickAwayListener } from '@resnet/client-shared-web/shared/registry/components/click-away-listener'
import { FocusTrap } from '@resnet/client-shared-web/shared/registry/components/focus-trap'

import { themeColors } from '../../constants/theme-colors'
import { mapBorderWidthToValue } from '../../utils/map-border-width-to-value'
import { mapPaddingToValue } from '../../utils/map-padding-to-value'
import { toPx } from '../../utils/to-px'
import { Button } from '../button'
import type { PopperPropsT } from '../popper'
import { Popper } from '../popper'

export type CalendarPopperPropsT = {
  children?: {
    anchor?: () => React.ReactElement
  }
  disablePortal?: PopperPropsT['disablePortal']
  onChange?: (value: null | Date) => void
  onClear?: () => void
  onClose?: PopperPropsT['onClose']
  onSave?: () => void
  placement?: PopperPropsT['placement']
  popperRef?: PopperPropsT['popperRef']
  showTime?: boolean
  value?: null | Date
} & Omit<React.ComponentProps<typeof DateCalendar>, 'value' | 'onChange' | 'sx' | 'displayStaticWrapperAs'>

export const CalendarPopper = ({
  children: { anchor: renderAnchor } = {},
  disablePortal,
  onChange,
  onClear,
  onClose,
  onSave,
  placement,
  popperRef,
  showTime,
  value,
  ...pickerProps
}: CalendarPopperPropsT) => {
  const isOpenedStateModel = useState(false)

  const [_, setIsOpened] = isOpenedStateModel

  const onKeyDown = useEventCallback((event: React.KeyboardEvent) => {
    if (event.key !== 'Escape') {
      return
    }

    event.stopPropagation()

    setIsOpened(false)
  })

  const renderDateCalendar = () => {
    return (
      <DateCalendar
        {...pickerProps}
        sx={{ flexShrink: 0 }}
        value={value}
        onChange={onChange}
      />
    )
  }

  const renderTime = () => {
    if (!showTime) {
      return null
    }

    return (
      <Box sx={{ display: 'flex', flexDirection: 'column' }}>
        <DigitalClock
          sx={{ '& li': { borderRadius: toPx(8) }, flexGrow: 1, height: 0, maxHeight: 'none' }}
          value={value}
          onChange={onChange}
        />
      </Box>
    )
  }

  const renderCalendar = () => {
    return (
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <Box sx={{ display: 'flex', gap: toPx(8) }}>
          {renderDateCalendar()}
          {renderTime()}
        </Box>
      </LocalizationProvider>
    )
  }

  const renderClearButton = () => {
    if (!onClear) {
      return null
    }

    return (
      <Button
        color="primary"
        size="sm"
        variant="outlined"
        onClick={onClear}
      >
        Clear
      </Button>
    )
  }

  const renderSaveButton = () => {
    if (!onSave) {
      return null
    }

    return (
      <Button
        color="primary"
        size="sm"
        variant="contained"
        onClick={onSave}
      >
        Save
      </Button>
    )
  }

  const renderFooter = () => {
    return foldElement(
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          padding: toPx(8),
        }}
      >
        {renderClearButton()}
        {renderSaveButton()}
      </Box>,
    )
  }

  const renderContent = () => {
    const borderWidth = 1

    const borderRadius = 8

    const padding = 8

    return (
      <ClickAwayListener
        mouseEvent="onMouseDown"
        onClickAway={() => setIsOpened(false)}
      >
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
          <FocusTrap open>
            <Box
              sx={{
                backgroundColor: themeColors.surfaceNeutralDefault,
                borderColor: themeColors.borderBold,
                borderRadius: toPx(borderRadius),
                borderStyle: 'solid',
                borderWidth: mapBorderWidthToValue({ borderWidth }),
                boxSizing: 'border-box',
                display: 'flex',
                flexDirection: 'column',
                gap: toPx(16),
                outline: 'unset',
                padding: mapPaddingToValue({ borderWidth, padding }),
                position: 'relative',
              }}
              tabIndex={-1}
              onKeyDown={onKeyDown}
            >
              {renderCalendar()}
              {renderFooter()}
            </Box>
          </FocusTrap>
        </Box>
      </ClickAwayListener>
    )
  }

  return (
    <Popper
      disablePortal={disablePortal}
      isOpenedStateModel={isOpenedStateModel}
      placement={placement}
      popperRef={popperRef}
      onClose={onClose}
    >
      {{
        anchor: renderAnchor,
        content: renderContent,
      }}
    </Popper>
  )
}
