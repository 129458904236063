import { Box } from '@mui/material'

import type { PresetTypeT } from '@resnet/client-api/api'

import { SimpleStaticOptionsDropdown } from '@resnet/client-shared-web/shared/common/components/simple-static-options-dropdown'
import { useNonNullableChangeHandler } from '@resnet/client-shared-web/shared/gdl/components/dropdown-input/hooks/use-non-nullable-change-handler'
import { FieldLabel } from '@resnet/client-shared-web/shared/gdl/components/field'
import { Field } from '@resnet/client-shared-web/shared/gdl/components/field'

export type SaveAsOptionT = { id: PresetTypeT; name: string }

export const PresetSaveAsField = <OptionT extends SaveAsOptionT>({
  value,
  onChange: onChangeActual,
  options,
  getOptionDisabled,
  getOptionTooltip,
}: {
  value: OptionT['id']
  onChange: (value: OptionT['id']) => void
  options: OptionT[]
  getOptionDisabled?: (option: OptionT) => boolean
  getOptionTooltip?: (option: OptionT) => undefined | string
}) => {
  const { onChange } = useNonNullableChangeHandler({ defaultOption: options[0], onChange: onChangeActual })

  return (
    <Box>
      <Field>
        <FieldLabel>Save As</FieldLabel>
        <SimpleStaticOptionsDropdown
          getOptionDisabled={getOptionDisabled}
          getOptionTooltip={getOptionTooltip}
          options={options}
          value={value}
          onChange={onChange}
        />
      </Field>
    </Box>
  )
}
