import { useMemo } from 'react'

import { createHookContainer } from '@resnet/client-common/react/utils/create-hook-container'
import type { SuccessSimpleQueryT } from '@resnet/client-common/react-query/types/query'

// is used when you don't need to fetch data

export const useOption = <OptionT extends { id: string }>({ id }: { id: OptionT['id'] }) => {
  const option = useMemo<OptionT>(() => {
    return { id } as OptionT
  }, [id])

  const optionQuery = useMemo<SuccessSimpleQueryT<OptionT>>(
    () => ({
      data: option,
      isSuccess: true,
    }),
    [option],
  )

  return { optionQuery }
}

export const OptionContainer = createHookContainer(useOption)
