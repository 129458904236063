import { SwitchPreview } from '@resnet/client-shared-web/shared/gdl/components/switch'

import type { RegularCellPropsT } from '../regular-cell'
import { RegularCell } from '../regular-cell'

export type CheckboxCellValueT = undefined | null | boolean

export type CheckboxCellPropsT = RegularCellPropsT & {
  value: CheckboxCellValueT
}

export const CheckboxCell = ({ value, ...props }: CheckboxCellPropsT) => {
  const renderContent = () => {
    if (value === undefined || value === null) {
      return '-'
    }

    return (
      <SwitchPreview
        size="sm"
        value={value}
      />
    )
  }

  return <RegularCell {...props}>{renderContent()}</RegularCell>
}
