import { Box, LinearProgress, Tooltip } from '@mui/material'
import { useVirtualizer } from '@tanstack/react-virtual'
import { useMemo, useRef } from 'react'

import { ChildrenDivider } from '@resnet/client-common/react/components/children-transformer'

import type { MediaT } from '@resnet/client-shared/shared/gdl/types/media'

import { checkIsSkeletonItem, useLoadableList } from '@resnet/client-shared-web/shared/async/hooks/use-loadable-list'
import type { AbstractOptionT } from '@resnet/client-shared-web/shared/gdl/types/abstract-option'

import { Divider } from '../../components/divider'
import { toPx } from '../../utils/to-px'
import type { ListActionItemActionPropsT } from '../list'
import { RadioListItem, RadioListItemSkeleton } from '../radio-list'
import { VirtualizedList } from '../virtualized-list-lisbon'

import { SelectDropdownMessagePanel } from './components/select-dropdown-message-panel'
import type { SelectDropdownOptionsBottomBoundary } from './components/select-dropdown-options-boundary'
import { selectDropdownSizesConfig } from './constants/select-dropdown-config'
import { getSelectOptionLabel } from './utils/get-select-option-label'

export type SelectDropdownPropsT<OptionT extends AbstractOptionT> = {
  bottomBoundary?: React.ComponentProps<typeof SelectDropdownOptionsBottomBoundary>
  error?: string
  getOptionActions?: (option: OptionT) => ListActionItemActionPropsT[]
  getOptionDescription?: (option: OptionT) => React.ReactNode
  getOptionDisabled?: (option: OptionT) => boolean
  getOptionLabel?: typeof getSelectOptionLabel<OptionT>
  getOptionMedia?: (option: OptionT) => undefined | MediaT
  getOptionTooltip?: (option: OptionT) => undefined | string
  getOptionVisible?: (option: OptionT, value: undefined | null | OptionT['id']) => boolean
  header?: React.ReactNode
  isBusy?: boolean
  isLoading?: boolean
  nonNullable?: boolean
  notFoundMessage?: string
  onChange?: (value: undefined | null | OptionT['id']) => void
  optionHeight?: number
  options?: OptionT[]
  search?: React.ReactNode
  showSelection?: boolean
  skeletonProps?: React.ComponentProps<typeof RadioListItemSkeleton>
  value: undefined | null | OptionT['id']
}

export const SelectDropdown = <OptionT extends AbstractOptionT>({
  bottomBoundary,
  error,
  getOptionActions,
  getOptionDescription,
  getOptionDisabled,
  getOptionLabel = getSelectOptionLabel,
  getOptionMedia,
  getOptionTooltip,
  getOptionVisible,
  header,
  isBusy = false,
  isLoading = false,
  nonNullable,
  notFoundMessage = 'No options found',
  onChange,
  optionHeight = selectDropdownSizesConfig.optionHeight,
  options: optionsActualActual,
  search,
  showSelection = true,
  skeletonProps,
  value,
}: SelectDropdownPropsT<OptionT>) => {
  if (!optionsActualActual) {
    throw new Error('should be non nullable')
  }

  const optionsActual = useMemo(() => {
    if (!getOptionVisible) {
      return optionsActualActual
    }

    return optionsActualActual.filter((option) => getOptionVisible(option, value))
  }, [getOptionVisible, optionsActualActual, value])

  const { data: options } = useLoadableList({
    data: optionsActual,
    isFetching: isLoading,
    skeletonsCount: selectDropdownSizesConfig.maxVisibleOptions,
  })

  const virtualizedListRef = useRef<null | HTMLDivElement>(null)

  const virtualizer = useVirtualizer({
    count: options.length,
    estimateSize: () => optionHeight,
    getItemKey: (index) => options[index].id,
    getScrollElement: () => virtualizedListRef.current,
  })

  const { gap, maxVisibleOptions } = selectDropdownSizesConfig

  const visibleOptions = Math.min(options.length, maxVisibleOptions)

  const height = optionHeight * visibleOptions + gap * (visibleOptions - 1)

  const renderOptions = () => {
    if (error) {
      return (
        <Box sx={{ py: toPx(selectDropdownSizesConfig.listPadding) }}>
          <SelectDropdownMessagePanel>{error}</SelectDropdownMessagePanel>
        </Box>
      )
    }

    if (options.length === 0) {
      return (
        <Box sx={{ py: toPx(selectDropdownSizesConfig.listPadding) }}>
          <SelectDropdownMessagePanel>{notFoundMessage}</SelectDropdownMessagePanel>
        </Box>
      )
    }

    return (
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          height: toPx(height),
        }}
      >
        <VirtualizedList
          gap={selectDropdownSizesConfig.gap}
          items={options}
          renderItem={({ item: option }) => {
            if (checkIsSkeletonItem(option)) {
              return (
                <RadioListItemSkeleton
                  {...skeletonProps}
                  key={option.id}
                  sx={{ height: optionHeight }}
                />
              )
            }

            const isSelected = option.id === value

            const wrapWithTooltip = (element: React.ReactElement) => {
              const tooltip = getOptionTooltip?.(option)

              if (!tooltip) {
                return element
              }

              return <Tooltip title={tooltip}>{element}</Tooltip>
            }

            return wrapWithTooltip(
              <RadioListItem
                actions={getOptionActions?.(option)}
                description={getOptionDescription?.(option)}
                disabled={getOptionDisabled?.(option) || isBusy}
                isSelected={isSelected}
                key={option.id}
                media={getOptionMedia?.(option)}
                name={getOptionLabel(option)}
                showNameTooltip={!getOptionTooltip}
                showSelection={showSelection}
                sx={{ height: optionHeight }}
                onClick={() => {
                  if (nonNullable && isSelected) {
                    return
                  }

                  if (isSelected) {
                    onChange?.(null)

                    return
                  }

                  onChange?.(option.id)
                }}
              />,
            )
          }}
          virtualizedListRef={virtualizedListRef}
          virtualizer={virtualizer}
          onEndReached={bottomBoundary?.onObserve}
        />
      </Box>
    )
  }

  const renderLoader = () => {
    if (!isBusy) {
      return null
    }

    return (
      <Box sx={{ left: toPx(-8), position: 'absolute', right: toPx(-8), top: toPx(-7) }}>
        <LinearProgress />
      </Box>
    )
  }

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: toPx(8), position: 'relative' }}>
      {renderLoader()}
      <ChildrenDivider dividerNode={<Divider />}>
        {search}
        {header}
        {renderOptions()}
      </ChildrenDivider>
    </Box>
  )
}
