import { Box } from '@mui/material'

import type { CustomFieldFragmentT } from '@resnet/client-api/api'

import { typographyPresets } from '@resnet/client-shared/shared/gdl/constants/typography-presets'

import { Divider } from '@resnet/client-shared-web/shared/gdl/components/divider'
import { themeColors } from '@resnet/client-shared-web/shared/gdl/constants/theme-colors'
import { mapTypographyPresetToSx } from '@resnet/client-shared-web/shared/gdl/utils/map-typography-preset-to-sx'

export type SectionHeaderCustomFieldUserFormFieldPropsT = {
  index: number
  field: CustomFieldFragmentT
}

export const SectionHeaderCustomFieldUserFormField = ({
  index,
  field,
}: SectionHeaderCustomFieldUserFormFieldPropsT) => {
  const { name } = field

  const getSizeSx = () => {
    return {
      gridColumn: 'span 2',
    }
  }

  const renderDivider = () => {
    if (index === 0) {
      return null
    }

    return <Divider />
  }

  const renderHeader = () => {
    return (
      <Box
        sx={[
          mapTypographyPresetToSx(typographyPresets.titleLarge),
          { color: themeColors.overBackgroundDefault },
          getSizeSx(),
        ]}
      >
        {name}
      </Box>
    )
  }

  return (
    <>
      {renderDivider()}
      {renderHeader()}
    </>
  )
}
