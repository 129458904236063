import { checkNonNullable } from '@resnet/client-common/common/utils/nullable/non-nullable'

import { checkIsSpecialValue } from '../utils/check-is-special-value'

export const validateTextRequired = (value: undefined | null | string) => {
  if (checkIsSpecialValue(value)) {
    return true
  }

  if (!checkNonNullable(value) || value.trim().length === 0) {
    return 'This field is required'
  }

  return true
}
