import { Box } from '@mui/material'

import type { ForwardableT } from '@resnet/client-common/react/types/forwardable'
import { renderForwardable } from '@resnet/client-common/react/utils/render-forwardable'

import XmarkSolidIcon from '@resnet/client-shared/assets/icons/xmark-solid.svg'
import { typographyPresets } from '@resnet/client-shared/shared/gdl/constants/typography-presets'

import { themeColors } from '@resnet/client-shared-web/shared/gdl/constants/theme-colors'
import { mapTypographyPresetToSx } from '@resnet/client-shared-web/shared/gdl/utils/map-typography-preset-to-sx'
import { toPx } from '@resnet/client-shared-web/shared/gdl/utils/to-px'

import { Button } from '../button'

export const AsideHeader = ({
  onClose,
  children,
}: {
  onClose?: () => void
  children?: {
    icon?: ForwardableT<React.SVGProps<SVGSVGElement>>
    subtitle?: React.ReactNode
    title?: React.ReactNode
  }
}): React.ReactElement => {
  const renderTitle = () => {
    if (!children?.title) {
      return null
    }

    const renderIcon = () => {
      if (!children.icon) {
        return null
      }

      return renderForwardable(children.icon, { height: 32, width: 32 })
    }

    const renderText = () => {
      return (
        <Box
          sx={[
            mapTypographyPresetToSx(typographyPresets.headlineMedium),
            {
              color: themeColors.overBackgroundBold,
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
            },
          ]}
        >
          {children.title}
        </Box>
      )
    }

    return (
      <Box sx={{ alignItems: 'center', display: 'flex', gap: toPx(8) }}>
        {renderIcon()}
        {renderText()}
      </Box>
    )
  }

  const renderSubtitle = () => {
    if (!children?.subtitle) {
      return null
    }

    return (
      <Box
        sx={[
          mapTypographyPresetToSx(typographyPresets.labelSmall),
          {
            color: themeColors.overBackgroundBold,
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
          },
        ]}
      >
        {children.subtitle}
      </Box>
    )
  }

  const renderLeft = () => {
    if (!children?.title) {
      return null
    }

    return (
      <Box sx={{ display: 'flex', flexDirection: 'column', flexGrow: 1, gap: toPx(2), width: 0 }}>
        {renderTitle()}
        {renderSubtitle()}
      </Box>
    )
  }

  const renderRight = () => {
    if (!onClose) {
      return null
    }

    return (
      <Box sx={{ alignItems: 'center', display: 'flex', ml: 'auto' }}>
        <Button
          color="default"
          icon={<XmarkSolidIcon />}
          size="sm"
          variant="contained"
          onClick={onClose}
        />
      </Box>
    )
  }

  return (
    <Box sx={{ alignItems: 'center', display: 'flex', gap: toPx(8) }}>
      {renderLeft()}
      {renderRight()}
    </Box>
  )
}
