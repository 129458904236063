import { checkNonNullable } from '@resnet/client-common/common/utils/nullable/non-nullable'

import type { SiteFragmentT } from '@resnet/client-api/api'

export const mapSiteToCoordinates = ({
  site,
}: {
  site: Pick<SiteFragmentT, 'location'>
}): undefined | null | [number, number] => {
  const location = site.location

  if (!location) {
    return undefined
  }

  const [lon, lat] = location.coordinates

  if (!checkNonNullable(lon) || !checkNonNullable(lat)) {
    return undefined
  }

  return [lon, lat]
}
