import { Box, Tooltip } from '@mui/material'

import { ErrorBoundary } from '@resnet/client-common/react/components/error-boundary'

import UserGroupSolidIcon from '@resnet/client-shared/assets/icons/user-group-solid.svg'
import { EntityOptionContainer } from '@resnet/client-shared/shared/entities/hooks/use-entity-option'
import { mapEntityToMedia } from '@resnet/client-shared/shared/entities/utils/map-entity-to-media'
import { mapEntityToPathname } from '@resnet/client-shared/shared/entities/utils/map-entity-to-pathname'
import { mapEntityToTitle } from '@resnet/client-shared/shared/entities/utils/map-entity-to-title'
import { typographyPresets } from '@resnet/client-shared/shared/gdl/constants/typography-presets'
import type { MediaT } from '@resnet/client-shared/shared/gdl/types/media'
import { GroupOptionContainer } from '@resnet/client-shared/shared/groups/hooks/use-group-option'
import { mapGroupToPathname } from '@resnet/client-shared/shared/groups/utils/map-group-to-pathname'
import { mapGroupToTitle } from '@resnet/client-shared/shared/groups/utils/map-group-to-title'
import { UserOptionContainer } from '@resnet/client-shared/shared/users/hooks/use-user-option'
import { mapUserToMedia } from '@resnet/client-shared/shared/users/utils/map-user-to-media-boston'
import { mapUserToTitle } from '@resnet/client-shared/shared/users/utils/map-user-to-title'

import { CircularSkeleton } from '@resnet/client-shared-web/shared/async/components/circular-skeleton'
import { TextSkeleton } from '@resnet/client-shared-web/shared/async/components/text-skeleton'
import { Link } from '@resnet/client-shared-web/shared/gdl/components/link'
import { ListItemAvatar } from '@resnet/client-shared-web/shared/gdl/components/list'
import { themeColors } from '@resnet/client-shared-web/shared/gdl/constants/theme-colors'
import { disableableSx } from '@resnet/client-shared-web/shared/gdl/sx-presets/disableable'
import { mapTypographyPresetToSx } from '@resnet/client-shared-web/shared/gdl/utils/map-typography-preset-to-sx'
import { toPx } from '@resnet/client-shared-web/shared/gdl/utils/to-px'

import { mapUserToPathname } from '@resnet/client-web/shared/users/utils/map-user-to-pathname'

enum MENTION_TYPE {
  entity = 'entity',
  group = 'group',
  user = 'user',
}

const MentionFallbackWrapper = ({ children }: { children: React.ReactElement }) => {
  return (
    <Tooltip title="This mention can not be found">
      <Box
        component="span"
        sx={[
          disableableSx({ disabled: true }),
          {
            cursor: 'not-allowed',
          },
        ]}
      >
        {children}
      </Box>
    </Tooltip>
  )
}

const MentionLink = ({
  mentionType,
  mentionId,
  children,
}: {
  mentionType: string
  mentionId: string
  children: React.ReactElement
}) => {
  const renderLoading = () => {
    return children
  }

  const renderSuccess = ({ pathname }: { pathname: string }) => {
    return <Link to={pathname}>{children}</Link>
  }

  switch (mentionType) {
    case MENTION_TYPE.entity: {
      return (
        <EntityOptionContainer
          id={mentionId}
          key={mentionId}
        >
          {({ optionQuery: entityQuery }) => {
            if (!entityQuery.isSuccess) {
              return renderLoading()
            }

            const pathname = mapEntityToPathname(entityQuery.data)

            return renderSuccess({ pathname })
          }}
        </EntityOptionContainer>
      )
    }
    case MENTION_TYPE.group: {
      return (
        <GroupOptionContainer
          id={mentionId}
          key={mentionId}
        >
          {({ optionQuery: groupQuery }) => {
            if (!groupQuery.isSuccess) {
              return renderLoading()
            }

            const pathname = mapGroupToPathname(groupQuery.data)

            return renderSuccess({ pathname })
          }}
        </GroupOptionContainer>
      )
    }
    case MENTION_TYPE.user: {
      return (
        <UserOptionContainer
          id={mentionId}
          key={mentionId}
        >
          {({ optionQuery: userQuery }) => {
            if (!userQuery.isSuccess) {
              return renderLoading()
            }

            const pathname = mapUserToPathname(userQuery.data)

            return renderSuccess({ pathname })
          }}
        </UserOptionContainer>
      )
    }
    default: {
      throw new Error('Unknown mention type')
    }
  }
}

export const Mention = ({
  mentionType,
  mentionId,
  children,
}: {
  mentionType: string
  mentionId: string
  children: React.ReactElement
}) => {
  const renderTitle = () => {
    const renderLoading = () => {
      return (
        <Box sx={{ alignItems: 'center', display: 'flex', gap: toPx(8) }}>
          <CircularSkeleton size={32} />
          <TextSkeleton
            contentSx={{ width: toPx(100) }}
            typographyPreset={typographyPresets.bodySmall}
          />
        </Box>
      )
    }

    const renderSuccess = ({ media, name }: { media: MediaT; name: string }) => {
      return (
        <Box sx={{ alignItems: 'center', display: 'flex', gap: toPx(8) }}>
          <ListItemAvatar {...media} />
          <Box
            sx={[mapTypographyPresetToSx(typographyPresets.bodySmall), { color: themeColors.overBackgroundDefault }]}
          >
            {name}
          </Box>
        </Box>
      )
    }

    switch (mentionType) {
      case MENTION_TYPE.entity: {
        return (
          <EntityOptionContainer
            id={mentionId}
            key={mentionId}
          >
            {({ optionQuery: entityQuery }) => {
              if (!entityQuery.isSuccess) {
                return renderLoading()
              }

              const entity = entityQuery.data

              return renderSuccess({
                media: mapEntityToMedia(entity),
                name: mapEntityToTitle(entity),
              })
            }}
          </EntityOptionContainer>
        )
      }
      case MENTION_TYPE.group: {
        return (
          <GroupOptionContainer
            id={mentionId}
            key={mentionId}
          >
            {({ optionQuery: groupQuery }) => {
              if (!groupQuery.isSuccess) {
                return renderLoading()
              }

              const group = groupQuery.data

              return renderSuccess({
                media: { icon: <UserGroupSolidIcon />, type: 'icon' },
                name: mapGroupToTitle(group),
              })
            }}
          </GroupOptionContainer>
        )
      }
      case MENTION_TYPE.user: {
        return (
          <UserOptionContainer
            id={mentionId}
            key={mentionId}
          >
            {({ optionQuery: userQuery }) => {
              if (!userQuery.isSuccess) {
                return renderLoading()
              }

              const user = userQuery.data

              return renderSuccess({
                media: mapUserToMedia(user),
                name: mapUserToTitle(user),
              })
            }}
          </UserOptionContainer>
        )
      }
      default: {
        throw new Error('Unknown mention type')
      }
    }
  }

  return (
    <ErrorBoundary fallback={() => <MentionFallbackWrapper>{children}</MentionFallbackWrapper>}>
      <Tooltip
        arrow
        componentsProps={{
          arrow: {
            sx: {
              '&::before': {
                backgroundColor: themeColors.surfaceNeutralDefault,
                borderColor: themeColors.borderDefault,
                borderStyle: 'solid',
                borderWidth: toPx(1),
              },
              fontSize: toPx(24),
            },
          },
          tooltip: {
            sx: {
              backgroundColor: themeColors.surfaceNeutralDefault,
              borderColor: themeColors.borderDefault,
              borderStyle: 'solid',
              borderWidth: toPx(1),
              padding: toPx(8 - 1),
            },
          },
        }}
        placement="bottom-start"
        title={renderTitle()}
      >
        <Box component="span">
          <MentionLink
            mentionId={mentionId}
            mentionType={mentionType}
          >
            {children}
          </MentionLink>
        </Box>
      </Tooltip>
    </ErrorBoundary>
  )
}
