import { uniqBy } from 'ramda'

import { pipeline } from '@resnet/client-common/common/utils/function/pipeline'
import { checkNonNullable } from '@resnet/client-common/common/utils/nullable/non-nullable'

import type { DraftResourceT } from '../../files/types/draft-resource'
import type { ResourceT } from '../../files/types/resource'
import { checkIsDraftResource, checkIsNotDraftResource } from '../../files/utils/check-is-draft-resource'

export const diffUpdateResources = <D extends DraftResourceT>(
  resourcesActual: null | undefined | ResourceT<D>[],
  nextResourcesActual: null | undefined | ResourceT<D>[],
) => {
  const resources = resourcesActual?.filter(checkNonNullable) ?? []

  const nextResources = nextResourcesActual?.filter(checkNonNullable) ?? []

  const nextResourcesSet = new Set(nextResources.map((item) => item.id))

  const resourcesToDelete = resources.filter(checkIsNotDraftResource).filter((item) => !nextResourcesSet.has(item.id))

  const resourcesToUpload = pipeline(
    [...resources, ...nextResources],
    (x) => x.filter(checkIsDraftResource),
    (x) => uniqBy((item) => item.id, x),
  )

  return {
    resourcesToDelete,
    resourcesToUpload,
  }
}
