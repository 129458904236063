import { produce } from 'immer'

import { assertedNonNullable } from '@resnet/client-common/common/utils/nullable/non-nullable'

import {
  useArchiveImportMutation,
  useCloneImportMutation,
  useCreateImportMutation,
  useDeleteImportMutation,
  useDeleteResourceMutation,
  useGetImportQuery,
  useInfiniteListImportsQuery,
  useSetImportMappingFieldMutation,
  useStartImportMutation,
  useUnarchiveImportMutation,
  useUpdateImportMutation,
  useUploadImportFileMutation,
} from '@resnet/client-api/api'
import type { MutationCacheHandlersT } from '@resnet/client-api/services/query-client/types/mutation-cache-handlers'
import { createMutationHookMutationCacheHandlers } from '@resnet/client-api/services/query-client/utils/create-mutation-hook-mutation-cache-handlers'
import { findGetQueriesById, findInfiniteQueries, findQueries } from '@resnet/client-api/utils/find-queries'
import { invalidateQuery } from '@resnet/client-api/utils/invalidate-query'
import { setQueryData } from '@resnet/client-api/utils/set-query-data'
import type { QueryClient } from '@resnet/client-api/vendors/react-query'

import { useClearImportMappingsMutation, useGetImportMetricsQuery } from '../../codegen/graphql'

export const getImportQueriesHandlers = ({ queryClient }: { queryClient: QueryClient }): MutationCacheHandlersT[] => {
  const findImportQueriesById = ({ id }: { id: string }) => {
    return findGetQueriesById(queryClient, useGetImportQuery, (data) => data.getImport, { id })
  }

  const findImportQueriesByResourceId = ({ id }: { id: string }) => {
    return findQueries(queryClient, useGetImportQuery, (query) => {
      const { __typename, ...files } = query.state.data?.getImport.files ?? {}

      return Object.values(files).some((file) => file && file.resourceId === id)
    })
  }

  const updateImportHandlers = () => {
    return createMutationHookMutationCacheHandlers(useUpdateImportMutation, {
      onError: (error, { id }) => {
        findImportQueriesById({ id }).forEach(invalidateQuery(queryClient))
      },
      onMutate: ({ id, data }) => {
        findImportQueriesById({ id }).forEach((query) => {
          setQueryData(queryClient)(query)(
            produce((draft) => {
              const importObject = draft?.getImport

              if (!importObject) {
                return
              }

              Object.assign(importObject, data)
            }),
          )
        })
      },
      onSuccess: (data, { id }) => {
        findImportQueriesById({ id }).forEach((query) => {
          setQueryData(queryClient)(query)(() => ({
            getImport: data.updateImport,
          }))
        })
      },
    })
  }

  const setImportMappingFieldHandlers = () => {
    return createMutationHookMutationCacheHandlers(useSetImportMappingFieldMutation, {
      onError: (error, { id }) => {
        findImportQueriesById({ id }).forEach(invalidateQuery(queryClient))
      },
      onMutate: ({ id, entityTypeId, data }) => {
        findImportQueriesById({ id }).forEach((query) => {
          setQueryData(queryClient)(query)(
            produce((draft) => {
              const importObject = draft?.getImport

              if (!importObject) {
                return
              }

              const mappings = importObject.mappings ?? {}

              importObject.mappings = mappings

              const entityMappings = mappings[entityTypeId] ?? []

              mappings[entityTypeId] = entityMappings

              const currentMappingIndex = entityMappings.findIndex((item) => item.name === data.name)

              if (currentMappingIndex >= 0) {
                entityMappings.splice(currentMappingIndex, 1)
              }

              entityMappings.push(data)
            }),
          )
        })
      },
      onSuccess: (data, { id }) => {
        findImportQueriesById({ id }).forEach(invalidateQuery(queryClient))
      },
    })
  }

  const clearImportHandlers = () => {
    return createMutationHookMutationCacheHandlers(useClearImportMappingsMutation, {
      onError: (error, { id }) => {
        findImportQueriesById({ id }).forEach(invalidateQuery(queryClient))
      },
      onMutate: ({ id, entityTypeId }) => {
        findImportQueriesById({ id }).forEach((query) => {
          setQueryData(queryClient)(query)(
            produce((draft) => {
              const importObject = draft?.getImport

              if (!importObject) {
                return
              }

              const mappings = importObject.mappings

              if (!mappings) {
                return
              }

              mappings[entityTypeId] = null
            }),
          )
        })
      },
      onSuccess: (data, { id }) => {
        findImportQueriesById({ id }).forEach(invalidateQuery(queryClient))
      },
    })
  }

  const startImportHandlers = () => {
    return createMutationHookMutationCacheHandlers(useStartImportMutation, {
      onError: (error, { id }) => {
        findImportQueriesById({ id }).forEach(invalidateQuery(queryClient))
      },
      onSuccess: (data, { id }) => {
        findImportQueriesById({ id }).forEach((query) => {
          setQueryData(queryClient)(query)(() => ({
            getImport: data.startImport,
          }))
        })
      },
    })
  }

  const deleteImportHandlers = () => {
    return createMutationHookMutationCacheHandlers(useDeleteImportMutation, {
      onSuccess: (data, { id }) => {
        findImportQueriesById({ id }).forEach(invalidateQuery(queryClient))
      },
    })
  }

  const archiveImportHandlers = () => {
    return createMutationHookMutationCacheHandlers(useArchiveImportMutation, {
      onSuccess: (data, { id }) => {
        findImportQueriesById({ id }).forEach(invalidateQuery(queryClient))
      },
    })
  }

  const unarchiveImportHandlers = () => {
    return createMutationHookMutationCacheHandlers(useUnarchiveImportMutation, {
      onSuccess: (data, { id }) => {
        findImportQueriesById({ id }).forEach(invalidateQuery(queryClient))
      },
    })
  }

  const uploadImportFileHandlers = () => {
    return createMutationHookMutationCacheHandlers(useUploadImportFileMutation, {
      onSuccess: (data, { importId }) => {
        findImportQueriesById({ id: importId }).forEach(invalidateQuery(queryClient))
      },
    })
  }

  const deleteResourceHandlers = () => {
    return createMutationHookMutationCacheHandlers(useDeleteResourceMutation, {
      onSuccess: (data, { id }) => {
        findImportQueriesByResourceId({ id }).forEach(invalidateQuery(queryClient))
      },
    })
  }

  return [
    updateImportHandlers(),
    setImportMappingFieldHandlers(),
    clearImportHandlers(),
    startImportHandlers(),
    deleteImportHandlers(),
    archiveImportHandlers(),
    unarchiveImportHandlers(),
    uploadImportFileHandlers(),
    deleteResourceHandlers(),
  ]
}

export const getImportMetricsQueriesHandlers = ({
  queryClient,
}: {
  queryClient: QueryClient
}): MutationCacheHandlersT[] => {
  const findImportQueriesById = ({ id }: { id: string }) => {
    return findQueries(queryClient, useGetImportMetricsQuery, (query) => {
      const { id: importId } = query.queryKey[1]

      return importId === id
    })
  }

  const updateImportHandlers = () => {
    return createMutationHookMutationCacheHandlers(useUpdateImportMutation, {
      onSuccess: (data, { id }) => {
        findImportQueriesById({ id }).forEach(invalidateQuery(queryClient))
      },
    })
  }

  return [updateImportHandlers()]
}

export const importsQueriesHandlers = ({ queryClient }: { queryClient: QueryClient }): MutationCacheHandlersT[] => {
  const findInfiniteImportsQueries = () => findInfiniteQueries(queryClient, useInfiniteListImportsQuery)

  const findInfiniteImportsQueriesById = ({ id }: { id: string }) =>
    findInfiniteQueries(queryClient, useInfiniteListImportsQuery, (query) =>
      (query.state.data?.pages ?? []).some((page) =>
        (page.listImports?.items ?? []).some((importObject) => importObject.id === id),
      ),
    )

  const createImportHandlers = () => {
    return createMutationHookMutationCacheHandlers(useCreateImportMutation, {
      onSuccess: () => {
        findInfiniteImportsQueries().forEach(invalidateQuery(queryClient))
      },
    })
  }

  const cloneImportHandlers = () => {
    return createMutationHookMutationCacheHandlers(useCloneImportMutation, {
      onSuccess: () => {
        findInfiniteImportsQueries().forEach(invalidateQuery(queryClient))
      },
    })
  }

  const updateImportHandlers = () => {
    return createMutationHookMutationCacheHandlers(useUpdateImportMutation, {
      onError: (error, { id }) => {
        findInfiniteImportsQueriesById({ id }).forEach(invalidateQuery(queryClient))
      },
      onMutate: ({ id, data }) => {
        findInfiniteImportsQueriesById({ id }).forEach((query) => {
          setQueryData(queryClient)(query)(
            produce((draft) => {
              const items = draft?.pages.flatMap((page) => assertedNonNullable(page.listImports?.items))

              if (!items) {
                return
              }

              const importObject = items.find((item) => item.id === id)

              if (!importObject) {
                return
              }

              Object.assign(importObject, data)
            }),
          )
        })
      },
      onSuccess: (data, { id }) => {
        findInfiniteImportsQueriesById({ id }).forEach(invalidateQuery(queryClient))
      },
    })
  }

  const deleteImportHandlers = () => {
    return createMutationHookMutationCacheHandlers(useDeleteImportMutation, {
      onError: (error, { id }) => {
        findInfiniteImportsQueriesById({ id }).forEach(invalidateQuery(queryClient))
      },
      onMutate: ({ id }) => {
        findInfiniteImportsQueriesById({ id }).forEach((query) => {
          setQueryData(queryClient)(query)(
            produce((draft) => {
              draft?.pages?.forEach((page) => {
                const items = page.listImports?.items

                if (!items) {
                  return
                }

                const importIndex = items.findIndex((item) => item.id === id)

                if (importIndex < 0) {
                  return
                }

                items.splice(importIndex, 1)
              })
            }),
          )
        })
      },
      onSuccess: (data, { id }) => {
        findInfiniteImportsQueriesById({ id }).forEach(invalidateQuery(queryClient))
      },
    })
  }

  const archiveImportHandlers = () => {
    return createMutationHookMutationCacheHandlers(useArchiveImportMutation, {
      onSuccess: (data, { id }) => {
        findInfiniteImportsQueriesById({ id }).forEach(invalidateQuery(queryClient))
      },
    })
  }

  const unarchiveImportHandlers = () => {
    return createMutationHookMutationCacheHandlers(useUnarchiveImportMutation, {
      onSuccess: (data, { id }) => {
        findInfiniteImportsQueriesById({ id }).forEach(invalidateQuery(queryClient))
      },
    })
  }

  return [
    createImportHandlers(),
    cloneImportHandlers(),
    updateImportHandlers(),
    deleteImportHandlers(),
    archiveImportHandlers(),
    unarchiveImportHandlers(),
  ]
}
