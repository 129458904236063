import { Box } from '@mui/material'

import { themeColors } from '@resnet/client-shared-web/shared/gdl/constants/theme-colors'

import { selectDropdownSizesConfig } from '../constants/select-dropdown-config'

export const SelectDropdownMessagePanel = ({ children }: { children: React.ReactNode }): React.ReactElement => {
  return (
    <Box
      sx={{
        color: themeColors.overBackgroundFaded,
        display: 'flex',
        justifyContent: 'center',
        padding: `${selectDropdownSizesConfig.listPadding}px`,
        width: '100%',
      }}
    >
      {children}
    </Box>
  )
}
