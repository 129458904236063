import type { BoxProps } from '@mui/material'
import { Box } from '@mui/material'
import type { LinkProps } from 'react-router-dom'
import { Link } from 'react-router-dom'

import type { MergeAllT } from '@resnet/client-common/typescript/types/merge-all'

import { typographyPresets } from '@resnet/client-shared/shared/gdl/constants/typography-presets'

import { themeColors } from '@resnet/client-shared-web/shared/gdl/constants/theme-colors'
import { mapTypographyPresetToSx } from '@resnet/client-shared-web/shared/gdl/utils/map-typography-preset-to-sx'

export type RegularCellPropsT = MergeAllT<
  [
    Omit<BoxProps, 'ref'>,
    {
      isMarkedAsDeleted?: undefined | null | boolean
      to?: LinkProps['to']
    },
  ]
>

export const RegularCell = ({ to, children, sx = null, isMarkedAsDeleted, ...props }: RegularCellPropsT) => {
  const renderLink = () => {
    if (!to) {
      return null
    }

    return (
      <Box
        component={Link}
        sx={{ inset: 0, position: 'absolute', zIndex: 0 }}
        to={to}
      />
    )
  }

  return (
    <Box
      {...props}
      sx={[
        mapTypographyPresetToSx(typographyPresets.bodySmall),
        {
          alignItems: 'center',
          color: themeColors.overBackgroundDefault,
          display: 'flex',
          flexGrow: 1,
          minWidth: 0,
          position: 'relative',
        },
        !isMarkedAsDeleted
          ? null
          : {
              textDecoration: 'line-through',
            },
        sx,
      ].flat()}
    >
      {children}
      {renderLink()}
    </Box>
  )
}
