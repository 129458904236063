import { idToKey } from '@resnet/client-common/common/utils/object/prop-to-key'

export enum FieldTypeIdT {
  Asset = 'Asset',
  Battery = 'Battery',
  Checkbox = 'Checkbox',
  Date = 'Date',
  DateTime = 'DateTime',
  Dropdown = 'Dropdown',
  Float = 'Float',
  Integer = 'Integer',
  Site = 'Site',
  Text = 'Text',
}

export const fieldTypes = [
  {
    hasConnections: false,
    id: FieldTypeIdT.Text as const,
    name: 'Text',
    supportsCustomFields: true,
  },
  {
    hasConnections: false,
    id: FieldTypeIdT.Integer as const,
    name: 'Integer',
    supportsCustomFields: true,
  },
  {
    hasConnections: false,
    id: FieldTypeIdT.Float as const,
    name: 'Float',
    supportsCustomFields: true,
  },
  {
    hasConnections: false,
    id: FieldTypeIdT.Checkbox as const,
    name: 'Checkbox',
    supportsCustomFields: true,
  },
  {
    hasConnections: false,
    id: FieldTypeIdT.Date as const,
    name: 'Date',
    supportsCustomFields: true,
  },
  {
    hasConnections: false,
    id: FieldTypeIdT.DateTime as const,
    name: 'Date & Time',
    supportsCustomFields: true,
  },
  {
    hasConnections: false,
    id: FieldTypeIdT.Dropdown as const,
    name: 'Dropdown',
    supportsCustomFields: true,
  },
  {
    hasConnections: true,
    id: FieldTypeIdT.Site as const,
    name: 'Site',
    supportsCustomFields: false,
  },
  {
    hasConnections: true,
    id: FieldTypeIdT.Battery as const,
    name: 'Battery',
    supportsCustomFields: false,
  },
  {
    hasConnections: true,
    id: FieldTypeIdT.Asset as const,
    name: 'Asset',
    supportsCustomFields: false,
  },
]

export const fieldTypeById = idToKey(fieldTypes)
