import { GraphQLClient } from 'graphql-request'

import { assertedNonNullable } from '@resnet/client-common/common/utils/nullable/non-nullable'

let graphQlClient: null | GraphQLClient = null

export const getGraphQlClient = (): GraphQLClient => {
  return assertedNonNullable(graphQlClient)
}

export const setGraphQlClient = (baseUrl: string): void => {
  graphQlClient = new GraphQLClient(baseUrl + '/graphql')
}
