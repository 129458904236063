import { Box } from '@mui/material'
import type { DragDropContextProps } from 'react-beautiful-dnd'
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd'

import { updateAt } from '@resnet/client-common/common/utils/array/update-at'
import { checkNonNullable } from '@resnet/client-common/common/utils/nullable/non-nullable'
import { uniqId } from '@resnet/client-common/common/utils/object/uniq-id'
import { update } from '@resnet/client-common/common/utils/object/update'
import { MemoContainer } from '@resnet/client-common/react/components/memo-container'
import { useEventCallback } from '@resnet/client-common/react/hooks/use-event-callback'

import type { ApprovalTemplatePresetFragmentT, ApproverT } from '@resnet/client-api/api'
import { IssueStatusT } from '@resnet/client-api/api'
import { ApproveStatusT } from '@resnet/client-api/api'

import DeleteSolidIcon from '@resnet/client-shared/assets/icons/delete-solid.svg'
import DragDotsSolidIcon from '@resnet/client-shared/assets/icons/drag-dots-solid.svg'
import PlusSolidIcon from '@resnet/client-shared/assets/icons/plus-solid.svg'
import { mapApprovalStatusToLabel } from '@resnet/client-shared/shared/issues/utils/map-approval-status-to-label'

import { Accordion } from '@resnet/client-shared-web/shared/gdl/components/accordion'
import { Button } from '@resnet/client-shared-web/shared/gdl/components/button'
import { themeColors } from '@resnet/client-shared-web/shared/gdl/constants/theme-colors'
import { toPx } from '@resnet/client-shared-web/shared/gdl/utils/to-px'

import { FieldTitle } from '@resnet/client-web/shared/form/components/field-title'

import { IssueApprovalLevel } from '../issue-approval-level'
import { IssueApprovalTag } from '../issue-approval-tag'
import { IssueApprovalTemplatePresetControls } from '../issue-approval-template-preset-controls'

import type { IssueApprovalLevelT } from './types'

export type CreateIssueApprovalPropsT = {
  disabled?: boolean
  error?: boolean
  issueStatus?: IssueStatusT
  onChange?: (value: IssueApprovalLevelT[]) => void
  value: IssueApprovalLevelT[]
  isPreview?: boolean
}

export const emptyApproval: IssueApprovalLevelT[] = [
  {
    approvers: [],
    id: uniqId(),
    isDraft: true,
    requiredApprovals: 1,
    status: ApproveStatusT.PendingT,
  },
]

const getUsersWhoApproved = (value?: null | ApproverT[]): ApproverT[] => {
  if (!value) {
    return []
  }

  return value.filter((approver) => approver.status === ApproveStatusT.ApprovedT)
}

export const IssueApproval = ({
  value,
  error,
  onChange,
  issueStatus,
  isPreview = false,
}: CreateIssueApprovalPropsT): React.ReactElement => {
  const invalidLevelIndex = error
    ? value.findIndex((item) => item.approvers && item.approvers.length < (item.requiredApprovals ?? 0))
    : -1

  const onSelectApprovers = useEventCallback((index: number, userIds: string[]) => {
    const nextValue = updateAt(value, index, (item) => {
      return update(item, 'approvers', () =>
        userIds
          .filter(checkNonNullable)
          .map((userId) => ({ id: userId, status: ApproveStatusT.PendingT, updatedAt: '' })),
      )
    })

    onChange?.(nextValue)
  })

  const onRemoveApprover = useEventCallback((index: number, userId: string) => {
    const nextValue = updateAt(value, index, (item) => {
      return update(item, 'approvers', (approvers) => approvers.filter((item) => item.id !== userId))
    })

    onChange?.(nextValue)
  })

  const onChangeApproversRequired = useEventCallback((index: number, requiredApprovals: number) => {
    const nextValue = updateAt(value, index, (item) => {
      return update(item, 'requiredApprovals', () => requiredApprovals)
    })

    onChange?.(nextValue)
  })

  const onAddApprovalLevel = useEventCallback(() => {
    if (value.length >= 5) {
      return
    }

    onChange?.(
      value.concat({
        approvers: [],
        id: uniqId(),
        isDraft: true,
        requiredApprovals: 1,
        status: ApproveStatusT.PendingT,
      }),
    )
  })

  const onRemoveApprovalLevel = useEventCallback((index: number) => {
    if (value.length < 2) {
      return
    }

    onChange?.(value.filter((_, itemIndex) => itemIndex !== index))
  })

  const onDragEnd: DragDropContextProps['onDragEnd'] = useEventCallback(({ source, destination }) => {
    if (!destination) {
      return
    }

    const nextValue = value.filter((_, index) => index !== source.index)

    onChange?.([...nextValue.slice(0, destination.index), value[source.index], ...nextValue.slice(destination.index)])
  })

  const onApplyApprovalTemplatePreset = (approvalTemplatePreset: ApprovalTemplatePresetFragmentT) => {
    const levels = approvalTemplatePreset.levels.filter((level) => level.approvers && level.approvers.length > 0)

    if (levels.length === 0) {
      return
    }

    onChange?.(
      levels.map(
        (level): IssueApprovalLevelT => ({
          approvers: (level.approvers as string[]).map((userId) => ({
            id: userId,
            status: ApproveStatusT.PendingT,
            updatedAt: '',
          })),
          id: uniqId(),
          isDraft: true,
          requiredApprovals: level.requiredApprovals ?? 1,
          status: ApproveStatusT.PendingT,
        }),
      ),
    )
  }

  const renderLevelContent = (index: number, level: IssueApprovalLevelT, isAwaiting: boolean): React.ReactNode => {
    return (
      <IssueApprovalLevel
        index={index}
        isAwaiting={isAwaiting}
        isPreview={isPreview}
        level={level}
        value={value}
        onChangeApproversRequired={onChangeApproversRequired}
        onRemoveApprover={onRemoveApprover}
        onSelectApprovers={onSelectApprovers}
      />
    )
  }

  const renderAccordion = (level: IssueApprovalLevelT, index: number) => {
    const isApprovedOrRejected = level.status === ApproveStatusT.ApprovedT || level.status === ApproveStatusT.RejectedT

    const isPendingLevel = index === 0 || value[index - 1].status === ApproveStatusT.ApprovedT

    const isAwaiting = !isApprovedOrRejected && (issueStatus !== IssueStatusT.InReviewT || !isPendingLevel)

    // NOTE this mimics 4th status which does not exists on BE, but is present in UI
    const levelApprovalStatusLabel = isAwaiting ? { name: 'Awaiting approval' } : mapApprovalStatusToLabel(level)

    return (
      <Accordion
        defaultIsOpened={!isPreview}
        hasError={invalidLevelIndex === index}
      >
        {{
          content: () => renderLevelContent(index, level, isAwaiting),
          header: {
            title: (
              <Box sx={{ alignItems: 'center', display: 'flex', gap: '16px' }}>
                {level.isDraft ? null : (
                  <IssueApprovalTag
                    {...levelApprovalStatusLabel}
                    size="sm"
                  />
                )}
                <Box>
                  {getUsersWhoApproved(level.approvers).length} / {level.requiredApprovals}
                </Box>
              </Box>
            ),
          },
        }}
      </Accordion>
    )
  }

  if (isPreview) {
    return (
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: '8px' }}>
        {value.map((item, index) => {
          return (
            <Box
              data-testid="approval-level-preview"
              key={item.id}
            >
              <FieldTitle>{`Approval level ${index + 1}`}</FieldTitle>
              {renderAccordion(item, index)}
            </Box>
          )
        })}
      </Box>
    )
  }

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: toPx(16) }}>
      <IssueApprovalTemplatePresetControls
        approvalLevels={value}
        onApplyApprovalTemplatePreset={onApplyApprovalTemplatePreset}
      />
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="any">
          {(droppable) => (
            <Box
              ref={droppable.innerRef}
              sx={{ marginTop: '-16px' }}
            >
              <MemoContainer deps={[value, invalidLevelIndex]}>
                {() =>
                  value.map((item, index) => {
                    return (
                      <Draggable
                        draggableId={item.id}
                        index={index}
                        key={item.id}
                      >
                        {(draggable) => (
                          <Box
                            {...draggable.draggableProps}
                            data-testid="approval-level"
                            ref={draggable.innerRef}
                            style={draggable.draggableProps.style}
                            sx={{ marginTop: '16px' }}
                          >
                            <Box sx={{ pl: '28px' }}>
                              <FieldTitle>{`Approval level ${index + 1}`}</FieldTitle>
                            </Box>
                            <Box
                              key={index}
                              sx={{ display: 'flex', gap: '8px' }}
                            >
                              <Box sx={{ alignItems: 'center', display: 'flex', height: '38px' }}>
                                <Box
                                  {...draggable.dragHandleProps}
                                  data-testid="move-approval-level"
                                >
                                  <DragDotsSolidIcon
                                    fill={themeColors.overBackgroundBold}
                                    height={20}
                                    width={20}
                                  />
                                </Box>
                              </Box>
                              <Box sx={{ flexGrow: 1, width: 0 }}>{renderAccordion(item, index)}</Box>
                              <Box sx={{ alignItems: 'center', height: '38px' }}>
                                <Button
                                  color="default"
                                  data-testid="delete-approval-level"
                                  disabled={value.length < 2}
                                  icon={<DeleteSolidIcon />}
                                  size="md"
                                  variant="contained"
                                  onClick={() => onRemoveApprovalLevel(index)}
                                />
                              </Box>
                            </Box>
                          </Box>
                        )}
                      </Draggable>
                    )
                  })}
              </MemoContainer>
              {droppable.placeholder}
            </Box>
          )}
        </Droppable>
        <Button
          color="primary"
          disabled={value.length >= 5}
          icon={<PlusSolidIcon />}
          size="md"
          sx={{ alignSelf: 'flex-start' }}
          variant="outlined"
          onClick={onAddApprovalLevel}
        >
          Add Approval Level
        </Button>
      </DragDropContext>
    </Box>
  )
}
