import type { BoxProps } from '@mui/material'
import { Box } from '@mui/material'

import type { MergeT } from '@resnet/client-common/typescript/types/merge'

import { toPx } from '@resnet/client-shared-web/shared/gdl/utils/to-px'

export type MessageCardHeaderLayoutPropsT = MergeT<
  BoxProps,
  {
    children: {
      avatar?: React.ReactNode
      meta?: React.ReactNode
      name?: React.ReactNode
      tag?: React.ReactNode
    }
  }
>

export const MessageCardHeaderLayout = ({ sx = null, children, ...props }: MessageCardHeaderLayoutPropsT) => {
  return (
    <Box
      {...props}
      sx={[
        {
          alignItems: 'center',
          display: 'flex',
          gap: toPx(8),
        },
        sx,
      ].flat()}
    >
      {children.avatar}
      <Box sx={{ display: 'flex', flexDirection: 'column', flexGrow: 1, width: 0 }}>
        <Box sx={{ display: 'flex', gap: toPx(8) }}>
          {children.name}
          {children.tag}
        </Box>
        {children.meta}
      </Box>
    </Box>
  )
}
