import { useState, useMemo } from 'react'

import { compareStringsBy } from '@resnet/client-common/common/utils/compare/compare-by'
import { idToKey } from '@resnet/client-common/common/utils/object/prop-to-key'
import type { MergeAllT } from '@resnet/client-common/typescript/types/merge-all'

import {
  SegmentedControl,
  SegmentedControlItem,
} from '@resnet/client-shared-web/shared/gdl/components/segmented-control'

export type WithCategoriesPropsT<
  QueryVariablesT extends unknown,
  CategoryOptionT extends { id: string; name: string },
> = {
  getCategoryOptions: () => CategoryOptionT[]
  Component: React.ComponentType<{
    withQueryVariables?: (input: QueryVariablesT) => QueryVariablesT
    dropdownHeader?: React.ReactNode
  }>
  withActiveCategory: (props: {
    activeCategoryId: CategoryOptionT['id']
  }) => (input: QueryVariablesT) => QueryVariablesT
}

export const withCategories = <QueryVariablesT extends unknown, CategoryOptionT extends { id: string; name: string }>({
  getCategoryOptions,
  Component,
  withActiveCategory,
}: WithCategoriesPropsT<QueryVariablesT, CategoryOptionT>) => {
  type WrapperPropsT = MergeAllT<[React.ComponentProps<typeof Component>, { categoryIds?: CategoryOptionT['id'][] }]>

  const Wrapper = ({ categoryIds: categoryIdsActual, ...props }: WrapperPropsT) => {
    const categoryOptions = getCategoryOptions()

    const categoryOptionsById = useMemo(() => idToKey(categoryOptions), [categoryOptions])

    const categoryIdsAll = useMemo(
      () => categoryOptions.map(({ id }) => id as CategoryOptionT['id']),
      [categoryOptions],
    )

    const categoryIds = categoryIdsActual ?? categoryIdsAll

    if (categoryIds.length === 0) {
      throw new Error('"categoryIds" should not be empty')
    }

    const [activeCategoryId, setActiveCategoryId] = useState(categoryIds[0])

    if (!categoryIds.includes(activeCategoryId)) {
      setActiveCategoryId(categoryIds[0])
    }

    const renderDropdownHeader = () => {
      if (categoryIds.length === 1) {
        return null
      }

      return (
        <SegmentedControl>
          {categoryIds
            .toSorted(compareStringsBy((categoryId) => categoryOptionsById[categoryId].name))
            .map((categoryId) => (
              <SegmentedControlItem
                checked={categoryId === activeCategoryId}
                key={categoryId}
                label={categoryOptionsById[categoryId].name}
                value={categoryId}
                onChange={() => {
                  setActiveCategoryId(categoryId)
                }}
              />
            ))}
        </SegmentedControl>
      )
    }

    const withQueryVariables = useMemo(() => withActiveCategory({ activeCategoryId }), [activeCategoryId])

    return (
      <Component
        {...props}
        dropdownHeader={renderDropdownHeader()}
        withQueryVariables={withQueryVariables}
      />
    )
  }

  return Wrapper
}
