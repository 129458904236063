import { identity } from 'ramda'
import { useMemo, useState } from 'react'

import { pipeline } from '@resnet/client-common/common/utils/function/pipeline'
import { transform } from '@resnet/client-common/common/utils/object/transform'
import { useDebounced } from '@resnet/client-common/react/hooks/use-debounced'
import type { MergeAllT } from '@resnet/client-common/typescript/types/merge-all'
import { toTuple } from '@resnet/client-common/typescript/utils/to-tuple'

import { defaultLimit } from '@resnet/client-api/constants/variables'
import type { InfiniteQueryHookT } from '@resnet/client-api/types/query-hook'

import { createGetNextPageParam } from '@resnet/client-shared/shared/common/factories/create-get-next-page-param'
import type { MediaT } from '@resnet/client-shared/shared/gdl/types/media'

import type { ListActionItemActionPropsT } from '@resnet/client-shared-web/shared/gdl/components/list'
import { useSearchHandlers } from '@resnet/client-shared-web/shared/gdl/components/search'
import type { AbstractOptionT } from '@resnet/client-shared-web/shared/gdl/types/abstract-option'

export type GenericQueryVariablesT = MergeAllT<
  [
    Record<string, unknown>,
    {
      limit?: undefined | null | number
      offset?: undefined | null | number
    },
  ]
>

export type UseGetOptionActionsReturnT<OptionT extends AbstractOptionT> = [
  undefined | ((option: OptionT) => ListActionItemActionPropsT[]),
  undefined | (() => React.ReactNode),
]

export type CreateUseSelectDropdownPropsT<QueryVariablesT, QueryDataT, OptionT extends AbstractOptionT> = {
  getOptionLabel?: (option: OptionT) => React.ReactNode
  getOptionMedia?: (option: OptionT) => MediaT
  limit?: number
  mapOptionsQueryDataToOptions: (data: QueryDataT) => OptionT[]
  useGetOptionActions?: () => UseGetOptionActionsReturnT<OptionT>
  useOptionsQuery: InfiniteQueryHookT<QueryVariablesT, QueryDataT>
  withSearch?: (input: QueryVariablesT, props: { search: string }) => QueryVariablesT
}

export type UseSelectDropdownPropsT<QueryVariablesT> = {
  exclude?: string[]
  withQueryVariables?: (input: QueryVariablesT) => QueryVariablesT
}

export const useGetOptionActionsDefault = () => toTuple([undefined, undefined])

export const createUseSelectDropdown = <QueryVariablesT, QueryDataT, OptionT extends AbstractOptionT>({
  getOptionLabel,
  getOptionMedia,
  limit = defaultLimit,
  mapOptionsQueryDataToOptions,
  useGetOptionActions = useGetOptionActionsDefault,
  useOptionsQuery,
  withSearch: withSearchActual = identity,
}: CreateUseSelectDropdownPropsT<QueryVariablesT, QueryDataT, OptionT>) => {
  const getNextPageParam = createGetNextPageParam<QueryDataT>(
    limit,
    (data) => mapOptionsQueryDataToOptions(data).length,
  )

  const useSelectDropdown = ({
    exclude,
    withQueryVariables = identity,
  }: UseSelectDropdownPropsT<QueryVariablesT> = {}): [typeof result, React.ReactNode] => {
    const [searchActual, setSearch] = useState('')

    const { onChange: onSearchChange, onClear: onSearchClear } = useSearchHandlers({ setSearch })

    const search = useDebounced(searchActual)

    const [getOptionActions, renderOptionActionsElements] = useGetOptionActions()

    const variables = useMemo(() => {
      const withSearch = (input: QueryVariablesT) => {
        if (search.length === 0) {
          return input
        }

        return withSearchActual(input, { search })
      }

      const withLimit = (input: QueryVariablesT) => {
        return transform(input as GenericQueryVariablesT, {
          limit: () => limit,
        }) as QueryVariablesT
      }

      return pipeline({} as QueryVariablesT, withSearch, withLimit, withQueryVariables)
    }, [search, withQueryVariables])

    const optionsQuery = useOptionsQuery<QueryDataT>(variables, {
      getNextPageParam,
    })

    const options = useMemo(
      () =>
        pipeline(
          optionsQuery.data,
          (data) => (!data ? [] : data.pages),
          (pages) => pages.flatMap(mapOptionsQueryDataToOptions),
          (options) => (!exclude ? options : options.filter((option) => !exclude.includes(option.id))),
        ),
      [exclude, optionsQuery.data],
    )

    const bottomBoundary = useMemo(() => {
      if (optionsQuery.isFetchingNextPage || !optionsQuery.hasNextPage) {
        return undefined
      }

      return { onObserve: optionsQuery.fetchNextPage }
    }, [optionsQuery.hasNextPage, optionsQuery.isFetchingNextPage, optionsQuery.fetchNextPage])

    const dropdownProps = {
      bottomBoundary,
      getOptionActions,
      getOptionLabel,
      getOptionMedia,
      isLoading: optionsQuery.isFetching,
      options,
    }

    const searchProps = {
      onChange: onSearchChange,
      onClear: onSearchClear,
      value: searchActual,
    }

    const result = {
      dropdownProps,
      searchProps,
    }

    return [result, renderOptionActionsElements?.()]
  }

  return useSelectDropdown
}
