import type { BoxProps } from '@mui/material'
import { Box } from '@mui/material'

import type { MergeT } from '@resnet/client-common/typescript/types/merge'
import { assert } from '@resnet/client-common/typescript/utils/assert'

import type { ResourceFragmentT } from '@resnet/client-api/api'

import PlaySolidIcon from '@resnet/client-shared/assets/icons/play-solid.svg'
import { checkIsMediaType } from '@resnet/client-shared/shared/resources/utils/check-is-media-type'
import { mapMimetypeToType } from '@resnet/client-shared/shared/resources/utils/map-mimetype-to-type'

import { RectangularSkeleton } from '@resnet/client-shared-web/shared/async/components/rectangular-skeleton'
import type { ClickableOpacityPropsT } from '@resnet/client-shared-web/shared/gdl/components/clickable-opacity'
import { ClickableOpacity } from '@resnet/client-shared-web/shared/gdl/components/clickable-opacity'
import { themeColors } from '@resnet/client-shared-web/shared/gdl/constants/theme-colors'
import { toPx } from '@resnet/client-shared-web/shared/gdl/utils/to-px'

import { ImageResource } from '../image-resource'
import { VideoResource } from '../video-resource'

const thumbnailSx = {
  alignItems: 'center',
  aspectRatio: '1',
  backgroundColor: themeColors.surfaceVariantDefault,
  borderRadius: toPx(4),
  display: 'flex',
  justifyContent: 'center',
  overflow: 'hidden',
  position: 'relative',
  width: '100%',
}

const thumbnailIconSize = 24

const thumbnailIconProps: React.ComponentProps<SVGComponentT> = {
  fill: themeColors.basePrimary,
  height: thumbnailIconSize,
  width: thumbnailIconSize,
}

export type MediaResourceThumbnailPropsT = MergeT<
  ClickableOpacityPropsT,
  {
    resource: Pick<ResourceFragmentT, 'id' | 'mimetype' | 'filename' | 'url'>
  }
>

export const MediaResourceThumbnail = ({ resource, sx = null, ...props }: MediaResourceThumbnailPropsT) => {
  const type = mapMimetypeToType(resource.mimetype)

  assert(type, checkIsMediaType)

  const renderThumbnailMedia = () => {
    switch (type) {
      case 'image': {
        return (
          <ImageResource
            resource={resource}
            sx={{ height: '100%', objectFit: 'cover', width: '100%' }}
          />
        )
      }
      case 'video': {
        return (
          <Box sx={{ height: '100%', width: '100%' }}>
            <VideoResource
              resource={resource}
              sx={{ height: '100%', width: '100%' }}
            />
            <Box
              sx={{
                alignItems: 'center',
                backgroundColor: themeColors.surfaceVariantDefault,
                display: 'flex',
                inset: 0,
                justifyContent: 'center',
                position: 'absolute',
              }}
            >
              <PlaySolidIcon {...thumbnailIconProps} />
            </Box>
          </Box>
        )
      }
    }
  }

  return (
    <ClickableOpacity
      {...props}
      sx={[thumbnailSx, sx].flat()}
    >
      {renderThumbnailMedia()}
    </ClickableOpacity>
  )
}

export type MediaResourceThumbnailSkeletonPropsT = BoxProps

export const MediaResourceThumbnailSkeleton = ({ sx = null, ...props }: MediaResourceThumbnailSkeletonPropsT) => {
  const renderThumbnailMedia = () => {
    return (
      <RectangularSkeleton
        sx={{
          height: '100%',
          width: '100%',
        }}
      />
    )
  }

  return (
    <Box
      {...props}
      sx={[thumbnailSx, sx].flat()}
    >
      {renderThumbnailMedia()}
    </Box>
  )
}
