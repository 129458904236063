import { useEffect } from 'react'
import type { FieldValues, Path, UseFormReturn } from 'react-hook-form'

import type { AnyTypeT } from '@resnet/client-common/typescript/types/any-type'

export const useRestoreFormDirtyState = <TFieldValues extends FieldValues>(
  form: UseFormReturn<TFieldValues>,
  restoredValues: null | TFieldValues,
) => {
  useEffect(() => {
    if (!restoredValues) {
      return
    }

    Object.keys(restoredValues).forEach((key) => {
      const path = key as Path<TFieldValues>

      const value = restoredValues[key] as AnyTypeT

      form.setValue(path, value, { shouldDirty: true })
    })
  }, [form, restoredValues])
}
