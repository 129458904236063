import { useMemo } from 'react'

import { createHookContainer } from '@resnet/client-common/react/utils/create-hook-container'

import type { AbstractOptionT } from '../types/abstract-option'

const emptyArray: never[] = []

export const createStaticOptionsSelectDropdownContainer = <OptionT extends AbstractOptionT>({
  options: optionsActual,
}: {
  options: OptionT[]
}) => {
  const OptionContainer = createHookContainer(({ exclude = emptyArray }: { exclude?: OptionT['id'][] }) => {
    const options = useMemo(() => {
      return optionsActual.filter((option) => {
        return !exclude.includes(option.id)
      })
    }, [exclude])

    const dropdownProps = useMemo(() => {
      return { options }
    }, [options])

    return {
      dropdownProps,
    }
  })

  return OptionContainer
}
