import type { QueryClient } from '@tanstack/react-query'
import { produce } from 'immer'

import {
  useGetCampaignTypeQuery,
  useDeleteCampaignTypeMutation,
  useInfiniteListCampaignTypesQuery,
  useCreateCampaignTypeMutation,
  useUpdateCampaignTypeMutation,
} from '@resnet/client-api/api'
import type { MutationCacheHandlersT } from '@resnet/client-api/services/query-client/types/mutation-cache-handlers'
import { createMutationHookMutationCacheHandlers } from '@resnet/client-api/services/query-client/utils/create-mutation-hook-mutation-cache-handlers'
import { findGetQueriesById, findInfiniteQueries } from '@resnet/client-api/utils/find-queries'
import { invalidateQuery } from '@resnet/client-api/utils/invalidate-query'
import { setQueryData } from '@resnet/client-api/utils/set-query-data'

export const campaignTypeQueriesHandlers = ({
  queryClient,
}: {
  queryClient: QueryClient
}): MutationCacheHandlersT[] => {
  const findCampaignTypeQueries = ({ id }: { id: string }) =>
    findGetQueriesById(queryClient, useGetCampaignTypeQuery, (data) => data.getCampaignType, { id })

  const updateCampaignTypeHandlers = () => {
    return createMutationHookMutationCacheHandlers(useUpdateCampaignTypeMutation, {
      onError: (error, { id }) => {
        findCampaignTypeQueries({ id }).forEach(invalidateQuery(queryClient))
      },
      onMutate: ({ id, data }) => {
        findCampaignTypeQueries({ id }).forEach((query) => {
          setQueryData(queryClient)(query)(
            produce((draft) => {
              const campaignType = draft?.getCampaignType

              if (!campaignType) {
                return
              }

              Object.assign(campaignType, data)
            }),
          )
        })
      },
      onSuccess: (data, { id }) => {
        findCampaignTypeQueries({ id }).forEach(invalidateQuery(queryClient))
      },
    })
  }

  const deleteCampaignTypeHandlers = () => {
    return createMutationHookMutationCacheHandlers(useDeleteCampaignTypeMutation, {
      onSuccess: (data, { id }) => {
        findCampaignTypeQueries({ id }).forEach(invalidateQuery(queryClient))
      },
    })
  }

  return [updateCampaignTypeHandlers(), deleteCampaignTypeHandlers()]
}

export const infiniteCampaignTypesQueriesHandlers = ({
  queryClient,
}: {
  queryClient: QueryClient
}): MutationCacheHandlersT[] => {
  const findInfiniteCampaignTypesQueries = () => findInfiniteQueries(queryClient, useInfiniteListCampaignTypesQuery)

  const createCampaignTypeHandlers = () => {
    return createMutationHookMutationCacheHandlers(useCreateCampaignTypeMutation, {
      onSuccess: () => {
        findInfiniteCampaignTypesQueries().forEach(invalidateQuery(queryClient))
      },
    })
  }

  const updateCampaignTypeMutation = () => {
    return createMutationHookMutationCacheHandlers(useUpdateCampaignTypeMutation, {
      onSuccess: () => {
        findInfiniteCampaignTypesQueries().forEach(invalidateQuery(queryClient))
      },
    })
  }

  const deleteCampaignTypeMutation = () => {
    return createMutationHookMutationCacheHandlers(useDeleteCampaignTypeMutation, {
      onSuccess: () => {
        findInfiniteCampaignTypesQueries().forEach(invalidateQuery(queryClient))
      },
    })
  }

  return [createCampaignTypeHandlers(), updateCampaignTypeMutation(), deleteCampaignTypeMutation()]
}
