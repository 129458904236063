import { identity } from 'ramda'

import { stringifySearchParams } from '@resnet/client-common/network/utils/stringify-search-params'

import type { UseMutationOptions, UseQueryOptions } from '@resnet/client-api/vendors/react-query'
import { useMutation, useQuery } from '@resnet/client-api/vendors/react-query'

import { useGraphQLSubscription } from '../api-fetchers/use-graphql-subscription'
import { useHTTPGet } from '../api-fetchers/use-http-get'
import { useHTTPJSONPost } from '../api-fetchers/use-http-json-post'
import { useHTTPPost } from '../api-fetchers/use-http-post'
import type { EntityTypeIdsT, ResourceFragmentT, ResourceTypesT } from '../codegen/graphql'

export * from '../auth-api'
export * from '../codegen/graphql'

const mapFileToFormData = (file: File) => {
  const formData = new FormData()

  formData.append('file', file)

  return formData
}

export type UploadResourceMutationVariablesT = {
  type: ResourceTypesT
  originId: string
  originType: 'ENTITY' | 'GROUP' | 'USER' | 'COMMENT' | 'POST' | 'USER_CREDENTIAL' | 'USER_FORM'
  file: File
  id?: string
}

export type UploadResourceMutationT = ResourceFragmentT[]

export const useUploadResourceMutation = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<UploadResourceMutationT, TError, UploadResourceMutationVariablesT, TContext>,
) =>
  useMutation<UploadResourceMutationT, TError, UploadResourceMutationVariablesT, TContext>(
    ['UploadResource'],
    useHTTPPost<UploadResourceMutationT, UploadResourceMutationVariablesT>(
      ({ type, originId, originType, id }) => {
        const searchParams = stringifySearchParams({
          id,
          originId,
          originType,
          type,
        })

        return `/resource/upload${searchParams}`
      },
      ({ file }) => mapFileToFormData(file),
    ),
    options,
  )

useUploadResourceMutation.getKey = () => ['UploadResource']

export type MapboxOperatorsQueryT = {
  type: 'FeatureCollection'
  features: {
    type: 'Feature'
    properties: {
      id: string
      iconSize: [number, number]
      iconUrl: string
      timestamp: string
    }
    geometry: {
      type: 'Point'
      coordinates: number[]
    }
  }[]
}

export type MapboxOperatorsQueryVariablesT = undefined

export const useMapboxOperatorsQuery = <TData = MapboxOperatorsQueryT, TError = unknown>(
  variables?: MapboxOperatorsQueryVariablesT,
  options?: UseQueryOptions<MapboxOperatorsQueryT, TError, TData>,
) =>
  useQuery<MapboxOperatorsQueryT, TError, TData>(
    variables === undefined ? ['MapboxOperators'] : ['MapboxOperators', variables],
    useHTTPGet(() => '/mapbox/2022-10-17/operators'),
    options,
  )

useMapboxOperatorsQuery.getKey = (variables?: MapboxOperatorsQueryVariablesT) =>
  variables === undefined ? ['MapboxOperators'] : ['MapboxOperators', variables]

export type MapboxIssuesQueryT = {
  type: 'FeatureCollection'
  features: {
    type: 'Feature'
    properties: {
      id: string
      name: string
      status: string
      cluster: false
      asset: {
        id: string
        entityTypeId: string
        name: string
      }
    }
    geometry: {
      type: 'Point'
      coordinates: number[]
    }
  }[]
}

export type MapboxIssuesQueryVariablesT = undefined

export const useMapboxIssuesQuery = <TData = MapboxIssuesQueryT, TError = unknown>(
  variables?: MapboxIssuesQueryVariablesT,
  options?: UseQueryOptions<MapboxIssuesQueryT, TError, TData>,
) =>
  useQuery<MapboxIssuesQueryT, TError, TData>(
    variables === undefined ? ['MapboxIssues'] : ['MapboxIssues', variables],
    useHTTPGet(() => '/mapbox/2022-10-17/issues'),
    options,
  )

useMapboxIssuesQuery.getKey = (variables?: MapboxIssuesQueryVariablesT) =>
  variables === undefined ? ['MapboxIssues'] : ['MapboxIssues', variables]

export type MapboxAssetsQueryT = {
  type: 'FeatureCollection'
  features: {
    type: 'Feature'
    properties: {
      cluster: false
      id: string
      name: string
    }
    geometry: {
      type: 'Point'
      coordinates: number[]
    }
  }[]
}

type TrackLocationMutationVariablesT = { coordinates: [longitude: number, latitude: number] }

type TrackLocationMutationT = {
  location: {
    type: string
    coordinates: [longitude: number, latitude: number]
  }
  places: {
    type: string
    id: number
  }[]
  timestamp: string
}

export const useTrackLocationMutation = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<TrackLocationMutationT, TError, TrackLocationMutationVariablesT, TContext>,
) =>
  useMutation<TrackLocationMutationT, TError, TrackLocationMutationVariablesT, TContext>(
    ['TrackLocation'],
    useHTTPJSONPost<TrackLocationMutationT, TrackLocationMutationVariablesT>(
      () => '/location/track',
      ({ coordinates }) => ({ coordinates }),
    ),
    options,
  )

useTrackLocationMutation.getKey = () => ['TrackLocation']

export type OfflineTrackLocationMutationVariablesT = {
  locations: {
    timestamp: Date
    coordinates: [longitude: number, latitude: number]
  }[]
}

type OfflineTrackLocationMutationT = {
  location: {
    type: string
    coordinates: [longitude: number, latitude: number]
  }
  places: {
    type: string
    id: number
  }[]
  timestamp: string
}[]

export const useOfflineTrackLocationMutation = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<OfflineTrackLocationMutationT, TError, OfflineTrackLocationMutationVariablesT, TContext>,
) =>
  useMutation<OfflineTrackLocationMutationT, TError, OfflineTrackLocationMutationVariablesT, TContext>(
    ['OfflineTrackLocation'],
    useHTTPJSONPost<OfflineTrackLocationMutationT, OfflineTrackLocationMutationVariablesT>(
      () => '/location/offline-track',
      ({ locations }) => ({ locations }),
    ),
    options,
  )

useOfflineTrackLocationMutation.getKey = () => ['OfflineTrackLocation']

export type NotificationSubscriptionQueryT = {
  data: {
    notification: {
      id: string
      userId: string
    }
  }
}

const NotificationSubscriptionDocument = `
  subscription {
    notification {
      id
      userId
    }
  }
`

export const useNotificationSubscription = () =>
  useGraphQLSubscription<NotificationSubscriptionQueryT>(NotificationSubscriptionDocument)

export type CollectAppErrorsMutationVariablesT = {
  error: {
    message: string
  } & Record<string, unknown>
  meta: Record<string, unknown>
}

type CollectAppErrorsMutationT = { status: string }

export const useCollectAppErrorsMutation = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<CollectAppErrorsMutationT, TError, CollectAppErrorsMutationVariablesT, TContext>,
) =>
  useMutation<CollectAppErrorsMutationT, TError, CollectAppErrorsMutationVariablesT, TContext>(
    useCollectAppErrorsMutation.getKey(),
    useHTTPJSONPost<CollectAppErrorsMutationT, CollectAppErrorsMutationVariablesT>(
      () => '/analytics/collect-app-error',
      identity,
    ),
    options,
  )

useCollectAppErrorsMutation.getKey = () => ['CollectAppErrors']

type CollectPageViewEventEntityPayloadT = { type: 'entity'; id: string; entityTypeId: EntityTypeIdsT }

type CollectPageViewEventT = { type: 'page-view'; payload: CollectPageViewEventEntityPayloadT }

export type CollectEventT = CollectPageViewEventT

export type CollectMutationVariablesT = {
  tenant: string
  event: CollectEventT
}

type CollectMutationT = { status: string }

export const useCollectMutation = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<CollectMutationT, TError, CollectMutationVariablesT, TContext>,
) =>
  useMutation<CollectMutationT, TError, CollectMutationVariablesT, TContext>(
    useCollectMutation.getKey(),
    useHTTPJSONPost<CollectMutationT, CollectMutationVariablesT>(
      ({ tenant }) => `/analytics/${tenant}/collect`,
      ({ event }) => ({ event }),
    ),
    options,
  )

useCollectMutation.getKey = () => ['Collect']

export type UploadImportFileMutationVariablesT = {
  entityTypeId: 'Well' | 'Site' | 'Battery' | 'Issue'
  file: File
  importId: string
}

export type UploadImportFileMutationT = ResourceFragmentT[]

export const useUploadImportFileMutation = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<UploadImportFileMutationT, TError, UploadImportFileMutationVariablesT, TContext>,
) =>
  useMutation<UploadImportFileMutationT, TError, UploadImportFileMutationVariablesT, TContext>(
    ['UploadImportFile'],
    useHTTPPost<UploadImportFileMutationT, UploadImportFileMutationVariablesT>(
      ({ entityTypeId, importId }) => {
        const searchParams = stringifySearchParams({
          entityTypeId,
          importId,
        })

        return `/import/upload${searchParams}`
      },
      ({ file }) => mapFileToFormData(file),
    ),
    options,
  )

useUploadImportFileMutation.getKey = () => ['UploadImportFile']

export type UploadAccountAvatarMutationVariablesT = {
  file: File
  accountId: string
}

export type UploadAccountAvatarMutationT = ResourceFragmentT[]

export const useUploadAccountAvatarMutation = <TError = unknown, TContext = unknown>(
  options?: UseMutationOptions<UploadAccountAvatarMutationT, TError, UploadAccountAvatarMutationVariablesT, TContext>,
) =>
  useMutation<UploadAccountAvatarMutationT, TError, UploadAccountAvatarMutationVariablesT, TContext>(
    ['UploadAccountAvatar'],
    useHTTPPost<UploadAccountAvatarMutationT, UploadAccountAvatarMutationVariablesT>(
      ({ accountId }) => {
        const searchParams = stringifySearchParams({
          accountId,
        })

        return `/upload-avatar${searchParams}`
      },
      ({ file }) => mapFileToFormData(file),
    ),
    options,
  )

useUploadAccountAvatarMutation.getKey = () => ['UploadAccountAvatar']
