import { produce } from 'immer'

import type { GetCampaignQueryT, ListCampaignsQueryT } from '@resnet/client-api/api'
import {
  useArchiveCampaignMutation,
  useGetCampaignQuery,
  useCreateCampaignMutation,
  useCreateIssuesMutation,
  useDeleteCampaignMutation,
  useDeleteIssueMutation,
  useInfiniteListCampaignsQuery,
  useUnarchiveCampaignMutation,
  useUpdateCampaignMutation,
  useUpdateIssueMutation,
  useUpdateRelationsMutation,
  EntityTypeIdsT,
  useUpdateEntityTypeMutation,
} from '@resnet/client-api/api'
import { createMutationHookMutationCacheHandlers } from '@resnet/client-api/services/query-client/utils/create-mutation-hook-mutation-cache-handlers'
import {
  getEntityQueryUpdateRecipe,
  infiniteListEntityQueryUpdateRecipe,
} from '@resnet/client-api/shared/entities/utils/update-entity-recipe'
import { createQueriesHandlers } from '@resnet/client-api/utils/create-queries-handlers'
import { findGetQueriesById, findInfiniteQueries, findQueries } from '@resnet/client-api/utils/find-queries'
import { invalidateQuery } from '@resnet/client-api/utils/invalidate-query'
import { setQueryData } from '@resnet/client-api/utils/set-query-data'

import type { UpdateIssueMutationMetaT } from '../issue/types/meta'

export const campaignQueriesHandlers = createQueriesHandlers(({ queryClient }) => {
  const findCampaignQueries = () => {
    return findQueries(queryClient, useGetCampaignQuery)
  }

  const findCampaignQueriesById = ({ id }: { id: string }) =>
    findGetQueriesById(queryClient, useGetCampaignQuery, (data) => data.getCampaign, { id })

  const mapDataToEntity = (data: GetCampaignQueryT) => data.getCampaign

  const updateCampaignHandlers = () => {
    return createMutationHookMutationCacheHandlers(useUpdateCampaignMutation, {
      onError: (error, { id }) => {
        findCampaignQueriesById({ id }).forEach(invalidateQuery(queryClient))
      },
      onMutate: ({ id, data: input }) => {
        findCampaignQueriesById({ id }).forEach((query) => {
          setQueryData(queryClient)(query)(
            produce((data) => {
              getEntityQueryUpdateRecipe({ data, input, mapDataToEntity })
            }),
          )
        })
      },
      onSuccess: (data, { id }) => {
        findCampaignQueriesById({ id }).forEach(invalidateQuery(queryClient))
      },
    })
  }

  const deleteCampaignHandlers = () => {
    return createMutationHookMutationCacheHandlers(useDeleteCampaignMutation, {
      onSuccess: (data, { id }) => {
        findCampaignQueriesById({ id }).forEach(invalidateQuery(queryClient))
      },
    })
  }

  const createIssuesHandlers = () => {
    return createMutationHookMutationCacheHandlers(useCreateIssuesMutation, {
      onSuccess: (data, { data: issue }) => {
        const campaignId = issue?.campaignId

        if (!campaignId) {
          return
        }

        findCampaignQueriesById({ id: campaignId }).forEach(invalidateQuery(queryClient))
      },
    })
  }

  const updateIssueHandlers = () => {
    return createMutationHookMutationCacheHandlers(useUpdateIssueMutation, {
      onSuccess: (data, variables, context, mutation) => {
        const meta = mutation.meta as UpdateIssueMutationMetaT

        const campaignId = data.updateIssue?.campaignId ?? meta?.issue?.campaignId

        if (!campaignId) {
          return
        }

        findCampaignQueriesById({ id: campaignId }).forEach(invalidateQuery(queryClient))
      },
    })
  }

  const deleteIssueHandlers = () => {
    return createMutationHookMutationCacheHandlers(useDeleteIssueMutation, {
      onSuccess: (data) => {
        const campaignId = data.deleteIssue?.campaignId

        if (!campaignId) {
          return
        }

        findCampaignQueriesById({ id: campaignId }).forEach(invalidateQuery(queryClient))
      },
    })
  }

  const archiveCampaignHandlers = () => {
    return createMutationHookMutationCacheHandlers(useArchiveCampaignMutation, {
      onError: (data, { id }) => {
        findCampaignQueriesById({ id }).forEach(invalidateQuery(queryClient))
      },
      onMutate: ({ id }) => {
        findCampaignQueriesById({ id }).forEach((query) => {
          setQueryData(queryClient)(query)(
            produce((data) => {
              getEntityQueryUpdateRecipe({
                data,
                input: { archived: { at: new Date().toISOString() } },
                mapDataToEntity: (data) => data.getCampaign,
              })
            }),
          )
        })
      },
      onSuccess: (data, { id }) => {
        findCampaignQueriesById({ id }).forEach(invalidateQuery(queryClient))
      },
    })
  }

  const unarchiveCampaignHandlers = () => {
    return createMutationHookMutationCacheHandlers(useUnarchiveCampaignMutation, {
      onError: (data, { id }) => {
        findCampaignQueriesById({ id }).forEach(invalidateQuery(queryClient))
      },
      onMutate: ({ id }) => {
        findCampaignQueriesById({ id }).forEach((query) => {
          setQueryData(queryClient)(query)(
            produce((data) => {
              getEntityQueryUpdateRecipe({
                data,
                input: { archived: null },
                mapDataToEntity: (data) => data.getCampaign,
              })
            }),
          )
        })
      },
      onSuccess: (data, { id }) => {
        findCampaignQueriesById({ id }).forEach(invalidateQuery(queryClient))
      },
    })
  }

  const updateRelationsHandlers = () => {
    return createMutationHookMutationCacheHandlers(useUpdateRelationsMutation, {
      onSuccess: (data, { entityId }) => {
        findCampaignQueriesById({ id: entityId }).forEach(invalidateQuery(queryClient))
      },
    })
  }

  const updateEntityTypeHandlers = () => {
    return createMutationHookMutationCacheHandlers(useUpdateEntityTypeMutation, {
      onSuccess: (data, { id }) => {
        if (id !== EntityTypeIdsT.CampaignT) {
          return
        }

        findCampaignQueries().forEach(invalidateQuery(queryClient))
      },
    })
  }

  return [
    updateCampaignHandlers(),
    deleteCampaignHandlers(),
    deleteIssueHandlers(),
    createIssuesHandlers(),
    updateIssueHandlers(),
    deleteIssueHandlers(),
    archiveCampaignHandlers(),
    unarchiveCampaignHandlers(),
    updateRelationsHandlers(),
    updateEntityTypeHandlers(),
  ]
})

export const listCampaignsQueriesHandlers = createQueriesHandlers(({ queryClient }) => {
  const findInfiniteListCampaignsQueries = () => {
    return findInfiniteQueries(queryClient, useInfiniteListCampaignsQuery)
  }

  const mapDataToEntities = (data: ListCampaignsQueryT) => data.listCampaigns.items

  const updateCampaignHandlers = () => {
    return createMutationHookMutationCacheHandlers(useUpdateCampaignMutation, {
      onError: () => {
        findInfiniteListCampaignsQueries().forEach(invalidateQuery(queryClient))
      },
      onMutate: ({ id, data: input }) => {
        findInfiniteListCampaignsQueries().forEach((query) => {
          setQueryData(queryClient)(query)(
            produce((data) => {
              infiniteListEntityQueryUpdateRecipe({
                data,
                id,
                input,
                mapDataToEntities,
              })
            }),
          )
        })
      },
      onSuccess: () => {
        findInfiniteListCampaignsQueries().forEach(invalidateQuery(queryClient))
      },
    })
  }

  const createCampaignHandlers = () => {
    return createMutationHookMutationCacheHandlers(useCreateCampaignMutation, {
      onSuccess: () => {
        findInfiniteListCampaignsQueries().forEach(invalidateQuery(queryClient))
      },
    })
  }

  const deleteCampaignHandlers = () => {
    return createMutationHookMutationCacheHandlers(useDeleteCampaignMutation, {
      onSuccess: () => {
        findInfiniteListCampaignsQueries().forEach(invalidateQuery(queryClient))
      },
    })
  }

  const archiveCampaignHandlers = () => {
    return createMutationHookMutationCacheHandlers(useArchiveCampaignMutation, {
      onSuccess: () => {
        findInfiniteListCampaignsQueries().forEach(invalidateQuery(queryClient))
      },
    })
  }

  const unarchiveCampaignHandlers = () => {
    return createMutationHookMutationCacheHandlers(useUnarchiveCampaignMutation, {
      onSuccess: () => {
        findInfiniteListCampaignsQueries().forEach(invalidateQuery(queryClient))
      },
    })
  }

  const updateRelationsHandlers = () => {
    return createMutationHookMutationCacheHandlers(useUpdateRelationsMutation, {
      onSuccess: () => {
        findInfiniteListCampaignsQueries().forEach(invalidateQuery(queryClient))
      },
    })
  }

  const updateEntityTypeHandlers = () => {
    return createMutationHookMutationCacheHandlers(useUpdateEntityTypeMutation, {
      onSuccess: (data, { id }) => {
        if (id !== EntityTypeIdsT.CampaignT) {
          return
        }

        findInfiniteListCampaignsQueries().forEach(invalidateQuery(queryClient))
      },
    })
  }

  return [
    createCampaignHandlers(),
    updateCampaignHandlers(),
    deleteCampaignHandlers(),
    archiveCampaignHandlers(),
    unarchiveCampaignHandlers(),
    updateRelationsHandlers(),
    updateEntityTypeHandlers(),
  ]
})
