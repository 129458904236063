import type { BoxProps } from '@mui/material'
import { Box, Tooltip } from '@mui/material'

import type { ForwardableT } from '@resnet/client-common/react/types/forwardable'
import { forwardFunctionalComponentRef } from '@resnet/client-common/react/utils/forward-functional-component-ref'
import { renderForwardable } from '@resnet/client-common/react/utils/render-forwardable'
import type { MergeAllT } from '@resnet/client-common/typescript/types/merge-all'

import CircleSolidIcon from '@resnet/client-shared/assets/icons/circle-solid.svg'
import { mapPickerColorPresetToColor } from '@resnet/client-shared/shared/common/utils/map-picker-color-preset-to-color'
import { typographyPresets } from '@resnet/client-shared/shared/gdl/constants/typography-presets'
import type { MediaT } from '@resnet/client-shared/shared/gdl/types/media'

import { TextSkeleton } from '@resnet/client-shared-web/shared/async/components/text-skeleton'
import { themeColors } from '@resnet/client-shared-web/shared/gdl/constants/theme-colors'

import { mapTypographyPresetToSx } from '../../utils/map-typography-preset-to-sx'
import { toPx } from '../../utils/to-px'
import { LoadableAvatar, IconAvatar } from '../avatar'
import { DropdownIcon } from '../dropdown-icon'
import { SingleLineText } from '../single-line-text'

export type DropdownInputPropsT = MergeAllT<
  [
    Omit<BoxProps<'button'>, 'placeholder' | 'value'>,
    {
      tooltip?: React.ReactNode
      disabledTooltipTitle?: string
      hasError?: boolean
      icon?: ForwardableT<React.SVGProps<SVGSVGElement>>
      isOpened?: boolean
      onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void
      size?: 'sm' | 'md'
    },
  ]
>

const dropdownSizeProps = {
  md: {
    iconSize: 20,
  },
  sm: {
    iconSize: 16,
  },
} as const

export const DropdownInput = forwardFunctionalComponentRef(
  (
    {
      children,
      disabled,
      tooltip,
      disabledTooltipTitle,
      hasError,
      icon,
      isOpened,
      onClick,
      sx = null,
      size = 'md',
      ...props
    }: DropdownInputPropsT,
    ref: React.Ref<HTMLButtonElement>,
  ) => {
    const borderRadius = 8

    const borderWidth = 1

    const { iconSize } = dropdownSizeProps[size]

    const renderIcon = () => {
      if (!icon) {
        return null
      }

      return renderForwardable(icon, { fill: themeColors.overBackgroundFaded, height: iconSize, width: iconSize })
    }

    const renderContent = () => {
      return <Box sx={{ display: 'flex', flexGrow: 1, flexShrink: 999, minWidth: 0 }}>{children}</Box>
    }

    const renderDropdownIcon = () => {
      return (
        <DropdownIcon
          fill={themeColors.overBackgroundFaded}
          height={iconSize}
          isOpened={isOpened}
          width={iconSize}
        />
      )
    }

    const getTooltipTitle = () => {
      if (disabled) {
        return disabledTooltipTitle
      }

      return tooltip
    }

    return (
      <Tooltip
        placement="top"
        title={getTooltipTitle()}
      >
        <Box
          {...props}
          component="button"
          disabled={disabled}
          ref={ref}
          sx={[
            { all: 'unset' },
            {
              alignItems: 'center',
              backgroundColor: themeColors.surfaceNeutralDefault,
              borderColor: themeColors.borderFaded,
              borderRadius: toPx(borderRadius),
              borderStyle: 'solid',
              borderWidth: toPx(borderWidth),
              cursor: 'pointer',
              display: 'flex',
              gap: toPx(8),
              position: 'relative',
              px: toPx(16 - borderWidth),
              py: toPx(8 - borderWidth),
              transition: 'background-color 200ms ease',
            },
            !disabled
              ? null
              : {
                  cursor: 'not-allowed',
                  userSelect: 'none',
                },
            !disabled
              ? null
              : {
                  opacity: 0.64,
                },
            disabled
              ? null
              : {
                  '&:hover': {
                    backgroundColor: themeColors.surfaceNeutralHover,
                  },
                },

            disabled
              ? null
              : {
                  '&:active': {
                    backgroundColor: themeColors.surfaceNeutralPressed,
                  },
                },
            {
              '&:focus-visible': {
                outlineColor: themeColors.borderFocus,
                outlineStyle: 'solid',
                outlineWidth: toPx(2),
              },
            },
            !hasError ? {} : { borderColor: themeColors.feedbackCritical },
            sx,
          ].flat()}
          type="button"
          onClick={onClick}
        >
          {renderIcon()}
          {renderContent()}
          {renderDropdownIcon()}
        </Box>
      </Tooltip>
    )
  },
)

export const DropdownInputMedia = ({
  sx,
  media,
  size = 'md',
  ...props
}: React.ComponentProps<typeof Box> & { size?: 'sm' | 'md'; media: MediaT }) => {
  const renderMedia = () => {
    if (!media || !media.type) {
      return null
    }

    const { iconSize: mediaSize } = dropdownSizeProps[size]

    switch (media.type) {
      case 'icon': {
        return renderForwardable(media.icon, {
          fill: themeColors.overBackgroundDefault,
          height: mediaSize,
          width: mediaSize,
        })
      }
      case 'iconAvatar': {
        return (
          <IconAvatar
            size={size === 'sm' ? 'xs' : 'sm'}
            {...media}
          />
        )
      }
      case 'avatar': {
        return (
          <LoadableAvatar
            size={size === 'sm' ? 'xs' : 'sm'}
            {...media}
          />
        )
      }
      case 'color': {
        return (
          <CircleSolidIcon
            fill={mapPickerColorPresetToColor(media.color)}
            height={mediaSize}
            width={mediaSize}
          />
        )
      }
    }
  }

  return (
    <Box
      {...props}
      sx={sx}
    >
      {renderMedia()}
    </Box>
  )
}

const inputTextSizeProps = {
  md: {
    typographyPreset: typographyPresets.bodyMedium,
  },
  sm: {
    typographyPreset: typographyPresets.bodyExtraSmall,
  },
}

export type DropdownInputTextPropsT = MergeAllT<
  [
    Omit<BoxProps, 'ref'>,
    {
      size?: 'sm' | 'md'
    },
  ]
>

export const DropdownInputText = ({ sx = null, children, size = 'md', ...props }: DropdownInputTextPropsT) => {
  const { typographyPreset } = inputTextSizeProps[size]

  return (
    <SingleLineText
      {...props}
      showTooltip
      sx={[
        mapTypographyPresetToSx(typographyPreset),
        {
          color: themeColors.overBackgroundDefault,
        },
        sx,
      ].flat()}
    >
      {children}
    </SingleLineText>
  )
}

export const DropdownInputTextSkeleton = ({
  size = 'md',
  ...props
}: Omit<React.ComponentProps<typeof TextSkeleton>, 'typographyPreset'> & { size?: 'sm' | 'md' }) => {
  const { typographyPreset } = inputTextSizeProps[size]

  return (
    <TextSkeleton
      {...props}
      contentSx={{ width: '70%' }}
      sx={{ flexGrow: 1 }}
      typographyPreset={typographyPreset}
    />
  )
}

export const DropdownInputPlaceholder = ({
  sx,
  children,
  ...props
}: React.ComponentProps<typeof DropdownInputText>) => {
  return (
    <DropdownInputText
      {...props}
      sx={[
        {
          color: themeColors.overBackgroundFaded,
        },
        sx ?? null,
      ].flat()}
    >
      {children}
    </DropdownInputText>
  )
}
