import type { SxProps, Theme } from '@mui/material'

import type { AbstractOptionT } from '@resnet/client-shared-web/shared/gdl/types/abstract-option'

import { DropdownInputPlaceholder, DropdownInputText } from '../dropdown-input'

const defaultPlaceholder = 'Not Selected'

export type StaticOptionsDropdownTextPropsT<OptionT extends AbstractOptionT> = {
  value: undefined | null | OptionT['id']
  options: OptionT[]
  getOptionLabel: (option: OptionT) => string
  placeholder?: string
  sx?: SxProps<Theme>
  size?: 'sm' | 'md'
}

export const StaticOptionsDropdownText = <OptionT extends AbstractOptionT>({
  value,
  options,
  getOptionLabel,
  placeholder = defaultPlaceholder,
  sx,
  size,
}: StaticOptionsDropdownTextPropsT<OptionT>): React.ReactElement => {
  if (!value) {
    return (
      <DropdownInputPlaceholder
        size={size}
        sx={sx}
      >
        {placeholder}
      </DropdownInputPlaceholder>
    )
  }

  const selectedOption = options.find((option) => option.id === value)

  if (!selectedOption) {
    return (
      <DropdownInputPlaceholder
        size={size}
        sx={sx}
      >
        Option not found
      </DropdownInputPlaceholder>
    )
  }

  return (
    <DropdownInputText
      size={size}
      sx={sx}
    >
      {getOptionLabel(selectedOption)}
    </DropdownInputText>
  )
}
