import { Box, CircularProgress } from '@mui/material'

import { themeColors } from '@resnet/client-shared-web/shared/gdl/constants/theme-colors'

export const Spinner = ({ sx, ...props }: React.ComponentProps<typeof CircularProgress>): React.ReactElement => {
  return (
    <CircularProgress
      {...props}
      sx={[{ color: props.color ?? themeColors.basePrimary }, sx ?? null].flat()}
    />
  )
}

export const SpinnerPanel = () => {
  return (
    <Box sx={{ alignItems: 'center', display: 'flex', height: '100%', justifyContent: 'center' }}>
      <Spinner />
    </Box>
  )
}
