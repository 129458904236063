import type { UserFragmentT } from '@resnet/client-api/api'

import { mapUserToFirstName } from './map-user-to-first-name'
import { mapUserToLastName } from './map-user-to-last-name'

export const mapUserToFirstLastName = (user: Pick<UserFragmentT, 'firstName' | 'lastName'>): null | string => {
  const firstName = mapUserToFirstName(user)

  const lastName = mapUserToLastName(user)

  if (!firstName && !lastName) {
    return null
  }

  const firstLastName = `${firstName} ${lastName}`.trim()

  return firstLastName
}
