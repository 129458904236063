import type { ColumnData, Row, Sortable, Vido, htmlResult } from 'gantt-schedule-timeline-calendar'

import type { AnyTypeT } from '@resnet/client-common/typescript/types/any-type'

import type { ViewSettingsT } from '@resnet/client-web/shared/presets/types/view-settings'

import { createGanttId } from '../create-gantt-id'

export const createSimpleGanttColumn = <
  RowT extends Record<string, AnyTypeT>,
  ColumnT extends { field: string; headerName?: string } & Record<string, AnyTypeT>,
>({
  width,
  data,
  expander,
  sortable,
  viewSettings,
  column,
  position: positionActual,
  hidden: hiddenActual,
  isReactElementRenderer,
  renderElement,
}: Omit<ColumnData, 'id' | 'data' | 'header'> & {
  viewSettings?: Pick<ViewSettingsT, 'ganttColumns'>
  column: ColumnT
  position?: number
  hidden?: boolean
  isReactElementRenderer?: boolean
  renderElement?: (props: { row: RowT; onSuccess: () => void }) => React.ReactNode
  data:
    | keyof RowT
    | (({ row, vido }: { row: Row & Omit<RowT, 'id'>; vido: Vido }) => string)
    | (({ row, vido }: { row: Row & Omit<RowT, 'id'>; vido: Vido }) => htmlResult)
}) => {
  const ganttId = createGanttId(column.field)

  return {
    data,
    expander,
    header: {
      content: column.headerName ?? column.field,
    },
    hidden: hiddenActual ?? !viewSettings?.ganttColumns?.find((item) => item.id === column.field)?.enabled,
    id: ganttId,
    isReactElementRenderer,
    originalId: column.field,
    position: positionActual ?? (viewSettings?.ganttColumns?.findIndex((item) => item.id === column.field) ?? 0) + 2,
    renderElement,
    sortable: (!sortable ? ({ row }: { row: RowT }) => row[column.field] ?? '-' : sortable) as Sortable,
    width,
  }
}
