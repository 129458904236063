import type { SxProps } from '@mui/material'
import { Box } from '@mui/material'
import { useState } from 'react'

import { useBooleanHandlers } from '@resnet/client-common/react/hooks/use-boolean-handlers'
import { useLocalStorageState } from '@resnet/client-common/react/hooks/use-local-storage-state'

import { typographyPresets } from '@resnet/client-shared/shared/gdl/constants/typography-presets'

import { themeColors } from '@resnet/client-shared-web/shared/gdl/constants/theme-colors'

import { mapBorderWidthToValue } from '../../utils/map-border-width-to-value'
import { mapPaddingToValue } from '../../utils/map-padding-to-value'
import { mapTypographyPresetToSx } from '../../utils/map-typography-preset-to-sx'
import { toPx } from '../../utils/to-px'
import { ClickableOpacity } from '../clickable-opacity'
import { DropdownIcon } from '../dropdown-icon'

export const accordionHeaderHeight =
  typographyPresets.titleSmall.lineHeight + typographyPresets.captionRegular.lineHeight

export const closedAccordionHeight = 4 + accordionHeaderHeight + 4

export type AccordionPropsT = {
  isOpened: boolean
  setIsOpened: (isOpened: boolean) => void
  keepMounted?: boolean
  children: {
    header: {
      title: React.ReactNode
      subtitle?: React.ReactNode
    }
    content: () => React.ReactNode
  }
  defaultIsOpened?: boolean
  hasError?: boolean
  sx?: SxProps
}

export const Accordion = ({ defaultIsOpened = false, ...props }: Omit<AccordionPropsT, 'isOpened' | 'setIsOpened'>) => {
  const [isOpened, setIsOpened] = useState(defaultIsOpened)

  return (
    <StatelessAccordion
      {...props}
      isOpened={isOpened}
      setIsOpened={setIsOpened}
    />
  )
}

export const PersistentAccordion = ({
  defaultIsOpened = false,
  storageKey,
  ...props
}: { storageKey: string } & Omit<AccordionPropsT, 'isOpened' | 'setIsOpened'>) => {
  const [isOpened, setIsOpened] = useLocalStorageState<boolean>(storageKey, defaultIsOpened)

  return (
    <StatelessAccordion
      {...props}
      isOpened={Boolean(isOpened)}
      setIsOpened={setIsOpened}
    />
  )
}

export const StatelessAccordion = ({
  children,
  hasError,
  sx = null,
  isOpened,
  setIsOpened,
  keepMounted = false,
}: AccordionPropsT): React.ReactElement => {
  const { onToggle } = useBooleanHandlers({ setState: setIsOpened })

  const renderHeaderTitle = () => {
    return (
      <Box
        sx={[
          mapTypographyPresetToSx(typographyPresets.titleSmall),
          {
            color: themeColors.overBackgroundBold,
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
          },
        ]}
      >
        {children.header.title}
      </Box>
    )
  }

  const renderHeaderSubtitle = () => {
    if (!children.header.subtitle) {
      return null
    }

    return (
      <Box
        sx={[
          mapTypographyPresetToSx(typographyPresets.captionRegular),
          {
            color: themeColors.overBackgroundMuted,
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
          },
        ]}
      >
        {children.header.subtitle}
      </Box>
    )
  }

  const renderHeaderTitleAndSubtitle = () => {
    return (
      <Box sx={{ display: 'flex', flexDirection: 'column', flexGrow: 1, width: 0 }}>
        {renderHeaderTitle()}
        {renderHeaderSubtitle()}
      </Box>
    )
  }

  const renderDropdownIcon = () => {
    const iconSize = 20

    return (
      <DropdownIcon
        fill={themeColors.overBackgroundFaded}
        height={iconSize}
        isOpened={isOpened}
        width={iconSize}
      />
    )
  }

  const renderHeader = () => {
    return (
      <ClickableOpacity onClick={onToggle}>
        <Box sx={{ alignItems: 'center', display: 'flex', flexGrow: 1, height: toPx(accordionHeaderHeight) }}>
          {renderHeaderTitleAndSubtitle()}
          {renderDropdownIcon()}
        </Box>
      </ClickableOpacity>
    )
  }

  const renderContent = () => {
    if (!isOpened && !keepMounted) {
      return null
    }

    return (
      <Box
        sx={{
          backgroundColor: themeColors.surfaceVariantDefault,
          borderRadius: toPx(8),
          display: !isOpened ? 'none' : 'flex',
          flexDirection: 'column',
          padding: toPx(8),
        }}
      >
        {children.content()}
      </Box>
    )
  }

  const borderWidth = 1

  const padding = { bottom: 4, left: 8, right: 8, top: 4 }

  return (
    <Box
      sx={[
        {
          borderColor: themeColors.borderDefault,
          borderRadius: toPx(8),
          borderStyle: 'solid',
          borderWidth: mapBorderWidthToValue({ borderWidth }),
          display: 'flex',
          flexDirection: 'column',
          gap: toPx(8),
          padding: mapPaddingToValue({ borderWidth, padding }),
        },
        !hasError ? null : { borderColor: themeColors.feedbackCritical },
        sx,
      ].flat()}
    >
      {renderHeader()}
      {renderContent()}
    </Box>
  )
}
