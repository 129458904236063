import { Box } from '@mui/material'
import type { SxProps } from '@mui/system'

import { typographyPresets } from '@resnet/client-shared/shared/gdl/constants/typography-presets'

import { CircularSkeleton } from '@resnet/client-shared-web/shared/async/components/circular-skeleton'
import { TextSkeleton } from '@resnet/client-shared-web/shared/async/components/text-skeleton'
import { toPx } from '@resnet/client-shared-web/shared/gdl/utils/to-px'

export const SkeletonCell = ({ sx, withMedia }: { sx?: SxProps; withMedia?: boolean }): React.ReactElement => {
  return (
    <Box sx={[{ alignItems: 'center', display: 'flex', gap: toPx(8) }, sx ?? null].flat()}>
      {!withMedia ? null : <CircularSkeleton size={18} />}
      <TextSkeleton
        contentSx={{ width: '80%' }}
        sx={{ flexGrow: 1 }}
        typographyPreset={typographyPresets.bodySmall}
      />
    </Box>
  )
}
