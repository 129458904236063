import { Box } from '@mui/material'
import type { GridColDef } from '@mui/x-data-grid'
import type { FieldPathByValue, FieldValues, Path, UseFormReturn } from 'react-hook-form'

import { pipeline } from '@resnet/client-common/common/utils/function/pipeline'
import { checkNonNullable } from '@resnet/client-common/common/utils/nullable/non-nullable'
import { fullDateFormatter } from '@resnet/client-common/format/utils/date/full-date-formatter'
import { fullDateTimeFormatter } from '@resnet/client-common/format/utils/date/full-date-time-formatter'
import { assert } from '@resnet/client-common/typescript/utils/assert'

import { CustomFieldTypeT } from '@resnet/client-api/api'

import type { DateCustomFieldValueT } from '@resnet/client-shared/shared/custom-fields/presets/date/custom-field-value'

import { themeColors } from '@resnet/client-shared-web/shared/gdl/constants/theme-colors'

import type { ColumnWithCreateGanttColumnT } from '@resnet/client-web/shared/gantt/types/column-with-create-gantt-column'
import { createSimpleGanttColumn } from '@resnet/client-web/shared/gantt/utils/create-simple-gantt-column'
import { DateCell } from '@resnet/client-web/shared/tables/components/date-cell'
import { EditDateCell } from '@resnet/client-web/shared/tables/components/edit-date-cell'
import { RegularCell } from '@resnet/client-web/shared/tables/components/regular-cell'
import { createColumn as createCommonColumn } from '@resnet/client-web/shared/tables/factories/create-column'
import type { ColumnWithEditFieldT } from '@resnet/client-web/shared/tables/types/column-with-edit-field'
import type { ColumnWithGroupingT } from '@resnet/client-web/shared/tables/types/column-with-grouping'
import type { ColumnWithHeaderMenuFilterT } from '@resnet/client-web/shared/tables/types/column-with-header-menu-filter'
import type { ColumnWithValueT } from '@resnet/client-web/shared/tables/types/column-with-value'

import type { CreateColumnPropsT, CustomFieldT } from '../../types/custom-field'
import { getFieldValue } from '../../utils/get-field-value'
import { mapCustomFieldToFullKey } from '../../utils/map-custom-field-to-key'

import { DateCustomFieldUserFormField } from './components/date-custom-field-user-form-field'

export const createColumn: CustomFieldT['createColumn'] = <
  RowT extends Record<string, unknown>,
  FieldValuesKeyT extends Extract<keyof RowT, string>,
>({
  field,
  fieldValuesKey,
  mapRowToPathname,
}: CreateColumnPropsT<RowT, FieldValuesKeyT>) => {
  type ColumnT = ColumnWithHeaderMenuFilterT<
    ColumnWithEditFieldT<ColumnWithGroupingT<ColumnWithCreateGanttColumnT<GridColDef<RowT>>>>
  >

  type ColumnWithValueAppliedT = ColumnWithValueT<ColumnT, undefined | DateCustomFieldValueT>

  const payload = field.payload

  assert(payload, checkNonNullable)

  const datePayload = payload[CustomFieldTypeT.DateT]

  assert(datePayload, checkNonNullable)

  const { addTimeEnabled } = datePayload

  const formatter = addTimeEnabled ? fullDateTimeFormatter : fullDateFormatter

  const getValue = (row: Record<string, unknown>) => getFieldValue<DateCustomFieldValueT>(field, fieldValuesKey, row)

  const createGanttColumn: ColumnT['createGanttColumn'] = ({ viewSettings, column }) =>
    createSimpleGanttColumn<Record<string, unknown>, ColumnT>({
      column,
      data: ({ row, vido }) => {
        if (row.isGroup) {
          return null
        }

        const style = {
          color: themeColors.overBackgroundDefault,
          textDecoration: 'none',
          verticalAlign: 'baseline',
        }

        const value = getValue(row)

        if (value === undefined || value === null) {
          return vido.html`<div style=${vido.styleMap(style)}>${'-'}</div>`
        }

        const text = pipeline(
          value,
          (x) => (!checkNonNullable(x) ? x : new Date(x)),
          (x) => (!checkNonNullable(x) ? x : formatter.format(x)),
        )

        return vido.html`<div style=${vido.styleMap(style)}>${text}</div>`
      },
      isHTML: false,
      sortable: ({ row }) => {
        const value = getValue(row)

        if (value === undefined || value === null) {
          return -Infinity
        }

        return Number(new Date(value))
      },
      viewSettings,
      width: 100,
    })

  const columnField = mapCustomFieldToFullKey(field, fieldValuesKey)

  return createCommonColumn<ColumnWithValueAppliedT>({
    createGanttColumn,
    editable: true,
    field: columnField,
    filterOptionId: columnField,
    groupRowRenderCell: (rowNode) => {
      const { groupingKey } = rowNode

      assert(groupingKey, checkNonNullable)

      return <RegularCell>{groupingKey}</RegularCell>
    },
    groupable: true,
    groupingValueGetter: ({ row }) => {
      const value = getValue(row)

      if (value === undefined || value === null) {
        return undefined
      }

      const text = pipeline(
        value,
        (x) => (!checkNonNullable(x) ? x : new Date(x)),
        (x) => (!checkNonNullable(x) ? x : formatter.format(x)),
      )

      return text
    },
    headerName: field.name,
    renderCell: ({ row, value }) => {
      return (
        <DateCell
          to={mapRowToPathname?.(row)}
          value={value}
        />
      )
    },
    renderEditCell: () => {
      return <EditDateCell name={columnField} />
    },
    renderField: ({ form }) => {
      return (
        <Box sx={{ display: 'flex', flexDirection: 'column', flexGrow: 1 }}>
          <DateCustomFieldUserFormField<FieldValues, Path<FieldValues>>
            field={field}
            form={form as UseFormReturn<FieldValues>}
            name={columnField as FieldPathByValue<RowT, undefined | DateCustomFieldValueT>}
          />
        </Box>
      )
    },
    sortable: true,
    valueGetter: ({ row }) => getValue(row),
  })
}
