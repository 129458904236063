import type { BoxProps } from '@mui/material'
import { Box } from '@mui/material'

import type { MergeT } from '@resnet/client-common/typescript/types/merge'

import FileSolidIcon from '@resnet/client-shared/assets/icons/file-solid.svg'
import PdfSolidIcon from '@resnet/client-shared/assets/icons/pdf-solid.svg'
import PlaySolidIcon from '@resnet/client-shared/assets/icons/play-solid.svg'
import WordSolidIcon from '@resnet/client-shared/assets/icons/word-solid.svg'
import { typographyPresets } from '@resnet/client-shared/shared/gdl/constants/typography-presets'
import { mapMimetypeToType } from '@resnet/client-shared/shared/resources/utils/map-mimetype-to-type'

import { RectangularSkeleton } from '@resnet/client-shared-web/shared/async/components/rectangular-skeleton'
import { TextSkeleton } from '@resnet/client-shared-web/shared/async/components/text-skeleton'
import type { ResourceT } from '@resnet/client-shared-web/shared/files/types/resource'
import type { ClickableOpacityPropsT } from '@resnet/client-shared-web/shared/gdl/components/clickable-opacity'
import { ClickableOpacity } from '@resnet/client-shared-web/shared/gdl/components/clickable-opacity'
import { TextOverflow } from '@resnet/client-shared-web/shared/gdl/components/text-overflow'
import { themeColors } from '@resnet/client-shared-web/shared/gdl/constants/theme-colors'
import { mapTypographyPresetToSx } from '@resnet/client-shared-web/shared/gdl/utils/map-typography-preset-to-sx'
import { toPx } from '@resnet/client-shared-web/shared/gdl/utils/to-px'

import { ImageResource } from '../image-resource'
import { VideoResource } from '../video-resource'

const thumbnailSize = 32

const thumbnailSx = {
  alignItems: 'center',
  backgroundColor: themeColors.surfaceVariantDefault,
  borderColor: themeColors.borderDefault,
  borderRadius: toPx(8),
  borderStyle: 'solid',
  borderWidth: toPx(1),
  display: 'flex',
  flexShrink: 0,
  height: toPx(thumbnailSize),
  justifyContent: 'center',
  overflow: 'hidden',
  position: 'relative',
  width: toPx(thumbnailSize),
}

const thumbnailIconSize = 16

const thumbnailIconProps: React.ComponentProps<SVGComponentT> = {
  fill: themeColors.basePrimary,
  height: thumbnailIconSize,
  width: thumbnailIconSize,
}

type ResourceThumbnailPropsT = MergeT<
  ClickableOpacityPropsT,
  {
    resource: ResourceT
  }
>

export const ResourceThumbnail = ({ sx = null, resource, ...props }: ResourceThumbnailPropsT) => {
  const type = mapMimetypeToType(resource.mimetype)

  const renderThumbnailMedia = () => {
    switch (type) {
      case 'image': {
        return (
          <ImageResource
            resource={resource}
            sx={{ height: '100%', objectFit: 'cover', width: '100%' }}
          />
        )
      }
      case 'video': {
        return (
          <Box sx={{ height: '100%', position: 'relative', width: '100%' }}>
            <VideoResource
              resource={resource}
              sx={{ height: '100%', width: '100%' }}
            />
            <Box
              sx={{
                alignItems: 'center',
                backgroundColor: themeColors.surfaceVariantDefault,
                display: 'flex',
                inset: 0,
                justifyContent: 'center',
                position: 'absolute',
              }}
            >
              <PlaySolidIcon {...thumbnailIconProps} />
            </Box>
          </Box>
        )
      }
      case 'pdf': {
        return <PdfSolidIcon {...thumbnailIconProps} />
      }
      case 'word': {
        return <WordSolidIcon {...thumbnailIconProps} />
      }
      case 'unknown': {
        return <FileSolidIcon {...thumbnailIconProps} />
      }
    }
  }

  const renderThumbnail = () => {
    return <Box sx={thumbnailSx}>{renderThumbnailMedia()}</Box>
  }

  const renderName = () => {
    return (
      <Box
        sx={[
          mapTypographyPresetToSx(typographyPresets.bodySmall),
          { color: themeColors.overBackgroundBold, display: 'flex', flexGrow: 1, minWidth: 0 },
        ].flat()}
      >
        <TextOverflow>{resource.filename}</TextOverflow>
      </Box>
    )
  }

  return (
    <ClickableOpacity
      {...props}
      sx={[
        {
          alignItems: 'center',
          borderRadius: toPx(8),
          display: 'flex',
          flexDirection: 'row',
          gap: toPx(8),
          position: 'relative',
        },
        sx,
      ].flat()}
    >
      {renderThumbnail()}
      {renderName()}
    </ClickableOpacity>
  )
}

export type ResourceThumbnailSkeletonPropsT = BoxProps

export const ResourceThumbnailSkeleton = ({ sx = null, ...props }: ResourceThumbnailSkeletonPropsT) => {
  const renderThumbnail = () => {
    return (
      <Box sx={thumbnailSx}>
        <RectangularSkeleton
          sx={{
            height: '100%',
            width: '100%',
          }}
        />
      </Box>
    )
  }

  const renderName = () => {
    return (
      <Box sx={{ display: 'flex', flexGrow: 1 }}>
        <TextSkeleton
          contentSx={{ width: '70%' }}
          typographyPreset={typographyPresets.bodySmall}
        />
      </Box>
    )
  }

  return (
    <Box
      {...props}
      sx={[{ alignItems: 'center', display: 'flex', gap: toPx(8) }, sx].flat()}
    >
      {renderThumbnail()}
      {renderName()}
    </Box>
  )
}
