import { Box } from '@mui/material'

import { ChildrenDivider } from '@resnet/client-common/react/components/children-transformer'
import { ErrorBoundary } from '@resnet/client-common/react/components/error-boundary'
import { ErrorBoundaryFallbackEffectContainer } from '@resnet/client-common/react/hooks/use-error-boundary-fallback-effect'

import { FilterModeT } from '@resnet/client-api/api'

import type { MediaT } from '@resnet/client-shared/shared/gdl/types/media'

import type { AbstractOptionT } from '@resnet/client-shared-web/shared/gdl/types/abstract-option'

import type { OptionContainerT } from '../../types/option-container'
import { toPx } from '../../utils/to-px'
import type { DropdownInputTextPropsT } from '../dropdown-input'
import {
  DropdownInputMedia,
  DropdownInputPlaceholder,
  DropdownInputText,
  DropdownInputTextSkeleton,
} from '../dropdown-input'
import { renderCount } from '../dropdown-input/utils/render-count'
import { defaultNullOptionLabel } from '../select-dropdown/constants/null-option'

export type MultipleDropdownTextPropsT<
  OptionT extends AbstractOptionT,
  ItemT extends null | OptionT['id'] = OptionT['id'],
> = {
  getOptionLabel: (option: OptionT) => React.ReactNode
  getOptionMedia?: (option: OptionT) => MediaT
  mode?: FilterModeT
  multipleOptionSuffix?: string
  nullOptionLabel?: React.ReactNode
  onCurrentValueQueryError?: () => void
  OptionContainer: OptionContainerT<OptionT>
  placeholder?: string
  size?: DropdownInputTextPropsT['size']
  value?: null | ItemT[]
}

export const MultipleDropdownText = <
  OptionT extends AbstractOptionT,
  ItemT extends null | OptionT['id'] = OptionT['id'],
>({
  getOptionLabel,
  getOptionMedia,
  mode,
  multipleOptionSuffix = 'selected',
  nullOptionLabel = defaultNullOptionLabel,
  onCurrentValueQueryError,
  OptionContainer,
  placeholder = 'Not Selected',
  size,
  value,
}: MultipleDropdownTextPropsT<OptionT, ItemT>) => {
  const prefix = mode === FilterModeT.ExcludeT ? 'All except' : ''

  const notFoundLabel = 'Not Found'

  if (!value || value.length === 0) {
    return (
      <DropdownInputPlaceholder size={size}>
        {mode === FilterModeT.ExcludeT ? 'All Selected' : placeholder}
      </DropdownInputPlaceholder>
    )
  }

  if (value.length === 1) {
    const item = value[0]

    if (item === null) {
      return (
        <DropdownInputText size={size}>
          <ChildrenDivider dividerNode={' '}>
            {prefix}
            {nullOptionLabel}
          </ChildrenDivider>
        </DropdownInputText>
      )
    }

    return (
      <ErrorBoundary
        fallback={({ onRecover, recoveredCount }) => (
          <ErrorBoundaryFallbackEffectContainer
            onError={onCurrentValueQueryError}
            onRecover={recoveredCount > 0 ? undefined : onRecover}
          >
            {() => (
              <DropdownInputPlaceholder>
                <ChildrenDivider dividerNode={' '}>
                  {prefix}
                  {notFoundLabel}
                </ChildrenDivider>
              </DropdownInputPlaceholder>
            )}
          </ErrorBoundaryFallbackEffectContainer>
        )}
      >
        <OptionContainer id={item}>
          {({ optionQuery }) => {
            if (!optionQuery.isSuccess) {
              return <DropdownInputTextSkeleton size={size} />
            }

            const option = optionQuery.data

            const media = getOptionMedia?.(option)

            if (media) {
              return (
                <Box sx={{ alignItems: 'center', display: 'flex', gap: toPx(8), minWidth: 0 }}>
                  <DropdownInputMedia
                    media={media}
                    size={size}
                  />
                  <DropdownInputText size={size}>
                    <ChildrenDivider dividerNode={' '}>
                      {prefix}
                      {getOptionLabel(option)}
                    </ChildrenDivider>
                  </DropdownInputText>
                </Box>
              )
            }

            return (
              <DropdownInputText size={size}>
                <ChildrenDivider dividerNode={' '}>
                  {prefix}
                  {getOptionLabel(option)}
                </ChildrenDivider>
              </DropdownInputText>
            )
          }}
        </OptionContainer>
      </ErrorBoundary>
    )
  }

  return (
    <DropdownInputText size={size}>
      <ChildrenDivider dividerNode={' '}>
        {prefix}
        {renderCount(value.length)}
        {multipleOptionSuffix}
      </ChildrenDivider>
    </DropdownInputText>
  )
}
