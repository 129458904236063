import { Box } from '@mui/material'
import type { UseFormReturn } from 'react-hook-form'
import { useController } from 'react-hook-form'

import { getSpecialValuesFieldProps } from '@resnet/client-shared/shared/forms/utils/get-special-values-field-props'

import { type ButtonBasePropsT } from '@resnet/client-shared-web/shared/gdl/components/button'
import { Field, FieldLabel } from '@resnet/client-shared-web/shared/gdl/components/field'

import { CampaignSelectButtonBase } from '@resnet/client-web/shared/campaigns/components/campaign-select-button'

export type IssueCampaignSelectFieldPropsT = {
  form: UseFormReturn<{ campaignId?: null | string | undefined }>
  size: ButtonBasePropsT<'button'>['size']
  label?: string
}

export const IssueCampaignSelectField = ({ form, size, label }: IssueCampaignSelectFieldPropsT) => {
  const { control } = form

  const {
    field: { onChange },
    field,
  } = useController({ control, name: 'campaignId' })

  const specialValuesFieldProps = getSpecialValuesFieldProps(field)

  const campaignId = !specialValuesFieldProps ? field.value : specialValuesFieldProps.value

  const renderHeader = () => {
    if (!label) {
      return <Box sx={{ height: '24px' }} />
    }

    return <FieldLabel>{label}</FieldLabel>
  }

  const renderInput = () => {
    return (
      <CampaignSelectButtonBase
        buttonSize={size}
        buttonVariant="outlined"
        placeholder={specialValuesFieldProps?.placeholder}
        sx={{ flexDirection: 'column' }}
        value={campaignId}
        onChange={onChange}
      />
    )
  }

  return (
    <Field sx={{ flexGrow: 1, width: 0 }}>
      {renderHeader()}
      {renderInput()}
    </Field>
  )
}
