import { Box } from '@mui/material'
import { memo } from 'react'
import type { Path, PathValue, UseControllerProps, UseFormReturn } from 'react-hook-form'
import { useController } from 'react-hook-form'

import { mapToValues } from '@resnet/client-common/common/utils/object/map-to-values'
import type { AnyTypeT } from '@resnet/client-common/typescript/types/any-type'

import { EntityTypeIdsT } from '@resnet/client-api/api'

import { getSpecialValuesMultipleFieldProps } from '@resnet/client-shared/shared/forms/utils/get-special-values-field-props'

import { Field, FieldErrorText } from '@resnet/client-shared-web/shared/gdl/components/field'
import { FieldLabel } from '@resnet/client-shared-web/shared/gdl/components/field'
import { toPx } from '@resnet/client-shared-web/shared/gdl/utils/to-px'

import { EntitySelectMultiple } from '@resnet/client-web/shared/entities/selects/entity-select'

const entityTypeIdsAll = mapToValues(EntityTypeIdsT)

type EntitySelectFieldPropsT<FieldValuesT extends Record<string, AnyTypeT>, FieldNameT extends Path<FieldValuesT>> = {
  defaultValue?: PathValue<FieldValuesT, FieldNameT>
  form: UseFormReturn<FieldValuesT>
  headerRight?: React.ReactNode
  name: FieldNameT
  label: string
  entityTypeIds?: EntityTypeIdsT[]
  rules?: UseControllerProps<FieldValuesT, FieldNameT>['rules']
  nullOptionLabel?: React.ReactNode
}

export const EntitySelectMultipleField = memo(
  <FieldValuesT extends Record<string, AnyTypeT>, FieldNameT extends Path<FieldValuesT>>({
    form,
    name,
    label,
    rules,
    defaultValue,
    headerRight,
    entityTypeIds = entityTypeIdsAll,
  }: EntitySelectFieldPropsT<FieldValuesT, FieldNameT>) => {
    const {
      field,
      fieldState: { error, invalid },
    } = useController({ control: form.control, defaultValue, name, rules })

    const renderHeader = () => {
      return (
        <Box sx={{ alignItems: 'center', display: 'flex', gap: toPx(16) }}>
          <FieldLabel>{label}</FieldLabel>
          {headerRight}
        </Box>
      )
    }

    const renderInput = () => {
      const specialValuesFieldProps = getSpecialValuesMultipleFieldProps(field)

      return (
        <EntitySelectMultiple
          hasError={invalid}
          {...field}
          {...specialValuesFieldProps}
          categoryIds={entityTypeIds}
          onChange={(value: PathValue<FieldValuesT, FieldNameT>) => {
            field.onChange(value)
          }}
        />
      )
    }

    const renderFooter = () => {
      if (!error) {
        return null
      }

      return <FieldErrorText>{error.message as string}</FieldErrorText>
    }

    return (
      <Field sx={{ flexGrow: 1, width: 0 }}>
        {renderHeader()}
        {renderInput()}
        {renderFooter()}
      </Field>
    )
  },
)
