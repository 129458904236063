import { stringifySearchParams } from '@resnet/client-common/network/utils/stringify-search-params'
import type { ValuesT } from '@resnet/client-common/typescript/types/values'

import type { themes } from '@resnet/client-shared/shared/gdl/themes'

const googleMapsKey = process.env.PUBLIC_GOOGLE_MAPS_KEY

export const mapCoordinatesToMapResource = ({
  coordinates,
  themeProps,
}: {
  coordinates: undefined | null | [number, number]
  themeProps: ValuesT<typeof themes>
}) => {
  if (!googleMapsKey || !coordinates) {
    return undefined
  }

  const [lon, lat] = coordinates

  const color = themeProps.colors.basePrimary.replace('#', '0x')

  const searchParams = {
    key: googleMapsKey,
    // eslint-disable-next-line spellcheck/spell-checker
    maptype: 'satellite',
    markers: `color:${color}%7C${lat},${lon}`,
    size: '344x344',
    zoom: '15',
  }

  return { url: 'https://maps.googleapis.com/maps/api/staticmap' + stringifySearchParams(searchParams) }
}
