import { useEventCallback } from '@resnet/client-common/react/hooks/use-event-callback'

import type { AbstractOptionT } from '../../../types/abstract-option'

export type UseNonNullableChangeHandlerPropsT<OptionT extends AbstractOptionT> = {
  onChange: (optionId: OptionT['id']) => void
  defaultOption: OptionT
}

export const useNonNullableChangeHandler = <OptionT extends AbstractOptionT>({
  onChange: onChangeActual,
  defaultOption,
}: UseNonNullableChangeHandlerPropsT<OptionT>) => {
  const onChange = useEventCallback((optionIdActual: undefined | null | OptionT['id']) => {
    const optionId = optionIdActual ?? defaultOption.id

    onChangeActual(optionId)
  })

  return { onChange }
}
