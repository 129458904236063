import { Box, Drawer as MUIDrawer } from '@mui/material'
import { Suspense, createContext } from 'react'

import type { OmitDistributiveT } from '@resnet/client-common/common/types/common'
import { ChildrenDivider } from '@resnet/client-common/react/components/children-transformer'
import { ErrorBoundary } from '@resnet/client-common/react/components/error-boundary'
import { MemoProvider } from '@resnet/client-common/react/components/memo-provider'
import { useNonNullableContext } from '@resnet/client-common/react/hooks/use-non-nullable-context'

import XmarkSolidIcon from '@resnet/client-shared/assets/icons/xmark-solid.svg'

import { ErrorPanel } from '@resnet/client-shared-web/shared/common/components/error-panel'
import { SidebarHeader, SidebarTitle } from '@resnet/client-shared-web/shared/common/components/sidebar'
import type { ButtonPropsT } from '@resnet/client-shared-web/shared/gdl/components/button'
import { Button } from '@resnet/client-shared-web/shared/gdl/components/button'
import { Divider } from '@resnet/client-shared-web/shared/gdl/components/divider'
import { Spinner } from '@resnet/client-shared-web/shared/gdl/components/spinner'
import { themeColors } from '@resnet/client-shared-web/shared/gdl/constants/theme-colors'
import { toPx } from '@resnet/client-shared-web/shared/gdl/utils/to-px'
import {
  layoutSidebarBorderLeft,
  layoutSidebarHorizontalPadding,
  layoutSidebarVerticalPadding,
  layoutSidebarWidth,
} from '@resnet/client-shared-web/shared/layout/constants/common'
import { useDrawersRegistry } from '@resnet/client-shared-web/shared/registry/hooks/use-drawers-registry'
import { childrenNoShrinkStyles } from '@resnet/client-shared-web/styles/sx-presets'

import { mapBorderWidthToValue } from '../../utils/map-border-width-to-value'
import { mapPaddingToValue } from '../../utils/map-padding-to-value'

export const DrawerContext = createContext<null | { onClose?: () => void }>(null)

export const Drawer = ({
  isOpened: opened,
  onClose,
  children,
}: {
  isOpened: boolean
  onClose?: () => void
  children: React.ReactNode
}): React.ReactElement => {
  const { isFirstDrawerOpened } = useDrawersRegistry({ open: opened })

  const borderWidth = { left: layoutSidebarBorderLeft }

  const padding = {
    bottom: layoutSidebarVerticalPadding,
    left: layoutSidebarHorizontalPadding,
    right: layoutSidebarHorizontalPadding,
    top: layoutSidebarVerticalPadding,
  }

  return (
    <MUIDrawer
      PaperProps={{
        sx: [
          {
            backgroundColor: themeColors.background,
            backgroundImage: 'none',
            borderColor: themeColors.borderBold,
            borderStyle: 'solid',
            borderWidth: mapBorderWidthToValue({ borderWidth }),
            display: 'flex',
            flexDirection: 'column',
            overflow: 'unset',
            padding: mapPaddingToValue({ borderWidth, padding }),
            width: toPx(layoutSidebarWidth),
          },
          childrenNoShrinkStyles,
        ].flat(),
      }}
      anchor="right"
      open={opened}
      sx={{
        '& .MuiBackdrop-root': {
          opacity: `${isFirstDrawerOpened ? 1 : 0} !important`,
        },
      }}
      onClose={onClose}
    >
      <MemoProvider
        Context={DrawerContext}
        value={{ onClose }}
      >
        <ErrorBoundary fallback={() => <ErrorPanel />}>
          <Suspense
            fallback={
              <Box sx={{ alignItems: 'center', display: 'flex', flexGrow: 1, justifyContent: 'center' }}>
                <Spinner />
              </Box>
            }
          >
            {children}
          </Suspense>
        </ErrorBoundary>
      </MemoProvider>
    </MUIDrawer>
  )
}

export type DrawerCloseButtonPropsT = OmitDistributiveT<
  ButtonPropsT,
  'color' | 'icon' | 'size' | 'variant' | 'onClick' | 'children'
>

export const DrawerCloseButton = (props: DrawerCloseButtonPropsT) => {
  const { onClose } = useNonNullableContext(DrawerContext)

  return (
    <Button
      {...props}
      color="default"
      data-testid="drawer-close-button"
      icon={<XmarkSolidIcon />}
      size="sm"
      variant="contained"
      onClick={onClose}
    />
  )
}

export const DrawerContent = ({
  sx,
  children,
  ...props
}: Omit<React.ComponentProps<typeof Box>, 'children'> & {
  children: {
    header?: {
      title?: React.ReactNode
    }
    content: React.ReactNode
  }
}): React.ReactElement => {
  return (
    <Box
      {...props}
      sx={[{ display: 'flex', flexDirection: 'column', flexGrow: 1, gap: toPx(16) }, sx ?? null].flat()}
    >
      <ChildrenDivider dividerNode={<Divider />}>
        <SidebarHeader>
          <SidebarTitle>{children?.header?.title}</SidebarTitle>
          <DrawerCloseButton />
        </SidebarHeader>
        <Box sx={{ display: 'flex', flexDirection: 'column', flexGrow: 1 }}>{children.content}</Box>
      </ChildrenDivider>
    </Box>
  )
}
