import type { FieldPathByValue, FieldValues, PathValue, UseFormReturn } from 'react-hook-form'

import { checkNonNullable } from '@resnet/client-common/common/utils/nullable/non-nullable'
import { assert } from '@resnet/client-common/typescript/utils/assert'

import { CustomFieldTypeT, type CustomFieldFragmentT } from '@resnet/client-api/api'

import type { CheckboxCustomFieldValueT } from '@resnet/client-shared/shared/custom-fields/presets/checkbox/custom-field-value'

import { CheckboxField } from '@resnet/client-shared-web/shared/form/components/common/checkbox-field'

import { ObjectDetailsRow } from '@resnet/client-web/shared/object/components/object-details-row'

export type CheckboxCustomFieldUserFormFieldPropsT<
  TFieldValues extends FieldValues,
  TPath extends FieldPathByValue<TFieldValues, undefined | CheckboxCustomFieldValueT>,
> = {
  field: CustomFieldFragmentT
  form: UseFormReturn<TFieldValues>
  name: TPath
}

export const CheckboxCustomFieldUserFormField = <
  TFieldValues extends FieldValues,
  TPath extends FieldPathByValue<TFieldValues, undefined | CheckboxCustomFieldValueT>,
>({
  field,
  form,
  name,
}: CheckboxCustomFieldUserFormFieldPropsT<TFieldValues, TPath>) => {
  const payload = field.payload

  assert(payload, checkNonNullable)

  const checkboxPayload = payload[CustomFieldTypeT.CheckboxT]

  assert(checkboxPayload, checkNonNullable)

  const { defaultValue } = checkboxPayload

  const getSizeSx = () => {
    return {
      gridColumn: 'span 2',
    }
  }

  return (
    <ObjectDetailsRow sx={getSizeSx()}>
      <CheckboxField
        defaultValue={defaultValue as PathValue<TFieldValues, TPath>}
        form={form}
        label={field.name}
        name={name}
      />
    </ObjectDetailsRow>
  )
}
