import { mapResourceObjectTypeToTitle } from '@resnet/client-shared/shared/resource-objects/utils/map-resource-object-type-to-title'

import { createSelect } from '@resnet/client-shared-web/shared/common/factories/create-select'

import { ResourceObjectTypeOptionContainer } from '../../hooks/use-resource-object-type-option'
import {
  ResourceObjectTypeSelectDropdownContainer,
  ResourceObjectTypeSelectWithActionsDropdownContainer,
} from '../../hooks/use-resource-object-type-select-dropdown'

export const ResourceObjectTypeSelect = createSelect({
  OptionContainer: ResourceObjectTypeOptionContainer,
  SelectDropdownContainer: ResourceObjectTypeSelectDropdownContainer,
  getOptionLabel: mapResourceObjectTypeToTitle,
})

export const ResourceObjectTypeWithActionsSelect = createSelect({
  OptionContainer: ResourceObjectTypeOptionContainer,
  SelectDropdownContainer: ResourceObjectTypeSelectWithActionsDropdownContainer,
  getOptionLabel: mapResourceObjectTypeToTitle,
})
