import { Box } from '@mui/material'
import { useContext } from 'react'

import { assertedNonNullable } from '@resnet/client-common/common/utils/nullable/non-nullable'
import { useAsyncEventCallback } from '@resnet/client-common/react/hooks/use-async-event-callback'

import { typographyPresets } from '@resnet/client-shared/shared/gdl/constants/typography-presets'

import { Button } from '@resnet/client-shared-web/shared/gdl/components/button'
import {
  ModalContent,
  ModalContext,
  createModalContainer,
  createModalHook,
} from '@resnet/client-shared-web/shared/gdl/components/modal'
import { themeColors } from '@resnet/client-shared-web/shared/gdl/constants/theme-colors'
import { mapTypographyPresetToSx } from '@resnet/client-shared-web/shared/gdl/utils/map-typography-preset-to-sx'
import { toPx } from '@resnet/client-shared-web/shared/gdl/utils/to-px'

export type ConfirmModalPropsT = {
  title?: string
  message?: React.ReactNode
  renderContent?: () => React.ReactNode
  onConfirm?: (() => void) | (() => Promise<unknown>)
  onSuccess?: () => void
  onFail?: (error: unknown) => void
  confirmButtonText?: string
  abortButtonText?: string
  isDangerAction?: boolean
  isConfirmDisabled?: boolean
  isAbortDisabled?: boolean
}

export const ConfirmModal = ({
  onSuccess,
  onFail,
  message,
  renderContent: renderContentActual,
  title = 'Confirm',
  onConfirm: onConfirmProp,
  isDangerAction = true,
  confirmButtonText = 'Proceed',
  abortButtonText = 'Cancel',
  isConfirmDisabled = false,
  isAbortDisabled = false,
}: ConfirmModalPropsT) => {
  const { onClose } = assertedNonNullable(useContext(ModalContext))

  const [isConfirmLoading, onConfirm] = useAsyncEventCallback(async () => {
    try {
      await onConfirmProp?.()
      onSuccess?.()
    } catch (error) {
      onFail?.(error)
    }
  })

  const renderHeaderTitle = (): React.ReactNode => {
    return title
  }

  const renderHeader = (): { title: React.ReactNode } => {
    return {
      title: renderHeaderTitle(),
    }
  }

  const renderContent = (): React.ReactNode => {
    return (
      <Box
        sx={[
          mapTypographyPresetToSx(typographyPresets.bodyMedium),
          { color: themeColors.overBackgroundDefault },
        ].flat()}
      >
        {message}
      </Box>
    )
  }

  const renderFooter = (): React.ReactNode => {
    return (
      <Box
        sx={{
          alignItems: 'center',
          display: 'flex',
          flexDirection: !isDangerAction ? 'row-reverse' : 'row',
          gap: toPx(8),
          justifyContent: 'space-between',
        }}
      >
        <Button
          color={!isDangerAction ? 'primary' : 'danger'}
          disabled={isConfirmDisabled}
          isLoading={isConfirmLoading}
          size="md"
          variant="contained"
          onClick={onConfirm}
        >
          {confirmButtonText}
        </Button>
        <Button
          color="primary"
          disabled={isAbortDisabled}
          size="md"
          variant="outlined"
          onClick={onClose}
        >
          {abortButtonText}
        </Button>
      </Box>
    )
  }

  return (
    <ModalContent sx={{ maxWidth: toPx(512), width: '100%' }}>
      {{
        content: renderContentActual?.() ?? renderContent(),
        footer: renderFooter(),
        header: renderHeader(),
      }}
    </ModalContent>
  )
}

export const useConfirmModal = createModalHook((params: React.ComponentProps<typeof ConfirmModal>) => (
  <ConfirmModal {...params} />
))

export const ConfirmModalContainer = createModalContainer(useConfirmModal)
