import type { SxProps } from '@mui/material'
import { Box } from '@mui/material'
import type { VirtualItem, Virtualizer } from '@tanstack/react-virtual'

import type { AbstractOptionT } from '../../types/abstract-option'
import { toPx } from '../../utils/to-px'
import { EndBoundary } from '../bottom-boundary'

export type VirtualizedListItemPropsT = {
  children: React.ReactNode
  gap?: number
  virtualItem: VirtualItem
  virtualizer: Virtualizer<HTMLDivElement, Element>
}

export const VirtualizedListItem = ({ children, gap = 0, virtualItem, virtualizer }: VirtualizedListItemPropsT) => {
  return (
    <Box
      data-index={virtualItem.index}
      key={virtualItem.key}
      ref={virtualizer.measureElement}
      style={{
        transform: `translateY(${toPx(virtualItem.start)})`,
      }}
      sx={[
        {
          left: 0,
          paddingBottom: toPx(gap),
          position: 'absolute',
          top: 0,
          width: '100%',
        },
      ]}
    >
      {children}
    </Box>
  )
}

export type VirtualizedListPropsT<ItemT> = {
  disableScrollY?: boolean
  gap?: number
  items: ItemT[]
  noScroll?: boolean
  onEndReached?: () => void
  renderItem: (props: { item: ItemT }) => React.ReactNode
  sx?: SxProps
  virtualizedListInnerRef?: React.Ref<HTMLDivElement>
  virtualizedListRef?: React.Ref<HTMLDivElement>
  virtualizer: Virtualizer<HTMLDivElement, Element>
}

export const VirtualizedList = <ItemT extends AbstractOptionT>({
  disableScrollY,
  gap = 0,
  items,
  noScroll,
  onEndReached,
  renderItem,
  sx = null,
  virtualizedListInnerRef,
  virtualizedListRef,
  virtualizer,
}: VirtualizedListPropsT<ItemT>) => {
  const virtualItems = virtualizer.getVirtualItems()

  const height = virtualizer.getTotalSize()

  const renderEndBoundary = () => {
    if (!onEndReached) {
      return null
    }

    return (
      <EndBoundary
        sx={{ bottom: 0, left: 0, position: 'absolute', right: 0 }}
        onObserve={onEndReached}
      />
    )
  }

  return (
    <Box
      ref={virtualizedListRef}
      style={{
        height: noScroll ? toPx(height - gap) : 0,
      }}
      sx={[
        {
          contain: 'strict',
          display: 'flex',
          flexDirection: 'column',
          flexGrow: 1,
          flexShrink: 0,
          overflowY: noScroll ? 'hidden' : disableScrollY ? 'hidden' : 'auto',
        },
        sx,
      ].flat()}
    >
      <Box
        ref={virtualizedListInnerRef}
        style={{
          height: toPx(height - gap),
        }}
        sx={{
          display: 'flex',
          flexDirection: 'column',
          flexGrow: 1,
          flexShrink: 0,
          overflow: 'hidden',
          position: 'relative',
        }}
      >
        {virtualItems.map((virtualItem) => {
          const item = items[virtualItem.index]

          return (
            <VirtualizedListItem
              gap={gap}
              key={virtualItem.key}
              virtualItem={virtualItem}
              virtualizer={virtualizer}
            >
              {renderItem({ item })}
            </VirtualizedListItem>
          )
        })}
        {renderEndBoundary?.()}
      </Box>
    </Box>
  )
}
