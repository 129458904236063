import { produce } from 'immer'

import { assertedNonNullable } from '@resnet/client-common/common/utils/nullable/non-nullable'

import {
  useCommentsQuery,
  useCreateCommentMutation,
  useDeleteCommentMutation,
  useInfiniteCommentsQuery,
  useLikeCommentMutation,
  useUnlikeCommentMutation,
  useUpdateCommentMutation,
} from '@resnet/client-api/api'
import type { MutationCacheHandlersT } from '@resnet/client-api/services/query-client/types/mutation-cache-handlers'
import { createMutationHookMutationCacheHandlers } from '@resnet/client-api/services/query-client/utils/create-mutation-hook-mutation-cache-handlers'
import { findInfiniteQueries, findQueries } from '@resnet/client-api/utils/find-queries'
import { invalidateQuery } from '@resnet/client-api/utils/invalidate-query'
import { setQueryData } from '@resnet/client-api/utils/set-query-data'
import type { QueryClient } from '@resnet/client-api/vendors/react-query'

export const commentsQueriesHandlers = ({ queryClient }: { queryClient: QueryClient }): MutationCacheHandlersT[] => {
  const findCommentsQueries = ({ commentId }: { commentId: string }) =>
    findQueries(queryClient, useCommentsQuery, (query) => {
      return assertedNonNullable(query.state.data?.listComments?.items).some((item) => item.id === commentId)
    })

  const findCommentsQueriesByPostId = ({ postId }: { postId: string }) =>
    findQueries(queryClient, useCommentsQuery, (query) => {
      return query.queryKey[1]?.filter?.postId === postId
    })

  const createCommentHandlers = () => {
    return createMutationHookMutationCacheHandlers(useCreateCommentMutation, {
      onSuccess: (data, { postId }) => {
        findCommentsQueriesByPostId({ postId }).forEach(invalidateQuery(queryClient))
      },
    })
  }

  const updateCommentHandlers = () => {
    return createMutationHookMutationCacheHandlers(useUpdateCommentMutation, {
      onError: (error, { id }) => {
        findCommentsQueries({ commentId: id }).forEach(invalidateQuery(queryClient))
      },
      onMutate: ({ id, comment }) => {
        findCommentsQueries({ commentId: id }).forEach((query) => {
          setQueryData(queryClient)(query)(
            produce((draft) => {
              const items = draft?.listComments?.items

              if (!items) {
                return
              }

              const item = items.find((item) => item.id === id)

              if (!item) {
                return
              }

              Object.assign(item, comment, {
                updatedAt: new Date().toISOString(),
              })
            }),
          )
        })
      },
      onSuccess: (data, { id }) => {
        findCommentsQueries({ commentId: id }).forEach(invalidateQuery(queryClient))
      },
    })
  }

  const deleteCommentHandlers = () => {
    return createMutationHookMutationCacheHandlers(useDeleteCommentMutation, {
      onError: (error, { id }) => {
        findCommentsQueries({ commentId: id }).forEach(invalidateQuery(queryClient))
      },
      onMutate: ({ id }) => {
        findCommentsQueries({ commentId: id }).forEach((query) => {
          setQueryData(queryClient)(query)(
            produce((draft) => {
              const items = draft?.listComments?.items

              if (!items) {
                return
              }

              const itemIndex = items.findIndex((item) => item.id === id)

              if (itemIndex < 0) {
                return
              }

              items.splice(itemIndex, 1)
            }),
          )
        })
      },
      onSuccess: (data, { id }) => {
        findCommentsQueries({ commentId: id }).forEach(invalidateQuery(queryClient))
      },
    })
  }

  const likeCommentHandlers = () => {
    return createMutationHookMutationCacheHandlers(useLikeCommentMutation, {
      onError: (error, { commentId }) => {
        findCommentsQueries({ commentId }).forEach(invalidateQuery(queryClient))
      },
      onMutate: ({ commentId }) => {
        findCommentsQueries({ commentId }).forEach((query) => {
          setQueryData(queryClient)(query)(
            produce((draft) => {
              const items = draft?.listComments?.items

              if (!items) {
                return
              }

              const item = items.find((item) => item.id === commentId)

              if (!item) {
                return
              }

              Object.assign(item, { likedByMe: true, totalLikes: (item.totalLikes ?? 0) + 1 })
            }),
          )
        })
      },
      onSuccess: (data, { commentId }) => {
        findCommentsQueries({ commentId }).forEach(invalidateQuery(queryClient))
      },
    })
  }

  const unlikeCommentHandlers = () => {
    return createMutationHookMutationCacheHandlers(useUnlikeCommentMutation, {
      onError: (error, { commentId }) => {
        findCommentsQueries({ commentId }).forEach(invalidateQuery(queryClient))
      },
      onMutate: ({ commentId }) => {
        findCommentsQueries({ commentId }).forEach((query) => {
          setQueryData(queryClient)(query)(
            produce((draft) => {
              const items = draft?.listComments?.items

              if (!items) {
                return
              }

              const item = items.find((item) => item.id === commentId)

              if (!item) {
                return
              }

              Object.assign(item, { likedByMe: false, totalLikes: (item.totalLikes ?? 1) - 1 })
            }),
          )
        })
      },
      onSuccess: (data, { commentId }) => {
        findCommentsQueries({ commentId }).forEach(invalidateQuery(queryClient))
      },
    })
  }

  return [
    createCommentHandlers(),
    updateCommentHandlers(),
    deleteCommentHandlers(),
    likeCommentHandlers(),
    unlikeCommentHandlers(),
  ]
}

export const infiniteCommentsQueriesHandlers = ({
  queryClient,
}: {
  queryClient: QueryClient
}): MutationCacheHandlersT[] => {
  const findCommentsQueries = ({ commentId }: { commentId: string }) =>
    findInfiniteQueries(queryClient, useInfiniteCommentsQuery, (query) => {
      return assertedNonNullable(query.state.data)
        .pages.flatMap((page) => assertedNonNullable(page.listComments?.items))
        .some((item) => item.id === commentId)
    })

  const findCommentsQueriesByPostId = ({ postId }: { postId: string }) =>
    findInfiniteQueries(queryClient, useInfiniteCommentsQuery, (query) => {
      return query.queryKey[1]?.filter?.postId === postId
    })

  const createCommentHandlers = () => {
    return createMutationHookMutationCacheHandlers(useCreateCommentMutation, {
      onSuccess: (data, { postId }) => {
        findCommentsQueriesByPostId({ postId }).forEach(invalidateQuery(queryClient))
      },
    })
  }

  const updateCommentHandlers = () => {
    return createMutationHookMutationCacheHandlers(useUpdateCommentMutation, {
      onError: (error, { id }) => {
        findCommentsQueries({ commentId: id }).forEach(invalidateQuery(queryClient))
      },
      onMutate: ({ id, comment }) => {
        findCommentsQueries({ commentId: id }).forEach((query) => {
          setQueryData(queryClient)(query)(
            produce((draft) => {
              const pages = draft?.pages

              if (!pages) {
                return
              }

              pages.forEach((page) => {
                const items = page?.listComments?.items

                if (!items) {
                  return
                }

                const item = items.find((item) => item.id === id)

                if (!item) {
                  return
                }

                Object.assign(item, comment, {
                  updatedAt: new Date().toISOString(),
                })
              })
            }),
          )
        })
      },
      onSuccess: (data, { id }) => {
        findCommentsQueries({ commentId: id }).forEach(invalidateQuery(queryClient))
      },
    })
  }

  const likeCommentHandlers = () => {
    return createMutationHookMutationCacheHandlers(useLikeCommentMutation, {
      onError: (error, { commentId }) => {
        findCommentsQueries({ commentId }).forEach(invalidateQuery(queryClient))
      },
      onMutate: ({ commentId }) => {
        findCommentsQueries({ commentId }).forEach((query) => {
          setQueryData(queryClient)(query)(
            produce((draft) => {
              const pages = draft?.pages

              if (!pages) {
                return
              }

              pages.forEach((page) => {
                const items = page?.listComments?.items

                if (!items) {
                  return
                }

                const item = items.find((item) => item.id === commentId)

                if (!item) {
                  return
                }

                Object.assign(item, { likedByMe: true, totalLikes: (item.totalLikes ?? 0) + 1 })
              })
            }),
          )
        })
      },
      onSuccess: (data, { commentId }) => {
        findCommentsQueries({ commentId }).forEach(invalidateQuery(queryClient))
      },
    })
  }

  const unlikeCommentHandlers = () => {
    return createMutationHookMutationCacheHandlers(useUnlikeCommentMutation, {
      onError: (error, { commentId }) => {
        findCommentsQueries({ commentId }).forEach(invalidateQuery(queryClient))
      },
      onMutate: ({ commentId }) => {
        findCommentsQueries({ commentId }).forEach((query) => {
          setQueryData(queryClient)(query)(
            produce((draft) => {
              const pages = draft?.pages

              if (!pages) {
                return
              }

              pages.forEach((page) => {
                const items = page?.listComments?.items

                if (!items) {
                  return
                }

                const item = items.find((item) => item.id === commentId)

                if (!item) {
                  return
                }

                Object.assign(item, { likedByMe: false, totalLikes: (item.totalLikes ?? 1) - 1 })
              })
            }),
          )
        })
      },
      onSuccess: (data, { commentId }) => {
        findCommentsQueries({ commentId }).forEach(invalidateQuery(queryClient))
      },
    })
  }

  return [createCommentHandlers(), updateCommentHandlers(), likeCommentHandlers(), unlikeCommentHandlers()]
}
