import { flattenRoutes } from '../utils/flatten-routes'

import { importsScreenRoutes } from './imports-screen-routes'

export const adminPanelScreenRoutes = flattenRoutes({
  billing: '/billing',
  importWizard: ['/import-wizard', importsScreenRoutes],
  integrationHub: '/integration-hub',
  organizationSetting: '/organization-settings',
  users: '/users',
})
