import type { OmitDistributiveT } from '@resnet/client-common/common/types/common'

import type { RegularCellPropsT } from '../regular-cell'
import { RegularCell } from '../regular-cell'

export type EmptyCellPropsT = OmitDistributiveT<RegularCellPropsT, 'children'>

export const EmptyCell = (props: EmptyCellPropsT) => {
  return <RegularCell {...props}>-</RegularCell>
}
