import { useBooleanHandlers } from '@resnet/client-common/react/hooks/use-boolean-handlers'
import { useLocationStateBoolean } from '@resnet/client-common/react/hooks/use-location-state-boolean'
import { createHookContainer } from '@resnet/client-common/react/utils/create-hook-container'

export const IS_OPENED_FORM_OBJECTS_DRAWER_KEY = 'isOpenedFormObjectsDrawer'

export const useFormObjectsDrawer = () => {
  const [opened, setOpened] = useLocationStateBoolean(IS_OPENED_FORM_OBJECTS_DRAWER_KEY)

  const { onDisable: onClose, onEnable: onOpen } = useBooleanHandlers({ setState: setOpened })

  return { onClose, onOpen, opened }
}

export const FormObjectsDrawerContainer = createHookContainer(useFormObjectsDrawer)
