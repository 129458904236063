import { ObjectId } from 'bson'

import { checkNonNullable } from '@resnet/client-common/common/utils/nullable/non-nullable'
import { assert } from '@resnet/client-common/typescript/utils/assert'

import { CustomFieldTypeT } from '@resnet/client-api/api'

import type {
  AttachmentCustomFieldValueT,
  AttachmentMultipleCustomFieldValueT,
  AttachmentSingleCustomFieldValueT,
} from '@resnet/client-shared/shared/custom-fields/presets/attachment/custom-field-value'

import type { CustomFieldT } from '../../types/custom-field'

import type { AttachmentFormCustomFieldValueT } from './form-custom-field-value'

export const patchCustomFieldValueResources: CustomFieldT<
  AttachmentCustomFieldValueT,
  AttachmentFormCustomFieldValueT
>['patchCustomFieldValueResources'] = ({ value, field, resourcesRef }) => {
  const payload = field.payload

  assert(payload, checkNonNullable)

  const attachmentPayload = payload[CustomFieldTypeT.AttachmentT]

  assert(attachmentPayload, checkNonNullable)

  const { multiple } = attachmentPayload

  if (multiple) {
    const attachmentMultipleFieldValue = value as undefined | AttachmentMultipleCustomFieldValueT

    if (!attachmentMultipleFieldValue) {
      return attachmentMultipleFieldValue
    }

    return attachmentMultipleFieldValue.map((resourceId) => {
      const newResourceId = new ObjectId().toHexString()

      resourcesRef[resourceId] = newResourceId

      return newResourceId
    })
  }

  const attachmentSingleFieldValue = value as undefined | AttachmentSingleCustomFieldValueT

  if (!attachmentSingleFieldValue) {
    return attachmentSingleFieldValue
  }

  const newResourceId = new ObjectId().toHexString()

  resourcesRef[attachmentSingleFieldValue] = newResourceId

  return newResourceId
}
