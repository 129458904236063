const TO_FIXED_MIN = 0

const TO_FIXED_MAX = 20

export const toPrecision =
  (fn: (number: number) => number) =>
  (number: number, precision: number): number =>
    parseFloat(
      (fn(number / precision) * precision).toFixed(
        Math.min(Math.max(TO_FIXED_MIN, Math.ceil(-Math.log10(precision))), TO_FIXED_MAX),
      ),
    )

export const roundToPrecision = toPrecision(Math.round)

export const ceilToPrecision = toPrecision(Math.ceil)

export const floorToPrecision = toPrecision(Math.floor)
