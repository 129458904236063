import { useSnackbar } from 'notistack'
import { useCallback } from 'react'

import { mapErrorToMessage } from '@resnet/client-common/network/utils/map-error-to-message'

import { useUpdateResourceObjectTypeMutation, type ResourceObjectTypeFragmentT } from '@resnet/client-api/api'

import DeleteSolidIcon from '@resnet/client-shared/assets/icons/delete-solid.svg'
import PenSolidIcon from '@resnet/client-shared/assets/icons/pen-solid.svg'
import { useProfile } from '@resnet/client-shared/shared/users/hooks/use-profile'
import { checkHasAdminRights } from '@resnet/client-shared/shared/users/utils/check-has-admin-rights'

import type { ListActionItemActionPropsT } from '@resnet/client-shared-web/shared/gdl/components/list'

import { useSimpleTextInputModal } from '../../common/components/simple-text-input-modal'
import { useDeleteResourceObjectTypeModal } from '../components/delete-resourse-object-type-modal'

export const useResourceObjectTypeOptionActions = (): [
  undefined | ((option: ResourceObjectTypeFragmentT) => ListActionItemActionPropsT[]),
  undefined | (() => undefined | React.ReactElement),
] => {
  const { enqueueSnackbar } = useSnackbar()

  const { profile } = useProfile()

  const [renderEditModal, editModalController] = useSimpleTextInputModal()

  const [renderDeleteModal, deleteModalController] = useDeleteResourceObjectTypeModal()

  const updateResourceObjectTypeMutation = useUpdateResourceObjectTypeMutation()

  const onEditSubmit = useCallback(
    async ({ value, id }: { value: string; id: string }) => {
      try {
        await updateResourceObjectTypeMutation.mutateAsync({
          data: {
            name: value,
          },
          id,
        })

        editModalController.onClose()

        enqueueSnackbar('Successfully renamed', {
          variant: 'success',
        })
      } catch (error) {
        enqueueSnackbar(mapErrorToMessage(error), {
          variant: 'error',
        })
      }
    },
    [editModalController, enqueueSnackbar, updateResourceObjectTypeMutation],
  )

  const getOptionActions = useCallback(
    (option: ResourceObjectTypeFragmentT) => [
      {
        icon: PenSolidIcon,
        id: 'edit',
        onClick: () => {
          editModalController.onOpen({
            confirmActionLabel: 'Save',
            label: 'Resource Type Name',
            onSubmit: ({ value }) => {
              return onEditSubmit({ id: option.id, value })
            },
            placeholder: 'Type in here',
            title: 'Rename',
            value: option.name,
          })
        },
        title: 'Edit',
      },
      {
        icon: DeleteSolidIcon,
        id: 'delete',
        onClick: () => {
          deleteModalController.onOpen({ resourceObjectType: option })
        },
      },
    ],
    [deleteModalController, editModalController, onEditSubmit],
  )

  const renderModals = useCallback(
    () => (
      <>
        {renderEditModal()}
        {renderDeleteModal()}
      </>
    ),
    [renderDeleteModal, renderEditModal],
  )

  if (!checkHasAdminRights(profile)) {
    return [undefined, undefined]
  }

  return [getOptionActions, renderModals]
}
