import { Box } from '@mui/material'
import { forwardRef } from 'react'

import { subscribeEvent } from '@resnet/client-common/common/utils/event/subscribe-event'
import { useEventCallbackRefEffect } from '@resnet/client-common/react/hooks/use-callback-ref-effect'
import { useCombineRefs } from '@resnet/client-common/react/hooks/use-combine-refs'

import { visuallyHiddenStyles } from '@resnet/client-shared-web/styles/sx-presets'

export type FileInputPropsT = {
  disabled?: boolean
  accept?: string
  multiple?: boolean
  onChange?: (event: React.FormEvent<HTMLInputElement>) => void
  onCancel?: () => void
}

export type FileInputRefT = React.Ref<HTMLInputElement>

export const FileInput = forwardRef(
  ({ disabled, accept, multiple, onChange, onCancel }: FileInputPropsT, refActual: FileInputRefT) => {
    const inputRef = useEventCallbackRefEffect<HTMLInputElement>((input) => {
      if (!input) {
        return
      }

      return subscribeEvent(input, 'cancel', () => {
        onCancel?.()
      })
    })

    const ref = useCombineRefs(refActual, inputRef)

    return (
      <Box
        accept={accept}
        component="input"
        disabled={disabled}
        multiple={multiple}
        ref={ref}
        sx={visuallyHiddenStyles}
        type="file"
        value=""
        onChange={onChange}
      />
    )
  },
)
