import { produce } from 'immer'

import {
  useApproveIssueMutation,
  useArchiveIssueMutation,
  useCreateIssuesMutation,
  useDeleteCampaignMutation,
  useDeleteIssueMutation,
  useFollowMutation,
  useInfiniteIssuesQuery,
  useGetIssueQuery,
  useIssuesQuery,
  useMoveIssueMutation,
  useRejectIssueMutation,
  useResetIssueApprovalMutation,
  useUnarchiveIssueMutation,
  useUnfollowMutation,
  useUpdateFollowersMutation,
  useUpdateIssueMutation,
  useUploadResourceMutation,
  useUpdateRelationsMutation,
  useUpdateEntityTypeMutation,
  EntityTypeIdsT,
  useDeleteResourceMutation,
  useGetResourceObjectQuery,
} from '@resnet/client-api/api'
import { createMutationHookMutationCacheHandlers } from '@resnet/client-api/services/query-client/utils/create-mutation-hook-mutation-cache-handlers'
import {
  getEntityQueryUpdateRecipe,
  listEntityQueryUpdateRecipe,
} from '@resnet/client-api/shared/entities/utils/update-entity-recipe'
import { createQueriesHandlers } from '@resnet/client-api/utils/create-queries-handlers'
import { findGetQueriesById, findInfiniteQueries, findQueries } from '@resnet/client-api/utils/find-queries'
import { invalidateQuery } from '@resnet/client-api/utils/invalidate-query'
import { setQueryData } from '@resnet/client-api/utils/set-query-data'

export const createGetIssueQueriesHandlers = createQueriesHandlers(({ queryClient }) => {
  const findIssueQueries = () => {
    return findQueries(queryClient, useGetIssueQuery)
  }

  const findIssueQueriesById = ({ id }: { id: string }) => {
    return findGetQueriesById(queryClient, useGetIssueQuery, (data) => data.getIssue, { id })
  }

  const findIssueQueriesByCampaignId = ({ id }: { id: string }) => {
    return findQueries(queryClient, useGetIssueQuery, (query): boolean => {
      const issue = query.state.data?.getIssue

      if (!issue) {
        return false
      }

      const { campaignId } = issue

      return campaignId === id
    })
  }

  const updateIssueHandlers = () => {
    return createMutationHookMutationCacheHandlers(useUpdateIssueMutation, {
      onError: (error, { id }) => {
        findIssueQueriesById({ id }).forEach(invalidateQuery(queryClient))
      },
      onMutate: ({ id, data: input }) => {
        findIssueQueriesById({ id }).forEach((query) => {
          setQueryData(queryClient)(query)(
            produce((data) => {
              getEntityQueryUpdateRecipe({ data, input, mapDataToEntity: (data) => data.getIssue })
            }),
          )
        })
      },
      onSuccess: (data, { id }) => {
        findIssueQueriesById({ id }).forEach((query) => {
          setQueryData(queryClient)(query)(() => ({
            getIssue: data.updateIssue,
          }))
        })

        if (data.updateIssue.status && data.updateIssue.resourceObjectId) {
          findGetQueriesById(queryClient, useGetResourceObjectQuery, (data) => data.getResourceObject, {
            id: data.updateIssue.resourceObjectId,
          }).forEach(invalidateQuery(queryClient))
        }
      },
    })
  }

  const followHandlers = () => {
    return createMutationHookMutationCacheHandlers(useFollowMutation, {
      onSuccess: (data, { entityId }) => {
        findIssueQueriesById({ id: entityId }).forEach(invalidateQuery(queryClient))
      },
    })
  }

  const unfollowHandlers = () => {
    return createMutationHookMutationCacheHandlers(useUnfollowMutation, {
      onSuccess: (data, { entityId }) => {
        findIssueQueriesById({ id: entityId }).forEach(invalidateQuery(queryClient))
      },
    })
  }

  const updateFollowersHandlers = () => {
    return createMutationHookMutationCacheHandlers(useUpdateFollowersMutation, {
      onSuccess: (data, { entityId }) => {
        findIssueQueriesById({ id: entityId }).forEach(invalidateQuery(queryClient))
      },
    })
  }

  const uploadResourceHandlers = () => {
    return createMutationHookMutationCacheHandlers(useUploadResourceMutation, {
      onSuccess: (data, { originId }) => {
        findIssueQueriesById({ id: originId }).forEach(invalidateQuery(queryClient))
      },
    })
  }

  const deleteResourceHandlers = () => {
    return createMutationHookMutationCacheHandlers(useDeleteResourceMutation, {
      onSuccess: (data) => {
        const originId = data?.deleteResource?.resource?.refId

        if (!originId) {
          return
        }

        findIssueQueriesById({ id: originId }).forEach(invalidateQuery(queryClient))
      },
    })
  }

  const archiveIssueHandlers = () => {
    return createMutationHookMutationCacheHandlers(useArchiveIssueMutation, {
      onError: (error, { id }) => {
        findIssueQueriesById({ id }).forEach(invalidateQuery(queryClient))
      },
      onMutate: ({ id }) => {
        findIssueQueriesById({ id }).forEach((query) => {
          setQueryData(queryClient)(query)(
            produce((data) => {
              getEntityQueryUpdateRecipe({
                data,
                input: { archived: { at: new Date().toISOString() } },
                mapDataToEntity: (data) => data.getIssue,
              })
            }),
          )
        })
      },
      onSuccess: (data, { id }) => {
        findIssueQueriesById({ id }).forEach(invalidateQuery(queryClient))
      },
    })
  }

  const unarchiveIssueHandlers = () => {
    return createMutationHookMutationCacheHandlers(useUnarchiveIssueMutation, {
      onError: (error, { id }) => {
        findIssueQueriesById({ id }).forEach(invalidateQuery(queryClient))
      },
      onMutate: ({ id }) => {
        findIssueQueriesById({ id }).forEach((query) => {
          setQueryData(queryClient)(query)(
            produce((data) => {
              getEntityQueryUpdateRecipe({
                data,
                input: { archived: null },
                mapDataToEntity: (data) => data.getIssue,
              })
            }),
          )
        })
      },
      onSuccess: (data, { id }) => {
        findIssueQueriesById({ id }).forEach(invalidateQuery(queryClient))
      },
    })
  }

  const rejectIssueHandlers = () => {
    return createMutationHookMutationCacheHandlers(useRejectIssueMutation, {
      onSuccess: (_, variables) => {
        findIssueQueriesById({ id: variables.id }).forEach(invalidateQuery(queryClient))
      },
    })
  }

  const approveIssueHandlers = () => {
    return createMutationHookMutationCacheHandlers(useApproveIssueMutation, {
      onSuccess: (_, variables) => {
        findIssueQueriesById({ id: variables.id }).forEach(invalidateQuery(queryClient))
      },
    })
  }

  const resetApprovalIssueHandlers = () => {
    return createMutationHookMutationCacheHandlers(useResetIssueApprovalMutation, {
      onSuccess: (_, variables) => {
        findIssueQueriesById({ id: variables.id }).forEach(invalidateQuery(queryClient))
      },
    })
  }

  const deleteIssueHandlers = () => {
    return createMutationHookMutationCacheHandlers(useDeleteIssueMutation, {
      onSuccess: (data, { id }) => {
        findIssueQueriesById({ id }).forEach(invalidateQuery(queryClient))
      },
    })
  }

  const deleteCampaignHandlers = () => {
    return createMutationHookMutationCacheHandlers(useDeleteCampaignMutation, {
      onSuccess: (data, { id }) => {
        findIssueQueriesByCampaignId({ id }).forEach(invalidateQuery(queryClient))
      },
    })
  }

  const updateRelationsHandlers = () => {
    return createMutationHookMutationCacheHandlers(useUpdateRelationsMutation, {
      onSuccess: (data, { entityId }) => {
        findIssueQueriesById({ id: entityId }).forEach(invalidateQuery(queryClient))
      },
    })
  }

  const updateEntityTypeHandlers = () => {
    return createMutationHookMutationCacheHandlers(useUpdateEntityTypeMutation, {
      onSuccess: (data, { id }) => {
        if (id !== EntityTypeIdsT.IssueT) {
          return
        }

        findIssueQueries().forEach(invalidateQuery(queryClient))
      },
    })
  }

  return [
    updateIssueHandlers(),
    followHandlers(),
    unfollowHandlers(),
    updateFollowersHandlers(),
    uploadResourceHandlers(),
    deleteResourceHandlers(),
    archiveIssueHandlers(),
    unarchiveIssueHandlers(),
    deleteIssueHandlers(),
    deleteCampaignHandlers(),
    approveIssueHandlers(),
    rejectIssueHandlers(),
    resetApprovalIssueHandlers(),
    updateRelationsHandlers(),
    updateEntityTypeHandlers(),
  ]
})

export const createIssuesQueriesHandlers = createQueriesHandlers(({ queryClient }) => {
  const findIssuesQueries = () => {
    return findQueries(queryClient, useIssuesQuery)
  }

  const findIssuesQueriesById = ({ id, assigneeId }: { id: string; assigneeId?: null | string }) =>
    findQueries(queryClient, useIssuesQuery, (query) => {
      const findById = () => (query.state.data?.listIssues?.items ?? []).some((issue) => issue.id === id)

      if (!assigneeId) {
        return findById()
      }

      const findByAssigneeFilter = () =>
        query.queryKey.some((item) => {
          if (item && typeof item !== 'string' && 'filter' in item) {
            const assigneeFilter = item?.filter?.assigneeId

            if (!assigneeId) {
              return false
            }

            return assigneeFilter?.eq === assigneeId || assigneeFilter?.in?.includes(assigneeId)
          }

          return false
        })

      return findByAssigneeFilter() || findById()
    })

  const findInfiniteIssuesQueries = () => findInfiniteQueries(queryClient, useInfiniteIssuesQuery)

  const findIssuesQueriesByCampaignId = ({ id }: { id: string }) =>
    findQueries(queryClient, useIssuesQuery, (query): boolean => {
      const issues = query.state.data?.listIssues

      if (!issues) {
        return false
      }

      return issues.items.some(({ campaignId }) => campaignId === id)
    })

  const createIssueHandlers = () => {
    return createMutationHookMutationCacheHandlers(useCreateIssuesMutation, {
      onSuccess: () => {
        findIssuesQueries().forEach(invalidateQuery(queryClient))
      },
    })
  }

  const updateIssueHandlers = () => {
    return createMutationHookMutationCacheHandlers(useUpdateIssueMutation, {
      onError: (error, { id }) => {
        findIssuesQueriesById({ id }).forEach(invalidateQuery(queryClient))
      },
      onMutate: ({ id, data: input }) => {
        findIssuesQueries().forEach((query) => {
          setQueryData(queryClient)(query)(
            produce((data) => {
              listEntityQueryUpdateRecipe({
                data,
                id,
                input,
                mapDataToEntities: (data) => data.listIssues.items,
              })
            }),
          )
        })
      },
      onSuccess: (data, { id, data: payloadData }) => {
        if (payloadData?.assigneeId) {
          findIssuesQueriesById({ assigneeId: payloadData.assigneeId, id }).forEach(invalidateQuery(queryClient))
        }

        findIssuesQueriesById({ id }).forEach(invalidateQuery(queryClient))
      },
    })
  }

  const moveIssueHandlers = () => {
    return createMutationHookMutationCacheHandlers(useMoveIssueMutation, {
      onError: (error, { id }) => {
        findIssuesQueriesById({ id }).forEach(invalidateQuery(queryClient))
      },
      onMutate: ({ id, beforeId }) => {
        findIssuesQueries().forEach((query) => {
          setQueryData(queryClient)(query)(
            produce((draft) => {
              const issues = draft?.listIssues?.items

              if (!issues) {
                return
              }

              const issueIndex = issues.findIndex((issue) => issue.id === id)

              const [issue] = issues.splice(issueIndex, 1)

              const beforeIssueIndex = !beforeId
                ? issues.length - 1
                : issues.findIndex((issue) => issue.id === beforeId)

              issues.splice(beforeIssueIndex, 0, issue)
            }),
          )
        })
      },
      onSuccess: (data, { id }) => {
        findIssuesQueriesById({ id }).forEach(invalidateQuery(queryClient))
      },
    })
  }

  const archiveIssueHandlers = () => {
    return createMutationHookMutationCacheHandlers(useArchiveIssueMutation, {
      onSuccess: (data, { id }) => {
        findIssuesQueriesById({ id }).forEach(invalidateQuery(queryClient))
        findInfiniteIssuesQueries().forEach(invalidateQuery(queryClient))
      },
    })
  }

  const unarchiveIssueHandlers = () => {
    return createMutationHookMutationCacheHandlers(useUnarchiveIssueMutation, {
      onSuccess: (data, { id }) => {
        findIssuesQueriesById({ id }).forEach(invalidateQuery(queryClient))
      },
    })
  }

  const deleteIssueHandlers = () => {
    return createMutationHookMutationCacheHandlers(useDeleteIssueMutation, {
      onSuccess: (data, { id }) => {
        findIssuesQueriesById({ id }).forEach(invalidateQuery(queryClient))
      },
    })
  }

  const followHandlers = () => {
    return createMutationHookMutationCacheHandlers(useFollowMutation, {
      onSuccess: (data, { entityId }) => {
        findIssuesQueriesById({ id: entityId }).forEach(invalidateQuery(queryClient))
      },
    })
  }

  const unfollowHandlers = () => {
    return createMutationHookMutationCacheHandlers(useUnfollowMutation, {
      onSuccess: (data, { entityId }) => {
        findIssuesQueriesById({ id: entityId }).forEach(invalidateQuery(queryClient))
      },
    })
  }

  const deleteCampaignHandlers = () => {
    return createMutationHookMutationCacheHandlers(useDeleteCampaignMutation, {
      onSuccess: (data, { id }) => {
        findIssuesQueriesByCampaignId({ id }).forEach(invalidateQuery(queryClient))
      },
    })
  }

  const updateRelationsHandlers = () => {
    return createMutationHookMutationCacheHandlers(useUpdateRelationsMutation, {
      onSuccess: (data, { entityId }) => {
        findIssuesQueriesByCampaignId({ id: entityId }).forEach(invalidateQuery(queryClient))
      },
    })
  }

  const updateEntityTypeHandlers = () => {
    return createMutationHookMutationCacheHandlers(useUpdateEntityTypeMutation, {
      onSuccess: (data, { id }) => {
        if (id !== EntityTypeIdsT.IssueT) {
          return
        }

        findIssuesQueries().forEach(invalidateQuery(queryClient))
        findInfiniteIssuesQueries().forEach(invalidateQuery(queryClient))
      },
    })
  }

  return [
    createIssueHandlers(),
    updateIssueHandlers(),
    moveIssueHandlers(),
    archiveIssueHandlers(),
    unarchiveIssueHandlers(),
    deleteIssueHandlers(),
    followHandlers(),
    unfollowHandlers(),
    deleteCampaignHandlers(),
    updateRelationsHandlers(),
    updateEntityTypeHandlers(),
  ]
})
