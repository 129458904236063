import { checkNonNullable } from '@resnet/client-common/common/utils/nullable/non-nullable'

import { useResourcesQuery, type CustomFieldFragmentT } from '@resnet/client-api/api'

import type { AttachmentCustomFieldValueT } from '@resnet/client-shared/shared/custom-fields/presets/attachment/custom-field-value'

import { Accordion } from '@resnet/client-shared-web/shared/gdl/components/accordion'
import {
  ResourcesGallery,
  ResourcesGallerySkeleton,
} from '@resnet/client-shared-web/shared/resources/components/resources-gallery'

import { EmptyPanel } from '@resnet/client-web/shared/common/components/empty-panel'
import { ObjectDetailsRow } from '@resnet/client-web/shared/object/components/object-details-row'

export type AttachmentCustomFieldUserFormPreviewPropsT = {
  field: CustomFieldFragmentT
  value: undefined | AttachmentCustomFieldValueT
}

export const AttachmentCustomFieldUserFormPreview = ({ field, value }: AttachmentCustomFieldUserFormPreviewPropsT) => {
  const getSizeSx = () => {
    return {
      gridColumn: 'span 2',
    }
  }

  const resourceIds = [value].flat().filter(checkNonNullable)

  const resourcesQuery = useResourcesQuery(
    {
      filter: {
        id: {
          in: resourceIds,
        },
      },
    },
    {
      select: (data) => data.listResources.items,
    },
  )

  const renderTitle = () => {
    return field.name
  }

  const renderContent = () => {
    if (!resourcesQuery.isSuccess) {
      return <ResourcesGallerySkeleton />
    }

    const resources = resourcesQuery.data

    if (!resources.length) {
      return (
        <EmptyPanel>
          {{
            title: 'No attachments',
          }}
        </EmptyPanel>
      )
    }

    return <ResourcesGallery resources={resources} />
  }

  return (
    <ObjectDetailsRow sx={getSizeSx()}>
      <Accordion>
        {{
          content: renderContent,
          header: { title: renderTitle() },
        }}
      </Accordion>
    </ObjectDetailsRow>
  )
}
