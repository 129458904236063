import { fullDateFormatter } from '@resnet/client-common/format/utils/date/full-date-formatter'
import { fullDateTimeFormatter } from '@resnet/client-common/format/utils/date/full-date-time-formatter'

import { DateInputInputPlaceholder, DateInputInputText } from '../../components/date-input'

export const datePlaceholderText = 'MM/DD/YYYY'

export const dateTimePlaceholderText = 'MM/DD/YYYY HH:MM AM/PM'

export type DateInputTextPropsT = { value?: null | Date; showTime?: boolean; placeholder?: string; size?: 'sm' | 'md' }

export const DateInputText = ({ value, showTime, placeholder, size }: DateInputTextPropsT): React.ReactElement => {
  if (!value) {
    const placeholderText = placeholder ?? (showTime ? dateTimePlaceholderText : datePlaceholderText)

    return <DateInputInputPlaceholder size={size}>{placeholderText}</DateInputInputPlaceholder>
  }

  const formatter = showTime ? fullDateTimeFormatter : fullDateFormatter

  return <DateInputInputText size={size}>{formatter.format(value)}</DateInputInputText>
}

type DateRangeT = [null | Date, null | Date]

export type DateRangeInputTextPropsT = { value: DateRangeT; showTime?: boolean }

export const DateRangeInputText = ({ value, showTime }: DateRangeInputTextPropsT): React.ReactElement => {
  const [from, to] = value

  if (!from || !to) {
    const placeholderText = showTime ? dateTimePlaceholderText : datePlaceholderText

    return (
      <DateInputInputPlaceholder>
        {placeholderText} ... {placeholderText}
      </DateInputInputPlaceholder>
    )
  }

  const formatter = showTime ? fullDateTimeFormatter : fullDateFormatter

  return (
    <DateInputInputText>
      {formatter.format(from)} ... {formatter.format(to)}
    </DateInputInputText>
  )
}
