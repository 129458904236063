import type { BoxProps } from '@mui/material'
import { Box, Tooltip } from '@mui/material'

import { idToKey } from '@resnet/client-common/common/utils/object/prop-to-key'
import { forwardFunctionalComponentRef } from '@resnet/client-common/react/utils/forward-functional-component-ref'
import type { MergeAllT } from '@resnet/client-common/typescript/types/merge-all'

import { typographyPresets } from '@resnet/client-shared/shared/gdl/constants/typography-presets'

import { themeColors } from '@resnet/client-shared-web/shared/gdl/constants/theme-colors'

import { focusOutlineSx } from '../../sx-presets/focus-outline'
import { mapTypographyPresetToSx } from '../../utils/map-typography-preset-to-sx'
import { toPx } from '../../utils/to-px'

export const sizeOptions = [
  {
    id: 'sm' as const,
    startSlotSize: 16,
    typographyPreset: typographyPresets.bodyExtraSmall,
  },
  {
    id: 'md' as const,
    startSlotSize: 20,
    typographyPreset: typographyPresets.bodyMedium,
  },
]

export const sizeOptionsById = idToKey(sizeOptions)

export type TextInputPropsT = MergeAllT<
  [
    BoxProps<'input'>,
    {
      disabled?: boolean
      disabledTooltipTitle?: string
      hasError?: boolean
      onBlur?: (event: React.FocusEvent<HTMLInputElement>) => void
      onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void
      placeholder?: string
      startSlot?: React.ReactNode
      endSlot?: React.ReactNode
      type?: string
      value?: null | string
      autoComplete?: string
      size?: (typeof sizeOptions)[number]['id']
    },
  ]
>

export const TextInput = forwardFunctionalComponentRef(
  (
    {
      disabled = false,
      disabledTooltipTitle,
      placeholder,
      value,
      hasError,
      onChange,
      onBlur,
      type = 'text',
      component = 'input',
      sx = null,
      startSlot,
      endSlot,
      autoComplete,
      size = 'md',
      ...props
    }: TextInputPropsT,
    ref: React.Ref<HTMLInputElement>,
  ) => {
    const borderRadius = 8

    const borderWidth = 1

    const { startSlotSize, typographyPreset } = sizeOptionsById[size]

    const paddingLeft = !startSlot ? 0 : startSlotSize + 8

    const paddingRight = !endSlot ? 0 : startSlotSize + 8

    const inputSx = [
      { all: 'unset' },
      mapTypographyPresetToSx(typographyPreset),
      {
        '&::placeholder': {
          color: themeColors.overBackgroundFaded,
        },
        backgroundColor: themeColors.surfaceNeutralDefault,
        borderColor: themeColors.borderFaded,
        borderRadius: toPx(borderRadius),
        borderStyle: 'solid',
        borderWidth: toPx(borderWidth),
        color: themeColors.overBackgroundDefault,
        pl: toPx(16 - borderWidth + paddingLeft),
        position: 'relative',
        pr: toPx(16 - borderWidth + paddingRight),
        py: toPx(8 - borderWidth),
      },
      {
        '&:focus': focusOutlineSx,
      },
      !hasError ? {} : { borderColor: themeColors.feedbackCritical },
      !disabled ? {} : { cursor: 'not-allowed', opacity: 0.64 },
      sx,
    ].flat()

    const renderStartSlot = () => {
      return (
        <Box
          sx={{
            alignItems: 'center',
            bottom: 0,
            display: 'flex',
            justifyContent: 'center',
            left: 16,
            position: 'absolute',
            top: 0,
            width: toPx(startSlotSize),
          }}
        >
          {startSlot}
        </Box>
      )
    }

    const renderEndSlot = () => {
      return (
        <Box
          sx={{
            alignItems: 'center',
            bottom: 0,
            display: 'flex',
            justifyContent: 'center',
            position: 'absolute',
            right: 16,
            top: 0,
            width: toPx(startSlotSize),
          }}
        >
          {endSlot}
        </Box>
      )
    }

    const renderInput = () => {
      return (
        <Tooltip title={disabled ? disabledTooltipTitle : ''}>
          <Box
            {...props}
            autoComplete={autoComplete}
            component={component}
            disabled={disabled}
            placeholder={placeholder}
            ref={ref}
            sx={inputSx}
            type={type}
            value={value === null ? '' : value}
            onBlur={onBlur}
            onChange={onChange}
          />
        </Tooltip>
      )
    }

    const renderContent = () => {
      if (!startSlot && !endSlot) {
        return renderInput()
      }

      return (
        <Box
          sx={[
            {
              display: 'flex',
              position: 'relative',
            },
            {
              '> input': {
                width: '100%',
              },
            },
          ]}
        >
          {renderInput()}
          {renderStartSlot()}
          {renderEndSlot()}
        </Box>
      )
    }

    return renderContent()
  },
)

export const TextInputPlaceholder = forwardFunctionalComponentRef(
  (
    {
      value,
      sx = null,
      ...props
    }: BoxProps<'div'> & {
      value: string
      hasError?: boolean
    },
    ref: React.Ref<HTMLDivElement>,
  ) => {
    const borderRadius = 8

    const borderWidth = 1

    return (
      <Box
        {...props}
        ref={ref}
        sx={[
          { all: 'unset' },
          mapTypographyPresetToSx(typographyPresets.bodyMedium),
          {
            backgroundColor: themeColors.surfaceNeutralDefault,
            borderColor: themeColors.borderFaded,
            borderRadius: toPx(borderRadius),
            borderStyle: 'solid',
            borderWidth: toPx(borderWidth),
            color: themeColors.overBackgroundFaded,
            position: 'relative',
            px: toPx(16 - borderWidth),
            py: toPx(8 - borderWidth),
          },
          sx,
        ].flat()}
      >
        {value}
      </Box>
    )
  },
)
