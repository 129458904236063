import { useSnackbar } from 'notistack'
import { useMemo, useState } from 'react'

import { pipeline } from '@resnet/client-common/common/utils/function/pipeline'
import { NonNullableContextContainer } from '@resnet/client-common/react/hooks/use-non-nullable-context'
import type { MergeT } from '@resnet/client-common/typescript/types/merge'
import { createConstrainer } from '@resnet/client-common/typescript/utils/create-constrainer'

import type { GroupFragmentT, UserFragmentT } from '@resnet/client-api/api'
import { FilterModeT } from '@resnet/client-api/api'

import { UserOptionContainer } from '@resnet/client-shared/shared/users/hooks/use-user-option'
import { mapUserToTitle } from '@resnet/client-shared/shared/users/utils/map-user-to-title'

import { DropdownInput } from '@resnet/client-shared-web/shared/gdl/components/dropdown-input'
import { MultipleDropdownText } from '@resnet/client-shared-web/shared/gdl/components/multiple-dropdown-text'
import { Popover } from '@resnet/client-shared-web/shared/gdl/components/popover'
import type { PopperPropsT } from '@resnet/client-shared-web/shared/gdl/components/popper'
import { Popper, PopperContext } from '@resnet/client-shared-web/shared/gdl/components/popper'
import {
  SegmentedControl,
  SegmentedControlItem,
} from '@resnet/client-shared-web/shared/gdl/components/segmented-control'
import { SelectSearch } from '@resnet/client-shared-web/shared/gdl/components/select-dropdown/components/select-search'
import { SelectMultipleDropdown } from '@resnet/client-shared-web/shared/gdl/components/select-multiple-dropdown'

import type { RecipientTypeT } from '@resnet/client-web/shared/users/hooks/use-recipients-select-dropdown'
import { RecipientsSelectDropdownContainer } from '@resnet/client-web/shared/users/hooks/use-recipients-select-dropdown'

const recipientTypeOptions = createConstrainer<{ id: RecipientTypeT; name: string }[]>()([
  {
    id: 'user',
    name: 'Users',
  },
  {
    id: 'group',
    name: 'Groups',
  },
])

type UserWithGroupsSelectMultipleDropdownPropsT = MergeT<
  Omit<PopperPropsT, 'children'>,
  {
    disablePortal?: boolean
    exclude?: string[]
    onClose?: () => void
    onChange: (value: null | string[]) => void
    placement?: React.ComponentProps<typeof Popper>['placement']
    value: string[]
    hasError?: boolean
    onCurrentValueQueryError?: () => void
    nullOptionLabel?: React.ReactNode
    nullOptionEnabled?: boolean
    placeholder?: string
    renderAnchor?: () => React.ReactElement
  }
>

export const UserWithGroupsSelectMultipleDropdown = ({
  placeholder,
  disablePortal,
  hasError,
  exclude,
  onChange: onChangeActual,
  onClose,
  onCurrentValueQueryError,
  placement,
  popperRef,
  value: valueActual,
  renderAnchor: renderAnchorActual,
}: UserWithGroupsSelectMultipleDropdownPropsT) => {
  const [recipientType, setRecipientType] = useState<RecipientTypeT>(recipientTypeOptions[0].id)
  const { enqueueSnackbar } = useSnackbar()

  const renderAnchor = (): React.ReactElement => {
    if (renderAnchorActual) {
      return renderAnchorActual()
    }

    return (
      <NonNullableContextContainer Context={PopperContext}>
        {({ setAnchorEl, isOpened, open }) => (
          <DropdownInput
            hasError={hasError}
            isOpened={isOpened}
            ref={setAnchorEl}
            onClick={open}
          >
            <MultipleDropdownText
              OptionContainer={UserOptionContainer}
              getOptionLabel={mapUserToTitle}
              mode={FilterModeT.IncludeT}
              placeholder={placeholder}
              value={valueActual}
              onCurrentValueQueryError={onCurrentValueQueryError}
            />
          </DropdownInput>
        )}
      </NonNullableContextContainer>
    )
  }

  const currentValueSet = useMemo(() => new Set(valueActual), [valueActual])

  const onChange: React.ComponentProps<typeof SelectMultipleDropdown<UserFragmentT | GroupFragmentT>>['onChange'] = (
    value,
    option,
  ) => {
    if (!value) {
      return
    }

    if (!option || option.__typename === 'User') {
      onChangeActual(value)

      return
    }

    if (!option.members) {
      return
    }

    const membersIds = option.members.map((member) => member.userId)

    if (membersIds.every((memberId) => currentValueSet.has(memberId))) {
      return
    }

    const nextValue = pipeline(
      value,
      (x) => x.slice(0, -1),
      (x) => x.concat(membersIds),
      (x) => new Set(x),
      (x) => Array.from(x) as string[],
    )

    onChangeActual(nextValue)

    enqueueSnackbar(`${nextValue.length - valueActual.length} users were added from ${option.name} group`, {
      variant: 'success',
    })
  }

  const renderContent = () => {
    return (
      <Popover>
        <RecipientsSelectDropdownContainer
          hideEmptyGroups
          excludeIds={exclude}
          recipientType={recipientType}
        >
          {({ searchProps, dropdownProps }) => (
            <SelectMultipleDropdown
              hideSelection={recipientType === 'group'}
              {...dropdownProps}
              header={
                <SegmentedControl>
                  {recipientTypeOptions.map((option) => (
                    <SegmentedControlItem
                      checked={recipientType === option.id}
                      key={option.id}
                      label={option.name}
                      value={option.id}
                      onChange={setRecipientType}
                    />
                  ))}
                </SegmentedControl>
              }
              search={<SelectSearch {...searchProps} />}
              value={valueActual}
              onChange={onChange}
            />
          )}
        </RecipientsSelectDropdownContainer>
      </Popover>
    )
  }

  return (
    <Popper
      disablePortal={disablePortal}
      placement={placement}
      popperRef={popperRef}
      onClose={onClose}
    >
      {{
        anchor: () => renderAnchor(),
        content: () => renderContent(),
      }}
    </Popper>
  )
}
