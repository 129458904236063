import { themeColors } from '@resnet/client-shared-web/shared/gdl/constants/theme-colors'

import { Skeleton } from '../skeleton'

export const RectangularSkeleton = ({ sx, ...props }: Omit<React.ComponentProps<typeof Skeleton>, 'children'>) => {
  return (
    <Skeleton
      {...props}
      sx={[
        { backgroundColor: themeColors.overBackgroundDefault, borderRadius: '2px', maxWidth: '100%' },
        sx ?? null,
      ].flat()}
    />
  )
}
