import type { OmitDistributiveT } from '@resnet/client-common/common/types/common'
import type { MergeT } from '@resnet/client-common/typescript/types/merge'

import ThumbsUpEmptySolidIcon from '@resnet/client-shared/assets/icons/thumbs-up-empty-solid.svg'
import ThumbsUpSolidIcon from '@resnet/client-shared/assets/icons/thumbs-up-solid.svg'

import type { ButtonPropsT } from '@resnet/client-shared-web/shared/gdl/components/button'
import { Button } from '@resnet/client-shared-web/shared/gdl/components/button'

import type { MessageT } from '../../types/message'

export type MessageCardLikeButtonPropsT = MergeT<
  OmitDistributiveT<ButtonPropsT, 'color' | 'icon' | 'size' | 'variant' | 'children'>,
  { message: MessageT }
>

export const MessageCardLikeButton = ({ message, ...props }: MessageCardLikeButtonPropsT) => {
  const { likedByMe } = message

  const Icon = likedByMe ? ThumbsUpSolidIcon : ThumbsUpEmptySolidIcon

  return (
    <Button
      {...props}
      color="primary"
      icon={<Icon />}
      size="sm"
      variant="text"
    >
      Like
    </Button>
  )
}
