import type { SxProps } from '@mui/material'
import { Box, Tooltip, type BoxProps } from '@mui/material'

import { forwardFunctionalComponentRef } from '@resnet/client-common/react/utils/forward-functional-component-ref'
import type { MergeAllT } from '@resnet/client-common/typescript/types/merge-all'

import { themeColors } from '../../constants/theme-colors'
import type { SidesT } from '../../types/sides'
import { mapBorderWidthToValue } from '../../utils/map-border-width-to-value'
import { mapPaddingToValue } from '../../utils/map-padding-to-value'
import { toPx } from '../../utils/to-px'

export type CardPropsT = MergeAllT<
  [
    Omit<BoxProps, 'ref'>,
    {
      borderRadius?: number
      borderWidth?: number | SidesT
      disabled?: boolean
      onClick?: () => void
      padding?: number | SidesT
      tooltipTitle?: React.ReactNode
    },
  ]
>

export type CardRefT = React.Ref<HTMLDivElement>

export const Card = forwardFunctionalComponentRef(
  (
    {
      borderRadius = 0,
      borderWidth = 0,
      children,
      disabled,
      onClick,
      padding = 0,
      sx = null,
      tooltipTitle,
      ...props
    }: CardPropsT,
    ref: CardRefT,
  ) => {
    const renderButton = () => {
      if (!onClick) {
        return null
      }

      const getButtonSx = (): SxProps => {
        return [
          { all: 'unset', width: '100%' },
          { borderRadius: toPx(borderRadius), cursor: 'pointer', inset: 0, position: 'absolute' },
          {
            '&:hover + *, &:focus + *': {
              backgroundColor: themeColors.surfaceNeutralHover,
            },
          },
          {
            '&:active + *': {
              backgroundColor: themeColors.surfaceNeutralPressed,
            },
          },
        ]
      }

      return (
        <Tooltip
          PopperProps={{ sx: { pointerEvents: 'none' } }}
          placement="bottom"
          title={tooltipTitle}
        >
          <Box
            component="button"
            data-testid="list-item-button"
            disabled={disabled}
            sx={getButtonSx()}
            type="button"
            onClick={onClick}
          />
        </Tooltip>
      )
    }

    const renderUnderlay = () => {
      if (!onClick) {
        return null
      }

      return (
        <Box
          sx={{
            borderRadius: toPx(borderRadius),
            inset: 0,
            position: 'absolute',
            transition: 'background-color 200ms ease',
            zIndex: -1,
          }}
        />
      )
    }

    const renderContent = () => {
      return children
    }

    return (
      <Box
        {...props}
        ref={ref}
        sx={[
          {
            borderRadius: toPx(borderRadius),
            borderStyle: 'solid',
            borderWidth: mapBorderWidthToValue({ borderWidth }),
            display: 'flex',
            flexDirection: 'column',
            padding: mapPaddingToValue({ borderWidth, padding }),
            position: 'relative',
            zIndex: 0,
          },
          sx,
        ].flat()}
      >
        {renderButton()}
        {renderUnderlay()}
        {renderContent()}
      </Box>
    )
  },
)
