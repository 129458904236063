import { type SxProps } from '@mui/material'
import { useRef, useEffect } from 'react'
import type { UseControllerReturn } from 'react-hook-form'
import { useController } from 'react-hook-form'

import { useAsyncEventCallback } from '@resnet/client-common/react/hooks/use-async-event-callback'
import { useNonNullableContext } from '@resnet/client-common/react/hooks/use-non-nullable-context'
import type { ForwardableT } from '@resnet/client-common/react/types/forwardable'
import { renderForwardable } from '@resnet/client-common/react/utils/render-forwardable'

import type { DropdownInputPropsT } from '@resnet/client-shared-web/shared/gdl/components/dropdown-input'
import type { PopperPropsT, PopperRefValueT } from '@resnet/client-shared-web/shared/gdl/components/popper'

import { CellContext } from '../../contexts/cell-context'
import { EditableTableContext } from '../../contexts/editable-table'
import type { OptionMultipleCellValueT } from '../option-multiple-cell'

export type SelectMultiplePropsT = {
  hasError?: boolean
  isBusy?: boolean
  onChange?: (nextValue: OptionMultipleCellValueT) => void
  popperRef?: PopperPropsT['popperRef']
  size?: DropdownInputPropsT['size']
  sx?: SxProps
  value: OptionMultipleCellValueT
}

export type EditOptionMultipleCellPropsT = {
  name: string
  selectMultiple: ForwardableT<SelectMultiplePropsT>
}

export const EditOptionMultipleCell = ({ name, selectMultiple }: EditOptionMultipleCellPropsT) => {
  const { useUpdateRowForm } = useNonNullableContext(EditableTableContext)

  const { row } = useNonNullableContext(CellContext)

  const { form, onSubmit } = useUpdateRowForm({ row })

  const {
    field: { value, onChange: onChangeActual },
  } = useController({ control: form.control, name }) as UseControllerReturn<
    Record<string, OptionMultipleCellValueT>,
    string
  >

  const popperRef = useRef<null | PopperRefValueT>(null)

  const [isChangeLoading, onChange] = useAsyncEventCallback(async (nextValue: OptionMultipleCellValueT) => {
    onChangeActual(nextValue)

    await onSubmit(form.getValues())
  })

  useEffect(() => {
    popperRef.current?.open()
  }, [])

  return renderForwardable(selectMultiple, {
    isBusy: isChangeLoading,
    onChange: onChange,
    popperRef: popperRef,
    size: 'sm',
    sx: { flexGrow: 1, minWidth: 0 },
    value: value,
  })
}
