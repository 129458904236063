import { transform } from '@resnet/client-common/common/utils/object/transform'

import type { UserFragmentT } from '@resnet/client-api/api'

import { UserOptionContainer } from '@resnet/client-shared/shared/users/hooks/use-user-option'
import { mapUserToTitle } from '@resnet/client-shared/shared/users/utils/map-user-to-title'

import { createMultipleFilterOption } from '@resnet/client-web/shared/filters/factories/create-multiple-filter-option'
import type { FilterOptionT } from '@resnet/client-web/shared/presets/types/filter-option'
import { UsersSelectDropdownContainer } from '@resnet/client-web/shared/users/hooks/use-users-select-dropdown-vienna'

import type { CustomFieldT } from '../../types/custom-field'
import { mapCustomFieldToFullKey, mapCustomFieldToKey } from '../../utils/map-custom-field-to-key'

export const createFilterOption: CustomFieldT['createFilterOption'] = ({
  field,
  fieldValuesKey,
  filterObjectType,
  updateFieldsFilter,
}) => {
  const filterOption = createMultipleFilterOption<
    FilterOptionT<ReturnType<typeof updateFieldsFilter>, typeof filterObjectType>,
    UserFragmentT
  >({
    OptionContainer: UserOptionContainer,
    SelectDropdownContainer: UsersSelectDropdownContainer,
    getOptionLabel: mapUserToTitle,
    id: mapCustomFieldToFullKey(field, fieldValuesKey),
    name: field.name,
    objectType: filterObjectType,
    withFilter: (input, updater) =>
      updateFieldsFilter(input as ReturnType<typeof updateFieldsFilter>, (fieldsFilter) =>
        transform(fieldsFilter ?? {}, {
          [mapCustomFieldToKey(field)]: updater,
        }),
      ) as typeof input,
  })

  return filterOption
}
