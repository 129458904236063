import type { GridColDef } from '@mui/x-data-grid'

// This is used to define common defaults for columns

export const createColumn = <ColumnT extends GridColDef>(column: ColumnT) => ({
  filterable: false,
  flex: 1,
  groupable: false,
  minWidth: 160,
  resizable: true,
  sortable: true,
  ...column,
})
