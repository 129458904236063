import { Box } from '@mui/material'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { StaticDateRangePicker } from '@mui/x-date-pickers-pro'
import { endOfDay, startOfDay } from 'date-fns'
import { useContext } from 'react'

import { assertedNonNull } from '@resnet/client-common/common/utils/nullable/non-nullable'
import { useEventCallback } from '@resnet/client-common/react/hooks/use-event-callback'

import { ClickAwayListener } from '@resnet/client-shared-web/shared/registry/components/click-away-listener'
import { FocusTrap } from '@resnet/client-shared-web/shared/registry/components/focus-trap'

import { themeColors } from '../../constants/theme-colors'
import { PopperContext } from '../popper'

type DateRangeT = [null | Date, null | Date]

export const DateRangeDropdown = ({
  value,
  onChange: onChangeActual,
  disabled,
}: {
  value: DateRangeT
  onChange?: (value: DateRangeT) => void
  disabled?: boolean
}) => {
  const { close } = assertedNonNull(useContext(PopperContext))

  const onKeyDown = useEventCallback((event: React.KeyboardEvent) => {
    if (event.key !== 'Escape') {
      return
    }

    event.stopPropagation()

    close()
  })

  const onChange = useEventCallback(([from, to]: DateRangeT) => {
    onChangeActual?.([!from ? from : startOfDay(from), !to ? to : endOfDay(to)])
  })

  return (
    <ClickAwayListener onClickAway={close}>
      <Box sx={{ display: 'flex', flexDirection: 'column' }}>
        <FocusTrap open>
          <Box
            sx={{
              borderColor: themeColors.borderDefault,
              borderRadius: '8px',
              borderStyle: 'solid',
              borderWidth: '1px',
              display: 'flex',
              flexDirection: 'column',
              outline: 'unset',
              overflow: 'hidden',
            }}
            tabIndex={-1}
            onKeyDown={onKeyDown}
          >
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <StaticDateRangePicker
                disabled={disabled}
                displayStaticWrapperAs="desktop"
                value={value}
                onChange={onChange}
              />
            </LocalizationProvider>
          </Box>
        </FocusTrap>
      </Box>
    </ClickAwayListener>
  )
}
