import type { ToNonUndefinedPropsT } from '@resnet/client-common/typescript/types/to-non-undefined-props'
import { createConstrainer } from '@resnet/client-common/typescript/utils/create-constrainer'

import type { CustomFieldFragmentT } from '@resnet/client-api/api'
import { IssuePriorityT, IssueStatusT, IssueTypeT } from '@resnet/client-api/api'

import { mapCustomFieldsToCustomFieldValues } from '@resnet/client-web/shared/custom-fields/utils/map-custom-fields-to-custom-field-values'

import { emptyApproval } from '../../issue-approval'
import type { CreateIssueFormValuesT } from '../types/create-issue-form-values'

export const createDefaultCreateIssueFormValues = ({
  fields,
}: {
  fields: CustomFieldFragmentT[]
}): CreateIssueFormValuesT => {
  const { fieldValues: customFields } = mapCustomFieldsToCustomFieldValues({ fields })

  return createConstrainer<ToNonUndefinedPropsT<CreateIssueFormValuesT>>()({
    access: { private: false },
    actualEndDate: null,
    actualStartDate: null,
    afe: '',
    approval: emptyApproval,
    assetIds: [],
    assigneeId: null,
    attachments: [],
    campaignId: null,
    cloneForWells: false,
    customFields,
    customerLookupId: '',
    description: '',
    dispatched: true,
    estimatedEndDate: null,
    estimatedStartDate: null,
    flag: false,
    labels: [],
    name: '',
    needsApproval: false,
    priority: IssuePriorityT.MediumT,
    relations: [],
    resourceObjectId: null,
    status: IssueStatusT.BacklogT,
    type: IssueTypeT.TaskT,
  })
}
