import { Box } from '@mui/material'
import type { GridColDef } from '@mui/x-data-grid'
import type { FieldPathByValue, FieldValues, UseFormReturn } from 'react-hook-form'

import { checkNonNullable } from '@resnet/client-common/common/utils/nullable/non-nullable'
import { assert } from '@resnet/client-common/typescript/utils/assert'

import { CustomFieldTypeT } from '@resnet/client-api/api'

import type { OptionGroupCustomFieldValueT } from '@resnet/client-shared/shared/custom-fields/presets/option-group/custom-field-value'

import { createSelect } from '@resnet/client-shared-web/shared/common/factories/create-select'
import { createSelectMultiple } from '@resnet/client-shared-web/shared/common/factories/create-select-multiple'
import { Tag } from '@resnet/client-shared-web/shared/gdl/components/tag'
import { createStaticOptionContainer } from '@resnet/client-shared-web/shared/gdl/factories/create-static-option-container'
import { createStaticOptionsSelectDropdownContainer } from '@resnet/client-shared-web/shared/gdl/factories/create-static-options-select-dropdown-container'
import { toPx } from '@resnet/client-shared-web/shared/gdl/utils/to-px'

import type { ColumnWithCreateGanttColumnT } from '@resnet/client-web/shared/gantt/types/column-with-create-gantt-column'
import { createSimpleGanttColumn } from '@resnet/client-web/shared/gantt/utils/create-simple-gantt-column'
import { createRenderColumnAsReactElement } from '@resnet/client-web/shared/gantt/utils/render-column-as-react-element'
import { EditOptionCell } from '@resnet/client-web/shared/tables/components/edit-option-cell'
import { EditOptionMultipleCell } from '@resnet/client-web/shared/tables/components/edit-option-multiple-cell'
import { RegularCell } from '@resnet/client-web/shared/tables/components/regular-cell'
import { createColumn as createCommonColumn } from '@resnet/client-web/shared/tables/factories/create-column'
import type { ColumnWithEditFieldT } from '@resnet/client-web/shared/tables/types/column-with-edit-field'
import type { ColumnWithGroupingT } from '@resnet/client-web/shared/tables/types/column-with-grouping'
import type { ColumnWithHeaderMenuFilterT } from '@resnet/client-web/shared/tables/types/column-with-header-menu-filter'
import type { ColumnWithValueT } from '@resnet/client-web/shared/tables/types/column-with-value'

import type { CreateColumnPropsT, CustomFieldT } from '../../types/custom-field'
import { getFieldValue } from '../../utils/get-field-value'
import { mapCustomFieldToFullKey } from '../../utils/map-custom-field-to-key'

import { OptionGroupCustomFieldUserBulkFormField } from './components/option-group-custom-field-user-bulk-form-field'
import { mapOptionToOptionIdFromKey } from './utils/map-option-to-option-id-from-key'

export const createColumn: CustomFieldT['createColumn'] = <
  RowT extends Record<string, unknown>,
  FieldValuesKeyT extends Extract<keyof RowT, string>,
>({
  field,
  fieldValuesKey,
  mapRowToPathname,
}: CreateColumnPropsT<RowT, FieldValuesKeyT>) => {
  type ColumnT = ColumnWithHeaderMenuFilterT<
    ColumnWithEditFieldT<ColumnWithGroupingT<ColumnWithCreateGanttColumnT<GridColDef<RowT>>>>
  >

  type ColumnWithValueAppliedT = ColumnWithValueT<ColumnT, undefined | OptionGroupCustomFieldValueT>

  assert(field, checkNonNullable)

  const payload = field.payload

  assert(payload, checkNonNullable)

  const optionGroupPayload = payload[CustomFieldTypeT.OptionGroupT]

  assert(optionGroupPayload, checkNonNullable)

  const { options, multiple } = optionGroupPayload

  const getValue = (row: Record<string, unknown>) =>
    getFieldValue<OptionGroupCustomFieldValueT>(field, fieldValuesKey, row)

  const createGanttColumn: ColumnT['createGanttColumn'] = ({ viewSettings, rowElementsRef, column }) => {
    const columnId = column.field

    const data = createRenderColumnAsReactElement<Record<string, unknown>>({ columnId, getValue, rowElementsRef })

    return createSimpleGanttColumn<Record<string, unknown>, ColumnT>({
      column,
      data,
      isReactElementRenderer: true,
      renderElement: ({ row }) => {
        const value = getValue(row)

        if (value === undefined) {
          return <RegularCell>-</RegularCell>
        }

        const [key, ...rest] = [value].flat()

        const option = options.find((item) => item.key === key)

        if (option === undefined) {
          return <RegularCell>-</RegularCell>
        }

        return (
          <Box sx={{ alignItems: 'center', display: 'flex', gap: '4px' }}>
            <RegularCell isMarkedAsDeleted={option.isMarkedAsDeleted}>{option.name}</RegularCell>
            {rest.length < 1 ? null : <Tag>+{rest.length}</Tag>}
          </Box>
        )
      },
      sortable: ({ row }) => {
        const value = getValue(row)

        if (value === undefined) {
          return '-'
        }

        const [key] = [value].flat()

        const option = options.find((item) => item.key === key)

        if (option === undefined) {
          return '-'
        }

        return option.name
      },
      viewSettings,
      width: 174,
    })
  }

  const columnField = mapCustomFieldToFullKey(field, fieldValuesKey)

  const optionsWithIdFromKey = mapOptionToOptionIdFromKey({ options })

  const OptionContainer = createStaticOptionContainer({
    options: optionsWithIdFromKey,
  })

  const SelectDropdownContainer = createStaticOptionsSelectDropdownContainer({
    options: optionsWithIdFromKey,
  })

  const Select = createSelect({
    OptionContainer: OptionContainer,
    SelectDropdownContainer: SelectDropdownContainer,
    getOptionLabel: (option) => option.name,
    getOptionVisible: (option, value) => !option.isMarkedAsDeleted || option.id === value,
  })

  const SelectMultiple = createSelectMultiple({
    OptionContainer: OptionContainer,
    SelectDropdownContainer: SelectDropdownContainer,
    getOptionLabel: (option) => option.name,
    getOptionVisible: (option, value) =>
      !option.isMarkedAsDeleted || (!value ? false : value.some((id) => id === option.id)),
  })

  return createCommonColumn<ColumnWithValueAppliedT>({
    createGanttColumn,
    editable: true,
    field: columnField,
    filterOptionId: columnField,
    groupRowRenderCell: (rowNode) => {
      const { groupingKey } = rowNode

      assert(groupingKey, checkNonNullable)

      const option = options.find((item) => item.key === groupingKey)

      if (option === undefined) {
        return <RegularCell>{groupingKey}</RegularCell>
      }

      return <RegularCell isMarkedAsDeleted={option.isMarkedAsDeleted}>{option.name}</RegularCell>
    },
    groupable: multiple ? false : true,
    groupingValueGetter: ({ row }) => {
      const value = getValue(row)

      if (value === undefined) {
        return undefined
      }

      if (Array.isArray(value)) {
        throw new Error('grouping is not available for arrays')
      }

      return value
    },
    headerName: field.name,
    renderCell: ({ row, value }) => {
      const to = mapRowToPathname?.(row)

      if (value === undefined) {
        return <RegularCell to={to}>-</RegularCell>
      }

      const [key, ...rest] = [value].flat()

      const option = options.find((item) => item.key === key)

      if (option === undefined) {
        return <RegularCell to={to}>-</RegularCell>
      }

      return (
        <Box sx={{ alignItems: 'center', display: 'flex', flexGrow: 1, gap: toPx(4), width: 0 }}>
          <RegularCell
            isMarkedAsDeleted={option.isMarkedAsDeleted}
            to={to}
          >
            {option.name}
          </RegularCell>
          {rest.length < 1 ? null : <Tag>+{rest.length}</Tag>}
        </Box>
      )
    },
    renderEditCell: () => {
      if (multiple) {
        return (
          <EditOptionMultipleCell
            name={columnField}
            selectMultiple={(props) => <SelectMultiple {...props} />}
          />
        )
      }

      return (
        <EditOptionCell
          name={columnField}
          select={(props) => <Select {...props} />}
        />
      )
    },
    renderField: ({ form }) => {
      return (
        <Box sx={{ display: 'flex', flexDirection: 'column', flexGrow: 1 }}>
          <OptionGroupCustomFieldUserBulkFormField
            field={field}
            form={form as UseFormReturn<FieldValues>}
            name={columnField as FieldPathByValue<RowT, undefined | OptionGroupCustomFieldValueT>}
          />
        </Box>
      )
    },
    valueGetter: ({ row }) => getValue(row),
  })
}
