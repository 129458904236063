import { checkNonNullable } from '@resnet/client-common/common/utils/nullable/non-nullable'

import type { BatteryFragmentT } from '@resnet/client-api/api'

export const mapBatteryToCoordinates = ({
  battery,
}: {
  battery: Pick<BatteryFragmentT, 'location'>
}): undefined | null | [number, number] => {
  const location = battery.location

  if (!location) {
    return undefined
  }

  const [lon, lat] = location.coordinates

  if (!checkNonNullable(lon) || !checkNonNullable(lat)) {
    return undefined
  }

  return [lon, lat]
}
