import { Box } from '@mui/material'
import type { UseFormReturn } from 'react-hook-form'
import { Controller, useController } from 'react-hook-form'

import { NonNullableContextContainer } from '@resnet/client-common/react/hooks/use-non-nullable-context'
import type { MergeAllT } from '@resnet/client-common/typescript/types/merge-all'

import type { EntityTypeIdsT } from '@resnet/client-api/api'

import LabelSolidIcon from '@resnet/client-shared/assets/icons/label-solid.svg'
import PlusSolidIcon from '@resnet/client-shared/assets/icons/plus-solid.svg'
import { getSpecialValuesMultipleFieldProps } from '@resnet/client-shared/shared/forms/utils/get-special-values-field-props'

import { CreateNewButton } from '@resnet/client-shared-web/shared/gdl/components/create-new-button'
import { Field, FieldLabel } from '@resnet/client-shared-web/shared/gdl/components/field'
import { Popover } from '@resnet/client-shared-web/shared/gdl/components/popover'
import type { PopperPropsT } from '@resnet/client-shared-web/shared/gdl/components/popper'
import { Popper, PopperContext } from '@resnet/client-shared-web/shared/gdl/components/popper'
import { SelectSearch } from '@resnet/client-shared-web/shared/gdl/components/select-dropdown/components/select-search'
import { SelectMultipleButton } from '@resnet/client-shared-web/shared/gdl/components/select-multiple-button'
import { SelectMultipleDropdown } from '@resnet/client-shared-web/shared/gdl/components/select-multiple-dropdown'

import { SimpleTextInputModalContainer } from '@resnet/client-web/shared/common/components/simple-text-input-modal'
import { EntityLabelsSelectDropdownContainer } from '@resnet/client-web/shared/entities/hooks/use-entity-labels-select-dropdown'

export type EntityLabelsSelectMultipleFieldPropsT = MergeAllT<
  [
    Omit<PopperPropsT, 'children'>,
    {
      form: UseFormReturn<{ labels?: string[] }>
      entityTypeId: EntityTypeIdsT
      label?: string
    },
  ]
>

export const EntityLabelsMultipleSelectField = ({
  form,
  entityTypeId,
  label,
  ...props
}: EntityLabelsSelectMultipleFieldPropsT) => {
  const { field } = useController({ control: form.control, name: 'labels' })

  const specialValuesFieldProps = getSpecialValuesMultipleFieldProps(field)

  const value = !specialValuesFieldProps ? field.value : specialValuesFieldProps.value

  const renderAnchor = (): React.ReactElement => {
    const renderHeader = () => {
      if (!label) {
        return <Box sx={{ height: '24px' }} />
      }

      return <FieldLabel>{label}</FieldLabel>
    }

    return (
      <Field sx={{ flexGrow: 1, width: 0 }}>
        {renderHeader()}
        <NonNullableContextContainer Context={PopperContext}>
          {({ setAnchorEl, open }) => (
            <Controller
              control={form.control}
              name="labels"
              render={({ field }) => (
                <SelectMultipleButton
                  buttonIcon={<PlusSolidIcon />}
                  buttonSize="md"
                  data-testid="entity-labels-select-button"
                  itemIcon={<LabelSolidIcon />}
                  itemName="Label"
                  items={!specialValuesFieldProps ? field.value : specialValuesFieldProps.value}
                  label={!specialValuesFieldProps ? 'Add Label' : specialValuesFieldProps.placeholder}
                  ref={setAnchorEl}
                  onClick={open}
                />
              )}
            />
          )}
        </NonNullableContextContainer>
      </Field>
    )
  }

  const renderContent = (): React.ReactElement => {
    return (
      <SimpleTextInputModalContainer>
        {({ onOpen, onClose }) => (
          <Popover>
            <EntityLabelsSelectDropdownContainer
              entityTypeId={entityTypeId}
              value={value}
            >
              {({ dropdownProps, searchProps }) => (
                <SelectMultipleDropdown
                  {...dropdownProps}
                  header={
                    <CreateNewButton
                      onClick={() => {
                        onOpen({
                          confirmActionLabel: 'Create',
                          label: 'Label Name',
                          onSubmit: ({ value: nextValue }) => {
                            field.onChange([nextValue, ...(value ?? [])])
                            onClose()
                          },
                          placeholder: 'Type in here',
                          title: 'Create New Label',
                        })
                      }}
                    />
                  }
                  search={<SelectSearch {...searchProps} />}
                  value={value ?? []}
                  onChange={field.onChange}
                />
              )}
            </EntityLabelsSelectDropdownContainer>
          </Popover>
        )}
      </SimpleTextInputModalContainer>
    )
  }

  return (
    <Popper {...props}>
      {{
        anchor: renderAnchor,
        content: renderContent,
      }}
    </Popper>
  )
}
