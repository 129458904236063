import type { FieldValues, UseFormReturn } from 'react-hook-form'

import { SidebarFooter } from '@resnet/client-shared-web/shared/common/components/sidebar'

import { ResetButton } from '../reset-button'
import { SaveButton } from '../save-button'

export type SaveFormFooterPropsT<TFieldValues extends FieldValues> = {
  form: UseFormReturn<TFieldValues>
  onReset: () => void
}

export const SaveFormFooter = <TFieldValues extends FieldValues>({
  form,
  onReset,
}: SaveFormFooterPropsT<TFieldValues>) => {
  const buttonSize = 'sm'

  const renderSaveButton = () => {
    return (
      <SaveButton
        disabled={form.formState.isSubmitting || !form.formState.isDirty}
        isLoading={form.formState.isSubmitting}
        size={buttonSize}
        type="submit"
      />
    )
  }

  const renderResetButton = () => {
    return (
      <ResetButton
        disabled={!form.formState.isDirty}
        size={buttonSize}
        type="button"
        onClick={onReset}
      />
    )
  }

  return (
    <SidebarFooter>
      {{
        left: <>{renderSaveButton()}</>,
        right: <>{renderResetButton()}</>,
      }}
    </SidebarFooter>
  )
}
