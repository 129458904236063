import { useMemo } from 'react'
import type { PathValue } from 'react-hook-form'
import { type FieldPathByValue, type FieldValues, type UseFormReturn } from 'react-hook-form'

import { checkNonNullable } from '@resnet/client-common/common/utils/nullable/non-nullable'
import { assert } from '@resnet/client-common/typescript/utils/assert'

import { CustomFieldTypeT, type CustomFieldFragmentT } from '@resnet/client-api/api'

import { validateRequired } from '@resnet/client-shared/shared/forms/validators/any-required'
import { composeValidators } from '@resnet/client-shared/shared/forms/validators/compose-validators'

import { SimpleStaticOptionsDropdownField } from '@resnet/client-shared-web/shared/form/components/common/simple-static-options-dropdown-field'

import { ObjectDetailsRow } from '@resnet/client-web/shared/object/components/object-details-row'

import type { DiscriminatorFormCustomFieldValueT } from '../../form-custom-field-value'
import { mapOptionToOptionIdFromKey } from '../../utils/map-option-to-option-id-from-key'

export type DiscriminatorCustomFieldUserFormFieldPropsT<
  TFieldValues extends FieldValues,
  TPath extends FieldPathByValue<TFieldValues, undefined | DiscriminatorFormCustomFieldValueT>,
> = {
  field: CustomFieldFragmentT
  form: UseFormReturn<TFieldValues>
  name: TPath
}

export const DiscriminatorCustomFieldUserFormField = <
  TFieldValues extends FieldValues,
  TPath extends FieldPathByValue<TFieldValues, undefined | DiscriminatorFormCustomFieldValueT>,
>({
  field,
  form,
  name,
}: DiscriminatorCustomFieldUserFormFieldPropsT<TFieldValues, TPath>) => {
  const payload = field.payload

  assert(payload, checkNonNullable)

  const discriminatorPayload = payload[CustomFieldTypeT.DiscriminatorT]

  assert(discriminatorPayload, checkNonNullable)

  const { required, options, defaultValue, isHalfWidth } = discriminatorPayload

  const getSizeSx = () => {
    if (isHalfWidth) {
      return null
    }

    return {
      gridColumn: 'span 2',
    }
  }

  const rules = useMemo(() => {
    return {
      validate: composeValidators(...(!required ? [] : [validateRequired])),
    }
  }, [required])

  const value = form.watch(name, defaultValue as PathValue<TFieldValues, TPath>)

  const availableOptions = useMemo(
    () => options.filter((option) => !option.isMarkedAsDeleted || option.key === value),
    [options, value],
  )

  const optionsWithIdFromKey = useMemo(
    () => mapOptionToOptionIdFromKey({ options: availableOptions }),
    [availableOptions],
  )

  return (
    <ObjectDetailsRow sx={getSizeSx()}>
      <SimpleStaticOptionsDropdownField
        defaultValue={defaultValue as PathValue<TFieldValues, TPath>}
        form={form}
        label={field.name}
        name={name}
        options={optionsWithIdFromKey}
        rules={rules}
      />
    </ObjectDetailsRow>
  )
}
