import type { BoxProps } from '@mui/material'
import { Box, Tooltip } from '@mui/material'

import { useCombineRefs } from '@resnet/client-common/react/hooks/use-combine-refs'
import { useEventCallback } from '@resnet/client-common/react/hooks/use-event-callback'
import { forwardFunctionalComponentRef } from '@resnet/client-common/react/utils/forward-functional-component-ref'
import type { MergeAllT } from '@resnet/client-common/typescript/types/merge-all'

import SearchSolidIcon from '@resnet/client-shared/assets/icons/search-solid.svg'
import XmarkSolidIcon from '@resnet/client-shared/assets/icons/xmark-solid.svg'

import { ClickableOpacity } from '@resnet/client-shared-web/shared/gdl/components/clickable-opacity'
import { themeColors } from '@resnet/client-shared-web/shared/gdl/constants/theme-colors'

import { focusOutlineSx } from '../../sx-presets/focus-outline'
import { toPx } from '../../utils/to-px'

export type SearchRefT = React.Ref<HTMLDivElement>

export type InputRefT = React.Ref<HTMLInputElement>

export type SearchPropsT = MergeAllT<
  [
    Omit<BoxProps<'input'>, 'ref'>,
    {
      placeholder?: string
      value: string
      onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void
      onClear?: (event: React.MouseEvent) => void
      inputRef?: InputRefT
      inputName?: string
      onInputFocus?: (event: React.FocusEvent<HTMLInputElement>) => void
    },
  ]
>

export const Search = forwardFunctionalComponentRef(
  (
    {
      sx,
      placeholder,
      value,
      onChange,
      onClear: onClearProp,
      inputRef: inputRefProp,
      onInputFocus,
      inputName,
      ...props
    }: SearchPropsT,
    ref: SearchRefT,
  ): React.ReactElement => {
    const iconSize = 20

    const borderWidth = 1

    const inputRef = useCombineRefs(inputRefProp)

    const onClear = useEventCallback((event: React.MouseEvent) => {
      const inputElement = inputRef.current

      if (inputElement) {
        inputElement.focus()
      }

      onClearProp?.(event)
    })

    const renderInput = (): React.ReactNode => {
      return (
        <Box
          component="input"
          name={inputName}
          placeholder={placeholder}
          ref={inputRef}
          sx={[
            {
              all: 'unset',
            },
            {
              '&::placeholder': {
                color: themeColors.overBackgroundFaded,
              },
              backgroundColor: themeColors.surfaceNeutralDefault,
              borderColor: themeColors.borderFaded,
              borderRadius: toPx(999),
              borderStyle: 'solid',
              borderWidth: toPx(borderWidth),
              color: themeColors.overBackgroundDefault,
              display: 'flex',
              flexGrow: 1,
              fontSize: toPx(16),
              letterSpacing: toPx(0.25),
              lineHeight: toPx(24),
              minWidth: toPx(80),
              position: 'relative',
              px: toPx(16 - borderWidth + iconSize + 8),
              py: toPx(8 - borderWidth),
              width: 0,
            },
            {
              '&:focus': focusOutlineSx,
            },
          ]}
          type="text"
          value={value}
          onChange={(event) => {
            onChange?.(event)
          }}
          onFocus={onInputFocus}
        />
      )
    }

    const renderSearchIcon = (): React.ReactNode => {
      return (
        <Box
          sx={{
            alignItems: 'center',
            bottom: 0,
            display: 'flex',
            left: 0,
            pl: toPx(16),
            pointerEvents: 'none',
            position: 'absolute',
            top: 0,
          }}
        >
          <SearchSolidIcon
            fill={themeColors.overBackgroundFaded}
            height={iconSize}
            width={iconSize}
          />
        </Box>
      )
    }

    const renderClearButton = (): React.ReactNode => {
      if (value.length === 0) {
        return null
      }

      return (
        <Box
          sx={{
            alignItems: 'center',
            bottom: 0,
            display: 'flex',
            pointerEvents: 'none',
            position: 'absolute',
            pr: toPx(16),
            right: 0,
            top: 0,
          }}
        >
          <Tooltip title="Clear">
            <ClickableOpacity
              sx={{ display: 'flex', pointerEvents: 'auto' }}
              onClick={onClear}
            >
              <XmarkSolidIcon
                fill={themeColors.overBackgroundFaded}
                height={iconSize}
                width={iconSize}
              />
            </ClickableOpacity>
          </Tooltip>
        </Box>
      )
    }

    return (
      <Box
        {...props}
        ref={ref}
        sx={[
          {
            display: 'flex',
            position: 'relative',
          },
          sx ?? null,
        ].flat()}
      >
        {renderInput()}
        {renderSearchIcon()}
        {renderClearButton()}
      </Box>
    )
  },
)

export const useSearchHandlers = ({ setSearch }: { setSearch: React.Dispatch<React.SetStateAction<string>> }) => {
  const onChange = useEventCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(event.target.value)
  })

  const onClear = useEventCallback(() => {
    setSearch('')
  })

  return { onChange, onClear }
}
