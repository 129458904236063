import { useCallback } from 'react'

import type { Popper } from '@resnet/client-shared-web/shared/gdl/components/popper'
import type { StaticOptionsDropdownTextPropsT } from '@resnet/client-shared-web/shared/gdl/components/static-options-dropdown-text'
import type { AbstractSimpleOptionT } from '@resnet/client-shared-web/shared/gdl/types/abstract-option'

import { SimpleStaticOptionsDropdown } from '../simple-static-options-dropdown'

type SimpleStaticNonEmptyOptionsDropdownT<OptionT extends AbstractSimpleOptionT> = {
  options: OptionT[]
  value: OptionT['id']
  onChange: (nextValue: OptionT['id']) => void
  disablePortal?: boolean
  placement?: React.ComponentProps<typeof Popper>['placement']
  placeholder?: string
  getOptionLabel?: StaticOptionsDropdownTextPropsT<OptionT>['getOptionLabel']
}

export const SimpleStaticNonEmptyOptionsDropdown = <OptionT extends AbstractSimpleOptionT>({
  value,
  onChange: onChangeActual,
  options,
  ...props
}: SimpleStaticNonEmptyOptionsDropdownT<OptionT>) => {
  const onChange = useCallback(
    (option: OptionT['id']) => {
      if (!option) {
        return
      }

      onChangeActual?.(option)
    },
    [onChangeActual],
  )

  return (
    <SimpleStaticOptionsDropdown
      {...props}
      options={options}
      value={value}
      onChange={onChange}
    />
  )
}

export const createSimpleStaticNonEmptyOptionsDropdownComponent =
  <OptionT extends AbstractSimpleOptionT>({ options }: { options: OptionT[] }) =>
  (props: Omit<SimpleStaticNonEmptyOptionsDropdownT<OptionT>, 'options'>) => (
    <SimpleStaticNonEmptyOptionsDropdown
      {...props}
      options={options}
    />
  )
