import { createContext } from 'react'
import type { FieldValues, UseFormReturn } from 'react-hook-form'

export type EditableTableContextValueT<TFieldValues extends FieldValues = FieldValues> = {
  useUpdateRowForm: (props: { row: Record<string, unknown> }) => {
    form: UseFormReturn<TFieldValues>
    onSubmit: (values: TFieldValues) => void
  }
}

export const EditableTableContext = createContext<null | EditableTableContextValueT>(null)
