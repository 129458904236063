import { assertedNonNullable } from '@resnet/client-common/common/utils/nullable/non-nullable'
import type { HTTPClientT } from '@resnet/client-common/network/utils/create-http-client'
import { createHTTPClient } from '@resnet/client-common/network/utils/create-http-client'

let apiHttpClient: null | HTTPClientT = null

export const getApiHttpClient = (): HTTPClientT => {
  return assertedNonNullable(apiHttpClient)
}

export const setApiHttpClient = (baseUrl: string): void => {
  apiHttpClient = createHTTPClient(baseUrl)
}
