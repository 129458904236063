import { setApiHttpClient } from '@resnet/client-api/services/api-http-client'
import { setAuthApiHttpClient } from '@resnet/client-api/services/auth-api-http-client'
import { setGraphQlClient } from '@resnet/client-api/services/graphql-client'
import { setGraphQlWsClient } from '@resnet/client-api/services/graphql-ws-client'

export const setApiClients = ({
  authApiEndpoint,
  apiEndpoint,
  wsApiEndpoint,
}: {
  authApiEndpoint: string
  apiEndpoint: string
  wsApiEndpoint: string
}) => {
  setAuthApiHttpClient(authApiEndpoint)
  setApiHttpClient(apiEndpoint)
  setGraphQlClient(apiEndpoint)
  setGraphQlWsClient(wsApiEndpoint)
}
