import { Box } from '@mui/material'
import { useImperativeHandle, useRef } from 'react'

import { noop } from '@resnet/client-common/common/utils/function/noop'
import { fitNumber } from '@resnet/client-common/common/utils/number/fit-number'
import { useEventCallback } from '@resnet/client-common/react/hooks/use-event-callback'

import { FieldHelperText } from '@resnet/client-shared-web/shared/gdl/components/field'
import { NumberInput } from '@resnet/client-shared-web/shared/gdl/components/number-input'
import type { PopperRefValueT } from '@resnet/client-shared-web/shared/gdl/components/popper'
import { toPx } from '@resnet/client-shared-web/shared/gdl/utils/to-px'

export type NumberRangeFilterPropsT = {
  value: undefined | null | { from?: undefined | null | number; to?: undefined | null | number }
  onChange?: (value: undefined | null | { from?: undefined | null | number; to?: undefined | null | number }) => void
  min?: undefined | null | number
  max?: undefined | null | number
  isInteger?: boolean
  // NOTE: this filter have no popper, but just as temporary solution we'll define compatible interface here
  popperRef?: React.Ref<PopperRefValueT>
}

export const NumberRangeFilter = ({ value, onChange, popperRef, min, max, isInteger }: NumberRangeFilterPropsT) => {
  const fromRef = useRef<HTMLInputElement>(null)

  const onFocusFrom = useEventCallback(() => {
    const element = fromRef.current

    if (!element) {
      return
    }

    element.focus()
  })

  const onFromChange = useEventCallback((fromActual: null | number) => {
    const from = fitNumber({ isInteger, max, min, number: fromActual })

    const nextValueActual = { ...value, from }

    const nextValue =
      nextValueActual.from === undefined && nextValueActual.to === undefined ? undefined : nextValueActual

    onChange?.(nextValue)
  })

  const onToChange = useEventCallback((toActual: null | number) => {
    const to = fitNumber({ isInteger, max, min, number: toActual })

    const nextValueActual = { ...value, to }

    const nextValue =
      nextValueActual.from === undefined && nextValueActual.to === undefined ? undefined : nextValueActual

    onChange?.(nextValue)
  })

  useImperativeHandle(popperRef, () => ({ close: noop, open: onFocusFrom, setAnchorEl: noop }), [onFocusFrom])

  return (
    <Box sx={{ display: 'flex', gap: toPx(16) }}>
      <Box sx={{ display: 'flex', flexDirection: 'column', flexGrow: 1, gap: toPx(8), width: 0 }}>
        <NumberInput
          ref={fromRef}
          value={value?.from ?? null}
          onChange={onFromChange}
        />
        <FieldHelperText>From</FieldHelperText>
      </Box>
      <Box sx={{ display: 'flex', flexDirection: 'column', flexGrow: 1, gap: toPx(8), width: 0 }}>
        <NumberInput
          value={value?.to ?? null}
          onChange={onToChange}
        />
        <FieldHelperText>To</FieldHelperText>
      </Box>
    </Box>
  )
}
