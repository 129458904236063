export type PropNameT = string

export type ObjectT = Record<PropNameT, unknown>

export type ObjectPropT<
  SpecificObjectT extends ObjectT,
  SpecificPropNameT extends PropNameT,
> = SpecificPropNameT extends keyof SpecificObjectT ? SpecificObjectT[SpecificPropNameT] : undefined

export type MaybeObjectT = undefined | null | ObjectT

export type MaybeObjectPropT<
  SpecificMaybeObjectT extends MaybeObjectT,
  SpecificPropNameT extends string,
> = SpecificMaybeObjectT extends ObjectT ? ObjectPropT<SpecificMaybeObjectT, SpecificPropNameT> : undefined

export const getObjectProp = <
  SpecificMaybeObjectT extends MaybeObjectT,
  SpecificPropNameT extends PropNameT,
>(
  maybeObject: SpecificMaybeObjectT,
  propName: SpecificPropNameT
) => {
  return (maybeObject && typeof maybeObject === 'object' && propName in maybeObject
    ? maybeObject[propName]
    : undefined) as MaybeObjectPropT<SpecificMaybeObjectT, SpecificPropNameT>;
};