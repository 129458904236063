import type { CustomFieldFragmentT } from '@resnet/client-api/api'

import { mapCustomFieldScopeToPrefix } from './map-custom-field-scope-to-prefix'

export const mapCustomFieldToKey = (field: CustomFieldFragmentT) => {
  if (!field.scope) {
    return field.key
  }

  const prefix = mapCustomFieldScopeToPrefix(field.scope)

  return `${prefix}/${field.key}`
}

export const mapCustomFieldToFullKey = (field: CustomFieldFragmentT, fieldValuesKey: string) => {
  return `${fieldValuesKey}.${mapCustomFieldToKey(field)}`
}
