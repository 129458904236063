import type { QueryClient } from '@tanstack/react-query'
import { produce } from 'immer'

import {
  useDepartmentQuery,
  useDeleteDepartmentMutation,
  useInfiniteDepartmentsQuery,
  useCreateDepartmentMutation,
  useUpdateDepartmentMutation,
} from '@resnet/client-api/api'
import type { MutationCacheHandlersT } from '@resnet/client-api/services/query-client/types/mutation-cache-handlers'
import { createMutationHookMutationCacheHandlers } from '@resnet/client-api/services/query-client/utils/create-mutation-hook-mutation-cache-handlers'
import { findGetQueriesById, findInfiniteQueries } from '@resnet/client-api/utils/find-queries'
import { invalidateQuery } from '@resnet/client-api/utils/invalidate-query'
import { setQueryData } from '@resnet/client-api/utils/set-query-data'

export const departmentQueriesHandlers = ({ queryClient }: { queryClient: QueryClient }): MutationCacheHandlersT[] => {
  const findDepartmentQueries = ({ id }: { id: string }) =>
    findGetQueriesById(queryClient, useDepartmentQuery, (data) => data.getDepartment, { id })

  const updateDepartmentHandlers = () => {
    return createMutationHookMutationCacheHandlers(useUpdateDepartmentMutation, {
      onError: (error, { id }) => {
        findDepartmentQueries({ id }).forEach(invalidateQuery(queryClient))
      },
      onMutate: ({ id, data }) => {
        findDepartmentQueries({ id }).forEach((query) => {
          setQueryData(queryClient)(query)(
            produce((draft) => {
              const department = draft?.getDepartment

              if (!department) {
                return
              }

              Object.assign(department, data)
            }),
          )
        })
      },
      onSuccess: (data, { id }) => {
        findDepartmentQueries({ id }).forEach(invalidateQuery(queryClient))
      },
    })
  }

  const deleteDepartmentHandlers = () => {
    return createMutationHookMutationCacheHandlers(useDeleteDepartmentMutation, {
      onSuccess: (data, { id }) => {
        findDepartmentQueries({ id }).forEach(invalidateQuery(queryClient))
      },
    })
  }

  return [updateDepartmentHandlers(), deleteDepartmentHandlers()]
}

export const infiniteDepartmentsQueriesHandlers = ({
  queryClient,
}: {
  queryClient: QueryClient
}): MutationCacheHandlersT[] => {
  const findInfiniteDepartmentsQueries = () => findInfiniteQueries(queryClient, useInfiniteDepartmentsQuery)

  const createDepartmentHandlers = () => {
    return createMutationHookMutationCacheHandlers(useCreateDepartmentMutation, {
      onSuccess: () => {
        findInfiniteDepartmentsQueries().forEach(invalidateQuery(queryClient))
      },
    })
  }

  const updateDepartmentHandlers = () => {
    return createMutationHookMutationCacheHandlers(useUpdateDepartmentMutation, {
      onSuccess: () => {
        findInfiniteDepartmentsQueries().forEach(invalidateQuery(queryClient))
      },
    })
  }

  const deleteDepartmentHandlers = () => {
    return createMutationHookMutationCacheHandlers(useDeleteDepartmentMutation, {
      onSuccess: () => {
        findInfiniteDepartmentsQueries().forEach(invalidateQuery(queryClient))
      },
    })
  }

  return [createDepartmentHandlers(), updateDepartmentHandlers(), deleteDepartmentHandlers()]
}
