import type { BoxProps } from '@mui/material'
import { Box } from '@mui/material'

import { typographyPresets } from '@resnet/client-shared/shared/gdl/constants/typography-presets'

import { themeColors } from '@resnet/client-shared-web/shared/gdl/constants/theme-colors'
import { mapTypographyPresetToSx } from '@resnet/client-shared-web/shared/gdl/utils/map-typography-preset-to-sx'

export type ObjectDetailsSectionHeaderPropsT = BoxProps

export const ObjectDetailsSectionHeader = ({ sx = null, children, ...props }: ObjectDetailsSectionHeaderPropsT) => {
  return (
    <Box
      {...props}
      sx={[
        mapTypographyPresetToSx(typographyPresets.titleLarge),
        { color: themeColors.overBackgroundDefault },
        sx,
      ].flat()}
    >
      {children}
    </Box>
  )
}
