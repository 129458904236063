import { createConstrainer } from '@resnet/client-common/typescript/utils/create-constrainer'

import { optionGroupCustomField as optionGroupCustomFieldShared } from '@resnet/client-shared/shared/custom-fields/presets/option-group/custom-field'

import type { CustomFieldT } from '../../types/custom-field'

import { createColumn } from './create-column'
import { createField } from './create-field'
import { createFilterOption } from './create-filter-option'
import { mapCustomFieldToDefaultValue } from './map-custom-field-to-default-value'
import { mapCustomFieldToMutationVariable } from './map-custom-field-to-mutation-variable'
import { renderPayload } from './render-payload'
import { renderUserFormField } from './render-user-form-field'
import { renderUserFormPreview } from './render-user-form-preview'

export const optionGroupCustomField = createConstrainer<CustomFieldT>()({
  ...optionGroupCustomFieldShared,
  createColumn,
  createField,
  createFilterOption,
  mapCustomFieldToDefaultValue,
  mapCustomFieldToMutationVariable,
  renderPayload,
  renderUserFormField,
  renderUserFormPreview,
})
