import { useMemo, useState } from 'react'

import { pipeline } from '@resnet/client-common/common/utils/function/pipeline'
import { fold } from '@resnet/client-common/common/utils/object/fold'
import { useDebounced } from '@resnet/client-common/react/hooks/use-debounced'
import { createHookContainer } from '@resnet/client-common/react/utils/create-hook-container'

import type { GroupFragmentT, UserFragmentT } from '@resnet/client-api/api'
import { useInfiniteSearchGroupsQuery, useInfiniteSearchUsersQuery } from '@resnet/client-api/api'
import { defaultLimit } from '@resnet/client-api/constants/variables'

import { getGroupOptionMedia } from '@resnet/client-shared/shared/groups/utils/get-group-option-media'
import { mapGroupToTitle } from '@resnet/client-shared/shared/groups/utils/map-group-to-title'
import { mapUserToMedia } from '@resnet/client-shared/shared/users/utils/map-user-to-media-boston'
import { mapUserToTitle } from '@resnet/client-shared/shared/users/utils/map-user-to-title'

import { useSearchHandlers } from '@resnet/client-shared-web/shared/gdl/components/search'

export type RecipientTypeT = 'user' | 'group'

type UseRecipientsSelectDropdownPropsT = {
  recipientType: RecipientTypeT
  excludeIds?: string[]
  hideEmptyGroups?: boolean
}

export const useRecipientsSelectDropdown = ({
  recipientType,
  excludeIds,
  hideEmptyGroups,
}: UseRecipientsSelectDropdownPropsT) => {
  const [searchActual, setSearch] = useState('')

  const search = useDebounced(searchActual)

  const isUserRecipientType = recipientType === 'user'

  const isGroupRecipientType = recipientType === 'group'

  const usersQuery = useInfiniteSearchUsersQuery(
    {
      filter: {
        id: {
          notIn: excludeIds,
        },
      },
      limit: defaultLimit,
      search,
    },
    {
      enabled: isUserRecipientType,
      getNextPageParam: (lastPage, allPages) =>
        fold({
          offset: lastPage.searchUsers.items.length === 0 ? undefined : allPages.length * defaultLimit,
        }),
      keepPreviousData: true,
    },
  )

  const usersQueryFetchNextPage = usersQuery.fetchNextPage

  const users = useMemo(
    () =>
      pipeline(
        usersQuery.data,
        (data) => (!data ? [] : data.pages),
        (pages) => pages.flatMap((page) => page.searchUsers.items),
      ),
    [usersQuery.data],
  )

  const groupsQuery = useInfiniteSearchGroupsQuery(
    {
      filter: {
        id: {
          notIn: excludeIds,
        },
      },
      limit: defaultLimit,
      search,
    },
    {
      enabled: isGroupRecipientType,
      getNextPageParam: (lastPage, allPages) =>
        fold({
          offset: lastPage.searchGroups.items.length === 0 ? undefined : allPages.length * defaultLimit,
        }),
      keepPreviousData: true,
    },
  )

  const groupsQueryFetchNextPage = groupsQuery.fetchNextPage

  const groups = useMemo(
    () =>
      pipeline(
        groupsQuery.data,
        (data) => (!data ? [] : data.pages),
        (pages) => pages.flatMap((page) => page.searchGroups.items),
        (items) => (hideEmptyGroups ? items.filter((group) => group.members && group.members.length > 0) : items),
      ),
    [groupsQuery.data, hideEmptyGroups],
  )

  const options = useMemo(() => {
    switch (recipientType) {
      case 'user': {
        return users
      }
      case 'group': {
        return groups
      }
    }
  }, [groups, recipientType, users])

  const { onChange: onSearchChange, onClear: onSearchClear } = useSearchHandlers({ setSearch })

  const bottomBoundary = useMemo(() => {
    switch (recipientType) {
      case 'user':
        if (usersQuery.isFetchingNextPage || !usersQuery.hasNextPage) {
          return
        }

        return { onObserve: usersQueryFetchNextPage }
      case 'group':
        if (groupsQuery.isFetchingNextPage || !groupsQuery.hasNextPage) {
          return
        }

        return { onObserve: groupsQueryFetchNextPage }
    }
  }, [
    groupsQuery.hasNextPage,
    groupsQuery.isFetchingNextPage,
    groupsQueryFetchNextPage,
    recipientType,
    usersQuery.hasNextPage,
    usersQuery.isFetchingNextPage,
    usersQueryFetchNextPage,
  ])

  const getOptionLabel = (option: (typeof options)[number]) => {
    switch (recipientType) {
      case 'user': {
        return mapUserToTitle(option as UserFragmentT)
      }
      case 'group': {
        return mapGroupToTitle(option as GroupFragmentT)
      }
    }
  }

  const getOptionMedia = (option: (typeof options)[number]) => {
    switch (recipientType) {
      case 'user': {
        return mapUserToMedia(option as UserFragmentT)
      }
      case 'group': {
        return getGroupOptionMedia()
      }
    }
  }

  const isLoading = (() => {
    switch (recipientType) {
      case 'user': {
        return usersQuery.isFetching
      }
      case 'group': {
        return groupsQuery.isFetching
      }
    }
  })()

  const dropdownProps = {
    bottomBoundary,
    getOptionLabel,
    getOptionMedia,
    isLoading,
    options,
    skeletonProps: { media: { type: 'avatar' as const } },
  }

  const searchProps = {
    onChange: onSearchChange,
    onClear: onSearchClear,
    value: searchActual,
  }

  return {
    dropdownProps,
    searchProps,
  }
}

export const RecipientsSelectDropdownContainer = createHookContainer(useRecipientsSelectDropdown)
