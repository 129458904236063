import { SingleLineText } from '@resnet/client-shared-web/shared/gdl/components/single-line-text'

import type { RegularCellPropsT } from '../regular-cell'
import { RegularCell } from '../regular-cell'

export type TextCellValueT = undefined | null | string

export type TextCellPropsT = RegularCellPropsT & {
  value: TextCellValueT
}

export const TextCell = ({ value, ...props }: TextCellPropsT) => {
  const renderContent = () => {
    if (value === undefined || value === null || value.length === 0) {
      return '-'
    }

    return value
  }

  return (
    <RegularCell {...props}>
      <SingleLineText>{renderContent()}</SingleLineText>
    </RegularCell>
  )
}
