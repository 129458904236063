import { useMapFieldModel } from '@resnet/client-common/react/hooks/use-map-field-model'
import { NonNullableContextContainer } from '@resnet/client-common/react/hooks/use-non-nullable-context'

import { DateInputInput } from '@resnet/client-shared-web/shared/gdl/components/date-input'
import { DateRangeInputText } from '@resnet/client-shared-web/shared/gdl/components/date-input-text'
import { DateRangeDropdown } from '@resnet/client-shared-web/shared/gdl/components/date-range-dropdown'
import { Popper, PopperContext } from '@resnet/client-shared-web/shared/gdl/components/popper'

import type { DateRangeFilterT } from '../../../presets/types/filters'

export const DateRangeFilter = ({
  disablePortal,
  onChange: onChangeProp,
  placement,
  popperRef,
  value: valueProp,
}: {
  disablePortal?: boolean
  onChange: (value: DateRangeFilterT) => void
  placement?: React.ComponentProps<typeof Popper>['placement']
  popperRef?: React.ComponentProps<typeof Popper>['popperRef']
  value: undefined | null | DateRangeFilterT
}) => {
  const [value, onChange] = useMapFieldModel(
    [valueProp, onChangeProp],
    (inputValue): [Date | null, Date | null] => {
      if (!inputValue) {
        return [null, null]
      }

      return [new Date(inputValue.from), new Date(inputValue.to)]
    },
    (outputValue) => {
      if (!outputValue[0] || !outputValue[1]) {
        return null
      }

      return {
        from: outputValue[0].toISOString(),
        to: outputValue[1].toISOString(),
      }
    },
  )

  const renderAnchor = (): React.ReactElement => {
    return (
      <NonNullableContextContainer Context={PopperContext}>
        {({ setAnchorEl, open }) => (
          <DateInputInput
            ref={setAnchorEl}
            onClick={open}
          >
            <DateRangeInputText value={value} />
          </DateInputInput>
        )}
      </NonNullableContextContainer>
    )
  }

  const renderContent = (): React.ReactElement => {
    return (
      <DateRangeDropdown
        value={value}
        onChange={onChange}
      />
    )
  }

  return (
    <Popper
      disablePortal={disablePortal}
      placement={placement}
      popperRef={popperRef}
    >
      {{
        anchor: renderAnchor,
        content: renderContent,
      }}
    </Popper>
  )
}
