import { checkIsSpecialValue } from '../utils/check-is-special-value'

export const validateRequired = (value?: null | unknown) => {
  if (checkIsSpecialValue(value)) {
    return true
  }

  if (value == null) {
    return 'This field is required'
  }

  return true
}
