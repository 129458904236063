import type { GridColDef } from '@mui/x-data-grid'

import { checkNonNullable } from '@resnet/client-common/common/utils/nullable/non-nullable'
import { assert } from '@resnet/client-common/typescript/utils/assert'

import { CustomFieldTypeT } from '@resnet/client-api/api'

import type { DiscriminatorCustomFieldValueT } from '@resnet/client-shared/shared/custom-fields/presets/discriminator/custom-field-value'

import type { ColumnWithCreateGanttColumnT } from '@resnet/client-web/shared/gantt/types/column-with-create-gantt-column'
import { createSimpleGanttColumn } from '@resnet/client-web/shared/gantt/utils/create-simple-gantt-column'
import { createRenderColumnAsReactElement } from '@resnet/client-web/shared/gantt/utils/render-column-as-react-element'
import { RegularCell } from '@resnet/client-web/shared/tables/components/regular-cell'
import { createColumn as createCommonColumn } from '@resnet/client-web/shared/tables/factories/create-column'
import type { ColumnWithEditFieldT } from '@resnet/client-web/shared/tables/types/column-with-edit-field'
import type { ColumnWithGroupingT } from '@resnet/client-web/shared/tables/types/column-with-grouping'
import type { ColumnWithHeaderMenuFilterT } from '@resnet/client-web/shared/tables/types/column-with-header-menu-filter'
import type { ColumnWithValueT } from '@resnet/client-web/shared/tables/types/column-with-value'

import type { CreateColumnPropsT, CustomFieldT } from '../../types/custom-field'
import { getFieldValue } from '../../utils/get-field-value'
import { mapCustomFieldToFullKey } from '../../utils/map-custom-field-to-key'

export const createColumn: CustomFieldT['createColumn'] = <
  RowT extends Record<string, unknown>,
  FieldValuesKeyT extends Extract<keyof RowT, string>,
>({
  field,
  fieldValuesKey,
  mapRowToPathname,
}: CreateColumnPropsT<RowT, FieldValuesKeyT>) => {
  type ColumnT = ColumnWithHeaderMenuFilterT<
    ColumnWithEditFieldT<ColumnWithGroupingT<ColumnWithCreateGanttColumnT<GridColDef<RowT>>>>
  >

  type ColumnWithValueAppliedT = ColumnWithValueT<ColumnT, undefined | DiscriminatorCustomFieldValueT>

  const payload = field.payload

  assert(payload, checkNonNullable)

  const discriminatorPayload = payload[CustomFieldTypeT.DiscriminatorT]

  assert(discriminatorPayload, checkNonNullable)

  const getValue = (row: Record<string, unknown>) =>
    getFieldValue<DiscriminatorCustomFieldValueT>(field, fieldValuesKey, row)

  const createGanttColumn: ColumnT['createGanttColumn'] = ({ viewSettings, rowElementsRef, column }) => {
    const columnId = column.field

    const data = createRenderColumnAsReactElement<Record<string, unknown>>({ columnId, getValue, rowElementsRef })

    return createSimpleGanttColumn<Record<string, unknown>, ColumnT>({
      column,
      data,
      isReactElementRenderer: true,
      renderElement: ({ row }) => {
        const value = getValue(row)

        if (value === undefined) {
          return <RegularCell>-</RegularCell>
        }

        const option = discriminatorPayload.options.find((item) => item.key === value)

        if (option === undefined) {
          return <RegularCell>-</RegularCell>
        }

        return <RegularCell isMarkedAsDeleted={option.isMarkedAsDeleted}>{option.name}</RegularCell>
      },
      sortable: ({ row }) => {
        const value = getValue(row)

        if (value === undefined) {
          return '-'
        }

        const option = discriminatorPayload.options.find((item) => item.key === value)

        if (option === undefined) {
          return '-'
        }

        return option.name
      },
      viewSettings,
      width: 174,
    })
  }

  const columnField = mapCustomFieldToFullKey(field, fieldValuesKey)

  return createCommonColumn<ColumnWithValueAppliedT>({
    createGanttColumn,
    field: columnField,
    filterOptionId: columnField,
    groupRowRenderCell: (rowNode) => {
      const { groupingKey } = rowNode

      assert(groupingKey, checkNonNullable)

      const option = discriminatorPayload.options.find((item) => item.key === groupingKey)

      if (option === undefined) {
        return <RegularCell>-</RegularCell>
      }

      return <RegularCell isMarkedAsDeleted={option.isMarkedAsDeleted}>{option.name}</RegularCell>
    },
    groupable: true,
    groupingValueGetter: ({ row }) => {
      const value = getValue(row)

      if (value === undefined) {
        return undefined
      }

      return value
    },
    headerName: field.name,
    renderCell: ({ row, value }) => {
      const to = mapRowToPathname?.(row)

      if (value === undefined) {
        return <RegularCell to={to}>-</RegularCell>
      }

      const option = discriminatorPayload.options.find((item) => item.key === value)

      if (option === undefined) {
        return <RegularCell to={to}>-</RegularCell>
      }

      return (
        <RegularCell
          isMarkedAsDeleted={option.isMarkedAsDeleted}
          to={to}
        >
          {option.name}
        </RegularCell>
      )
    },
    valueGetter: ({ row }) => getValue(row),
  })
}
