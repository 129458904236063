import { Box } from '@mui/material'
import type { SxProps } from '@mui/system'
import { Fragment, useMemo } from 'react'

import { toggle } from '@resnet/client-common/common/utils/array/toggle'
import { forwardFunctionalComponentRef } from '@resnet/client-common/react/utils/forward-functional-component-ref'

import type { MediaT } from '@resnet/client-shared/shared/gdl/types/media'

import { TextSkeleton } from '@resnet/client-shared-web/shared/async/components/text-skeleton'
import type { AbstractOptionT, AbstractSimpleOptionT } from '@resnet/client-shared-web/shared/gdl/types/abstract-option'

import { IconAvatar } from '../../components/avatar'
import { Radio } from '../../components/radio'
import { toPx } from '../../utils/to-px'
import type { ListActionItemActionPropsT, ListItemRefT } from '../list'
import {
  List,
  ListActionItem,
  ListItem,
  ListItemAvatar,
  ListItemAvatarSkeleton,
  ListItemColor,
  ListItemColorSkeleton,
  ListItemDescription,
  ListItemIcon,
  ListItemContentLayout,
  ListItemName,
} from '../list'

export const RadioList = <OptionT extends AbstractOptionT>({
  options,
  selected: selectedProp,
  setSelected,
  renderOption,
  ...props
}: Omit<React.ComponentProps<typeof List>, 'options' | 'selected' | 'setSelected' | 'renderOption' | 'children'> & {
  options: OptionT[]
  selected: OptionT['id'][]
  setSelected: React.Dispatch<React.SetStateAction<OptionT['id'][]>>
  renderOption: (props: { option: OptionT; isSelected: boolean; onClick?: () => void }) => React.ReactNode
}) => {
  const selected = useMemo(() => new Set(selectedProp), [selectedProp])

  return (
    <List {...props}>
      {options.map((option) => {
        const isSelected = selected.has(option.id)

        const onClick = () => {
          setSelected((selected) => toggle(selected, option.id))
        }

        return <Fragment key={option.id}>{renderOption({ isSelected, onClick: onClick, option })}</Fragment>
      })}
    </List>
  )
}

export const SimpleRadioList = <OptionT extends AbstractSimpleOptionT>({
  options,
  selected,
  setSelected,
}: {
  options: OptionT[]
  selected: OptionT['id'][]
  setSelected: React.Dispatch<React.SetStateAction<OptionT['id'][]>>
}) => {
  return (
    <RadioList
      options={options}
      renderOption={({ option, isSelected, onClick }) => (
        <RadioListItem
          isSelected={isSelected}
          name={option.name}
          onClick={onClick}
        />
      )}
      selected={selected}
      setSelected={setSelected}
    />
  )
}

export type RadioListItemPropsT = {
  actions?: ListActionItemActionPropsT[]
  description?: React.ReactNode
  disabled?: boolean
  isSelected: boolean
  media?: MediaT
  name: React.ReactNode
  onClick?: () => void
  showNameTooltip?: boolean
  showSelection?: boolean
  sx?: SxProps
}

export type RadioListItemRefT = ListItemRefT

export const RadioListItem = forwardFunctionalComponentRef(
  (
    {
      actions,
      description,
      disabled,
      isSelected,
      media,
      name,
      onClick: onClickActual,
      showNameTooltip,
      showSelection = true,
      sx,
    }: RadioListItemPropsT,
    ref: RadioListItemRefT,
  ) => {
    const onClick = !disabled ? onClickActual : undefined

    const renderSelection = () => {
      if (!showSelection) {
        return null
      }

      return (
        <Radio
          size="md"
          tabIndex={-1}
          value={isSelected}
          onClick={onClick}
        />
      )
    }

    const renderMedia = () => {
      if (!media || !media.type) {
        return null
      }

      switch (media.type) {
        case 'icon': {
          return <ListItemIcon>{media.icon}</ListItemIcon>
        }
        case 'iconAvatar': {
          return (
            <IconAvatar
              size="sm"
              {...media}
            />
          )
        }
        case 'avatar': {
          return <ListItemAvatar {...media} />
        }
        case 'color': {
          return <ListItemColor color={media.color} />
        }
      }
    }

    const renderName = () => {
      return <ListItemName>{name}</ListItemName>
    }

    const renderActions = () => {
      if (!actions) {
        return null
      }

      return (
        <Box
          sx={{
            alignItems: 'center',
            display: 'flex',
            gap: toPx(4),
          }}
        >
          {actions.map((action) => (
            <ListActionItem
              key={action.id}
              {...action}
            />
          ))}
        </Box>
      )
    }

    const renderDescription = () => {
      if (!description) {
        return null
      }

      return <ListItemDescription>{description}</ListItemDescription>
    }

    return (
      <ListItem
        disabled={disabled}
        ref={ref}
        sx={sx}
        tooltipTitle={!showNameTooltip ? null : name}
        onClick={onClick}
      >
        <ListItemContentLayout>
          {{
            action: renderActions(),
            description: renderDescription(),
            media: renderMedia(),
            name: renderName(),
            selection: renderSelection(),
          }}
        </ListItemContentLayout>
      </ListItem>
    )
  },
)

export const RadioListItemSkeleton = ({ media, sx }: { sx?: SxProps; media?: MediaT }) => {
  const renderMedia = () => {
    if (!media || !media.type) {
      return null
    }

    switch (media.type) {
      case 'icon': {
        return null
      }
      case 'iconAvatar': {
        return (
          <IconAvatar
            size="md"
            {...media}
          />
        )
      }
      case 'avatar': {
        return <ListItemAvatarSkeleton />
      }
      case 'color': {
        return <ListItemColorSkeleton />
      }
    }
  }

  const renderName = () => {
    return (
      <TextSkeleton
        contentSx={{ width: '95%' }}
        typographyPreset={{ fontSize: 16, lineHeight: 24 }}
      />
    )
  }

  const renderSelection = () => {
    return (
      <Radio
        disabled
        size="md"
      />
    )
  }

  return (
    <ListItem sx={sx}>
      <ListItemContentLayout>
        {{ media: renderMedia(), name: renderName(), selection: renderSelection() }}
      </ListItemContentLayout>
    </ListItem>
  )
}
