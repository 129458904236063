import type { BoxProps, SxProps } from '@mui/material'
import { Box } from '@mui/material'
import { Link, type LinkProps } from 'react-router-dom'

import type { ForwardableT } from '@resnet/client-common/react/types/forwardable'
import { foldElement } from '@resnet/client-common/react/utils/fold-element'
import { forwardFunctionalComponentRef } from '@resnet/client-common/react/utils/forward-functional-component-ref'
import { renderForwardable } from '@resnet/client-common/react/utils/render-forwardable'
import type { MergeAllT } from '@resnet/client-common/typescript/types/merge-all'

import StarEmptySolidIcon from '@resnet/client-shared/assets/icons/star-empty-solid.svg'
import StarSolidIcon from '@resnet/client-shared/assets/icons/star-solid.svg'
import { typographyPresets } from '@resnet/client-shared/shared/gdl/constants/typography-presets'

import type { TextSkeletonPropsT } from '@resnet/client-shared-web/shared/async/components/text-skeleton'
import { TextSkeleton } from '@resnet/client-shared-web/shared/async/components/text-skeleton'
import { ClickableOpacity } from '@resnet/client-shared-web/shared/gdl/components/clickable-opacity'
import { themeColors } from '@resnet/client-shared-web/shared/gdl/constants/theme-colors'

import { mapTypographyPresetToSx } from '../../utils/map-typography-preset-to-sx'
import { toPx } from '../../utils/to-px'
import { Outline } from '../outline'
import { SingleLineText } from '../single-line-text'
import { Spinner } from '../spinner'

export type PopoverMenuSectionPropsT = BoxProps

export const PopoverMenuSection = ({ sx = null, children, ...props }: PopoverMenuSectionPropsT) => {
  return (
    <Box
      {...props}
      sx={[{ display: 'flex', flexDirection: 'column', gap: toPx(4) }, sx].flat()}
    >
      {children}
    </Box>
  )
}

export type PopoverMenuSectionNamePropsT = BoxProps

export const PopoverMenuSectionName = ({ sx = null, children, ...props }: PopoverMenuSectionNamePropsT) => {
  return (
    <Box
      {...props}
      sx={[
        mapTypographyPresetToSx(typographyPresets.overlineSmall),
        { color: themeColors.overBackgroundFaded, textTransform: 'uppercase' },
        sx,
      ].flat()}
    >
      {children}
    </Box>
  )
}

export type PopoverMenuEmptySectionMessagePropsT = BoxProps

export const PopoverMenuEmptySectionMessage = ({
  sx = null,
  children,
  ...props
}: PopoverMenuEmptySectionMessagePropsT) => {
  return (
    <Box
      {...props}
      sx={[
        mapTypographyPresetToSx(typographyPresets.bodyMedium),
        { color: themeColors.overBackgroundBold, padding: toPx(8) },
        sx,
      ].flat()}
    >
      {children}
    </Box>
  )
}

export type PopoverMenuItemPropsT = MergeAllT<
  [
    BoxProps,
    {
      to?: string | LinkProps['to']
      onClick?: (event: React.MouseEvent<HTMLElement>) => void
      isLoading?: boolean
      children: {
        icon?: React.ReactNode
        name: React.ReactNode
        isFavouriteIndicator?: React.ReactNode
        actions?: React.ReactNode
      }
    },
  ]
>

export type PopoverMenuItemRefT = React.Ref<HTMLElement>

export const PopoverMenuItem = forwardFunctionalComponentRef(
  ({ sx = null, children, to, onClick, isLoading, ...props }: PopoverMenuItemPropsT, ref: PopoverMenuItemRefT) => {
    const borderRadius = toPx(8)

    const renderButton = () => {
      const getButtonSx = (): SxProps => {
        return [
          { all: 'unset', width: '100%' },
          { borderRadius, cursor: 'pointer', inset: 0, position: 'absolute' },
          {
            '&:hover + *, &:focus + *': {
              backgroundColor: themeColors.surfaceNeutralHover,
            },
          },
          {
            '&:active + *': {
              backgroundColor: themeColors.surfaceNeutralPressed,
            },
          },
        ]
      }

      const dataTestid = 'popover-menu-item-button'

      if (to) {
        return (
          <Box
            component={Link}
            data-testid={dataTestid}
            sx={getButtonSx()}
            to={to}
            onClick={onClick}
          />
        )
      }

      return (
        <Box
          component="button"
          data-testid={dataTestid}
          sx={getButtonSx()}
          onClick={isLoading ? undefined : onClick}
        />
      )
    }

    const renderUnderlay = () => {
      return (
        <Box
          sx={{ borderRadius, inset: 0, position: 'absolute', transition: 'background-color 200ms ease', zIndex: -1 }}
        />
      )
    }

    const renderContent = () => {
      return (
        <Box sx={{ alignItems: 'center', display: 'flex', flexDirection: 'row', gap: toPx(8), minWidth: 0 }}>
          {children.icon}
          {children.name}
          {children.isFavouriteIndicator}
        </Box>
      )
    }

    const renderActions = () => {
      return foldElement(
        <Box sx={{ alignItems: 'center', display: 'flex', flexDirection: 'row', gap: toPx(8) }}>
          {children.actions}
        </Box>,
      )
    }

    const renderSpinner = () => {
      if (!isLoading) {
        return null
      }

      return (
        <Box
          sx={{
            alignItems: 'center',
            display: 'flex',
            inset: '0',
          }}
        >
          <Spinner
            color="inherit"
            size={16}
          />
        </Box>
      )
    }

    return (
      <Box
        {...props}
        data-testid="popover-menu-item"
        ref={ref}
        sx={[
          {
            '--popover-item-show-on-hover-opacity': 0,
            alignItems: 'center',
            borderRadius,
            display: 'flex',
            flexDirection: 'row',
            gap: toPx(8),
            justifyContent: 'space-between',
            padding: toPx(8),
            position: 'relative',
            zIndex: 0,
          },
          {
            '&:hover, &:focus-within': {
              '--popover-item-show-on-hover-opacity': 1,
            },
          },
          sx,
        ].flat()}
      >
        {renderButton()}
        {renderUnderlay()}
        {renderContent()}
        {renderSpinner()}
        {renderActions()}
      </Box>
    )
  },
)

export type PopoverMenuItemIconPropsT = { children: ForwardableT<React.SVGProps<SVGSVGElement>> }

export const PopoverMenuItemIcon = ({ children }: PopoverMenuItemIconPropsT) => {
  return <>{renderForwardable(children, { fill: themeColors.overBackgroundBold, height: 20, width: 20 })}</>
}

export type PopoverMenuItemNamePropsT = MergeAllT<[Omit<BoxProps, 'ref'>, { showTooltip?: boolean }]>

export const PopoverMenuItemName = ({ showTooltip, sx = null, children, ...props }: PopoverMenuItemNamePropsT) => {
  return (
    <SingleLineText
      {...props}
      data-testid="popover-menu-item-name"
      showTooltip={showTooltip}
      sx={[
        mapTypographyPresetToSx(typographyPresets.bodyMedium),
        { color: themeColors.overBackgroundBold, overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' },
        sx,
      ].flat()}
    >
      {children}
    </SingleLineText>
  )
}

export type PopoverMenuItemNameSkeletonPropsT = Omit<TextSkeletonPropsT, 'typographyPreset'>

export const PopoverMenuItemNameSkeleton = (props: PopoverMenuItemNameSkeletonPropsT) => {
  return (
    <TextSkeleton
      {...props}
      typographyPreset={typographyPresets.bodyMedium}
    />
  )
}

export type PopoverMenuItemIsFavouriteIndicatorPropsT = MergeAllT<
  [
    Omit<BoxProps, 'children'>,
    {
      isActive: boolean
      onSelect?: () => void
      onUnselect?: () => void
    },
  ]
>

export const PopoverMenuItemIsFavouriteIndicator = ({
  sx = null,
  isActive,
  onSelect,
  onUnselect,
  ...props
}: PopoverMenuItemIsFavouriteIndicatorPropsT) => {
  const Icon = isActive ? StarSolidIcon : StarEmptySolidIcon

  return (
    <Box
      {...props}
      data-testid="popover-menu-item-is-favourite-indicator"
      sx={[
        {
          color: themeColors.overBackgroundBold,
          display: 'flex',
          flexDirection: 'column',
          opacity: 'var(--popover-item-show-on-hover-opacity)',
          position: 'relative',
          transition: 'opacity 200ms ease',
        },
        !isActive
          ? {}
          : {
              color: themeColors.basePrimary,
              opacity: 1,
            },
        sx,
      ].flat()}
    >
      <ClickableOpacity
        onClick={() => {
          if (isActive) {
            onUnselect?.()
          } else {
            onSelect?.()
          }
        }}
      >
        <Icon
          height={16}
          width={16}
        />
      </ClickableOpacity>
    </Box>
  )
}

export type PopoverMenuItemActionPropsT = MergeAllT<
  [
    Omit<BoxProps, 'children'>,
    {
      children: ForwardableT<React.SVGProps<SVGSVGElement>>
    },
  ]
>

export const PopoverMenuItemAction = ({ sx = null, children, ...props }: PopoverMenuItemActionPropsT) => {
  return (
    <Box
      {...props}
      sx={[
        {
          color: themeColors.overBackgroundBold,
          display: 'flex',
          flexDirection: 'column',
          opacity: 'var(--popover-item-show-on-hover-opacity)',
          position: 'relative',
          transition: 'opacity 200ms ease',
        },
        sx,
      ].flat()}
    >
      <ClickableOpacity>{renderForwardable(children, { height: 16, width: 16 })}</ClickableOpacity>
    </Box>
  )
}

export type PopoverMenuItemSkeletonPropsT = Omit<BoxProps, 'children'>

export const PopoverMenuItemSkeleton = ({ sx = null, ...props }: PopoverMenuItemSkeletonPropsT) => {
  const borderRadius = toPx(8)

  return (
    <Box
      {...props}
      sx={[
        {
          alignItems: 'center',
          borderRadius,
          display: 'flex',
          flexDirection: 'row',
          gap: toPx(8),
          justifyContent: 'space-between',
          padding: toPx(8),
          position: 'relative',
          zIndex: 0,
        },
        sx,
      ].flat()}
    >
      <Box sx={{ alignItems: 'center', display: 'flex', flexDirection: 'row', gap: toPx(8), width: '70%' }}>
        <PopoverMenuItemNameSkeleton
          contentSx={{ width: '100%' }}
          sx={{ width: '100%' }}
        />
      </Box>
    </Box>
  )
}

export type PopoverMenuPropsT = Omit<BoxProps, 'ref'>

export const PopoverMenu = forwardFunctionalComponentRef(
  ({ children, sx, ...props }: PopoverMenuPropsT, ref: React.Ref<HTMLDivElement>) => {
    const borderRadius = toPx(8)

    return (
      <Box
        {...props}
        ref={ref}
        sx={[
          {
            backgroundColor: themeColors.surfaceNeutralDefault,
            borderRadius,
            boxSizing: 'border-box',
            display: 'flex',
            flexDirection: 'column',
            gap: toPx(16),
            outline: 'unset',
            padding: toPx(8),
            position: 'relative',
            width: toPx(320),
          },
          sx ?? null,
        ].flat()}
        tabIndex={-1}
      >
        {children}
        <Outline
          color="borderBold"
          sx={{ borderRadius }}
        />
      </Box>
    )
  },
)
