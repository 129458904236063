import { useAutoFocusRef } from '@resnet/client-shared-web/shared/form/hooks/use-auto-focus-ref'

import type { SearchPropsT } from '../../../components/search'
import { Search } from '../../../components/search'

export type SelectSearchPropsT = SearchPropsT

export const SelectSearch = (props: SelectSearchPropsT) => {
  const autoFocusRef = useAutoFocusRef(true, 300)

  return (
    <Search
      inputName="select-search"
      inputRef={autoFocusRef}
      {...props}
    />
  )
}
