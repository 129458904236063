import { identity } from 'ramda'

import { pipeline } from '@resnet/client-common/common/utils/function/pipeline'
import { assertedNonUndefined } from '@resnet/client-common/common/utils/nullable/non-nullable'

import type { CustomFieldFragmentT } from '@resnet/client-api/api'
import { type CreateIssuesMutationVariablesT } from '@resnet/client-api/api'

import type { FormCustomFieldValuesT } from '@resnet/client-web/shared/custom-fields/types/form-custom-field-values'
import { mapFormCustomFieldValuesToCustomFieldValues } from '@resnet/client-web/shared/custom-fields/utils/map-form-custom-field-values-to-custom-field-values'
import { mapEntityAccessFieldValueToRemoteValue } from '@resnet/client-web/shared/entities/utils/map-entity-access-field-value-to-remote-value'
import {
  ignoreDirtyFields,
  mapFieldValuesToRemoteValues,
} from '@resnet/client-web/shared/form-dalaran/utils/map-field-values-to-remote-values'

import type { CreateIssueFormValuesT } from '../types/create-issue-form-values'

export const mapCreateIssueFormValuesToCreateIssuesMutationVariables = ({
  fields,
  values,
}: {
  fields: CustomFieldFragmentT[]
  values: CreateIssueFormValuesT
}): CreateIssuesMutationVariablesT => {
  const mapSpecificFormCustomFieldValuesToCustomFieldValues = (fieldValues: FormCustomFieldValuesT) =>
    mapFormCustomFieldValuesToCustomFieldValues(fields)(fieldValues)

  return {
    assetIds: pipeline(values.assetIds, assertedNonUndefined),
    cloneForWells: pipeline(values.cloneForWells, assertedNonUndefined),
    data: mapFieldValuesToRemoteValues(values, ignoreDirtyFields, {
      access: mapEntityAccessFieldValueToRemoteValue,
      actualEndDate: identity,
      actualStartDate: identity,
      afe: (x) => x.trim(),
      assigneeId: identity,
      campaignId: identity,
      customFields: mapSpecificFormCustomFieldValuesToCustomFieldValues,
      customerLookupId: identity,
      description: (x) => x.trim(),
      dispatched: identity,
      estimatedEndDate: identity,
      estimatedStartDate: identity,
      flag: identity,
      labels: identity,
      name: (x) => x.trim(),
      needsApproval: identity,
      priority: identity,
      resourceObjectId: identity,
      status: identity,
      type: identity,
    }),
  }
}
