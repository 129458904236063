import { useEffect, useMemo, useState } from 'react'
import type { FieldPathByValue, FieldValues, UseFormReturn } from 'react-hook-form'
import { useController } from 'react-hook-form'

import { transform } from '@resnet/client-common/common/utils/object/transform'
import { useEventCallback } from '@resnet/client-common/react/hooks/use-event-callback'
import { useMapFieldModel } from '@resnet/client-common/react/hooks/use-map-field-model'

import type { ResourceObjectsQueryVariablesT } from '@resnet/client-api/api'
import { useResourceObjectTypesQuery } from '@resnet/client-api/api'

import { getSpecialValuesMultipleFieldProps } from '@resnet/client-shared/shared/forms/utils/get-special-values-field-props'
import { ResourceObjectOptionContainer } from '@resnet/client-shared/shared/resource-objects/hooks/use-resource-object-option'
import { mapResourceObjectToPathname } from '@resnet/client-shared/shared/resource-objects/utils/map-resource-object-to-pathname'
import { mapResourceObjectToTitle } from '@resnet/client-shared/shared/resource-objects/utils/map-resource-object-to-title'
import { mapResourceObjectTypeToTitle } from '@resnet/client-shared/shared/resource-objects/utils/map-resource-object-type-to-title'

import {
  ListField,
  ListFieldFooterColumn,
  ListFieldItemName,
  ListFieldItemReadableId,
  ListFieldItemTypeName,
} from '@resnet/client-shared-web/shared/gdl/components/list-field'

import { ResourceObjectTypeSelect } from '@resnet/client-web/shared/resource-objects/components/resource-object-type-select-vienna'
import { ResourceObjectTypeOptionContainer } from '@resnet/client-web/shared/resource-objects/hooks/use-resource-object-type-option'
import { ResourceObjectSelect } from '@resnet/client-web/shared/resource-objects/selects/resource-object-select-vienna'

export type ResourceObjectRelationFieldValueT = undefined | null | string

export type ResourceObjectRelationFieldPropsT<TFieldValues extends FieldValues> = {
  label: string
  form: UseFormReturn<TFieldValues>
  name: FieldPathByValue<TFieldValues, ResourceObjectRelationFieldValueT>
}

export const ResourceObjectRelationField = <TFieldValues extends FieldValues>({
  label,
  form,
  name,
}: ResourceObjectRelationFieldPropsT<TFieldValues>) => {
  const { control } = form

  const {
    field: { value: valueActualActual, onChange: onChangeActualActual },
    field,
  } = useController({ control, name })

  const valueActual = valueActualActual as Exclude<ResourceObjectRelationFieldValueT, undefined>

  const specialValuesFieldProps = useMemo(() => getSpecialValuesMultipleFieldProps(field), [field])

  const onChangeActual = onChangeActualActual as (value: Exclude<ResourceObjectRelationFieldValueT, undefined>) => void

  // Even so this component is supposed to be used with a single value it's design is implemented to display multiple ones.
  // Perhaps in future we will unlock it for multiple values so then we can drop this mapping without affecting other code.
  const [value, onChange] = useMapFieldModel(
    [valueActual, onChangeActual],
    (inputValue) => {
      if (!specialValuesFieldProps) {
        return !inputValue ? [] : [inputValue]
      }

      return specialValuesFieldProps.value
    },
    (outputValue) => (outputValue.length === 0 ? null : outputValue[outputValue.length - 1]),
  )

  const [activeResourceObjectTypeId, setActiveResourceObjectTypeId] = useState<undefined | null | string>(null)

  const defaultResourceObjectTypeQuery = useResourceObjectTypesQuery(
    { limit: 1 },
    {
      enabled: !activeResourceObjectTypeId && !specialValuesFieldProps,
      select: (data) => data.listResourceObjectTypes.items.at(0),
    },
  )

  useEffect(() => {
    if (
      !activeResourceObjectTypeId &&
      defaultResourceObjectTypeQuery.isSuccess &&
      defaultResourceObjectTypeQuery.data
    ) {
      setActiveResourceObjectTypeId(defaultResourceObjectTypeQuery.data.id)
    }
  }, [activeResourceObjectTypeId, defaultResourceObjectTypeQuery.data, defaultResourceObjectTypeQuery.isSuccess])

  const withResourceObjectDropdownQueryVariables = (input: ResourceObjectsQueryVariablesT) => {
    if (!activeResourceObjectTypeId) {
      return input
    }

    return transform(input, {
      filter: (filter) =>
        transform(filter ?? {}, { resourceObjectTypeId: () => ({ in: [activeResourceObjectTypeId] }) }),
    })
  }

  const onItemRemove = useEventCallback((item: string) => {
    onChange(value.filter((currentItem) => currentItem !== item))
  })

  const onItemDropdownChange = useEventCallback((item: undefined | null | string) => {
    if (!item) {
      return
    }

    onChange(value.concat([item]))
  })

  const renderItemName = (item: string) => {
    return (
      <ListFieldItemName
        ItemOptionContainer={ResourceObjectOptionContainer}
        item={item}
        mapItemOptionToPathname={mapResourceObjectToPathname}
        mapItemOptionToTitle={mapResourceObjectToTitle}
      />
    )
  }

  const renderItemTypeName = (item: string) => {
    return (
      <ListFieldItemTypeName
        ItemOptionContainer={ResourceObjectOptionContainer}
        ItemTypeOptionContainer={ResourceObjectTypeOptionContainer}
        item={item}
        mapItemOptionToItemType={(x) => x.resourceObjectTypeId}
        mapItemTypeOptionToTitle={mapResourceObjectTypeToTitle}
      />
    )
  }

  const renderItemReadableId = (item: string) => {
    return (
      <ListFieldItemReadableId
        ItemOptionContainer={ResourceObjectOptionContainer}
        item={item}
        mapItemOptionToShortReadableId={(x) => x.readableId}
      />
    )
  }

  const renderItemTypeSelectFooterColumn = () => {
    return (
      <ListFieldFooterColumn name="Resource type">
        <ResourceObjectTypeSelect
          value={activeResourceObjectTypeId}
          onChange={setActiveResourceObjectTypeId}
        />
      </ListFieldFooterColumn>
    )
  }

  const renderItemSelectFooterColumn = () => {
    return (
      <ListFieldFooterColumn name="Resource">
        <ResourceObjectSelect
          exclude={value}
          placeholder={specialValuesFieldProps?.placeholder}
          value={null}
          withQueryVariables={withResourceObjectDropdownQueryVariables}
          onChange={onItemDropdownChange}
        />
      </ListFieldFooterColumn>
    )
  }

  return (
    <ListField
      items={value}
      label={label}
      renderItemName={renderItemName}
      renderItemReadableId={renderItemReadableId}
      renderItemSelectFooterColumn={renderItemSelectFooterColumn}
      renderItemTypeName={renderItemTypeName}
      renderItemTypeSelectFooterColumn={renderItemTypeSelectFooterColumn}
      onItemRemove={onItemRemove}
    />
  )
}
