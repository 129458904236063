import { ObjectId } from 'bson'

import { checkNonNullable } from '@resnet/client-common/common/utils/nullable/non-nullable'
import { assert } from '@resnet/client-common/typescript/utils/assert'

import { CustomFieldTypeT } from '@resnet/client-api/api'

import type {
  SketchCustomFieldValueT,
  SketchMultipleCustomFieldValueT,
  SketchSingleCustomFieldValueT,
} from '@resnet/client-shared/shared/custom-fields/presets/sketch/custom-field-value'

import type { CustomFieldT } from '../../types/custom-field'

import type { SketchFormCustomFieldValueT } from './form-custom-field-value'

export const patchCustomFieldValueResources: CustomFieldT<
  SketchCustomFieldValueT,
  SketchFormCustomFieldValueT
>['patchCustomFieldValueResources'] = ({ value, field, resourcesRef }) => {
  const payload = field.payload

  assert(payload, checkNonNullable)

  const sketchPayload = payload[CustomFieldTypeT.SketchT]

  assert(sketchPayload, checkNonNullable)

  const { multiple } = sketchPayload

  if (multiple) {
    const sketchMultipleFieldValue = value as undefined | SketchMultipleCustomFieldValueT

    if (!sketchMultipleFieldValue) {
      return sketchMultipleFieldValue
    }

    return sketchMultipleFieldValue.map((resourceId) => {
      const newResourceId = new ObjectId().toHexString()

      resourcesRef[resourceId] = newResourceId

      return newResourceId
    })
  }

  const sketchSingleFieldValue = value as undefined | SketchSingleCustomFieldValueT

  if (!sketchSingleFieldValue) {
    return sketchSingleFieldValue
  }

  const newResourceId = new ObjectId().toHexString()

  resourcesRef[sketchSingleFieldValue] = newResourceId

  return newResourceId
}
