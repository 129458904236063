import { Box } from '@mui/material'

import { ErrorBoundary } from '@resnet/client-common/react/components/error-boundary'
import { ErrorBoundaryFallbackEffectContainer } from '@resnet/client-common/react/hooks/use-error-boundary-fallback-effect'

import type { MediaT } from '@resnet/client-shared/shared/gdl/types/media'

import type { AbstractOptionT } from '@resnet/client-shared-web/shared/gdl/types/abstract-option'

import type { OptionContainerT } from '../../types/option-container'
import { toPx } from '../../utils/to-px'
import type { DropdownInputTextPropsT } from '../dropdown-input'
import {
  DropdownInputMedia,
  DropdownInputPlaceholder,
  DropdownInputText,
  DropdownInputTextSkeleton,
} from '../dropdown-input'

export type DropdownTextPropsT<
  OptionT extends AbstractOptionT,
  ValueT extends undefined | null | OptionT['id'] = OptionT['id'],
> = {
  getOptionLabel?: (option: OptionT) => string
  getOptionMedia?: (option: OptionT) => MediaT
  onCurrentValueQueryError?: () => void
  OptionContainer?: OptionContainerT<OptionT>
  placeholder?: string
  size?: DropdownInputTextPropsT['size']
  value: ValueT
}

export const DropdownText = <
  OptionT extends AbstractOptionT,
  ValueT extends undefined | null | OptionT['id'] = OptionT['id'],
>({
  getOptionLabel,
  getOptionMedia,
  onCurrentValueQueryError,
  OptionContainer,
  placeholder = 'Not Selected',
  size,
  value,
}: DropdownTextPropsT<OptionT, ValueT>) => {
  if (!value) {
    return <DropdownInputPlaceholder size={size}>{placeholder}</DropdownInputPlaceholder>
  }

  if (!OptionContainer || !getOptionLabel) {
    return <DropdownInputText size={size}>{value}</DropdownInputText>
  }

  return (
    <ErrorBoundary
      fallback={({ onRecover, recoveredCount }) => (
        <ErrorBoundaryFallbackEffectContainer
          onError={onCurrentValueQueryError}
          onRecover={recoveredCount > 0 ? undefined : onRecover}
        >
          {() => <DropdownInputPlaceholder>Not Found</DropdownInputPlaceholder>}
        </ErrorBoundaryFallbackEffectContainer>
      )}
    >
      <OptionContainer id={value}>
        {({ optionQuery }) => {
          if (!optionQuery.isSuccess) {
            return <DropdownInputTextSkeleton size={size} />
          }

          const option = optionQuery.data

          const media = getOptionMedia?.(option)

          if (media) {
            return (
              <Box sx={{ alignItems: 'center', display: 'flex', gap: toPx(8), minWidth: 0 }}>
                <DropdownInputMedia
                  media={media}
                  size={size}
                />
                <DropdownInputText size={size}>{getOptionLabel(option)}</DropdownInputText>
              </Box>
            )
          }

          return <DropdownInputText size={size}>{getOptionLabel(option)}</DropdownInputText>
        }}
      </OptionContainer>
    </ErrorBoundary>
  )
}
