import type { GridApi } from '@mui/x-data-grid'
import { createContext } from 'react'

import { MemoProvider } from '@resnet/client-common/react/components/memo-provider'

import type { AbstractOptionT } from '@resnet/client-shared-web/shared/gdl/types/abstract-option'

export type CellContextT<RowT extends AbstractOptionT = AbstractOptionT, ValueT = unknown> = {
  api: GridApi
  field: string
  row: RowT
  value: ValueT
}

export const CellContext = createContext<null | CellContextT>(null)

export const CellContextProvider = <RowT extends AbstractOptionT = AbstractOptionT, ValueT = unknown>({
  value,
  children,
}: {
  value: CellContextT<RowT, ValueT>
  children: React.ReactNode
}) => {
  return (
    <MemoProvider
      Context={CellContext}
      value={value}
    >
      {children}
    </MemoProvider>
  )
}
