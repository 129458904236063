import { type SxProps } from '@mui/material'
import { useRef, useEffect, useMemo } from 'react'
import type { UseControllerReturn } from 'react-hook-form'
import { useController } from 'react-hook-form'

import { useAsyncEventCallback } from '@resnet/client-common/react/hooks/use-async-event-callback'
import { useNonNullableContext } from '@resnet/client-common/react/hooks/use-non-nullable-context'
import type { ForwardableT } from '@resnet/client-common/react/types/forwardable'
import { renderForwardable } from '@resnet/client-common/react/utils/render-forwardable'

import type { DropdownInputPropsT } from '@resnet/client-shared-web/shared/gdl/components/dropdown-input'
import type { PopperPropsT, PopperRefValueT } from '@resnet/client-shared-web/shared/gdl/components/popper'

import { CellContext } from '../../contexts/cell-context'
import { EditableTableContext } from '../../contexts/editable-table'
import type { OptionCellValueT } from '../option-cell'

export type SelectPropsT = {
  hasError?: boolean
  isBusy?: boolean
  onChange?: (nextValue: OptionCellValueT) => void
  popperRef?: PopperPropsT['popperRef']
  size?: DropdownInputPropsT['size']
  sx?: SxProps
  value: OptionCellValueT
}

export type EditOptionCellPropsT = {
  name: string
  select: ForwardableT<SelectPropsT>
  mapFieldValueToSelectValue?: (value: unknown) => OptionCellValueT
  mapSelectValueToFieldValue?: (value: OptionCellValueT) => unknown
}

const mapFieldValueToSelectValueDefault = (value: unknown) => value as OptionCellValueT

const mapSelectValueToFieldValueDefault = (value: OptionCellValueT) => value as unknown

export const EditOptionCell = ({
  name,
  select,
  mapFieldValueToSelectValue = mapFieldValueToSelectValueDefault,
  mapSelectValueToFieldValue = mapSelectValueToFieldValueDefault,
}: EditOptionCellPropsT) => {
  const { useUpdateRowForm } = useNonNullableContext(EditableTableContext)

  const { row } = useNonNullableContext(CellContext)

  const { form, onSubmit } = useUpdateRowForm({ row })

  const {
    field: { value: valueActual, onChange: onChangeActual },
  } = useController({ control: form.control, name }) as UseControllerReturn<Record<string, unknown>, string>

  const value = useMemo(() => mapFieldValueToSelectValue(valueActual), [valueActual, mapFieldValueToSelectValue])

  const popperRef = useRef<null | PopperRefValueT>(null)

  const [isChangeLoading, onChange] = useAsyncEventCallback(async (nextValueActual: OptionCellValueT) => {
    const nextValue = mapSelectValueToFieldValue(nextValueActual)

    onChangeActual(nextValue)

    await onSubmit(form.getValues())
  })

  useEffect(() => {
    popperRef.current?.open()
  }, [])

  return renderForwardable(select, {
    isBusy: isChangeLoading,
    onChange,
    popperRef,
    size: 'sm',
    sx: { flexGrow: 1, minWidth: 0 },
    value,
  })
}
