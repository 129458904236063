import { checkNonNullable } from '@resnet/client-common/common/utils/nullable/non-nullable'

import type { WellFragmentT } from '@resnet/client-api/api'

export const mapWellToCoordinates = ({
  well,
}: {
  well: Pick<WellFragmentT, 'location'>
}): undefined | null | [number, number] => {
  const location = well.location

  if (!location) {
    return undefined
  }

  const [lon, lat] = location.coordinates

  if (!checkNonNullable(lon) || !checkNonNullable(lat)) {
    return undefined
  }

  return [lon, lat]
}
