import { produce } from 'immer'

import type { ListGroupsQueryT } from '@resnet/client-api/api'
import {
  useAddGroupMembersMutation,
  useChangeGroupDescriptionMutation,
  useChangeGroupNameMutation,
  useCreateGroupMutation,
  useDeleteGroupMutation,
  useGroupQuery,
  useInfiniteListGroupsQuery,
  useJoinGroupMutation,
  useLeaveGroupMutation,
  useListGroupsQuery,
  useRemoveGroupMembersMutation,
} from '@resnet/client-api/api'
import type { MutationCacheHandlersT } from '@resnet/client-api/services/query-client/types/mutation-cache-handlers'
import { createMutationHookMutationCacheHandlers } from '@resnet/client-api/services/query-client/utils/create-mutation-hook-mutation-cache-handlers'
import { findGetQueriesById, findInfiniteQueries, findQueries } from '@resnet/client-api/utils/find-queries'
import { invalidateQuery } from '@resnet/client-api/utils/invalidate-query'
import { setQueryData } from '@resnet/client-api/utils/set-query-data'
import type { QueryClient } from '@resnet/client-api/vendors/react-query'

export const groupQueriesHandlers = ({ queryClient }: { queryClient: QueryClient }): MutationCacheHandlersT[] => {
  const findGroupQueries = ({ id }: { id: string }) =>
    findGetQueriesById(queryClient, useGroupQuery, (data) => data.getGroup, { id })

  const joinGroupHandlers = () => {
    return createMutationHookMutationCacheHandlers(useJoinGroupMutation, {
      onSuccess: (data, { groupId }) => {
        findGroupQueries({ id: groupId }).forEach(invalidateQuery(queryClient))
      },
    })
  }

  const leaveGroupHandlers = () => {
    return createMutationHookMutationCacheHandlers(useLeaveGroupMutation, {
      onSuccess: (data, { groupId }) => {
        findGroupQueries({ id: groupId }).forEach(invalidateQuery(queryClient))
      },
    })
  }

  const changeGroupNameHandlers = () => {
    return createMutationHookMutationCacheHandlers(useChangeGroupNameMutation, {
      onError: (error, { groupId }) => {
        findGroupQueries({ id: groupId }).forEach(invalidateQuery(queryClient))
      },
      onMutate: ({ groupId, name }) => {
        findGroupQueries({ id: groupId }).forEach((query) => {
          setQueryData(queryClient)(query)(
            produce((draft) => {
              const group = draft?.getGroup

              if (!group) {
                return
              }

              Object.assign(group, { name })
            }),
          )
        })
      },
    })
  }

  const changeGroupDescriptionHandlers = () => {
    return createMutationHookMutationCacheHandlers(useChangeGroupDescriptionMutation, {
      onError: (error, { groupId }) => {
        findGroupQueries({ id: groupId }).forEach(invalidateQuery(queryClient))
      },
      onMutate: ({ groupId, description }) => {
        findGroupQueries({ id: groupId }).forEach((query) => {
          setQueryData(queryClient)(query)(
            produce((draft) => {
              const group = draft?.getGroup

              if (!group) {
                return
              }

              Object.assign(group, { description })
            }),
          )
        })
      },
      onSuccess: (data, { groupId }) => {
        findGroupQueries({ id: groupId }).forEach(invalidateQuery(queryClient))
      },
    })
  }

  const removeGroupMembersHandlers = () => {
    return createMutationHookMutationCacheHandlers(useRemoveGroupMembersMutation, {
      onSuccess: (data, { groupId }) => {
        findGroupQueries({ id: groupId }).forEach(invalidateQuery(queryClient))
      },
    })
  }

  const addGroupMembersHandlers = () => {
    return createMutationHookMutationCacheHandlers(useAddGroupMembersMutation, {
      onSuccess: (data, { groupId }) => {
        findGroupQueries({ id: groupId }).forEach(invalidateQuery(queryClient))
      },
    })
  }

  const deleteGroupHandlers = () => {
    return createMutationHookMutationCacheHandlers(useDeleteGroupMutation, {
      onSuccess: (data, { groupId }) => {
        findGroupQueries({ id: groupId }).forEach(invalidateQuery(queryClient))
      },
    })
  }

  return [
    joinGroupHandlers(),
    leaveGroupHandlers(),
    changeGroupNameHandlers(),
    changeGroupDescriptionHandlers(),
    removeGroupMembersHandlers(),
    addGroupMembersHandlers(),
    deleteGroupHandlers(),
  ]
}

export const groupsQueriesHandlers = ({ queryClient }: { queryClient: QueryClient }): MutationCacheHandlersT[] => {
  const findGroupsQueries = () => findQueries(queryClient, useListGroupsQuery)

  const findInfiniteGroupsQueries = () => findInfiniteQueries(queryClient, useInfiniteListGroupsQuery)

  const deleteGroup = (data: undefined | ListGroupsQueryT, { id }: { id: string }) => {
    if (!data) {
      return
    }

    const items = data.listGroups.items

    const index = items.findIndex((item) => item.id === id)

    if (index === -1) {
      return
    }

    items.splice(index, 1)
  }

  const createGroupHandlers = () => {
    return createMutationHookMutationCacheHandlers(useCreateGroupMutation, {
      onSuccess: () => {
        findGroupsQueries().forEach(invalidateQuery(queryClient))
        findInfiniteGroupsQueries().forEach(invalidateQuery(queryClient))
      },
    })
  }

  const deleteGroupHandlers = () => {
    return createMutationHookMutationCacheHandlers(useDeleteGroupMutation, {
      onError: () => {
        findGroupsQueries().forEach(invalidateQuery(queryClient))
        findInfiniteGroupsQueries().forEach(invalidateQuery(queryClient))
      },
      onMutate: ({ groupId: id }) => {
        findGroupsQueries().forEach((query) =>
          setQueryData(queryClient)(query)(produce((data) => deleteGroup(data, { id }))),
        )
        findInfiniteGroupsQueries().forEach((query) =>
          setQueryData(queryClient)(query)(produce((data) => data?.pages.forEach((page) => deleteGroup(page, { id })))),
        )
      },
      onSuccess: () => {
        findGroupsQueries().forEach(invalidateQuery(queryClient))
        findInfiniteGroupsQueries().forEach(invalidateQuery(queryClient))
      },
    })
  }

  return [createGroupHandlers(), deleteGroupHandlers()]
}
