import { produce } from 'immer'

import {
  EntityTypeIdsT,
  useArchiveCredentialMutation,
  useCreateCredentialMutation,
  useDeleteCredentialMutation,
  useGetCredentialQuery,
  useInfiniteListCredentialsQuery,
  useListCredentialsQuery,
  useUnarchiveCredentialMutation,
  useUpdateCredentialMutation,
  useUpdateEntityTypeMutation,
  useUpdateRelationsMutation,
} from '@resnet/client-api/api'
import type { MutationCacheHandlersT } from '@resnet/client-api/services/query-client/types/mutation-cache-handlers'
import { createMutationHookMutationCacheHandlers } from '@resnet/client-api/services/query-client/utils/create-mutation-hook-mutation-cache-handlers'
import {
  infiniteListEntityQueryDeleteRecipe,
  listEntityQueryDeleteRecipe,
} from '@resnet/client-api/shared/entities/utils/delete-entity-recipe'
import {
  getEntityQueryUpdateRecipe,
  infiniteListEntityQueryUpdateRecipe,
  listEntityQueryUpdateRecipe,
} from '@resnet/client-api/shared/entities/utils/update-entity-recipe'
import { findGetQueriesById, findInfiniteQueries, findQueries } from '@resnet/client-api/utils/find-queries'
import { invalidateQuery } from '@resnet/client-api/utils/invalidate-query'
import { setQueryData } from '@resnet/client-api/utils/set-query-data'
import type { QueryClient } from '@resnet/client-api/vendors/react-query'

export const getCredentialQueriesHandlers = ({
  queryClient,
}: {
  queryClient: QueryClient
}): MutationCacheHandlersT[] => {
  const findCredentialQueries = () => {
    return findQueries(queryClient, useGetCredentialQuery)
  }

  const findCredentialQueriesById = ({ id }: { id: string }) =>
    findGetQueriesById(queryClient, useGetCredentialQuery, (data) => data.getCredential, { id })

  const deleteCredentialHandlers = () => {
    return createMutationHookMutationCacheHandlers(useDeleteCredentialMutation, {
      onSuccess: (data, { id }) => {
        findCredentialQueriesById({ id }).forEach(invalidateQuery(queryClient))
      },
    })
  }

  const updateCredentialHandlers = () => {
    return createMutationHookMutationCacheHandlers(useUpdateCredentialMutation, {
      onError: (error, { id }) => {
        findCredentialQueriesById({ id }).forEach(invalidateQuery(queryClient))
      },
      onMutate: ({ id, data: input }) => {
        findCredentialQueriesById({ id }).forEach((query) =>
          setQueryData(queryClient)(query)(
            produce((data) => {
              getEntityQueryUpdateRecipe({ data, input, mapDataToEntity: (data) => data.getCredential })
            }),
          ),
        )
      },
      onSuccess: (data, { id }) => {
        findCredentialQueriesById({ id }).forEach(invalidateQuery(queryClient))
      },
    })
  }

  const archiveCredentialHandlers = () => {
    return createMutationHookMutationCacheHandlers(useArchiveCredentialMutation, {
      onSuccess: (data, { id }) => {
        findCredentialQueriesById({ id }).forEach(invalidateQuery(queryClient))
      },
    })
  }

  const unarchiveCredentialHandlers = () => {
    return createMutationHookMutationCacheHandlers(useUnarchiveCredentialMutation, {
      onSuccess: (data, { id }) => {
        findCredentialQueriesById({ id }).forEach(invalidateQuery(queryClient))
      },
    })
  }

  const updateRelationsHandlers = () => {
    return createMutationHookMutationCacheHandlers(useUpdateRelationsMutation, {
      onSuccess: (data, { entityId }) => {
        findCredentialQueriesById({ id: entityId }).forEach(invalidateQuery(queryClient))
      },
    })
  }

  const updateEntityTypeHandlers = () => {
    return createMutationHookMutationCacheHandlers(useUpdateEntityTypeMutation, {
      onSuccess: (data, { id }) => {
        if (id !== EntityTypeIdsT.CredentialT) {
          return
        }

        findCredentialQueries().forEach(invalidateQuery(queryClient))
      },
    })
  }

  return [
    deleteCredentialHandlers(),
    updateCredentialHandlers(),
    archiveCredentialHandlers(),
    unarchiveCredentialHandlers(),
    updateRelationsHandlers(),
    updateEntityTypeHandlers(),
  ]
}

export const listCredentialsQueriesHandlers = ({
  queryClient,
}: {
  queryClient: QueryClient
}): MutationCacheHandlersT[] => {
  const findCredentialsQueries = () => findQueries(queryClient, useListCredentialsQuery)

  const findInfiniteCredentialsQueries = () => findInfiniteQueries(queryClient, useInfiniteListCredentialsQuery)

  const createCredentialHandlers = () => {
    return createMutationHookMutationCacheHandlers(useCreateCredentialMutation, {
      onSuccess: () => {
        findCredentialsQueries().forEach(invalidateQuery(queryClient))
        findInfiniteCredentialsQueries().forEach(invalidateQuery(queryClient))
      },
    })
  }

  const deleteCredentialHandlers = () => {
    return createMutationHookMutationCacheHandlers(useDeleteCredentialMutation, {
      onError: () => {
        findCredentialsQueries().forEach(invalidateQuery(queryClient))
        findInfiniteCredentialsQueries().forEach(invalidateQuery(queryClient))
      },
      onMutate: ({ id }) => {
        findCredentialsQueries().forEach((query) =>
          setQueryData(queryClient)(query)(
            produce((data) => {
              listEntityQueryDeleteRecipe({ data, id, mapDataToEntities: (data) => data.listCredentials.items })
            }),
          ),
        )
        findInfiniteCredentialsQueries().forEach((query) =>
          setQueryData(queryClient)(query)(
            produce((data) => {
              infiniteListEntityQueryDeleteRecipe({ data, id, mapDataToEntities: (data) => data.listCredentials.items })
            }),
          ),
        )
      },
      onSuccess: () => {
        findCredentialsQueries().forEach(invalidateQuery(queryClient))
        findInfiniteCredentialsQueries().forEach(invalidateQuery(queryClient))
      },
    })
  }

  const updateCredentialHandlers = () => {
    return createMutationHookMutationCacheHandlers(useUpdateCredentialMutation, {
      onError: () => {
        findCredentialsQueries().forEach(invalidateQuery(queryClient))
        findInfiniteCredentialsQueries().forEach(invalidateQuery(queryClient))
      },
      onMutate: ({ id, data: input }) => {
        findCredentialsQueries().forEach((query) =>
          setQueryData(queryClient)(query)(
            produce((data) => {
              listEntityQueryUpdateRecipe({
                data,
                id,
                input,
                mapDataToEntities: (data) => data.listCredentials.items,
              })
            }),
          ),
        )
        findInfiniteCredentialsQueries().forEach((query) =>
          setQueryData(queryClient)(query)(
            produce((data) => {
              infiniteListEntityQueryUpdateRecipe({
                data,
                id,
                input,
                mapDataToEntities: (data) => data.listCredentials.items,
              })
            }),
          ),
        )
      },
      onSuccess: () => {
        findCredentialsQueries().forEach(invalidateQuery(queryClient))
        findInfiniteCredentialsQueries().forEach(invalidateQuery(queryClient))
      },
    })
  }

  const archiveCredentialHandlers = () => {
    return createMutationHookMutationCacheHandlers(useArchiveCredentialMutation, {
      onSuccess: () => {
        findCredentialsQueries().forEach(invalidateQuery(queryClient))
        findInfiniteCredentialsQueries().forEach(invalidateQuery(queryClient))
      },
    })
  }

  const unarchiveCredentialHandlers = () => {
    return createMutationHookMutationCacheHandlers(useUnarchiveCredentialMutation, {
      onSuccess: () => {
        findCredentialsQueries().forEach(invalidateQuery(queryClient))
        findInfiniteCredentialsQueries().forEach(invalidateQuery(queryClient))
      },
    })
  }

  const updateRelationsHandlers = () => {
    return createMutationHookMutationCacheHandlers(useUpdateRelationsMutation, {
      onSuccess: () => {
        findCredentialsQueries().forEach(invalidateQuery(queryClient))
        findInfiniteCredentialsQueries().forEach(invalidateQuery(queryClient))
      },
    })
  }

  const updateEntityTypeHandlers = () => {
    return createMutationHookMutationCacheHandlers(useUpdateEntityTypeMutation, {
      onSuccess: (data, { id }) => {
        if (id !== EntityTypeIdsT.CredentialT) {
          return
        }

        findInfiniteCredentialsQueries().forEach(invalidateQuery(queryClient))
      },
    })
  }

  return [
    createCredentialHandlers(),
    deleteCredentialHandlers(),
    updateCredentialHandlers(),
    archiveCredentialHandlers(),
    unarchiveCredentialHandlers(),
    updateRelationsHandlers(),
    updateEntityTypeHandlers(),
  ]
}
