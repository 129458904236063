import { Box } from '@mui/material'
import { useSnackbar } from 'notistack'

import { ChildrenDivider, ChildrenRepeater } from '@resnet/client-common/react/components/children-transformer'

import type { ApprovalTemplatePresetFragmentT } from '@resnet/client-api/api'
import {
  useDeleteCompanyApprovalTemplatePresetMutation,
  useDeleteMyApprovalTemplatePresetMutation,
  useListCompanyApprovalTemplatePresetsQuery,
  useListMyApprovalTemplatePresetsQuery,
} from '@resnet/client-api/api'

import DeleteSolidIcon from '@resnet/client-shared/assets/icons/delete-solid.svg'
import { useProfile } from '@resnet/client-shared/shared/users/hooks/use-profile'
import { checkHasAdminRights } from '@resnet/client-shared/shared/users/utils/check-has-admin-rights'

import { Divider } from '@resnet/client-shared-web/shared/gdl/components/divider'
import { Popover } from '@resnet/client-shared-web/shared/gdl/components/popover'
import {
  PopoverMenuEmptySectionMessage,
  PopoverMenuItem,
  PopoverMenuItemAction,
  PopoverMenuItemName,
  PopoverMenuItemSkeleton,
  PopoverMenuSection,
  PopoverMenuSectionName,
} from '@resnet/client-shared-web/shared/gdl/components/popover-menu'
import { toPx } from '@resnet/client-shared-web/shared/gdl/utils/to-px'

import { DeleteModalContainer } from '../../../common/components/delete-modal'

export const ApprovalTemplatePresetsPopover = ({
  onSelect,
}: {
  onSelect?: (approvalTemplatePreset: ApprovalTemplatePresetFragmentT) => void
}): React.ReactElement => {
  const { enqueueSnackbar } = useSnackbar()

  const { mutateAsync: deleteMyApprovalTemplatePreset } = useDeleteMyApprovalTemplatePresetMutation()

  const { mutateAsync: deleteCompanyApprovalTemplatePreset } = useDeleteCompanyApprovalTemplatePresetMutation()

  const { profile } = useProfile()

  const listMyApprovalTemplatePresetsQuery = useListMyApprovalTemplatePresetsQuery(
    {},
    {
      select: (data) => data.listMyApprovalTemplatePresets,
    },
  )

  const listCompanyApprovalTemplatePresetsQuery = useListCompanyApprovalTemplatePresetsQuery(
    {},
    {
      select: (data) => data.listCompanyApprovalTemplatePresets,
    },
  )

  const renderContent = (): React.ReactNode => {
    if (!listMyApprovalTemplatePresetsQuery.isSuccess || !listCompanyApprovalTemplatePresetsQuery.isSuccess) {
      return (
        <PopoverMenuSection>
          <ChildrenRepeater count={3}>
            <PopoverMenuItemSkeleton />
          </ChildrenRepeater>
        </PopoverMenuSection>
      )
    }

    const renderMyApprovalTemplatePresetsSection = (): React.ReactNode => {
      return (
        <PopoverMenuSection>
          <PopoverMenuSectionName>My Approval Presets</PopoverMenuSectionName>
          {listMyApprovalTemplatePresetsQuery.data.items.length === 0 ? (
            <PopoverMenuEmptySectionMessage>No approval presets yet</PopoverMenuEmptySectionMessage>
          ) : (
            listMyApprovalTemplatePresetsQuery.data.items.map((approvalTemplatePreset) => (
              <PopoverMenuItem
                key={approvalTemplatePreset.id}
                onClick={() => {
                  onSelect?.(approvalTemplatePreset)
                }}
              >
                {{
                  actions: (
                    <DeleteModalContainer>
                      {({ onOpen, onClose }) => (
                        <PopoverMenuItemAction
                          data-testid="delete-action"
                          onClick={() => {
                            onOpen({
                              message: `Are you sure you want to delete "${approvalTemplatePreset.name}" approval preset?`,
                              onConfirm: async () => {
                                await deleteMyApprovalTemplatePreset({ id: approvalTemplatePreset.id })

                                onClose()

                                enqueueSnackbar(`"${approvalTemplatePreset.name}" approval preset has been deleted`, {
                                  variant: 'warning',
                                })
                              },
                            })
                          }}
                        >
                          <DeleteSolidIcon />
                        </PopoverMenuItemAction>
                      )}
                    </DeleteModalContainer>
                  ),
                  name: <PopoverMenuItemName>{approvalTemplatePreset.name}</PopoverMenuItemName>,
                }}
              </PopoverMenuItem>
            ))
          )}
        </PopoverMenuSection>
      )
    }

    const renderCompanyApprovalTemplatePresetsSection = (): React.ReactNode => {
      return (
        <PopoverMenuSection>
          <PopoverMenuSectionName>Company Approval Presets</PopoverMenuSectionName>
          {listCompanyApprovalTemplatePresetsQuery.data.items.length === 0 ? (
            <PopoverMenuEmptySectionMessage>No company approval presets yet</PopoverMenuEmptySectionMessage>
          ) : (
            listCompanyApprovalTemplatePresetsQuery.data.items.map((approvalTemplatePreset) => (
              <PopoverMenuItem
                key={approvalTemplatePreset.id}
                onClick={() => {
                  onSelect?.(approvalTemplatePreset)
                }}
              >
                {{
                  actions: !checkHasAdminRights(profile) ? null : (
                    <DeleteModalContainer>
                      {({ onOpen, onClose }) => (
                        <PopoverMenuItemAction
                          data-testid="delete-action"
                          onClick={() => {
                            onOpen({
                              message: `Are you sure you want to delete "${approvalTemplatePreset.name}" approval preset?`,
                              onConfirm: async () => {
                                await deleteCompanyApprovalTemplatePreset({ id: approvalTemplatePreset.id })

                                onClose()

                                enqueueSnackbar(`"${approvalTemplatePreset.name}" approval preset has been deleted`, {
                                  variant: 'warning',
                                })
                              },
                            })
                          }}
                        >
                          <DeleteSolidIcon />
                        </PopoverMenuItemAction>
                      )}
                    </DeleteModalContainer>
                  ),
                  name: <PopoverMenuItemName>{approvalTemplatePreset.name}</PopoverMenuItemName>,
                }}
              </PopoverMenuItem>
            ))
          )}
        </PopoverMenuSection>
      )
    }

    return (
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: toPx(16), overflow: 'auto' }}>
        <ChildrenDivider dividerNode={<Divider />}>
          {renderMyApprovalTemplatePresetsSection()}
          {renderCompanyApprovalTemplatePresetsSection()}
        </ChildrenDivider>
      </Box>
    )
  }

  return <Popover sx={{ maxHeight: toPx(540), width: toPx(480) }}>{renderContent()}</Popover>
}
