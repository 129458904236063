import { propToKey } from '@resnet/client-common/common/utils/object/prop-to-key'

import { IssueTypeT } from '@resnet/client-api/api'

import CircleSolidIcon from '@resnet/client-shared/assets/icons/circle-solid.svg'
import RhombusSolidIcon from '@resnet/client-shared/assets/icons/rhombus-solid.svg'
import SquareSolidIcon from '@resnet/client-shared/assets/icons/square-solid.svg'
import TriangleSolidIcon from '@resnet/client-shared/assets/icons/triangle-solid.svg'

import { systemColors } from '../../gdl/constants/system-colors'

export const issueTypeOptions = [
  {
    Icon: TriangleSolidIcon,
    icon: <TriangleSolidIcon />,
    iconColor: systemColors.critical60,
    id: IssueTypeT.BustT as const,
    name: 'Bust',
  },
  {
    Icon: CircleSolidIcon,
    icon: <CircleSolidIcon />,
    iconColor: systemColors.alienSkin40,
    id: IssueTypeT.TaskT as const,
    name: 'Task',
  },
  {
    Icon: SquareSolidIcon,
    icon: <SquareSolidIcon />,
    iconColor: systemColors.info60,
    id: IssueTypeT.CaseT as const,
    name: 'Case',
  },
  {
    Icon: RhombusSolidIcon,
    icon: <RhombusSolidIcon />,
    iconColor: systemColors.moonBase40,
    id: IssueTypeT.InspectionT as const,
    name: 'Inspection',
  },
]

export const issueTypeOptionById = propToKey('id', issueTypeOptions)
