import type { MergeAllT } from '@resnet/client-common/typescript/types/merge-all'

import type { FunctionT } from '../../types/common'

import { mapToKeys } from './map-to-keys'
import { update } from './update'

type ToNonUndefinedPropsT<ObjectT extends Record<string, unknown>> = {
  [K in keyof ObjectT]-?: Exclude<ObjectT[K], undefined>
}

type ReturnTypePropsT<ObjectT extends Record<string, FunctionT>> = {
  [K in keyof ObjectT]: ReturnType<ObjectT[K]>
}

export const transform = <
  InputT extends Record<string, unknown>,
  SpecT extends Partial<{ [K in keyof InputT]: (input: InputT[K]) => unknown }>,
>(
  input: InputT,
  spec: SpecT,
) => {
  let output = input

  for (const key of mapToKeys(spec)) {
    const updater = spec[key]

    if (!updater) {
      continue
    }

    output = update(output, key as keyof InputT, updater as (input: InputT[keyof InputT]) => InputT[keyof InputT])
  }

  return output as MergeAllT<[InputT, ReturnTypePropsT<ToNonUndefinedPropsT<SpecT>>]>
}
