import type { SxProps } from '@mui/material'
import { Box } from '@mui/material'

import { ChildrenRepeater } from '@resnet/client-common/react/components/children-transformer'

import type { ResourceT } from '@resnet/client-shared-web/shared/files/types/resource'
import { toColumnWidth } from '@resnet/client-shared-web/shared/gdl/utils/to-column-width'
import { toPx } from '@resnet/client-shared-web/shared/gdl/utils/to-px'
import { AttachmentPreviewModalContainer } from '@resnet/client-shared-web/shared/resources/components/attachment-preview-modal'
import { useResourcesGallery } from '@resnet/client-shared-web/shared/resources/hooks/use-resources-gallery'
import { ResourcesGalleryItemContainer } from '@resnet/client-shared-web/shared/resources/hooks/use-resources-gallery-item'

import { SketchCard, SketchCardSkeleton } from '../sketch-card'

export type SketchGalleryPropsT = {
  resources: ResourceT[]
  isDeleteButtonVisible?: boolean
  onDelete?: (id: string) => void
}

export const SketchGallery = ({ resources, isDeleteButtonVisible, onDelete }: SketchGalleryPropsT) => {
  const gallerySize = resources.length

  const { openPrev, openNext, register } = useResourcesGallery()

  const renderRoot = () => {
    if (resources.length === 0) {
      return null
    }

    const gap = 8

    return (
      <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: toPx(gap) }}>
        {resources.map((resource, index) => {
          const galleryIndex = index

          return (
            <Box
              key={resource.id}
              sx={{ flexGrow: 1, flexShrink: 1, minWidth: toColumnWidth(2, gap), width: 0 }}
            >
              <AttachmentPreviewModalContainer>
                {({ onOpen: onOpenProp, onClose }) => {
                  const onOpen = () => {
                    onOpenProp({
                      attachment: resource,
                      hasNext: galleryIndex < gallerySize - 1,
                      hasPrev: galleryIndex > 0,
                      isDeleteButtonVisible: false,
                      onOpenNext: () => {
                        onClose()
                        openNext(galleryIndex)
                      },
                      onOpenPrev: () => {
                        onClose()
                        openPrev(galleryIndex)
                      },
                    })
                  }

                  return (
                    <ResourcesGalleryItemContainer
                      index={galleryIndex}
                      register={register}
                      onOpen={onOpen}
                    >
                      {() => {
                        const src = (() => {
                          switch (resource.__typename) {
                            case '@DraftResource':
                              return resource.url
                            case 'Resource':
                              return resource.optimized?.medium?.url ?? resource.url
                          }
                        })()

                        return (
                          <SketchCard
                            isDeleteButtonVisible={isDeleteButtonVisible}
                            src={src}
                            onClick={onOpen}
                            onDelete={() => onDelete?.(resource.id)}
                          />
                        )
                      }}
                    </ResourcesGalleryItemContainer>
                  )
                }}
              </AttachmentPreviewModalContainer>
            </Box>
          )
        })}
      </Box>
    )
  }

  return <>{renderRoot()}</>
}

type SketchGallerySkeletonPropsT = {
  sx?: SxProps
}

export const SketchGallerySkeleton = ({ sx = null }: SketchGallerySkeletonPropsT) => {
  const gap = 8

  return (
    <Box sx={[{ display: 'flex', flexWrap: 'wrap', gap: toPx(gap) }, sx].flat()}>
      <ChildrenRepeater count={2}>
        <Box sx={{ flexGrow: 1, flexShrink: 1, minWidth: toColumnWidth(2, gap), width: 0 }}>
          <SketchCardSkeleton />
        </Box>
      </ChildrenRepeater>
    </Box>
  )
}
