import { checkNonNullable } from '@resnet/client-common/common/utils/nullable/non-nullable'
import { foldElement } from '@resnet/client-common/react/utils/fold-element'
import { assert } from '@resnet/client-common/typescript/utils/assert'

import { CustomFieldTypeT, type CustomFieldFragmentT } from '@resnet/client-api/api'

import type { EntityPickerCustomFieldValueT } from '@resnet/client-shared/shared/custom-fields/presets/entity-picker/custom-field-value'

import {
  FieldPreviewLabel,
  FieldPreviewLayout,
  FieldPreviewValueContent,
} from '@resnet/client-shared-web/shared/gdl/components/field-preview'

import { EntityListItemCard } from '@resnet/client-web/shared/entities/components/entity-list-item-card'
import { ObjectDetailsRow } from '@resnet/client-web/shared/object/components/object-details-row'

export type EntityPickerFormUserFormPreviewPropsT = {
  field: CustomFieldFragmentT
  value: undefined | EntityPickerCustomFieldValueT
}
export const EntityPickerFormUserFormPreview = ({
  field,
  value: valueActual,
}: EntityPickerFormUserFormPreviewPropsT) => {
  const payload = field.payload

  assert(payload, checkNonNullable)

  const entityPickerPayload = payload[CustomFieldTypeT.EntityPickerT]

  assert(entityPickerPayload, checkNonNullable)

  const { isHalfWidth } = entityPickerPayload

  const getSizeSx = () => {
    if (isHalfWidth) {
      return null
    }

    return {
      gridColumn: 'span 2',
    }
  }

  const value = [valueActual].flat()

  return (
    <ObjectDetailsRow sx={getSizeSx()}>
      <FieldPreviewLayout>
        <FieldPreviewLabel>{field.name}</FieldPreviewLabel>
        <FieldPreviewValueContent>
          {foldElement(
            <>
              {value.filter(checkNonNullable).map((entityId) => (
                <EntityListItemCard
                  withLink
                  id={entityId}
                  key={entityId}
                />
              ))}
            </>,
          )}
        </FieldPreviewValueContent>
      </FieldPreviewLayout>
    </ObjectDetailsRow>
  )
}
