import type { BoxProps } from '@mui/material'
import { Box, Tooltip } from '@mui/material'

import { propToKey } from '@resnet/client-common/common/utils/object/prop-to-key'
import { forwardFunctionalComponentRef } from '@resnet/client-common/react/utils/forward-functional-component-ref'
import type { MergeT } from '@resnet/client-common/typescript/types/merge'

import CircleCheckSolidIcon from '@resnet/client-shared/assets/icons/circle-check-solid.svg'
import CircleXmarkSolidIcon from '@resnet/client-shared/assets/icons/circle-xmark-solid.svg'

import { visuallyHiddenStyles } from '@resnet/client-shared-web/styles/sx-presets'

import { themeColors } from '../../constants/theme-colors'
import { focusOutlineSx } from '../../sx-presets/focus-outline'
import { toPx } from '../../utils/to-px'

const switchDefaultHeight = 24

const switchWidth = ({ switchHeight }: { switchHeight: number }) => (switchHeight / switchDefaultHeight) * 48

const trackHeight = ({ switchHeight }: { switchHeight: number }) => (switchHeight / switchDefaultHeight) * 20

const switchPadding = ({ switchHeight }: { switchHeight: number }) => (switchHeight - trackHeight({ switchHeight })) / 2

const trackWidth = ({ switchHeight }: { switchHeight: number }) => (switchHeight / switchDefaultHeight) * 44

const trackBorder = 1

const thumbHeight = ({ switchHeight }: { switchHeight: number }) => (switchHeight / switchDefaultHeight) * 16

const trackPadding = ({ switchHeight }: { switchHeight: number }) =>
  (trackHeight({ switchHeight }) - trackBorder - thumbHeight({ switchHeight }) - trackBorder) / 2

const thumbWidth = ({ switchHeight }: { switchHeight: number }) => thumbHeight({ switchHeight })

const thumbBorder = 1

const thumbCheckedOffset = ({ switchHeight }: { switchHeight: number }) =>
  trackWidth({ switchHeight }) -
  trackBorder -
  trackPadding({ switchHeight }) -
  thumbWidth({ switchHeight }) -
  trackBorder -
  trackPadding({ switchHeight })

export const sizeOptions = [
  { id: 'sm' as const, switchHeight: 16 },
  { id: 'md' as const, switchHeight: 24 },
]

export const sizeOptionsById = propToKey('id', sizeOptions)

const switchSx = ({ switchHeight }: { switchHeight: number }) => {
  return {
    display: 'flex',
    flexDirection: 'column',
    height: toPx(switchHeight),
    padding: toPx(switchPadding({ switchHeight })),
    width: toPx(switchWidth({ switchHeight })),
  }
}

const trackSx = ({ switchHeight }: { switchHeight: number }) => {
  return {
    alignItems: 'center',
    backgroundColor: 'var(--switch-track-background-color)',
    borderColor: 'var(--switch-track-border-color)',
    borderRadius: toPx(999),
    borderStyle: 'solid',
    borderWidth: toPx(trackBorder),
    display: 'flex',
    height: toPx(trackHeight({ switchHeight })),
    padding: toPx(trackPadding({ switchHeight })),
    transition: 'background-color 0.2s ease-in-out, border-color 0.2s ease-in-out',
    width: toPx(trackWidth({ switchHeight })),
  }
}

const trackNotCheckedSx = () => {
  return {
    '--switch-thumb-background-color': themeColors.surfaceNeutralDefault,
    '--switch-thumb-border-color': themeColors.borderBold,
    '--switch-thumb-offset': toPx(0),
    '--switch-track-background-color': themeColors.surfaceNeutralDefault,
    '--switch-track-border-color': themeColors.borderBold,
  }
}

const trackNotCheckedHoverSx = () => {
  return {
    '--switch-track-background-color': themeColors.surfaceNeutralHover,
  }
}

const trackNotCheckedActiveSx = () => {
  return {
    '--switch-track-background-color': themeColors.surfaceNeutralPressed,
  }
}

const trackCheckedSx = ({ switchHeight }: { switchHeight: number }) => {
  return {
    '--switch-thumb-background-color': themeColors.background,
    '--switch-thumb-border-color': themeColors.background,
    '--switch-thumb-offset': toPx(thumbCheckedOffset({ switchHeight })),
    '--switch-track-background-color': themeColors.actionsPrimaryDefault,
    '--switch-track-border-color': themeColors.actionsPrimaryDefault,
  }
}

const trackCheckedHoverSx = () => {
  return {
    '--switch-track-background-color': themeColors.actionsPrimaryHover,
    '--switch-track-border-color': themeColors.actionsPrimaryHover,
  }
}

const trackCheckedActiveSx = () => {
  return {
    '--switch-track-background-color': themeColors.actionsPrimaryPressed,
    '--switch-track-border-color': themeColors.actionsPrimaryPressed,
  }
}

const trackDisabledSx = () => {
  return {
    cursor: 'not-allowed',
    filter: 'saturation(0)',
    opacity: 0.2,
  }
}

const thumbSx = ({ switchHeight }: { switchHeight: number }) => {
  return {
    backgroundColor: 'var(--switch-thumb-background-color)',
    borderColor: 'var(--switch-thumb-border-color)',
    borderRadius: '50%',
    borderStyle: 'solid',
    borderWidth: toPx(thumbBorder),
    height: toPx(thumbHeight({ switchHeight })),
    transform: 'translateX(var(--switch-thumb-offset))',
    transition: 'background-color 0.2s ease-in-out, border-color 0.2s ease-in-out, transform 0.2s ease-in-out',
    width: toPx(thumbWidth({ switchHeight })),
  }
}

export type SwitchPropsT = MergeT<
  Omit<BoxProps, 'children'>,
  {
    disabled?: boolean
    disabledTooltipTitle?: string
    onChange?: (value: boolean) => void
    size: (typeof sizeOptions)[number]['id']
    value: boolean
  }
>

export type SwitchRefT = React.Ref<HTMLElement>

export const Switch = forwardFunctionalComponentRef(
  ({ disabled, disabledTooltipTitle, onChange, size, sx = null, value, ...props }: SwitchPropsT, ref: SwitchRefT) => {
    const { switchHeight } = sizeOptionsById[size]

    const renderActualInput = () => {
      return (
        <Box
          checked={value}
          component="input"
          data-checked={value.toString()}
          data-testid="switch-input"
          disabled={disabled}
          sx={visuallyHiddenStyles}
          type="checkbox"
          onChange={(event) => {
            onChange?.(event.target.checked)
          }}
        />
      )
    }

    const renderThumb = () => {
      return <Box sx={thumbSx({ switchHeight })} />
    }

    const renderTrack = () => {
      return (
        <Box
          sx={[
            trackSx({ switchHeight }),
            { cursor: 'pointer' },
            {
              'input:not(:checked) + &': trackNotCheckedSx(),
            },
            {
              'input:not(:checked):not([disabled]) + &:hover': trackNotCheckedHoverSx(),
            },
            {
              'input:not(:checked):not([disabled]) + &:active': trackNotCheckedActiveSx(),
            },
            {
              'input:checked + &': trackCheckedSx({ switchHeight }),
            },
            {
              'input:checked:not([disabled]) + &:hover': trackCheckedHoverSx(),
            },
            {
              'input:checked:not([disabled]) + &:active': trackCheckedActiveSx(),
            },
            {
              'input:focus-visible:not([disabled]) + &': focusOutlineSx,
            },
            {
              'input[disabled] + &': trackDisabledSx(),
            },
          ]}
        >
          {renderThumb()}
        </Box>
      )
    }

    return (
      <Tooltip title={disabled ? disabledTooltipTitle : ''}>
        <Box
          {...props}
          data-testid="switch"
          ref={ref}
          sx={[switchSx({ switchHeight }), sx].flat()}
        >
          {renderActualInput()}
          {renderTrack()}
        </Box>
      </Tooltip>
    )
  },
)

export type SwitchPreviewPropsT = MergeT<
  Omit<React.SVGProps<SVGSVGElement>, 'fill' | 'width' | 'height'>,
  {
    size: (typeof sizeOptions)[number]['id']
    value: boolean
  }
>

export type SwitchPreviewRefT = React.Ref<SVGSVGElement>

export const SwitchPreview = forwardFunctionalComponentRef(
  ({ value, size, ...props }: SwitchPreviewPropsT, ref: SwitchPreviewRefT) => {
    const { switchHeight } = sizeOptionsById[size]

    const Icon = value ? CircleCheckSolidIcon : CircleXmarkSolidIcon

    const fill = value ? themeColors.actionsPrimaryDefault : themeColors.overBackgroundMuted

    return (
      <Icon
        {...props}
        data-checked={value.toString()}
        data-testid="switch-preview"
        fill={fill}
        height={switchHeight}
        ref={ref}
        width={switchHeight}
      />
    )
  },
)
