import { createConstrainer } from '@resnet/client-common/typescript/utils/create-constrainer'

import { EntityTypeIdsT, type MinimalAssetFragmentT } from '@resnet/client-api/api'

export const assetTypenames = createConstrainer<MinimalAssetFragmentT['__typename'][]>()([
  EntityTypeIdsT.BatteryT,
  EntityTypeIdsT.SiteT,
  EntityTypeIdsT.WellT,
])

export const assetTypenamesSet = new Set(assetTypenames)
