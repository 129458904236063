import type { FieldNamesMarkedBoolean, FieldValues } from 'react-hook-form'

export const returnUndefinedIfNotIsDirty = <FieldValuesT extends FieldValues, ValueT>(
  dirtyFields: FieldNamesMarkedBoolean<FieldValuesT>,
) => {
  return (key: keyof FieldValuesT) => {
    return (value: ValueT) => {
      const isDirty = dirtyFields?.[key as keyof FieldNamesMarkedBoolean<FieldValuesT>]

      if (!isDirty) {
        return undefined
      }

      return value
    }
  }
}
