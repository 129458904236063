import { produce } from 'immer'

import {
  useCreateWellMutation,
  useDeleteWellMutation,
  useFollowMutation,
  useInfiniteWellsQuery,
  useUnfollowMutation,
  useUpdateFollowersMutation,
  useUpdateWellMutation,
  useUploadResourceMutation,
  useGetWellQuery,
  useWellsQuery,
  useUpdateRelationsMutation,
  useArchiveWellMutation,
  useUnarchiveWellMutation,
  EntityTypeIdsT,
  useUpdateEntityTypeMutation,
} from '@resnet/client-api/api'
import type { MutationCacheHandlersT } from '@resnet/client-api/services/query-client/types/mutation-cache-handlers'
import { createMutationHookMutationCacheHandlers } from '@resnet/client-api/services/query-client/utils/create-mutation-hook-mutation-cache-handlers'
import { infiniteListEntityQueryDeleteRecipe } from '@resnet/client-api/shared/entities/utils/delete-entity-recipe'
import {
  getEntityQueryUpdateRecipe,
  infiniteListEntityQueryUpdateRecipe,
} from '@resnet/client-api/shared/entities/utils/update-entity-recipe'
import { findGetQueriesById, findInfiniteQueries, findQueries } from '@resnet/client-api/utils/find-queries'
import { invalidateQuery } from '@resnet/client-api/utils/invalidate-query'
import { setQueryData } from '@resnet/client-api/utils/set-query-data'
import type { QueryClient } from '@resnet/client-api/vendors/react-query'

export const wellQueriesHandlers = ({ queryClient }: { queryClient: QueryClient }): MutationCacheHandlersT[] => {
  const findWellQueries = () => {
    return findQueries(queryClient, useGetWellQuery)
  }

  const findWellQueriesById = ({ id }: { id: string }) => {
    return findGetQueriesById(queryClient, useGetWellQuery, (data) => data.getWell, { id })
  }

  const updateWellHandlers = () => {
    return createMutationHookMutationCacheHandlers(useUpdateWellMutation, {
      onError: (error, { id }) => {
        findWellQueriesById({ id }).forEach(invalidateQuery(queryClient))
      },
      onMutate: ({ id, data: input }) => {
        findWellQueriesById({ id }).forEach((query) => {
          setQueryData(queryClient)(query)(
            produce((data) => {
              getEntityQueryUpdateRecipe({ data, input, mapDataToEntity: (data) => data.getWell })
            }),
          )
        })
      },
      onSuccess: (data, { id }) => {
        findWellQueriesById({ id }).forEach((query) => {
          setQueryData(queryClient)(query)(() => ({
            getWell: data.updateWell,
          }))
        })
      },
    })
  }

  const followHandlers = () => {
    return createMutationHookMutationCacheHandlers(useFollowMutation, {
      onSuccess: (data, { entityId }) => {
        findWellQueriesById({ id: entityId }).forEach(invalidateQuery(queryClient))
      },
    })
  }

  const unfollowHandlers = () => {
    return createMutationHookMutationCacheHandlers(useUnfollowMutation, {
      onSuccess: (data, { entityId }) => {
        findWellQueriesById({ id: entityId }).forEach(invalidateQuery(queryClient))
      },
    })
  }

  const updateFollowersHandlers = () => {
    return createMutationHookMutationCacheHandlers(useUpdateFollowersMutation, {
      onSuccess: (data, { entityId }) => {
        findWellQueriesById({ id: entityId }).forEach(invalidateQuery(queryClient))
      },
    })
  }

  const uploadResourceHandlers = () => {
    return createMutationHookMutationCacheHandlers(useUploadResourceMutation, {
      onSuccess: (data, { originId }) => {
        findWellQueriesById({ id: originId }).forEach(invalidateQuery(queryClient))
      },
    })
  }

  const deleteWellHandlers = () => {
    return createMutationHookMutationCacheHandlers(useDeleteWellMutation, {
      onSuccess: (data, { id }) => {
        findWellQueriesById({ id }).forEach(invalidateQuery(queryClient))
      },
    })
  }

  const archiveWellHandlers = () => {
    return createMutationHookMutationCacheHandlers(useArchiveWellMutation, {
      onSuccess: (data, { id }) => {
        findWellQueriesById({ id }).forEach(invalidateQuery(queryClient))
      },
    })
  }

  const unarchiveWellHandlers = () => {
    return createMutationHookMutationCacheHandlers(useUnarchiveWellMutation, {
      onSuccess: (data, { id }) => {
        findWellQueriesById({ id }).forEach(invalidateQuery(queryClient))
      },
    })
  }

  const updateRelationsHandlers = () => {
    return createMutationHookMutationCacheHandlers(useUpdateRelationsMutation, {
      onSuccess: (data, { entityId }) => {
        findWellQueriesById({ id: entityId }).forEach(invalidateQuery(queryClient))
      },
    })
  }

  const updateEntityTypeHandlers = () => {
    return createMutationHookMutationCacheHandlers(useUpdateEntityTypeMutation, {
      onSuccess: (data, { id }) => {
        if (id !== EntityTypeIdsT.WellT) {
          return
        }

        findWellQueries().forEach(invalidateQuery(queryClient))
      },
    })
  }

  return [
    updateWellHandlers(),
    archiveWellHandlers(),
    unarchiveWellHandlers(),
    followHandlers(),
    unfollowHandlers(),
    updateFollowersHandlers(),
    uploadResourceHandlers(),
    deleteWellHandlers(),
    updateRelationsHandlers(),
    updateEntityTypeHandlers(),
  ]
}

export const wellsQueriesHandlers = ({ queryClient }: { queryClient: QueryClient }): MutationCacheHandlersT[] => {
  const findWellsQueries = () => findQueries(queryClient, useWellsQuery)

  const findInfiniteWellsQueries = () => findInfiniteQueries(queryClient, useInfiniteWellsQuery)

  const findInfiniteWellsQueriesById = ({ id }: { id: string }) =>
    findInfiniteQueries(queryClient, useInfiniteWellsQuery, (query) =>
      (query.state.data?.pages ?? []).some((page) => (page.listWells?.items ?? []).some((well) => well.id === id)),
    )

  const createWellHandlers = () => {
    return createMutationHookMutationCacheHandlers(useCreateWellMutation, {
      onSuccess: () => {
        findInfiniteWellsQueries().forEach(invalidateQuery(queryClient))
      },
    })
  }

  const updateWellHandlers = () => {
    return createMutationHookMutationCacheHandlers(useUpdateWellMutation, {
      onError: (error, { id }) => {
        findInfiniteWellsQueriesById({ id }).forEach(invalidateQuery(queryClient))
      },
      onMutate: ({ id, data: input }) => {
        findInfiniteWellsQueriesById({ id }).forEach((query) => {
          setQueryData(queryClient)(query)(
            produce((data) => {
              infiniteListEntityQueryUpdateRecipe({
                data,
                id,
                input,
                mapDataToEntities: (data) => data.listWells?.items ?? [],
              })
            }),
          )
        })
      },
      onSuccess: (data, { id }) => {
        findInfiniteWellsQueriesById({ id }).forEach(invalidateQuery(queryClient))

        findWellsQueries().forEach(invalidateQuery(queryClient))
      },
    })
  }

  const followHandlers = () => {
    return createMutationHookMutationCacheHandlers(useFollowMutation, {
      onSuccess: (data, { entityId }) => {
        findInfiniteWellsQueriesById({ id: entityId }).forEach(invalidateQuery(queryClient))
      },
    })
  }

  const unfollowHandlers = () => {
    return createMutationHookMutationCacheHandlers(useUnfollowMutation, {
      onSuccess: (data, { entityId }) => {
        findInfiniteWellsQueriesById({ id: entityId }).forEach(invalidateQuery(queryClient))
      },
    })
  }

  const deleteWellHandlers = () => {
    return createMutationHookMutationCacheHandlers(useDeleteWellMutation, {
      onError: (error, { id }) => {
        findInfiniteWellsQueriesById({ id }).forEach(invalidateQuery(queryClient))
      },
      onMutate: ({ id }) => {
        findInfiniteWellsQueriesById({ id }).forEach((query) => {
          setQueryData(queryClient)(query)(
            produce((data) => {
              infiniteListEntityQueryDeleteRecipe({
                data,
                id,
                mapDataToEntities: (data) => data.listWells?.items ?? [],
              })
            }),
          )
        })
      },
      onSuccess: (data, { id }) => {
        findInfiniteWellsQueriesById({ id }).forEach(invalidateQuery(queryClient))
      },
    })
  }

  const updateRelationsHandlers = () => {
    return createMutationHookMutationCacheHandlers(useUpdateRelationsMutation, {
      onSuccess: (data, { entityId }) => {
        findInfiniteWellsQueriesById({ id: entityId }).forEach(invalidateQuery(queryClient))
      },
    })
  }

  const archiveWellHandlers = () => {
    return createMutationHookMutationCacheHandlers(useArchiveWellMutation, {
      onSuccess: (data, { id }) => {
        findInfiniteWellsQueriesById({ id }).forEach(invalidateQuery(queryClient))
      },
    })
  }

  const unarchiveWellHandlers = () => {
    return createMutationHookMutationCacheHandlers(useUnarchiveWellMutation, {
      onSuccess: (data, { id }) => {
        findInfiniteWellsQueriesById({ id }).forEach(invalidateQuery(queryClient))
      },
    })
  }

  const updateEntityTypeHandlers = () => {
    return createMutationHookMutationCacheHandlers(useUpdateEntityTypeMutation, {
      onSuccess: (data, { id }) => {
        if (id !== EntityTypeIdsT.WellT) {
          return
        }

        findInfiniteWellsQueries().forEach(invalidateQuery(queryClient))
      },
    })
  }

  return [
    createWellHandlers(),
    updateWellHandlers(),
    followHandlers(),
    unfollowHandlers(),
    deleteWellHandlers(),
    updateRelationsHandlers(),
    archiveWellHandlers(),
    unarchiveWellHandlers(),
    updateEntityTypeHandlers(),
  ]
}
