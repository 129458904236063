import { Box } from '@mui/material'
import { Fragment, useMemo } from 'react'

import { checkNonNullable } from '@resnet/client-common/common/utils/nullable/non-nullable'
import { assert } from '@resnet/client-common/typescript/utils/assert'

import type { CustomFieldPayloadOptionGroupOptionT } from '@resnet/client-api/api'
import { CustomFieldTypeT, type CustomFieldFragmentT } from '@resnet/client-api/api'

import type { OptionGroupCustomFieldValueT } from '@resnet/client-shared/shared/custom-fields/presets/option-group/custom-field-value'

import {
  FieldPreviewLabel,
  FieldPreviewLayout,
  SwitchFieldPreview,
} from '@resnet/client-shared-web/shared/gdl/components/field-preview'

import { ObjectDetailsRow } from '@resnet/client-web/shared/object/components/object-details-row'

export type OptionGroupFormUserFormPreviewPropsT = {
  field: CustomFieldFragmentT
  value: undefined | OptionGroupCustomFieldValueT
}

export const OptionGroupFormUserFormPreview = ({ field, value }: OptionGroupFormUserFormPreviewPropsT) => {
  const payload = field.payload

  assert(payload, checkNonNullable)

  const optionGroupPayload = payload[CustomFieldTypeT.OptionGroupT]

  assert(optionGroupPayload, checkNonNullable)

  const { options } = optionGroupPayload

  const getSizeSx = () => {
    return {
      gridColumn: 'span 2',
    }
  }

  const selected = useMemo(() => new Set([value].flat()), [value])

  const availableOptions = useMemo(
    () => options.filter((option) => !option.isMarkedAsDeleted || selected.has(option.key)),
    [options, selected],
  )

  const renderList = () => {
    const renderOption = ({
      option,
      isSelected,
    }: {
      option: CustomFieldPayloadOptionGroupOptionT
      isSelected: boolean
    }) => {
      return (
        <SwitchFieldPreview
          isMarkedAsDeleted={option.isMarkedAsDeleted}
          label={option.name}
          value={isSelected}
        />
      )
    }

    return (
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: '8px' }}>
        {availableOptions.map((option) => {
          const isSelected = selected.has(option.key)

          return <Fragment key={option.id}>{renderOption({ isSelected, option })}</Fragment>
        })}
      </Box>
    )
  }

  return (
    <ObjectDetailsRow sx={getSizeSx()}>
      <FieldPreviewLayout>
        <FieldPreviewLabel>{field.name}</FieldPreviewLabel>
        {renderList()}
      </FieldPreviewLayout>
    </ObjectDetailsRow>
  )
}
