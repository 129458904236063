import { Box } from '@mui/material'

import { type IssueFragmentT } from '@resnet/client-api/api'

import ScrewdriverWrenchSolidIcon from '@resnet/client-shared/assets/icons/screwdriver-wrench-solid.svg'
import { typographyPresets } from '@resnet/client-shared/shared/gdl/constants/typography-presets'
import { mapIssueToReadableId } from '@resnet/client-shared/shared/issues/utils/map-issue-to-readable-id'
import { mapIssueToResolutionLabel } from '@resnet/client-shared/shared/issues/utils/map-issue-to-resolution-label'
import { mapIssueToTitle } from '@resnet/client-shared/shared/issues/utils/map-issue-to-title'
import { UserOptionContainer } from '@resnet/client-shared/shared/users/hooks/use-user-option'

import { RectangularSkeleton } from '@resnet/client-shared-web/shared/async/components/rectangular-skeleton'
import { TextSkeleton } from '@resnet/client-shared-web/shared/async/components/text-skeleton'
import { AvatarSkeleton, IconAvatar } from '@resnet/client-shared-web/shared/gdl/components/avatar'
import { themeColors } from '@resnet/client-shared-web/shared/gdl/constants/theme-colors'
import { mapTypographyPresetToSx } from '@resnet/client-shared-web/shared/gdl/utils/map-typography-preset-to-sx'
import { toPx } from '@resnet/client-shared-web/shared/gdl/utils/to-px'

import { IssueApprovalTag } from '@resnet/client-web/shared/issues/components/issue-approval-tag'
import { IssueTypeTag } from '@resnet/client-web/shared/issues/components/issue-type-tag'
import { UserAvatar } from '@resnet/client-web/shared/users/components/user-avatar'

import { AnyCard } from '../any-card'

export const getIssueCardSize = () => 84

export const IssueCardSkeleton = () => {
  const renderAside = () => {
    return (
      <Box>
        <AvatarSkeleton size="lg" />
      </Box>
    )
  }

  const renderType = () => {
    return <RectangularSkeleton sx={{ height: toPx(20), width: toPx(60) }} />
  }

  const renderReadableId = () => {
    return (
      <TextSkeleton
        sx={{ width: toPx(40) }}
        typographyPreset={typographyPresets.overlineSmall}
      />
    )
  }

  const renderApprovalStatus = () => {
    return <RectangularSkeleton sx={{ height: toPx(20), width: toPx(100) }} />
  }

  const renderMainFirstSection = () => {
    return (
      <Box
        sx={{
          alignItems: 'center',
          display: 'flex',
          gap: toPx(8),
        }}
      >
        <Box
          sx={{
            alignItems: 'center',
            display: 'flex',
            gap: toPx(4),
          }}
        >
          {renderType()}
          {renderReadableId()}
        </Box>
        <Box
          sx={{
            alignItems: 'center',
            display: 'flex',
            gap: toPx(8),
            ml: 'auto',
          }}
        >
          {renderApprovalStatus()}
        </Box>
      </Box>
    )
  }

  const renderMainSecondSection = () => {
    return (
      <TextSkeleton
        contentSx={{ width: '70%' }}
        typographyPreset={typographyPresets.titleMedium}
      />
    )
  }

  const renderMain = () => {
    return (
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          flexGrow: 1,
          gap: toPx(8),
          width: 0,
        }}
      >
        {renderMainFirstSection()}
        {renderMainSecondSection()}
      </Box>
    )
  }

  return (
    <AnyCard>
      <Box sx={{ display: 'flex', gap: toPx(8) }}>
        {renderAside()}
        {renderMain()}
      </Box>
    </AnyCard>
  )
}

export const IssueCard = ({
  issue,
  isActive,
  onClick,
}: {
  issue: IssueFragmentT
  isActive?: boolean
  onClick?: () => void
}) => {
  const renderAvatar = () => {
    if (!issue.assigneeId) {
      return (
        <IconAvatar
          icon={<ScrewdriverWrenchSolidIcon />}
          size="lg"
        />
      )
    }

    return (
      <UserOptionContainer id={issue.assigneeId}>
        {({ optionQuery }) => {
          if (!optionQuery.isSuccess) {
            return <AvatarSkeleton size="lg" />
          }

          const option = optionQuery.data

          return (
            <UserAvatar
              size="lg"
              user={option}
            />
          )
        }}
      </UserOptionContainer>
    )
  }

  const renderAside = () => {
    return <Box>{renderAvatar()}</Box>
  }

  const renderType = () => {
    return (
      <IssueTypeTag
        variant
        size="sm"
        type={issue.type}
      />
    )
  }

  const renderReadableId = () => {
    return (
      <Box
        sx={[
          mapTypographyPresetToSx(typographyPresets.overlineSmall),
          {
            color: themeColors.overBackgroundDefault,
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
          },
        ]}
      >
        {mapIssueToReadableId(issue)}
      </Box>
    )
  }

  const renderApprovalStatus = () => {
    const issueResolutionLabelProps = mapIssueToResolutionLabel(issue)

    if (!issueResolutionLabelProps) {
      return null
    }

    return (
      <IssueApprovalTag
        {...issueResolutionLabelProps}
        uppercase
        size="sm"
      />
    )
  }

  const renderMainFirstSection = () => {
    return (
      <Box
        sx={{
          alignItems: 'center',
          display: 'flex',
          gap: toPx(8),
        }}
      >
        <Box
          sx={{
            alignItems: 'center',
            display: 'flex',
            gap: toPx(4),
          }}
        >
          {renderType()}
          {renderReadableId()}
        </Box>
        <Box
          sx={{
            alignItems: 'center',
            display: 'flex',
            gap: toPx(8),
            ml: 'auto',
          }}
        >
          {renderApprovalStatus()}
        </Box>
      </Box>
    )
  }

  const renderMainSecondSection = () => {
    return (
      <Box
        sx={[
          mapTypographyPresetToSx(typographyPresets.titleMedium),
          { color: themeColors.overBackgroundBold, overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' },
        ]}
      >
        {mapIssueToTitle(issue)}
      </Box>
    )
  }

  const renderMain = () => {
    return (
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          flexGrow: 1,
          gap: toPx(8),
          width: 0,
        }}
      >
        {renderMainFirstSection()}
        {renderMainSecondSection()}
      </Box>
    )
  }

  return (
    <AnyCard
      isActive={isActive}
      onClick={onClick}
    >
      <Box sx={{ display: 'flex', gap: toPx(8) }}>
        {renderAside()}
        {renderMain()}
      </Box>
    </AnyCard>
  )
}
