import type { SxProps } from '@mui/material'
import { Box, Tooltip } from '@mui/material'
import type { UseQueryOptions } from '@tanstack/react-query'
import { useCallback } from 'react'

import { ErrorBoundary } from '@resnet/client-common/react/components/error-boundary'
import { NonNullableContextContainer } from '@resnet/client-common/react/hooks/use-non-nullable-context'
import { createHookContainer } from '@resnet/client-common/react/utils/create-hook-container'

import type { CampaignFragmentT, GetCampaignQueryT, IssueFragmentT } from '@resnet/client-api/api'
import { EntityTypeIdsT, useUpdateIssueMutation } from '@resnet/client-api/api'
import type { UpdateIssueMutationMetaT } from '@resnet/client-api/modules/issue/types/meta'

import CircleDotsSolidIcon from '@resnet/client-shared/assets/icons/circle-dots-solid.svg'
import ErrorSolidIcon from '@resnet/client-shared/assets/icons/error-solid.svg'
import { CampaignOptionContainer } from '@resnet/client-shared/shared/campaigns/hooks/use-campaign-option'
import { mapCampaignToTag } from '@resnet/client-shared/shared/campaigns/utils/map-campaign-to-tag'
import { mapPickerColorPresetToColor } from '@resnet/client-shared/shared/common/utils/map-picker-color-preset-to-color'
import { mapColorToOverBackgroundColor } from '@resnet/client-shared/shared/gdl/utils/map-color-to-over-background-color'

import type { ButtonButtonPropsT } from '@resnet/client-shared-web/shared/gdl/components/button'
import { Button, ButtonBase } from '@resnet/client-shared-web/shared/gdl/components/button'
import { PopperContext } from '@resnet/client-shared-web/shared/gdl/components/popper'
import { themeColors } from '@resnet/client-shared-web/shared/gdl/constants/theme-colors'

import { EntitySelect } from '@resnet/client-web/shared/entities/selects/entity-select'

export type CampaignSelectButtonBasePropsT = {
  buttonSize?: ButtonButtonPropsT['size']
  buttonVariant?: ButtonButtonPropsT['variant']
  onChange?: (value: undefined | null | string) => void
  placeholder?: string
  sx?: SxProps
  value: undefined | null | string
}

export const CampaignSelectButtonBase = ({
  buttonSize = 'sm',
  buttonVariant = 'text',
  onChange,
  placeholder = 'Add Campaign',
  sx = null,
  value,
}: CampaignSelectButtonBasePropsT) => {
  const renderAnchor = () => {
    return (
      <NonNullableContextContainer Context={PopperContext}>
        {({ setAnchorEl, open }) => {
          const renderContent = () => {
            if (!value) {
              return (
                <Button
                  color="primary"
                  data-testid="issue-campaign-select-button"
                  icon={<CircleDotsSolidIcon />}
                  size={buttonSize}
                  variant={buttonVariant}
                  onClick={open}
                >
                  {placeholder}
                </Button>
              )
            }

            return (
              <CampaignOptionContainer id={value}>
                {({ optionQuery: campaignQuery }) => {
                  if (!campaignQuery.isSuccess) {
                    return (
                      <Button
                        isLoading
                        color="primary"
                        data-testid="issue-campaign-select-button"
                        size={buttonSize}
                        variant={buttonVariant}
                      >
                        Loading
                      </Button>
                    )
                  }

                  const { data: campaign } = campaignQuery

                  const { color } = campaign

                  const backgroundColor = mapPickerColorPresetToColor(color)

                  const textColor = !backgroundColor ? undefined : mapColorToOverBackgroundColor(backgroundColor)

                  return (
                    <ButtonBase
                      borderSize={0}
                      component="button"
                      data-testid="issue-campaign-select-button"
                      size={buttonSize}
                      sx={[
                        {
                          backgroundColor,
                          color: textColor,
                          transition: 'opacity 0.2s ease',
                        },
                        {
                          '&:hover': {
                            opacity: 0.8,
                          },
                        },
                        {
                          '&:active': {
                            opacity: 0.6,
                          },
                        },
                      ]}
                      type="button"
                      onClick={open}
                    >
                      {mapCampaignToTag(campaign)}
                    </ButtonBase>
                  )
                }}
              </CampaignOptionContainer>
            )
          }

          return (
            <Box
              ref={setAnchorEl}
              sx={[{ display: 'flex', maxWidth: '100%' }, sx].flat()}
            >
              {renderContent()}
            </Box>
          )
        }}
      </NonNullableContextContainer>
    )
  }

  return (
    <EntitySelect
      categoryIds={[EntityTypeIdsT.CampaignT]}
      renderAnchor={renderAnchor}
      value={value}
      onChange={onChange}
    />
  )
}

export const CampaignSelectButtonContainer = createHookContainer(
  ({
    issue,
  }: {
    issue: IssueFragmentT
    campaignQueryOptions?: UseQueryOptions<GetCampaignQueryT, unknown, CampaignFragmentT>
  }) => {
    const meta: UpdateIssueMutationMetaT = { issue }

    const { mutate: updateIssueMutationMutate } = useUpdateIssueMutation({ meta })

    const value = issue.campaignId

    const onChange = useCallback(
      (campaignId: undefined | null | string) => {
        updateIssueMutationMutate({ data: { campaignId }, id: issue.id })
      },
      [updateIssueMutationMutate, issue.id],
    )

    return { onChange, value }
  },
)

export type CampaignSelectButtonPropsT = {
  issue: IssueFragmentT
  buttonSize?: ButtonButtonPropsT['size']
}

export const CampaignSelectButton = ({ issue, buttonSize = 'sm' }: CampaignSelectButtonPropsT) => {
  const renderFallback = ({ onRecover }: { onRecover: () => void }) => {
    return (
      <Tooltip title="Click to try to reload the component">
        <ButtonBase
          icon={<ErrorSolidIcon />}
          size={buttonSize}
          sx={{ border: `1px solid ${themeColors.borderDefault}` }}
          onClick={onRecover}
        >
          Not loaded
        </ButtonBase>
      </Tooltip>
    )
  }

  const renderContent = () => {
    return (
      <CampaignSelectButtonContainer issue={issue}>
        {({ value, onChange }) => {
          return (
            <CampaignSelectButtonBase
              buttonSize={buttonSize}
              value={value}
              onChange={onChange}
            />
          )
        }}
      </CampaignSelectButtonContainer>
    )
  }

  return <ErrorBoundary fallback={renderFallback}>{renderContent()}</ErrorBoundary>
}
