import { Box } from '@mui/material'
import type { FieldPathByValue, FieldValues, UseFormReturn } from 'react-hook-form'

import { pipeline } from '@resnet/client-common/common/utils/function/pipeline'
import { checkNonNullable } from '@resnet/client-common/common/utils/nullable/non-nullable'
import { ChildrenDivider } from '@resnet/client-common/react/components/children-transformer'

import { useAffectedAssetsQuery } from '@resnet/client-api/api'

import { typographyPresets } from '@resnet/client-shared/shared/gdl/constants/typography-presets'

import { TextSkeleton } from '@resnet/client-shared-web/shared/async/components/text-skeleton'
import type { SwitchFieldValueT } from '@resnet/client-shared-web/shared/form/components/common/switch-field'
import { Accordion } from '@resnet/client-shared-web/shared/gdl/components/accordion'
import { themeColors } from '@resnet/client-shared-web/shared/gdl/constants/theme-colors'
import { mapTypographyPresetToSx } from '@resnet/client-shared-web/shared/gdl/utils/map-typography-preset-to-sx'

import type { AssetsListFieldValueT } from '../../assets/assets-list-field'

export type IssueAffectedAssetsFieldPropsT<TFieldValues extends FieldValues> = {
  assetIdsName: FieldPathByValue<TFieldValues, AssetsListFieldValueT>
  cloneForWellsName: FieldPathByValue<TFieldValues, SwitchFieldValueT>
  form: UseFormReturn<TFieldValues>
  label: string
}

export const IssueAffectedAssetsField = <TFieldValues extends FieldValues>({
  assetIdsName,
  cloneForWellsName,
  form,
  label,
}: IssueAffectedAssetsFieldPropsT<TFieldValues>): null | React.ReactElement => {
  const assetIds = form.watch(assetIdsName) as Exclude<AssetsListFieldValueT, undefined>

  const cloneForWells = form.watch(cloneForWellsName) as Exclude<SwitchFieldValueT, undefined>

  const affectedAssetsQuery = useAffectedAssetsQuery(
    {
      assetIds,
      cloneForWells,
    },
    {
      select: (data) =>
        pipeline(
          data.listAffectedAssets,
          (x) => x ?? [],
          (x) => x.filter(checkNonNullable),
        ),
    },
  )

  const renderAffectedAssetsCount = () => {
    if (!affectedAssetsQuery.isSuccess) {
      return null
    }

    const affectedAssets = affectedAssetsQuery.data

    return `(${affectedAssets.length})`
  }

  const renderHeaderTitle = () => {
    return (
      <ChildrenDivider dividerNode={' '}>
        {label}
        {renderAffectedAssetsCount()}
      </ChildrenDivider>
    )
  }

  const renderContent = () => {
    if (!affectedAssetsQuery.isSuccess) {
      return (
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
          <TextSkeleton typographyPreset={typographyPresets.captionRegular} />
          <TextSkeleton typographyPreset={typographyPresets.captionRegular} />
          <TextSkeleton
            contentSx={{ width: '70%' }}
            typographyPreset={typographyPresets.captionRegular}
          />
        </Box>
      )
    }

    const affectedAssets = affectedAssetsQuery.data

    return (
      <Box
        sx={[mapTypographyPresetToSx(typographyPresets.captionRegular), { color: themeColors.overBackgroundDefault }]}
      >
        {affectedAssets.length === 0 ? (
          'No assets affected'
        ) : (
          <ChildrenDivider dividerNode=", ">{affectedAssets.map((item) => item.name)}</ChildrenDivider>
        )}
      </Box>
    )
  }

  return <Accordion>{{ content: () => renderContent(), header: { title: renderHeaderTitle() } }}</Accordion>
}
