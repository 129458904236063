import { createConstrainer } from '@resnet/client-common/typescript/utils/create-constrainer'

import type { CustomFieldPayloadOptionGroupOptionFragmentT } from '@resnet/client-api/api'

import type { AbstractSimpleOptionT } from '@resnet/client-shared-web/shared/gdl/types/abstract-option'

export const mapOptionToOptionIdFromKey = ({
  options,
}: {
  options: CustomFieldPayloadOptionGroupOptionFragmentT[]
}) => {
  return options.map((option) => {
    return createConstrainer<AbstractSimpleOptionT>()({
      id: option.key,
      isMarkedAsDeleted: option.isMarkedAsDeleted,
      name: option.name,
    })
  })
}
