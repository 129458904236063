import { Radio as MUIRadio } from '@mui/material'
import type { RadioProps } from '@mui/material'

import type { MaybeT } from '@resnet/client-common/common/types/common'
import { propToKey } from '@resnet/client-common/common/utils/object/prop-to-key'
import { createConstrainer } from '@resnet/client-common/typescript/utils/create-constrainer'

import RadioButtonSelectedIcon from '@resnet/client-shared/assets/icons/radio-button-selected-solid.svg'
import RadioButtonUnselectedIcon from '@resnet/client-shared/assets/icons/radio-button-unselected-solid.svg'

import { themeColors } from '@resnet/client-shared-web/shared/gdl/constants/theme-colors'

import { focusOutlineSx } from '../../sx-presets/focus-outline'
import { toPx } from '../../utils/to-px'

const sizes = createConstrainer<{ id: string; size: number }[]>()([
  { id: 'sm' as const, size: 16 },
  { id: 'md' as const, size: 24 },
])

const sizeById = propToKey('id', sizes)

export type RadioPropsT = Omit<RadioProps, 'onChange' | 'size'> & {
  disabled?: boolean
  value?: boolean
  error?: MaybeT<{
    value: boolean
    message?: string
  }>
  size: keyof typeof sizeById
  onChange?: (value: boolean) => void
}

export const Radio = ({
  disabled = false,
  value = false,
  size: sizeProp,
  onChange,
  sx,
  ...props
}: RadioPropsT): React.ReactElement => {
  const checked = value

  const size = toPx(sizeById[sizeProp].size)

  const iconProps = {
    color: disabled ? themeColors.borderFaded : !checked ? themeColors.borderBold : themeColors.actionsPrimaryDefault,
    height: size,
    width: size,
  }

  const radioSx = [
    {
      '& > svg': {
        borderRadius: toPx(4),
      },
      borderRadius: 0,
      padding: 0,
    },
    {
      '& > input:focus-visible + svg': focusOutlineSx,
      '&:hover > svg': { opacity: 0.8 },
    },
    { '&:active > svg': { opacity: 0.6 } },
  ]

  return (
    <MUIRadio
      {...props}
      disableRipple
      checked={checked}
      checkedIcon={<RadioButtonSelectedIcon {...iconProps} />}
      disabled={disabled}
      icon={<RadioButtonUnselectedIcon {...iconProps} />}
      sx={[radioSx, sx ?? null].flat()}
      onChange={(event) => {
        onChange?.(event.target.checked)
      }}
    />
  )
}
