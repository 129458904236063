import { Box } from '@mui/material'
import { useMemo } from 'react'

import type { ResourceFragmentT } from '@resnet/client-api/api'

import { checkIsMediaType } from '@resnet/client-shared/shared/resources/utils/check-is-media-type'
import { mapMimetypeToType } from '@resnet/client-shared/shared/resources/utils/map-mimetype-to-type'

import { themeColors } from '@resnet/client-shared-web/shared/gdl/constants/theme-colors'
import { toColumnWidth } from '@resnet/client-shared-web/shared/gdl/utils/to-column-width'
import { toPx } from '@resnet/client-shared-web/shared/gdl/utils/to-px'
import { AttachmentPreviewModalContainer } from '@resnet/client-shared-web/shared/resources/components/attachment-preview-modal'
import { MediaResourceThumbnail } from '@resnet/client-shared-web/shared/resources/components/media-resource-thumbnail'
import { ResourceThumbnail } from '@resnet/client-shared-web/shared/resources/components/resource-thumbnail'
import { useResourcesGallery } from '@resnet/client-shared-web/shared/resources/hooks/use-resources-gallery'
import { ResourcesGalleryItemContainer } from '@resnet/client-shared-web/shared/resources/hooks/use-resources-gallery-item'

const getUnderlayWidth = (itemsCount: number, maxItemsInRow: number = 1, gap: number = 0, padding: number = 0) => {
  const itemWidth = `calc((100% - ${toPx(2 * padding)} - ${toPx((maxItemsInRow - 1) * gap)}) / ${maxItemsInRow})`

  return `min(calc(${toPx(2 * padding)} + calc(${itemsCount} * ${itemWidth}) + calc(${itemsCount - 1} * ${toPx(gap)})), 100%)`
}

export type MessageCardGalleryPropsT = {
  resources: ResourceFragmentT[]
}

export const MessageCardGallery = ({ resources }: MessageCardGalleryPropsT) => {
  const gallerySize = resources.length

  const { openPrev, openNext, register } = useResourcesGallery()

  const mediaResources = useMemo(
    () => resources.filter((resource) => checkIsMediaType(mapMimetypeToType(resource.mimetype))),
    [resources],
  )

  const regularResources = useMemo(
    () => resources.filter((resource) => !checkIsMediaType(mapMimetypeToType(resource.mimetype))),
    [resources],
  )

  const renderMediaResources = () => {
    if (mediaResources.length === 0) {
      return null
    }

    return (
      <Box
        sx={{
          borderRadius: toPx(8),
          display: 'flex',
          flexWrap: 'wrap',
          gap: toPx(4),
          maxWidth: toPx(624),
          padding: toPx(8),
          position: 'relative',
          zIndex: 0,
        }}
      >
        <Box
          sx={{
            backgroundColor: themeColors.surfaceVariantDefault,
            borderRadius: toPx(8),
            bottom: 0,
            left: 0,
            position: 'absolute',
            top: 0,
            width: getUnderlayWidth(mediaResources.length, 3, 4, 8),
            zIndex: -1,
          }}
        />
        {mediaResources.map((resource, index) => {
          const galleryIndex = index

          return (
            <Box
              key={resource.id}
              sx={{
                display: 'flex',
                flexDirection: 'column',
                width: toColumnWidth(3, 4),
              }}
            >
              <AttachmentPreviewModalContainer>
                {({ onOpen: onOpenProp, onClose }) => {
                  const onOpen = () => {
                    onOpenProp({
                      attachment: resource,
                      hasNext: galleryIndex < gallerySize - 1,
                      hasPrev: galleryIndex > 0,
                      onOpenNext: () => {
                        onClose()
                        openNext(galleryIndex)
                      },
                      onOpenPrev: () => {
                        onClose()
                        openPrev(galleryIndex)
                      },
                    })
                  }

                  return (
                    <ResourcesGalleryItemContainer
                      index={galleryIndex}
                      register={register}
                      onOpen={onOpen}
                    >
                      {() => (
                        <MediaResourceThumbnail
                          resource={resource}
                          onClick={onOpen}
                        />
                      )}
                    </ResourcesGalleryItemContainer>
                  )
                }}
              </AttachmentPreviewModalContainer>
            </Box>
          )
        })}
      </Box>
    )
  }

  const renderRegularResources = () => {
    if (regularResources.length === 0) {
      return null
    }

    return (
      <Box
        sx={{
          backgroundColor: themeColors.surfaceVariantDefault,
          borderRadius: toPx(8),
          display: 'flex',
          flexDirection: 'column',
          gap: toPx(8),
          padding: toPx(8),
        }}
      >
        {regularResources.map((resource, index) => {
          const galleryIndex = mediaResources.length + index

          return (
            <Box
              key={resource.id}
              sx={{
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              <AttachmentPreviewModalContainer>
                {({ onOpen: onOpenProp, onClose }) => {
                  const onOpen = () => {
                    onOpenProp({
                      attachment: resource,
                      hasNext: galleryIndex < gallerySize - 1,
                      hasPrev: galleryIndex > 0,
                      onOpenNext: () => {
                        onClose()
                        openNext(galleryIndex)
                      },
                      onOpenPrev: () => {
                        onClose()
                        openPrev(galleryIndex)
                      },
                    })
                  }

                  return (
                    <ResourcesGalleryItemContainer
                      index={galleryIndex}
                      register={register}
                      onOpen={onOpen}
                    >
                      {() => (
                        <ResourceThumbnail
                          resource={resource}
                          onClick={onOpen}
                        />
                      )}
                    </ResourcesGalleryItemContainer>
                  )
                }}
              </AttachmentPreviewModalContainer>
            </Box>
          )
        })}
      </Box>
    )
  }

  return (
    <>
      {renderMediaResources()}
      {renderRegularResources()}
    </>
  )
}
