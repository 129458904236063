import { createSelect } from '@resnet/client-shared-web/shared/common/factories/create-select'
import type { CheckboxFieldValueT } from '@resnet/client-shared-web/shared/form/components/common/checkbox-field'
import { createStaticOptionContainer } from '@resnet/client-shared-web/shared/gdl/factories/create-static-option-container'
import { createStaticOptionsSelectDropdownContainer } from '@resnet/client-shared-web/shared/gdl/factories/create-static-options-select-dropdown-container'

import { EditOptionCell } from '../edit-option-cell'

export const checkboxOptions = [
  { id: 'false' as const, name: 'False' },
  { id: 'true' as const, name: 'True' },
]

export const CheckboxOptionContainer = createStaticOptionContainer({
  options: checkboxOptions,
})

export const CheckboxSelectDropdownContainer = createStaticOptionsSelectDropdownContainer({
  options: checkboxOptions,
})

export const CheckboxSelect = createSelect({
  OptionContainer: CheckboxOptionContainer,
  SelectDropdownContainer: CheckboxSelectDropdownContainer,
  getOptionLabel: (option) => option.name,
})

const mapFieldValueToSelectValue = (value: CheckboxFieldValueT) => {
  return !value ? 'false' : 'true'
}

const mapSelectValueToFieldValue = (value: (typeof checkboxOptions)[number]['id']) => {
  return value !== 'true' ? false : true
}

export type EditCheckboxCellPropsT = {
  name: string
}

export const EditCheckboxCell = ({ name }: EditCheckboxCellPropsT) => {
  return (
    <EditOptionCell
      mapFieldValueToSelectValue={mapFieldValueToSelectValue}
      mapSelectValueToFieldValue={mapSelectValueToFieldValue}
      name={name}
      select={(props) => (
        <CheckboxSelect
          {...props}
          nonNullable
        />
      )}
    />
  )
}
