import { Box, List } from '@mui/material'
import { Fragment, useMemo } from 'react'
import type { PathValue } from 'react-hook-form'
import { useController, type FieldPathByValue, type FieldValues, type UseFormReturn } from 'react-hook-form'

import { checkNonNullable } from '@resnet/client-common/common/utils/nullable/non-nullable'
import { useEventCallback } from '@resnet/client-common/react/hooks/use-event-callback'
import { assert } from '@resnet/client-common/typescript/utils/assert'

import type { CustomFieldPayloadOptionGroupOptionT } from '@resnet/client-api/api'
import { CustomFieldTypeT, type CustomFieldFragmentT } from '@resnet/client-api/api'

import type { OptionGroupCustomFieldValueT } from '@resnet/client-shared/shared/custom-fields/presets/option-group/custom-field-value'
import { validateRequired } from '@resnet/client-shared/shared/forms/validators/any-required'
import { validateListRequired } from '@resnet/client-shared/shared/forms/validators/list-required'

import { CheckboxListItem } from '@resnet/client-shared-web/shared/gdl/components/checkbox-list'
import { Field, FieldErrorText, FieldLabel } from '@resnet/client-shared-web/shared/gdl/components/field'
import { RadioListItem } from '@resnet/client-shared-web/shared/gdl/components/radio-list'
import { toPx } from '@resnet/client-shared-web/shared/gdl/utils/to-px'

import { ObjectDetailsRow } from '@resnet/client-web/shared/object/components/object-details-row'

export type OptionGroupCustomFieldUserFormFieldPropsT<
  TFieldValues extends FieldValues,
  TPath extends FieldPathByValue<TFieldValues, undefined | OptionGroupCustomFieldValueT>,
> = {
  field: CustomFieldFragmentT
  form: UseFormReturn<TFieldValues>
  name: TPath
}

export const OptionGroupCustomFieldUserFormField = <
  TFieldValues extends FieldValues,
  TPath extends FieldPathByValue<TFieldValues, undefined | OptionGroupCustomFieldValueT>,
>({
  field,
  form,
  name,
}: OptionGroupCustomFieldUserFormFieldPropsT<TFieldValues, TPath>) => {
  const payload = field.payload

  assert(payload, checkNonNullable)

  const optionGroupPayload = payload[CustomFieldTypeT.OptionGroupT]

  assert(optionGroupPayload, checkNonNullable)

  const { multiple, required, question, options } = optionGroupPayload

  const getSizeSx = () => {
    return {
      gridColumn: 'span 2',
    }
  }

  const rules = useMemo(() => {
    if (!required) {
      return undefined
    }

    return {
      validate: multiple ? validateListRequired : validateRequired,
    }
  }, [required, multiple])

  const {
    field: { value: valueActual, onChange },
    fieldState: { error },
  } = useController({ control: form.control, name, rules })

  const selected = useMemo(() => new Set([valueActual as OptionGroupCustomFieldValueT].flat()), [valueActual])

  const availableOptions = useMemo(
    () => options.filter((option) => !option.isMarkedAsDeleted || selected.has(option.key)),
    [options, selected],
  )

  const onOptionClick = useEventCallback((option: CustomFieldPayloadOptionGroupOptionT) => {
    if (multiple) {
      const value = valueActual ?? ([] as string[])

      if (selected.has(option.key)) {
        onChange(value.filter((key) => key !== option.key) as PathValue<TFieldValues, TPath>)

        return
      }

      onChange(value.concat([option.key]) as PathValue<TFieldValues, TPath>)

      return
    }

    onChange(option.key as PathValue<TFieldValues, TPath>)
  })

  const renderHeader = () => {
    return (
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: toPx(8) }}>
        <FieldLabel>{field.name}</FieldLabel>
        {question}
      </Box>
    )
  }

  const renderList = () => {
    const Option = !multiple ? RadioListItem : CheckboxListItem

    const renderOption = ({
      option,
      isSelected,
    }: {
      option: CustomFieldPayloadOptionGroupOptionT
      isSelected: boolean
    }) => {
      return (
        <Option
          isSelected={isSelected}
          name={option.name}
          onClick={() => onOptionClick(option)}
        />
      )
    }

    return (
      <List sx={{ display: 'flex', flexDirection: 'column' }}>
        {availableOptions.map((option) => {
          const isSelected = selected.has(option.key)

          return <Fragment key={option.id}>{renderOption({ isSelected, option })}</Fragment>
        })}
      </List>
    )
  }

  const renderFooter = () => {
    if (!error) {
      return null
    }

    return <FieldErrorText>{error.message as string}</FieldErrorText>
  }

  const renderField = () => {
    return (
      <Field sx={{ flexGrow: 1, width: 0 }}>
        {renderHeader()}
        {renderList()}
        {renderFooter()}
      </Field>
    )
  }

  return <ObjectDetailsRow sx={getSizeSx()}>{renderField()}</ObjectDetailsRow>
}
