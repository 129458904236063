import { renderForwardable } from '@resnet/client-common/react/utils/render-forwardable'
import type { MergeAllT } from '@resnet/client-common/typescript/types/merge-all'

import type { IssueTypeT } from '@resnet/client-api/api'

import { issueTypeOptionById } from '@resnet/client-shared/shared/issues/constants/issue-type-options'

import type { TagPropsT } from '@resnet/client-shared-web/shared/gdl/components/tag'
import { Tag } from '@resnet/client-shared-web/shared/gdl/components/tag'

export type IssueTypeIconPropsT = MergeAllT<
  [
    React.SVGProps<SVGSVGElement>,
    {
      type: IssueTypeT
    },
  ]
>

export const IssueTypeIcon = ({ type, ...props }: IssueTypeIconPropsT) => {
  const { icon, iconColor } = issueTypeOptionById[type]

  return renderForwardable(icon, { ...props, fill: iconColor })
}

export type IssueTypeTagPropsT = MergeAllT<
  [
    TagPropsT,
    {
      type: IssueTypeT
    },
  ]
>

export const IssueTypeTag = ({ type, ...props }: IssueTypeTagPropsT) => {
  const { name } = issueTypeOptionById[type]

  return (
    <Tag
      {...props}
      uppercase
      icon={<IssueTypeIcon type={type} />}
    >
      {name}
    </Tag>
  )
}
