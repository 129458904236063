import type { BoxProps, SxProps } from '@mui/material'
import { Box, Tooltip } from '@mui/material'
import React from 'react'

import { Wrap } from '@resnet/client-common/react/components/wrap'
import { useEventCallback } from '@resnet/client-common/react/hooks/use-event-callback'
import type { ForwardableT } from '@resnet/client-common/react/types/forwardable'
import { forwardFunctionalComponentRef } from '@resnet/client-common/react/utils/forward-functional-component-ref'
import { renderForwardable } from '@resnet/client-common/react/utils/render-forwardable'
import type { MergeAllT } from '@resnet/client-common/typescript/types/merge-all'

import type { ColorT } from '@resnet/client-api/api'

import CircleSolidIcon from '@resnet/client-shared/assets/icons/circle-solid.svg'
import { mapPickerColorPresetToColor } from '@resnet/client-shared/shared/common/utils/map-picker-color-preset-to-color'
import { typographyPresets } from '@resnet/client-shared/shared/gdl/constants/typography-presets'
import type { MediaT } from '@resnet/client-shared/shared/gdl/types/media'

import { CircularSkeleton } from '@resnet/client-shared-web/shared/async/components/circular-skeleton'
import { TextSkeleton } from '@resnet/client-shared-web/shared/async/components/text-skeleton'
import { themeColors } from '@resnet/client-shared-web/shared/gdl/constants/theme-colors'

import { AvatarSkeleton, IconAvatar, LoadableAvatar } from '../../components/avatar'
import { mapTypographyPresetToSx } from '../../utils/map-typography-preset-to-sx'
import type { CardRefT } from '../card'
import { Card, type CardPropsT } from '../card'
import { ClickableOpacity } from '../clickable-opacity'
import { SingleLineText } from '../single-line-text'

export type ListPropsT = MergeAllT<
  [
    BoxProps,
    {
      noGap?: boolean
    },
  ]
>

export const List = ({ children, noGap = false, sx = null, ...props }: ListPropsT) => {
  return (
    <Box
      {...props}
      sx={{ display: 'flex', flexDirection: 'column', gap: noGap ? 0 : '8px', ...sx }}
    >
      {children}
    </Box>
  )
}

export type ListActionItemActionPropsT = {
  'data-testid'?: string
  icon: ForwardableT<React.SVGProps<SVGSVGElement>>
  id: string
  onClick?: () => void
  title?: string
}

export const ListActionItem = ({
  'data-testid': dataTestId,
  icon,
  onClick: onClickProp,
  title,
}: ListActionItemActionPropsT) => {
  const onClick = useEventCallback((event: React.MouseEvent) => {
    event.stopPropagation()
    onClickProp?.()
  })

  const wrapWithTooltip = (children: React.ReactElement) => {
    if (!title) {
      return children
    }

    return <Tooltip title={title}>{children}</Tooltip>
  }

  return (
    <Wrap with={wrapWithTooltip}>
      <ClickableOpacity
        data-testid={dataTestId}
        sx={{ position: 'relative' }}
        onClick={onClick}
      >
        {renderForwardable(icon, { fill: themeColors.overBackgroundBold, height: 16, width: 16 })}
      </ClickableOpacity>
    </Wrap>
  )
}

export const listItemEstimateHeight = 40

export type ListItemPropsT = CardPropsT

export type ListItemRefT = CardRefT

export const ListItem = forwardFunctionalComponentRef(
  ({ children, disabled, onClick, sx = null, ...props }: ListItemPropsT, ref: ListItemRefT) => {
    const getDisabledSx = () => {
      if (!disabled) {
        return null
      }

      return { opacity: 0.3 }
    }

    return (
      <Card
        {...props}
        borderRadius={8}
        disabled={disabled}
        padding={8}
        ref={ref}
        sx={[getDisabledSx(), sx].flat()}
        onClick={onClick}
      >
        {children}
      </Card>
    )
  },
)

export type ListItemContentLayoutPropsT = MergeAllT<
  [
    BoxProps,
    {
      children?: {
        action?: React.ReactNode
        description?: React.ReactNode
        media?: React.ReactNode
        name?: React.ReactNode
        selection?: React.ReactNode
      }
    },
  ]
>

export const ListItemContentLayout = ({ children, sx = null, ...props }: ListItemContentLayoutPropsT) => {
  const renderSelection = () => {
    return children?.selection
  }

  const renderMedia = () => {
    return children?.media
  }

  const renderName = () => {
    return children?.name
  }

  const renderDescription = () => {
    return children?.description
  }

  const renderNameAndDescription = () => {
    return (
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          flexGrow: '1',
          width: 0,
        }}
      >
        {renderName()}
        {renderDescription()}
      </Box>
    )
  }

  const renderAction = () => {
    return children?.action
  }

  return (
    <Box
      sx={[
        {
          alignItems: 'center',
          display: 'flex',
          flexGrow: '1',
          gap: '8px',
        },
        sx,
      ].flat()}
      {...props}
    >
      {renderSelection()}
      {renderMedia()}
      {renderNameAndDescription()}
      {renderAction()}
    </Box>
  )
}

export type ListItemSelectionPropsT = {
  children: ForwardableT<{
    fill: string
    height: string
    width: string
  }>
  isSelected?: boolean
}

export const ListItemSelection = ({ children, isSelected = false }: ListItemSelectionPropsT) => {
  return (
    <>
      {renderForwardable(children, {
        fill: isSelected ? themeColors.actionsPrimaryDefault : themeColors.borderBold,
        height: '24px',
        width: '24px',
      })}
    </>
  )
}

export type ListItemIconPropsT = { children: ForwardableT<React.SVGProps<SVGSVGElement>> }

export const ListItemIcon = ({ children }: ListItemIconPropsT) => {
  return (
    <Box style={{ alignItems: 'center', display: 'flex', justifyContent: 'center', width: '16px' }}>
      {renderForwardable(children, {
        fill: themeColors.overBackgroundFaded,
        height: '16px',
        width: '16px',
      })}
    </Box>
  )
}

export type ListItemAvatarPropsT =
  | Omit<React.ComponentProps<typeof IconAvatar>, 'size'>
  | Omit<React.ComponentProps<typeof LoadableAvatar>, 'size'>

export const ListItemAvatar = (props: ListItemAvatarPropsT) => {
  if ('icon' in props) {
    return (
      <IconAvatar
        {...props}
        size="sm"
      />
    )
  }

  return (
    <LoadableAvatar
      {...props}
      size="sm"
    />
  )
}

export const ListItemAvatarSkeleton = () => {
  return <AvatarSkeleton size="sm" />
}

export type ListItemColorPropsT = { color: ColorT }

export const ListItemColor = ({ color }: ListItemColorPropsT) => {
  return (
    <CircleSolidIcon
      fill={mapPickerColorPresetToColor(color)}
      height={16}
      width={16}
    />
  )
}

export const ListItemColorSkeleton = () => {
  return <CircularSkeleton size={16} />
}

export type ListItemNamePropsT = Omit<BoxProps, 'ref'>

export const ListItemName = ({ sx = null, children, ...props }: ListItemNamePropsT) => {
  return (
    <SingleLineText
      {...props}
      sx={[
        mapTypographyPresetToSx(typographyPresets.bodyMedium),
        {
          color: themeColors.overBackgroundBold,
        },
        sx,
      ].flat()}
    >
      {children}
    </SingleLineText>
  )
}

export type ListItemDescriptionPropsT = BoxProps

export const ListItemDescription = ({ sx, children, ...props }: ListItemDescriptionPropsT) => {
  return (
    <Box
      {...props}
      sx={[
        { color: themeColors.overBackgroundMuted, ...sx },
        mapTypographyPresetToSx(typographyPresets.bodySmall),
      ].flat()}
    >
      {children}
    </Box>
  )
}

export type ListItemSkeletonPropsT = { sx?: SxProps; media?: Pick<NonNullable<MediaT>, 'type'> }

export const ListItemSkeleton = ({ sx = null }: ListItemSkeletonPropsT) => {
  const renderName = () => {
    return (
      <TextSkeleton
        contentSx={{ width: '100%' }}
        typographyPreset={{ fontSize: 16, lineHeight: 24 }}
      />
    )
  }

  return (
    <ListItem sx={sx}>
      <ListItemContentLayout>{{ name: renderName() }}</ListItemContentLayout>
    </ListItem>
  )
}

export type ListItemWithAvatarSkeletonPropsT = { sx?: SxProps; media?: Pick<NonNullable<MediaT>, 'type'> }

export const ListItemWithAvatarSkeleton = ({ sx = null }: ListItemWithAvatarSkeletonPropsT) => {
  const renderName = () => {
    return (
      <TextSkeleton
        contentSx={{ width: '100%' }}
        typographyPreset={{ fontSize: 16, lineHeight: 24 }}
      />
    )
  }

  const renderMedia = () => {
    return <ListItemAvatarSkeleton />
  }

  return (
    <ListItem sx={sx}>
      <ListItemContentLayout>{{ media: renderMedia(), name: renderName() }}</ListItemContentLayout>
    </ListItem>
  )
}
