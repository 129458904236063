import type { AbstractSimpleOptionT } from '@resnet/client-shared-web/shared/gdl/types/abstract-option'

export const mapOptionToOptionIdFromKey = ({ options }: { options: { key: string; name: string }[] }) => {
  return options.map((option): AbstractSimpleOptionT => {
    return {
      id: option.key,
      name: option.name,
    }
  })
}
