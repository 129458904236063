import { propToKey } from '@resnet/client-common/common/utils/object/prop-to-key'

import { ColorPresetT } from '@resnet/client-api/api'

import { systemColors } from './system-colors'

export const pickerColorPresets = [
  {
    id: ColorPresetT.Color_1T as const,
    name: 'Color 1',
    value: systemColors.alienSkin15,
  },
  {
    id: ColorPresetT.Color_2T as const,
    name: 'Color 2',
    value: systemColors.moonBase20,
  },
  {
    id: ColorPresetT.Color_3T as const,
    name: 'Color 3',
    value: systemColors.quantum50,
  },
  {
    id: ColorPresetT.Color_4T as const,
    name: 'Color 4',
    value: systemColors.radiation20,
  },
  {
    id: ColorPresetT.Color_5T as const,
    name: 'Color 5',
    value: systemColors.warning30,
  },
  {
    id: ColorPresetT.Color_6T as const,
    name: 'Color 6',
    value: systemColors.critical20,
  },
  {
    id: ColorPresetT.Color_7T as const,
    name: 'Color 7',
    value: systemColors.info20,
  },
  {
    id: ColorPresetT.Color_8T as const,
    name: 'Color 8',
    value: systemColors.neutral15,
  },
]

export const pickerColorPresetById = propToKey('id', pickerColorPresets)
