import { Box } from '@mui/material'
import type { FieldPathByValue, FieldValues, UseFormReturn } from 'react-hook-form'

import type { CustomFieldPayloadEntityPickerT } from '@resnet/client-api/api'
import { CustomFieldTypeT, type CustomFieldFragmentT } from '@resnet/client-api/api'

import { validateListRequired } from '@resnet/client-shared/shared/forms/validators/list-required'

import { SwitchField } from '@resnet/client-shared-web/shared/form/components/common/switch-field'
import { toPx } from '@resnet/client-shared-web/shared/gdl/utils/to-px'

import { EntityTypeSelectMultipleField } from '@resnet/client-web/shared/form-dalaran/components/entity/entity-type-select-multiple-field'

export type EntityPickerCustomFieldPayloadValueT = CustomFieldFragmentT

export type EntityPickerCustomFieldPayloadPropsT<
  TFieldValues extends FieldValues,
  TPath extends FieldPathByValue<TFieldValues, EntityPickerCustomFieldPayloadValueT>,
> = {
  form: UseFormReturn<TFieldValues>
  name: TPath
  isDraft: boolean
}

export const EntityPickerCustomFieldPayload = <
  TFieldValues extends FieldValues,
  TPath extends FieldPathByValue<TFieldValues, EntityPickerCustomFieldPayloadValueT>,
>({
  form,
  name,
  isDraft,
}: EntityPickerCustomFieldPayloadPropsT<TFieldValues, TPath>) => {
  type EntityPickerPayloadPathT = FieldPathByValue<TFieldValues, undefined | null | CustomFieldPayloadEntityPickerT>

  const entityPickerPayloadName = `${name}.payload.${CustomFieldTypeT.EntityPickerT}` as EntityPickerPayloadPathT

  type RequiredPathT = FieldPathByValue<TFieldValues, undefined | null | CustomFieldPayloadEntityPickerT['required']>

  const requiredName = `${entityPickerPayloadName}.required` as RequiredPathT

  type MultiplePathT = FieldPathByValue<TFieldValues, undefined | null | CustomFieldPayloadEntityPickerT['multiple']>

  const multipleName = `${entityPickerPayloadName}.multiple` as MultiplePathT

  type IsHalfWidthPathT = FieldPathByValue<
    TFieldValues,
    undefined | null | CustomFieldPayloadEntityPickerT['isHalfWidth']
  >

  const isHalfWidthName = `${entityPickerPayloadName}.isHalfWidth` as IsHalfWidthPathT

  type EntityTypePathT = FieldPathByValue<
    TFieldValues,
    undefined | null | CustomFieldPayloadEntityPickerT['entityTypeId']
  >

  const entityTypeName = `${entityPickerPayloadName}.entityTypeId` as EntityTypePathT

  const renderIsRequiredField = () => {
    return (
      <SwitchField
        form={form}
        label="Required"
        name={requiredName}
      />
    )
  }

  const renderIsMultipleField = () => {
    return (
      <SwitchField
        disabled={!isDraft}
        disabledTooltipTitle="Multiple cannot be changed after creation"
        form={form}
        label="Multiple"
        name={multipleName}
      />
    )
  }

  const renderIsHalfWidthField = () => {
    return (
      <SwitchField
        form={form}
        label="Half Width"
        name={isHalfWidthName}
      />
    )
  }

  const renderEntityTypeField = () => {
    return (
      <Box sx={{ display: 'flex' }}>
        <EntityTypeSelectMultipleField
          form={form}
          label="Entity Type"
          name={entityTypeName}
          rules={{ validate: validateListRequired }}
        />
      </Box>
    )
  }

  const renderSwitchFields = () => {
    return (
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: toPx(8) }}>
        {renderIsRequiredField()}
        {renderIsMultipleField()}
        {renderIsHalfWidthField()}
        {renderEntityTypeField()}
      </Box>
    )
  }

  return <>{renderSwitchFields()}</>
}
