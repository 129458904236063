import { produce } from 'immer'

import {
  useGetBatteryQuery,
  useCreateBatteryMutation,
  useDeleteBatteryMutation,
  useFollowMutation,
  useInfiniteBatteriesQuery,
  useUnfollowMutation,
  useUpdateBatteryMutation,
  useUpdateFollowersMutation,
  useUploadResourceMutation,
  useUpdateRelationsMutation,
  useArchiveBatteryMutation,
  useUnarchiveBatteryMutation,
  useUpdateEntityTypeMutation,
  EntityTypeIdsT,
} from '@resnet/client-api/api'
import type { MutationCacheHandlersT } from '@resnet/client-api/services/query-client/types/mutation-cache-handlers'
import { createMutationHookMutationCacheHandlers } from '@resnet/client-api/services/query-client/utils/create-mutation-hook-mutation-cache-handlers'
import { infiniteListEntityQueryDeleteRecipe } from '@resnet/client-api/shared/entities/utils/delete-entity-recipe'
import {
  getEntityQueryUpdateRecipe,
  infiniteListEntityQueryUpdateRecipe,
} from '@resnet/client-api/shared/entities/utils/update-entity-recipe'
import { findGetQueriesById, findInfiniteQueries, findQueries } from '@resnet/client-api/utils/find-queries'
import { invalidateQuery } from '@resnet/client-api/utils/invalidate-query'
import { setQueryData } from '@resnet/client-api/utils/set-query-data'
import type { QueryClient } from '@resnet/client-api/vendors/react-query'

export const getBatteryQueriesHandlers = ({ queryClient }: { queryClient: QueryClient }): MutationCacheHandlersT[] => {
  const findBatteryQueries = () => {
    return findQueries(queryClient, useGetBatteryQuery)
  }

  const findBatteryQueriesById = ({ id }: { id: string }) => {
    return findGetQueriesById(queryClient, useGetBatteryQuery, (data) => data.getBattery, { id })
  }

  const updateBatteryHandlers = () => {
    return createMutationHookMutationCacheHandlers(useUpdateBatteryMutation, {
      onError: (error, { id }) => {
        findBatteryQueriesById({ id }).forEach(invalidateQuery(queryClient))
      },
      onMutate: ({ id, data: input }) => {
        findBatteryQueriesById({ id }).forEach((query) => {
          setQueryData(queryClient)(query)(
            produce((data) => {
              getEntityQueryUpdateRecipe({ data, input, mapDataToEntity: (data) => data.getBattery })
            }),
          )
        })
      },
      onSuccess: (data, { id }) => {
        findBatteryQueriesById({ id }).forEach((query) => {
          setQueryData(queryClient)(query)(() => ({
            getBattery: data.updateBattery,
          }))
        })
      },
    })
  }

  const followHandlers = () => {
    return createMutationHookMutationCacheHandlers(useFollowMutation, {
      onSuccess: (data, { entityId }) => {
        findBatteryQueriesById({ id: entityId }).forEach(invalidateQuery(queryClient))
      },
    })
  }

  const unfollowHandlers = () => {
    return createMutationHookMutationCacheHandlers(useUnfollowMutation, {
      onSuccess: (data, { entityId }) => {
        findBatteryQueriesById({ id: entityId }).forEach(invalidateQuery(queryClient))
      },
    })
  }

  const updateFollowersHandlers = () => {
    return createMutationHookMutationCacheHandlers(useUpdateFollowersMutation, {
      onSuccess: (data, { entityId }) => {
        findBatteryQueriesById({ id: entityId }).forEach(invalidateQuery(queryClient))
      },
    })
  }

  const uploadResourceHandlers = () => {
    return createMutationHookMutationCacheHandlers(useUploadResourceMutation, {
      onSuccess: (data, { originId }) => {
        findBatteryQueriesById({ id: originId }).forEach(invalidateQuery(queryClient))
      },
    })
  }

  const deleteBatteryHandlers = () => {
    return createMutationHookMutationCacheHandlers(useDeleteBatteryMutation, {
      onSuccess: (data, { id }) => {
        findBatteryQueriesById({ id }).forEach(invalidateQuery(queryClient))
      },
    })
  }

  const archiveBatteryHandlers = () => {
    return createMutationHookMutationCacheHandlers(useArchiveBatteryMutation, {
      onSuccess: (data, { id }) => {
        findBatteryQueriesById({ id }).forEach(invalidateQuery(queryClient))
      },
    })
  }

  const unarchiveBatteryHandlers = () => {
    return createMutationHookMutationCacheHandlers(useUnarchiveBatteryMutation, {
      onSuccess: (data, { id }) => {
        findBatteryQueriesById({ id }).forEach(invalidateQuery(queryClient))
      },
    })
  }

  const updateRelationsHandlers = () => {
    return createMutationHookMutationCacheHandlers(useUpdateRelationsMutation, {
      onSuccess: (data, { entityId }) => {
        findBatteryQueriesById({ id: entityId }).forEach(invalidateQuery(queryClient))
      },
    })
  }

  const updateEntityTypeHandlers = () => {
    return createMutationHookMutationCacheHandlers(useUpdateEntityTypeMutation, {
      onSuccess: (data, { id }) => {
        if (id !== EntityTypeIdsT.BatteryT) {
          return
        }

        findBatteryQueries().forEach(invalidateQuery(queryClient))
      },
    })
  }

  return [
    updateBatteryHandlers(),
    followHandlers(),
    unfollowHandlers(),
    updateFollowersHandlers(),
    uploadResourceHandlers(),
    deleteBatteryHandlers(),
    updateRelationsHandlers(),
    archiveBatteryHandlers(),
    unarchiveBatteryHandlers(),
    updateEntityTypeHandlers(),
  ]
}

export const batteriesQueriesHandlers = ({ queryClient }: { queryClient: QueryClient }): MutationCacheHandlersT[] => {
  const findInfiniteBatteriesQueries = () => findInfiniteQueries(queryClient, useInfiniteBatteriesQuery)

  const findInfiniteBatteriesQueriesById = ({ id }: { id: string }) =>
    findInfiniteQueries(queryClient, useInfiniteBatteriesQuery, (query) =>
      (query.state.data?.pages ?? []).some((page) =>
        (page.listBatteries?.items ?? []).some((battery) => battery.id === id),
      ),
    )

  const createBatteryHandlers = () => {
    return createMutationHookMutationCacheHandlers(useCreateBatteryMutation, {
      onSuccess: () => {
        findInfiniteBatteriesQueries().forEach(invalidateQuery(queryClient))
      },
    })
  }

  const updateBatteryHandlers = () => {
    return createMutationHookMutationCacheHandlers(useUpdateBatteryMutation, {
      onError: (error, { id }) => {
        findInfiniteBatteriesQueriesById({ id }).forEach(invalidateQuery(queryClient))
      },
      onMutate: ({ id, data: input }) => {
        findInfiniteBatteriesQueriesById({ id }).forEach((query) => {
          setQueryData(queryClient)(query)(
            produce((data) => {
              infiniteListEntityQueryUpdateRecipe({
                data,
                id,
                input,
                mapDataToEntities: (data) => data.listBatteries.items,
              })
            }),
          )
        })
      },
      onSuccess: (data, { id }) => {
        findInfiniteBatteriesQueriesById({ id }).forEach(invalidateQuery(queryClient))
      },
    })
  }

  const followHandlers = () => {
    return createMutationHookMutationCacheHandlers(useFollowMutation, {
      onSuccess: (data, { entityId }) => {
        findInfiniteBatteriesQueriesById({ id: entityId }).forEach(invalidateQuery(queryClient))
      },
    })
  }

  const unfollowHandlers = () => {
    return createMutationHookMutationCacheHandlers(useUnfollowMutation, {
      onSuccess: (data, { entityId }) => {
        findInfiniteBatteriesQueriesById({ id: entityId }).forEach(invalidateQuery(queryClient))
      },
    })
  }

  const deleteBatteryHandlers = () => {
    return createMutationHookMutationCacheHandlers(useDeleteBatteryMutation, {
      onError: (error, { id }) => {
        findInfiniteBatteriesQueriesById({ id }).forEach(invalidateQuery(queryClient))
      },
      onMutate: ({ id }) => {
        findInfiniteBatteriesQueriesById({ id }).forEach((query) => {
          setQueryData(queryClient)(query)(
            produce((data) => {
              infiniteListEntityQueryDeleteRecipe({
                data,
                id,
                mapDataToEntities: (data) => data.listBatteries.items,
              })
            }),
          )
        })
      },
      onSuccess: (data, { id }) => {
        findInfiniteBatteriesQueriesById({ id }).forEach(invalidateQuery(queryClient))
      },
    })
  }

  const updateRelationsHandlers = () => {
    return createMutationHookMutationCacheHandlers(useUpdateRelationsMutation, {
      onSuccess: (data, { entityId }) => {
        findInfiniteBatteriesQueriesById({ id: entityId }).forEach(invalidateQuery(queryClient))
      },
    })
  }

  const archiveBatteryHandlers = () => {
    return createMutationHookMutationCacheHandlers(useArchiveBatteryMutation, {
      onSuccess: (data, { id }) => {
        findInfiniteBatteriesQueriesById({ id }).forEach(invalidateQuery(queryClient))
      },
    })
  }

  const unarchiveBatteryHandlers = () => {
    return createMutationHookMutationCacheHandlers(useUnarchiveBatteryMutation, {
      onSuccess: (data, { id }) => {
        findInfiniteBatteriesQueriesById({ id }).forEach(invalidateQuery(queryClient))
      },
    })
  }

  const updateEntityTypeHandlers = () => {
    return createMutationHookMutationCacheHandlers(useUpdateEntityTypeMutation, {
      onSuccess: (data, { id }) => {
        if (id !== EntityTypeIdsT.BatteryT) {
          return
        }

        findInfiniteBatteriesQueries().forEach(invalidateQuery(queryClient))
      },
    })
  }

  return [
    createBatteryHandlers(),
    updateBatteryHandlers(),
    followHandlers(),
    unfollowHandlers(),
    deleteBatteryHandlers(),
    updateRelationsHandlers(),
    archiveBatteryHandlers(),
    unarchiveBatteryHandlers(),
    updateEntityTypeHandlers(),
  ]
}
