import { Box } from '@mui/material'
import { useSnackbar } from 'notistack'
import { useCallback, useContext } from 'react'

import { assertedNonNullable } from '@resnet/client-common/common/utils/nullable/non-nullable'
import { mapErrorToMessage } from '@resnet/client-common/network/utils/map-error-to-message'

import type { ResourceObjectTypeFragmentT } from '@resnet/client-api/api'
import { useDeleteResourceObjectTypeMutation, useInfiniteResourceObjectsQuery } from '@resnet/client-api/api'

import { typographyPresets } from '@resnet/client-shared/shared/gdl/constants/typography-presets'

import { Button } from '@resnet/client-shared-web/shared/gdl/components/button'
import { ModalContent, ModalContext, createModalHook } from '@resnet/client-shared-web/shared/gdl/components/modal'
import { Spinner } from '@resnet/client-shared-web/shared/gdl/components/spinner'
import { themeColors } from '@resnet/client-shared-web/shared/gdl/constants/theme-colors'
import { mapTypographyPresetToSx } from '@resnet/client-shared-web/shared/gdl/utils/map-typography-preset-to-sx'
import { toPx } from '@resnet/client-shared-web/shared/gdl/utils/to-px'
import { placeContentCenterStyles, stretchColumnItemStyles } from '@resnet/client-shared-web/styles/sx-presets'

export type DeleteModalPropsT = {
  onConfirm?: () => void
  resourceObjectType: ResourceObjectTypeFragmentT
}

export const DeleteResourceObjectTypeModal = ({ onConfirm: onConfirmProp, resourceObjectType }: DeleteModalPropsT) => {
  const { onClose } = assertedNonNullable(useContext(ModalContext))

  const { enqueueSnackbar } = useSnackbar()

  const deleteResourceObjectTypeMutation = useDeleteResourceObjectTypeMutation()

  const resourceObjectQuery = useInfiniteResourceObjectsQuery({
    filter: {
      resourceObjectTypeId: {
        eq: resourceObjectType.id,
      },
    },
    limit: 1,
  })

  const isNoLinkedResourceObjects = !resourceObjectQuery.data?.pages[0].listResourceObjects.items.length

  const isDeleteButtonDisabled =
    deleteResourceObjectTypeMutation.isLoading || !resourceObjectQuery.isSuccess || !isNoLinkedResourceObjects

  const onConfirmDelete = useCallback(async () => {
    try {
      await deleteResourceObjectTypeMutation.mutateAsync({ id: resourceObjectType.id })

      onConfirmProp?.()

      onClose?.()

      enqueueSnackbar('Successfully deleted', {
        variant: 'success',
      })
    } catch (error) {
      enqueueSnackbar(mapErrorToMessage(error), {
        variant: 'error',
      })
    }
  }, [deleteResourceObjectTypeMutation, enqueueSnackbar, onClose, onConfirmProp, resourceObjectType.id])

  const renderHeaderTitle = (): React.ReactNode => {
    return 'Delete'
  }

  const renderHeader = (): { title: React.ReactNode } => {
    return {
      title: renderHeaderTitle(),
    }
  }

  const renderContent = (): React.ReactNode => {
    if (!resourceObjectQuery.isSuccess) {
      return (
        <Box sx={[{ paddingY: '24px' }, stretchColumnItemStyles, placeContentCenterStyles].flat()}>
          <Spinner />
        </Box>
      )
    }

    const message = isNoLinkedResourceObjects
      ? `Are you sure you want to delete "${resourceObjectType.name}" resource type?`
      : `"${resourceObjectType.name}" is linked to at least one resource and can not be deleted`

    return (
      <Box
        sx={[
          mapTypographyPresetToSx(typographyPresets.bodyMedium),
          { color: themeColors.overBackgroundDefault },
        ].flat()}
      >
        {message}
      </Box>
    )
  }

  const renderFooter = (): React.ReactNode => {
    return (
      <Box sx={{ alignItems: 'center', display: 'flex', gap: toPx(8), justifyContent: 'space-between' }}>
        <Button
          color="danger"
          disabled={isDeleteButtonDisabled}
          isLoading={deleteResourceObjectTypeMutation.isLoading}
          size="md"
          variant="contained"
          onClick={() => onConfirmDelete()}
        >
          Delete
        </Button>
        <Button
          color="primary"
          size="md"
          variant="outlined"
          onClick={onClose}
        >
          Cancel
        </Button>
      </Box>
    )
  }

  return (
    <ModalContent sx={{ maxWidth: toPx(512), width: '100%' }}>
      {{
        content: renderContent(),
        footer: renderFooter(),
        header: renderHeader(),
      }}
    </ModalContent>
  )
}

export const useDeleteResourceObjectTypeModal = createModalHook(
  (params: React.ComponentProps<typeof DeleteResourceObjectTypeModal>) => <DeleteResourceObjectTypeModal {...params} />,
)
