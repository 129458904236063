import { Box } from '@mui/material'
import type { GridColDef } from '@mui/x-data-grid'
import type { FieldPathByValue, FieldValues, UseFormReturn } from 'react-hook-form'

import { checkNonNullable } from '@resnet/client-common/common/utils/nullable/non-nullable'
import { assert } from '@resnet/client-common/typescript/utils/assert'

import type { TextCustomFieldValueT } from '@resnet/client-shared/shared/custom-fields/presets/text/custom-field-value'

import { themeColors } from '@resnet/client-shared-web/shared/gdl/constants/theme-colors'

import type { ColumnWithCreateGanttColumnT } from '@resnet/client-web/shared/gantt/types/column-with-create-gantt-column'
import { createSimpleGanttColumn } from '@resnet/client-web/shared/gantt/utils/create-simple-gantt-column'
import { EditTextCell } from '@resnet/client-web/shared/tables/components/edit-text-cell'
import { RegularCell } from '@resnet/client-web/shared/tables/components/regular-cell'
import { TextCell } from '@resnet/client-web/shared/tables/components/text-cell'
import { createColumn as createCommonColumn } from '@resnet/client-web/shared/tables/factories/create-column'
import type { ColumnWithEditFieldT } from '@resnet/client-web/shared/tables/types/column-with-edit-field'
import type { ColumnWithGroupingT } from '@resnet/client-web/shared/tables/types/column-with-grouping'
import type { ColumnWithHeaderMenuFilterT } from '@resnet/client-web/shared/tables/types/column-with-header-menu-filter'
import type { ColumnWithValueT } from '@resnet/client-web/shared/tables/types/column-with-value'

import type { CreateColumnPropsT, CustomFieldT } from '../../types/custom-field'
import { getFieldValue } from '../../utils/get-field-value'
import { mapCustomFieldToFullKey } from '../../utils/map-custom-field-to-key'

import { TextCustomFieldUserFormField } from './components/text-custom-field-user-form-field'

export const createColumn: CustomFieldT['createColumn'] = <
  RowT extends Record<string, unknown>,
  FieldValuesKeyT extends Extract<keyof RowT, string>,
>({
  field,
  fieldValuesKey,
  mapRowToPathname,
}: CreateColumnPropsT<RowT, FieldValuesKeyT>) => {
  type ColumnT = ColumnWithHeaderMenuFilterT<
    ColumnWithEditFieldT<ColumnWithGroupingT<ColumnWithCreateGanttColumnT<GridColDef<RowT>>>>
  >

  type ColumnWithValueAppliedT = ColumnWithValueT<ColumnT, undefined | TextCustomFieldValueT>

  const getValue = (row: Record<string, unknown>) => getFieldValue<TextCustomFieldValueT>(field, fieldValuesKey, row)

  const createGanttColumn: ColumnT['createGanttColumn'] = ({ viewSettings, column }) =>
    createSimpleGanttColumn<Record<string, unknown>, ColumnT>({
      column,
      data: ({ row, vido }) => {
        if (row.isGroup) {
          return null
        }

        const style = {
          color: themeColors.overBackgroundDefault,
          textDecoration: 'none',
          verticalAlign: 'baseline',
        }

        const value = getValue(row)

        if (value === undefined) {
          return vido.html`<div style=${vido.styleMap(style)}>-</div>`
        }

        return vido.html`<div style=${vido.styleMap(style)}>${value.length === 0 ? '-' : value}</div>`
      },
      isHTML: false,
      sortable: ({ row }) => {
        const value = getValue(row)

        if (value === undefined) {
          return '-'
        }

        return value.length === 0 ? '-' : value
      },
      viewSettings,
      width: 100,
    })

  const columnField = mapCustomFieldToFullKey(field, fieldValuesKey)

  return createCommonColumn<ColumnWithValueAppliedT>({
    createGanttColumn,
    editable: true,
    field: columnField,
    filterOptionId: columnField,
    groupRowRenderCell: (rowNode) => {
      const { groupingKey } = rowNode

      assert(groupingKey, checkNonNullable)

      return <RegularCell>{groupingKey}</RegularCell>
    },
    groupable: true,
    groupingValueGetter: ({ row }) => {
      const value = getValue(row)

      if (value === undefined || value.length === 0) {
        return undefined
      }

      return value
    },
    headerName: field.name,
    renderCell: ({ row, value }) => {
      return (
        <TextCell
          to={mapRowToPathname?.(row)}
          value={value}
        />
      )
    },
    renderEditCell: () => {
      return <EditTextCell name={columnField} />
    },
    renderField: ({ form }) => {
      return (
        <Box sx={{ display: 'flex', flexDirection: 'column', flexGrow: 1 }}>
          <TextCustomFieldUserFormField
            field={field}
            form={form as UseFormReturn<FieldValues>}
            name={columnField as FieldPathByValue<RowT, undefined | TextCustomFieldValueT>}
          />
        </Box>
      )
    },
    valueGetter: ({ row }) => getValue(row),
  })
}
