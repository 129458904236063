import type { BoxProps } from '@mui/material'
import { Box } from '@mui/material'
import { useContext } from 'react'

import { assertedNonNull } from '@resnet/client-common/common/utils/nullable/non-nullable'
import { useEventCallback } from '@resnet/client-common/react/hooks/use-event-callback'
import { forwardFunctionalComponentRef } from '@resnet/client-common/react/utils/forward-functional-component-ref'
import type { MergeAllT } from '@resnet/client-common/typescript/types/merge-all'

import { ClickAwayListener } from '@resnet/client-shared-web/shared/registry/components/click-away-listener'
import { FocusTrap } from '@resnet/client-shared-web/shared/registry/components/focus-trap'

import { PopoverMenu } from '../popover-menu'
import { PopperContext, PopperTransitionContext } from '../popper'

export type PopoverPropsT = MergeAllT<
  [
    Omit<BoxProps, 'ref'>,
    {
      isFocusTrapOpen?: boolean
      onBeforeClickAway?: (
        event: MouseEvent,
        options: { preventDefault: () => void; anchorEl: null | HTMLElement },
      ) => void
      onBeforeEscape?: (options: { preventDefault: () => void; anchorEl: null | HTMLElement }) => void
    },
  ]
>

export type PopoverRefT = React.Ref<HTMLDivElement>

export const Popover = forwardFunctionalComponentRef(
  (
    { sx = null, children, isFocusTrapOpen = true, onBeforeClickAway, onBeforeEscape, ...props }: PopoverPropsT,
    ref: PopoverRefT,
  ) => {
    const { close, anchorEl } = assertedNonNull(useContext(PopperContext))

    const { in: transitionIn = false } = useContext(PopperTransitionContext) ?? {}

    const checkIsFocusTrapEnabled = useEventCallback(() => {
      return transitionIn
    })

    const onClickAway = useEventCallback((event: MouseEvent) => {
      const preventRef = { current: false }

      const preventDefault = () => {
        preventRef.current = true
      }

      onBeforeClickAway?.(event, { anchorEl, preventDefault })

      if (preventRef.current) {
        return
      }

      close()
    })

    const onKeyDown = useEventCallback((event: React.KeyboardEvent) => {
      if (event.key !== 'Escape') {
        return
      }

      event.stopPropagation()

      const preventRef = { current: false }

      const preventDefault = () => {
        preventRef.current = true
      }

      onBeforeEscape?.({ anchorEl, preventDefault })

      if (preventRef.current) {
        return
      }

      close()
    })

    return (
      <ClickAwayListener onClickAway={onClickAway}>
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
          <FocusTrap
            isEnabled={checkIsFocusTrapEnabled}
            open={isFocusTrapOpen}
          >
            <PopoverMenu
              {...props}
              ref={ref}
              sx={sx}
              tabIndex={-1}
              onKeyDown={onKeyDown}
            >
              {children}
            </PopoverMenu>
          </FocusTrap>
        </Box>
      </ClickAwayListener>
    )
  },
)
