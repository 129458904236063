import type { MergeAllT } from '@resnet/client-common/typescript/types/merge-all'
import { toTuple } from '@resnet/client-common/typescript/utils/to-tuple'

import { fromEntries } from './from-entries'

export type ItemWithPropT<PropNameT extends PropertyKey> = MergeAllT<
  [Record<PropertyKey, unknown>, Record<PropNameT, PropertyKey>]
>

export type ItemsByPropT<PropNameT extends PropertyKey, ItemT extends ItemWithPropT<PropNameT>> = {
  [K in ItemT[PropNameT]]: Extract<ItemT, Record<PropNameT, K>>
}

export const propToKey = <PropNameT extends PropertyKey, ItemT extends ItemWithPropT<PropNameT>>(
  propName: PropNameT,
  list: readonly ItemT[],
) => {
  const itemsByProp = fromEntries(list.map((value) => toTuple([value[propName], value])))

  return itemsByProp as ItemsByPropT<PropNameT, ItemT>
}

export const idToKey = <ItemT extends ItemWithPropT<'id'>>(list: readonly ItemT[]) => {
  return propToKey('id', list)
}
