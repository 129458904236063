import React from "react";
import { Box } from "@mui/material";
import { useBatteriesQuery } from "@resnet/client-api/api";
import { EmptyPanel } from "@resnet/client-web/shared/common/components/empty-panel";
import {
    getBatteryCardSize, BatteryCardSkeleton, BatteryCard
  } from "@resnet/client-web/screens/field-dispatch-console-screen/modules/cards/components/battery-card";
import { useLoadableList, checkIsSkeletonItem } from "@resnet/client-shared-web/shared/async/hooks/use-loadable-list";
import { VirtualizedList } from "@resnet/client-shared-web/shared/gdl/components/virtualized-list";
import { useHistory } from "react-router-dom";

const emptyArray: never[] = [];

const listConfig = {
    gap: 8,
    maxVisibleOptions: 3,
    optionHeight: getBatteryCardSize(),
  } as const;

const HomeBatteries = ({search}: {search:string}): React.ReactElement => {

  const queryVariables = {
    filter: { name: { includes: search } },
  };

  const batteriesQuery = useBatteriesQuery(queryVariables, {
    select: (data) => data.listBatteries?.items,
  })

  const dataActual = batteriesQuery.data ?? emptyArray;

  const isFetching = batteriesQuery.isFetching;

  const { data } = useLoadableList({ data: dataActual, isFetching });

  const navigate = useHistory();

  const renderIssues = () => {
    if (!data.length && batteriesQuery.isSuccess) {
      return (
        <EmptyPanel>
          {{
            title: <>No issues found</>,
          }}
        </EmptyPanel>
      );
    }

    return (
      <VirtualizedList data={data} gap={listConfig.gap} getItemSize={() => listConfig.optionHeight}>
        {({ item, style }) => {
          return (
            <Box key={item.id} style={style} sx={{ display: "flex", flexDirection: "column" }}>
              {checkIsSkeletonItem(item) ? (
                <BatteryCardSkeleton />
              ) : (
                <BatteryCard onClick={() => navigate.push("/entity/" + item.id)} battery={item} />
              )}
            </Box>
          );
        }}
      </VirtualizedList>
    );
  };

  return (
    <Box sx={{ padding: "8px 16px", paddingTop: '200px' }}>{renderIssues()}</Box>
  )
}

export default HomeBatteries;