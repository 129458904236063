import { checkNonNullable } from '@resnet/client-common/common/utils/nullable/non-nullable'
import { assert } from '@resnet/client-common/typescript/utils/assert'

import { CustomFieldTypeT } from '@resnet/client-api/api'

import type { SketchCustomFieldValueT } from '@resnet/client-shared/shared/custom-fields/presets/sketch/custom-field-value'

import type { ResourceT } from '@resnet/client-shared-web/shared/files/types/resource'

import type { CustomFieldT } from '../../types/custom-field'

import type {
  SketchFormCustomFieldValueT,
  SketchMultipleFormCustomFieldValueT,
  SketchSingleFormCustomFieldValueT,
} from './form-custom-field-value'

export const mapFormCustomFieldValueToResources: CustomFieldT<
  SketchCustomFieldValueT,
  SketchFormCustomFieldValueT
>['mapFormCustomFieldValueToResources'] = ({ value, field }) => {
  const payload = field.payload

  assert(payload, checkNonNullable)

  const sketchPayload = payload[CustomFieldTypeT.SketchT]

  assert(sketchPayload, checkNonNullable)

  const { multiple } = sketchPayload

  if (multiple) {
    const sketchMultipleFormFieldValue = value as SketchMultipleFormCustomFieldValueT

    const resources: ResourceT[] = sketchMultipleFormFieldValue

    return resources
  }

  const sketchSingleFormFieldValue = value as SketchSingleFormCustomFieldValueT

  const resources: ResourceT[] = !sketchSingleFormFieldValue ? [] : [sketchSingleFormFieldValue]

  return resources
}
