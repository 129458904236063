import { createConstrainer } from '@resnet/client-common/typescript/utils/create-constrainer'

import type { CustomFieldPayloadDropdownOptionFragmentT } from '@resnet/client-api/api'

import type { AbstractSimpleOptionT } from '@resnet/client-shared-web/shared/gdl/types/abstract-option'

export const mapOptionToOptionIdFromKey = ({
  hasColor,
  options,
}: {
  hasColor: boolean
  options: CustomFieldPayloadDropdownOptionFragmentT[]
}) => {
  return options.map((option) => {
    const getMedia = () => {
      if (!hasColor) {
        return undefined
      }

      return {
        color: option.color ?? { custom: 'transparent' },
        type: 'color' as const,
      }
    }

    return createConstrainer<AbstractSimpleOptionT>()({
      id: option.key,
      isMarkedAsDeleted: option.isMarkedAsDeleted,
      media: getMedia(),
      name: option.name,
    })
  })
}
