import { checkNonNullable } from '@resnet/client-common/common/utils/nullable/non-nullable'
import { assert } from '@resnet/client-common/typescript/utils/assert'

import { CustomFieldTypeT, type CustomFieldFragmentT } from '@resnet/client-api/api'

import type { DateCustomFieldValueT } from '@resnet/client-shared/shared/custom-fields/presets/date/custom-field-value'

import {
  FieldPreview,
  renderDateFieldPreviewData,
  renderDateTimeFieldPreviewData,
} from '@resnet/client-shared-web/shared/gdl/components/field-preview'

import { ObjectDetailsRow } from '@resnet/client-web/shared/object/components/object-details-row'

export type DateFormUserFormPreviewPropsT = {
  field: CustomFieldFragmentT
  value: undefined | DateCustomFieldValueT
}

export const DateFormUserFormPreview = ({ field, value }: DateFormUserFormPreviewPropsT) => {
  const payload = field.payload

  assert(payload, checkNonNullable)

  const datePayload = payload[CustomFieldTypeT.DateT]

  assert(datePayload, checkNonNullable)

  const { addTimeEnabled, isHalfWidth } = datePayload

  const getSizeSx = () => {
    if (isHalfWidth) {
      return null
    }

    return {
      gridColumn: 'span 2',
    }
  }

  const renderData = addTimeEnabled ? renderDateTimeFieldPreviewData : renderDateFieldPreviewData

  return (
    <ObjectDetailsRow sx={getSizeSx()}>
      <FieldPreview
        label={field.name}
        renderData={renderData}
        value={value}
      />
    </ObjectDetailsRow>
  )
}
