import { Box, useEventCallback } from '@mui/material'
import { useContext, useRef, useState } from 'react'
import SignatureCanvas from 'react-signature-canvas'

import { assertedNonNullable } from '@resnet/client-common/common/utils/nullable/non-nullable'
import { useAsyncEventCallback } from '@resnet/client-common/react/hooks/use-async-event-callback'
import { useRect } from '@resnet/client-common/react/hooks/use-rect'

import { systemColors } from '@resnet/client-shared/shared/gdl/constants/system-colors'
import { useTheme } from '@resnet/client-shared/shared/gdl/contexts/theme-context'

import { Button } from '@resnet/client-shared-web/shared/gdl/components/button'
import { createModalContainer, ModalContext } from '@resnet/client-shared-web/shared/gdl/components/modal'
import { createModalHook } from '@resnet/client-shared-web/shared/gdl/components/modal'
import { ModalContent } from '@resnet/client-shared-web/shared/gdl/components/modal'
import { toPx } from '@resnet/client-shared-web/shared/gdl/utils/to-px'

import { sketchSx } from '../../styles/sketch-sx'

type CreateSketchModalPropsT = {
  label: string
  onCreate: (dataUrl: string) => Promise<void>
}

export const CreateSketchModal = ({ label, onCreate }: CreateSketchModalPropsT): React.ReactElement => {
  const theme = useTheme()

  const { onClose } = assertedNonNullable(useContext(ModalContext))

  const [contentElementRef, rect] = useRect()

  const canvasElementRef = useRef<null | SignatureCanvas>(null)

  const [isEmpty, setIsEmpty] = useState(true)

  const onClearButtonClick = useEventCallback(() => {
    const canvasElement = canvasElementRef.current

    if (!canvasElement) {
      return
    }

    canvasElement.clear()

    setIsEmpty(canvasElement.isEmpty())
  })

  const [isDoneClickPending, onDoneButtonClick] = useAsyncEventCallback(async () => {
    const canvasElement = canvasElementRef.current

    if (!canvasElement) {
      return
    }

    const dataUrl = canvasElement.toDataURL('image/png')

    await onCreate(dataUrl)

    onClose?.()
  })

  const onEnd = useEventCallback(() => {
    const canvasElement = canvasElementRef.current

    if (!canvasElement) {
      return
    }

    setIsEmpty(canvasElement.isEmpty())
  })

  const renderHeaderTitle = (): React.ReactNode => {
    return label
  }

  const renderHeader = () => {
    return {
      title: renderHeaderTitle(),
    }
  }

  const renderContent = (): React.ReactNode => {
    return (
      <Box
        ref={contentElementRef}
        sx={[{ flexGrow: 1, height: 0 }, sketchSx({ theme })]}
      >
        <SignatureCanvas
          backgroundColor={systemColors.white}
          canvasProps={{ height: rect?.height, width: rect?.width }}
          penColor={systemColors.black}
          ref={canvasElementRef}
          onEnd={onEnd}
        />
      </Box>
    )
  }

  const renderFooter = (): React.ReactNode => {
    return (
      <Box sx={{ display: 'flex', gap: toPx(8), justifyContent: 'center' }}>
        <Button
          color="primary"
          disabled={isEmpty || isDoneClickPending}
          isLoading={isDoneClickPending}
          size="md"
          variant="contained"
          onClick={onDoneButtonClick}
        >
          Done
        </Button>
        <Button
          color="primary"
          disabled={isEmpty}
          size="md"
          variant="text"
          onClick={onClearButtonClick}
        >
          Clear
        </Button>
      </Box>
    )
  }

  return (
    <ModalContent sx={{ height: '100%', width: '100%' }}>
      {{
        content: renderContent(),
        footer: renderFooter(),
        header: renderHeader(),
      }}
    </ModalContent>
  )
}

export const useCreateSketchModal = createModalHook((params: CreateSketchModalPropsT) => (
  <CreateSketchModal {...params} />
))

export const CreateSketchModalContainer = createModalContainer(useCreateSketchModal)
