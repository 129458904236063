import type { BoxProps } from '@mui/material'
import { Box } from '@mui/material'

import { toPx } from '@resnet/client-shared-web/shared/gdl/utils/to-px'

export type ObjectDetailsGridPropsT = BoxProps

export const ObjectDetailsGrid = ({ sx = null, children, ...props }: ObjectDetailsGridPropsT) => {
  return (
    <Box
      {...props}
      sx={[
        {
          display: 'grid',
          gap: toPx(16),
          gridTemplateColumns: '1fr 1fr',
        },
        sx,
      ].flat()}
    >
      {children}
    </Box>
  )
}
