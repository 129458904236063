import { ConfirmModal } from '@resnet/client-shared-web/shared/common/components/confirm-modal'
import { createModalContainer, createModalHook } from '@resnet/client-shared-web/shared/gdl/components/modal'

export type DeleteModalPropsT = {
  title?: string
  message: string
  onConfirm?: () => void
}

export const DeleteModal = ({ title = 'Delete', ...restProps }: DeleteModalPropsT) => {
  return (
    <ConfirmModal
      isDangerAction
      title={title}
      {...restProps}
      abortButtonText="Cancel"
      confirmButtonText="Delete"
    />
  )
}

export const useDeleteModal = createModalHook((params: React.ComponentProps<typeof DeleteModal>) => (
  <DeleteModal {...params} />
))

export const DeleteModalContainer = createModalContainer(useDeleteModal)
