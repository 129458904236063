import { useMapFieldModel } from '@resnet/client-common/react/hooks/use-map-field-model'

import { SimpleStaticOptionsDropdown } from '@resnet/client-shared-web/shared/common/components/simple-static-options-dropdown'
import type { Popper } from '@resnet/client-shared-web/shared/gdl/components/popper'

const booleanOptions = [{ id: '__true', name: 'Yes' } as const, { id: '__false', name: 'No' } as const]

export const BooleanFilter = ({
  disablePortal,
  onChange: onChangeProp,
  placement,
  popperRef,
  value: valueProp,
  optionNameById,
}: {
  disablePortal?: boolean
  onChange: (nextValue: undefined | null | boolean) => void
  placement?: React.ComponentProps<typeof Popper>['placement']
  popperRef?: React.ComponentProps<typeof Popper>['popperRef']
  value: undefined | null | boolean
  optionNameById?: Record<'__true' | '__false', string>
}) => {
  const [value, onChange] = useMapFieldModel(
    [valueProp, onChangeProp],
    (inputValue): undefined | null | (typeof booleanOptions)[number]['id'] =>
      inputValue == null ? undefined : inputValue ? '__true' : '__false',
    (outputValue) => (outputValue == null ? undefined : outputValue === '__true'),
  )

  const options = !optionNameById
    ? booleanOptions
    : booleanOptions.map((option) => ({
        ...option,
        name: optionNameById[option.id] ?? option.name,
      }))

  return (
    <SimpleStaticOptionsDropdown
      disablePortal={disablePortal}
      options={options}
      placement={placement}
      popperRef={popperRef}
      value={value}
      onChange={onChange}
    />
  )
}
