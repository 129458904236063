import { Box, LinearProgress } from '@mui/material'
import { useVirtualizer } from '@tanstack/react-virtual'
import { useMemo, useRef } from 'react'

import { ChildrenDivider } from '@resnet/client-common/react/components/children-transformer'
import { useEventCallback } from '@resnet/client-common/react/hooks/use-event-callback'
import type { MergeAllT } from '@resnet/client-common/typescript/types/merge-all'

import { FilterModeT } from '@resnet/client-api/api'

import { checkIsSkeletonItem, useLoadableList } from '@resnet/client-shared-web/shared/async/hooks/use-loadable-list'
import type { AbstractOptionT } from '@resnet/client-shared-web/shared/gdl/types/abstract-option'

import { Button } from '../../components/button'
import { Divider } from '../../components/divider'
import { toPx } from '../../utils/to-px'
import { CheckboxListItem, CheckboxListItemSkeleton } from '../checkbox-list'
import type { SelectDropdownPropsT } from '../select-dropdown'
import { SelectDropdownMessagePanel } from '../select-dropdown/components/select-dropdown-message-panel'
import { selectDropdownSizesConfig } from '../select-dropdown/constants/select-dropdown-config'
import { getSelectOptionLabel } from '../select-dropdown/utils/get-select-option-label'
import { VirtualizedList } from '../virtualized-list-lisbon'

export type SelectMultipleDropdownPropsT<OptionT extends AbstractOptionT = AbstractOptionT> = MergeAllT<
  [
    SelectDropdownPropsT<OptionT>,
    {
      getOptionVisible?: (option: OptionT, value: null | OptionT['id'][]) => boolean
      mode?: FilterModeT
      nonEmpty?: boolean
      onChange?: (value: OptionT['id'][], option?: OptionT) => void
      onClear?: () => void
      onSelectAll?: () => void
      value: null | OptionT['id'][]
      hideSelection?: boolean
    },
  ]
>

const emptyArray: never[] = []

export const SelectMultipleDropdown = <OptionT extends AbstractOptionT = AbstractOptionT>({
  bottomBoundary,
  error,
  getOptionDescription,
  getOptionDisabled,
  getOptionLabel = getSelectOptionLabel,
  getOptionMedia,
  getOptionVisible,
  header,
  isBusy = false,
  isLoading = false,
  mode = FilterModeT.IncludeT,
  nonEmpty,
  notFoundMessage = 'No options found',
  onChange,
  onClear,
  onSelectAll,
  optionHeight = selectDropdownSizesConfig.optionHeight,
  options: optionsActualActual,
  search,
  skeletonProps,
  value: valueActual,
  hideSelection,
}: SelectMultipleDropdownPropsT<OptionT>): React.ReactElement => {
  if (!optionsActualActual) {
    throw new Error('options should be non nullable')
  }

  const value = valueActual === null ? emptyArray : valueActual

  const optionsActual = useMemo(() => {
    if (!getOptionVisible) {
      return optionsActualActual
    }

    return optionsActualActual.filter((option) => getOptionVisible(option, value))
  }, [getOptionVisible, optionsActualActual, value])

  const { data: options } = useLoadableList({
    data: optionsActual,
    isFetching: isLoading,
    skeletonsCount: selectDropdownSizesConfig.maxVisibleOptions,
  })

  const virtualizedListRef = useRef<null | HTMLDivElement>(null)

  const virtualizer = useVirtualizer({
    count: options.length,
    estimateSize: () => optionHeight,
    getItemKey: (index) => options[index].id,
    getScrollElement: () => virtualizedListRef.current,
  })

  const { gap, maxVisibleOptions } = selectDropdownSizesConfig

  const visibleOptions = Math.min(options.length, maxVisibleOptions)

  const height = optionHeight * visibleOptions + gap * (visibleOptions - 1)

  const selected = useMemo(() => new Set(value), [value])

  const isExcludeMode = mode === FilterModeT.ExcludeT

  const unselectOption = useEventCallback((option: OptionT) => {
    onChange?.(
      value.filter((selectedOptionId) => selectedOptionId !== option.id),
      option,
    )
  })

  const selectOption = useEventCallback((option: OptionT) => {
    onChange?.([...value, option.id], option)
  })

  const renderSelectAllControls = () => {
    if (!onSelectAll || !onClear || !value || !options?.length) {
      return null
    }

    return (
      <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
        <Button
          color="primary"
          disabled={isExcludeMode && value.length === 0}
          size="md"
          variant="outlined"
          onClick={onSelectAll}
        >
          Select All
        </Button>
        <Button
          color="primary"
          disabled={!isExcludeMode && value.length === 0}
          size="md"
          variant="outlined"
          onClick={onClear}
        >
          Clear
        </Button>
      </Box>
    )
  }

  const renderOptions = (): React.ReactNode => {
    if (error) {
      return (
        <Box sx={{ py: toPx(selectDropdownSizesConfig.listPadding) }}>
          <SelectDropdownMessagePanel>{error}</SelectDropdownMessagePanel>
        </Box>
      )
    }

    if (options.length === 0) {
      return (
        <Box sx={{ py: toPx(selectDropdownSizesConfig.listPadding) }}>
          <SelectDropdownMessagePanel>{notFoundMessage}</SelectDropdownMessagePanel>
        </Box>
      )
    }

    return (
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          height: toPx(height),
        }}
      >
        <VirtualizedList
          gap={selectDropdownSizesConfig.gap}
          items={options}
          renderItem={({ item: option }) => {
            if (checkIsSkeletonItem(option)) {
              return (
                <CheckboxListItemSkeleton
                  {...skeletonProps}
                  hideSelection={hideSelection}
                  key={option.id}
                  sx={{ height: optionHeight }}
                />
              )
            }

            const isSelected = selected.has(option.id)

            const isSelectedVisual = isExcludeMode ? !isSelected : isSelected

            return (
              <CheckboxListItem
                showNameTooltip
                description={getOptionDescription?.(option)}
                disabled={getOptionDisabled?.(option) || isBusy}
                hideSelection={hideSelection}
                isSelected={isSelectedVisual}
                media={getOptionMedia?.(option)}
                name={getOptionLabel(option)}
                sx={{ height: optionHeight }}
                onClick={() => {
                  if (nonEmpty && value.length === 1 && isSelected) {
                    return
                  }

                  if (isSelected) {
                    unselectOption(option)

                    return
                  }

                  selectOption(option)
                }}
              />
            )
          }}
          virtualizedListRef={virtualizedListRef}
          virtualizer={virtualizer}
          onEndReached={bottomBoundary?.onObserve}
        />
      </Box>
    )
  }

  const renderLoader = () => {
    if (!isBusy) {
      return null
    }

    return (
      <Box sx={{ left: toPx(-8), position: 'absolute', right: toPx(-8), top: toPx(-7) }}>
        <LinearProgress />
      </Box>
    )
  }

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: toPx(8), position: 'relative' }}>
      {renderLoader()}
      <ChildrenDivider dividerNode={<Divider />}>
        {search}
        {header}
        {renderOptions()}
        {renderSelectAllControls()}
      </ChildrenDivider>
    </Box>
  )
}
