import { checkIsSpecialValue } from '../utils/check-is-special-value'

export const createValidateMinLength =
  (length: number) =>
  (value = '') => {
    if (checkIsSpecialValue(value)) {
      return true
    }

    if (value.trim().length < length) {
      return `This field can not be less then ${length} characters long`
    }

    return true
  }

export const createValidateMaxLength = (length: number) => (value?: null | string) => {
  if (checkIsSpecialValue(value)) {
    return true
  }

  if (!value) {
    return true
  }

  if (value.trim().length > length) {
    return `This field can not be more than ${length} characters long`
  }

  return true
}
