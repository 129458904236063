import { Tag } from '@resnet/client-shared-web/shared/gdl/components/tag'

type LabelPropsT = {
  feedbackLevel?: 'feedbackInfo' | 'feedbackCritical' | 'feedbackWarning' | 'feedbackSuccess'
  name: string
} & React.ComponentProps<typeof Tag>

const tagStatusByFeedbackLevel = {
  feedbackCritical: 'critical',
  feedbackInfo: 'info',
  feedbackSuccess: 'success',
  feedbackWarning: 'warning',
} as const

export const IssueApprovalTag = ({ feedbackLevel, name, ...rest }: LabelPropsT) => {
  const status = feedbackLevel ? tagStatusByFeedbackLevel[feedbackLevel] : undefined

  return (
    <Tag
      status={status}
      {...rest}
    >
      {name}
    </Tag>
  )
}
