import { Box } from "@mui/material";
import { MemoProvider } from "@resnet/client-common/react/components/memo-provider";
import { PostOriginTypeT, useCreatePostMutation, usePostsQuery } from "@resnet/client-api/api";
import type { PostsQueryVariablesT } from "@resnet/client-api/api";
import { maxLimit } from "@resnet/client-api/constants/variables";
import { MessageForm } from "@resnet/client-web/shared/feed/components/message-form";
import { EmptyPanel } from "@resnet/client-web/shared/common/components/empty-panel";
import { FeedContext } from "@resnet/client-web/shared/feed/contexts/feed-context";
import { Spinner } from "@resnet/client-shared-web/shared/gdl/components/spinner";
import { toPx } from "@resnet/client-shared-web/shared/gdl/utils/to-px";
import { useSnackbar } from "notistack";
import { OfficePostCard } from "./OfficePostCard";

export const FeedContent = ({
  variables,
  noPostsText = "No messages yet",
}: {
  variables: PostsQueryVariablesT;
  noPostsText?: string;
}): React.ReactElement => {
  const postsQuery = usePostsQuery(variables, { select: (data) => data.listPosts.items });

  if (postsQuery.isSuccess && postsQuery.data.length === 0) {
    return (
      <EmptyPanel>
        {{
          description: <>{noPostsText}</>,
        }}
      </EmptyPanel>
    );
  }

  const renderSpinner = () => {
    if (postsQuery.isSuccess) {
      return null;
    }

    return (
      <Box
        sx={{
          alignItems: "center",
          display: "flex",
          flexGrow: 1,
          inset: 0,
          justifyContent: "center",
          position: "absolute",
        }}
      >
        <Spinner />
      </Box>
    );
  };

  const renderPosts = () => {
    if (!postsQuery.isSuccess) {
      return null;
    }

    const posts = postsQuery.data;

    return (
      <Box sx={{ display: "flex", flexDirection: "column", gap: toPx(8) }}>
        {posts.map((post) => {
          return <OfficePostCard post={post} isMostRecentAIBotPost={false} />;
        })}
      </Box>
    );
  };

  return (
    <Box sx={{ display: "flex", flexDirection: "column", flexGrow: 1, gap: "16px", position: "relative" }}>
      {renderSpinner()}
      {renderPosts()}
    </Box>
  );
};

export const Feed = ({ origin }: { origin: string }): React.ReactElement => {
  const { mutateAsync: createPost } = useCreatePostMutation();
  const { enqueueSnackbar } = useSnackbar();

  const renderContent = () => {
    return (
      <FeedContent
        variables={{
          filter: { excludeBot: true, originId: origin },
          limit: { before: maxLimit },
        }}
      />
    );
  };

  const submit = async (message: string) => {
    try {
      await createPost({
        originId: origin,
        originType: PostOriginTypeT.EntityT,
        post: {
          message,
        },
      });

      enqueueSnackbar("Your post has been successfully created.", {
        variant: "success",
      });
    } catch (error) {
      enqueueSnackbar("An error occurred while creating the post.", {
        variant: "error",
      });
    }
  };

  const renderForm = () => {
    return <MessageForm onSubmit={(e) => submit(e.message)} placeholder="Post in feed..." />;
  };

  return (
    <MemoProvider Context={FeedContext}
    value={{
      isOriginScreen: true,
      //origin,
      type: "posts"
    }}
    >
      <Box sx={{ display: "flex", flexDirection: "column", flexGrow: 1 }}>
        {renderContent()}
        <Box sx={{ marginTop: toPx(8) }}>{renderForm()}</Box>
      </Box>
    </MemoProvider>
  );
};
