import { Box } from '@mui/material'
import type { FieldPathByValue, FieldValues, PathValue, UseControllerProps, UseFormReturn } from 'react-hook-form'
import { useController } from 'react-hook-form'

import { mapToValues } from '@resnet/client-common/common/utils/object/map-to-values'

import { EntityTypeIdsT } from '@resnet/client-api/api'

import { getSpecialValuesFieldProps } from '@resnet/client-shared/shared/forms/utils/get-special-values-field-props'

import { Field, FieldErrorText } from '@resnet/client-shared-web/shared/gdl/components/field'
import { FieldLabel } from '@resnet/client-shared-web/shared/gdl/components/field'
import { toPx } from '@resnet/client-shared-web/shared/gdl/utils/to-px'

import { EntitySelect } from '@resnet/client-web/shared/entities/selects/entity-select'

const entityTypeIdsAll = mapToValues(EntityTypeIdsT)

export type EntitySelectValueT = undefined | null | string

type EntitySelectFieldPropsT<
  TFieldValues extends FieldValues,
  TPath extends FieldPathByValue<TFieldValues, EntitySelectValueT>,
> = {
  defaultValue?: PathValue<TFieldValues, TPath>
  form: UseFormReturn<TFieldValues>
  headerRight?: React.ReactNode
  name: TPath
  label: string
  rules?: UseControllerProps<TFieldValues, TPath>['rules']
  entityTypeIds?: EntityTypeIdsT[]
}

export const EntitySelectField = <
  TFieldValues extends FieldValues,
  TPath extends FieldPathByValue<TFieldValues, EntitySelectValueT>,
>({
  form,
  name,
  label,
  rules,
  defaultValue,
  entityTypeIds = entityTypeIdsAll,
  headerRight,
}: EntitySelectFieldPropsT<TFieldValues, TPath>) => {
  const {
    field,
    fieldState: { error, invalid },
  } = useController({ control: form.control, defaultValue, name, rules })

  const renderHeader = () => {
    return (
      <Box sx={{ alignItems: 'center', display: 'flex', gap: toPx(16) }}>
        <FieldLabel>{label}</FieldLabel>
        {headerRight}
      </Box>
    )
  }

  const renderInput = () => {
    const specialValuesFieldProps = getSpecialValuesFieldProps(field)

    return (
      <EntitySelect
        categoryIds={entityTypeIds}
        hasError={invalid}
        {...field}
        {...specialValuesFieldProps}
        onChange={(value: PathValue<TFieldValues, TPath>) => {
          field.onChange(value)
        }}
      />
    )
  }

  const renderFooter = () => {
    if (!error) {
      return null
    }

    return <FieldErrorText>{error.message as string}</FieldErrorText>
  }

  return (
    <Field sx={{ flexGrow: 1, width: 0 }}>
      {renderHeader()}
      {renderInput()}
      {renderFooter()}
    </Field>
  )
}
