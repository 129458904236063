import type { CustomFieldScopeFragmentT } from '@resnet/client-api/api'

import type { FormCustomFieldValuesT } from '../types/form-custom-field-values'

import { mapCustomFieldScopeToPrefix } from './map-custom-field-scope-to-prefix'

export const pickScopeFieldValues = ({
  fieldValues: fieldValuesActual = {},
  scope,
}: {
  fieldValues: undefined | FormCustomFieldValuesT
  scope: undefined | null | CustomFieldScopeFragmentT
}) => {
  if (!scope) {
    return fieldValuesActual
  }

  const prefix = mapCustomFieldScopeToPrefix(scope)

  return Object.keys(fieldValuesActual).reduce<FormCustomFieldValuesT>((acc, key) => {
    if (key.startsWith(prefix)) {
      acc[key.slice(prefix.length + 1)] = fieldValuesActual[key]
    }

    return acc
  }, {})
}
