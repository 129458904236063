import type { FieldPathByValue, FieldValues, PathValue, UseControllerProps } from 'react-hook-form'
import { useController, type UseFormReturn } from 'react-hook-form'

import { useEventCallback } from '@resnet/client-common/react/hooks/use-event-callback'

import { Checkbox } from '@resnet/client-shared-web/shared/gdl/components/checkbox'
import { Label } from '@resnet/client-shared-web/shared/gdl/components/label'

export type CheckboxFieldValueT = undefined | boolean

export type CheckboxFieldPropsT<
  TFieldValues extends FieldValues,
  TPath extends FieldPathByValue<TFieldValues, CheckboxFieldValueT>,
> = {
  form: UseFormReturn<TFieldValues>
  name: TPath
  label?: string
  rules?: UseControllerProps<TFieldValues, TPath>['rules']
  defaultValue?: PathValue<TFieldValues, TPath>
}

export const CheckboxField = <
  TFieldValues extends FieldValues,
  TPath extends FieldPathByValue<TFieldValues, CheckboxFieldValueT>,
>({
  form,
  name,
  label,
  rules,
  defaultValue,
}: CheckboxFieldPropsT<TFieldValues, TPath>) => {
  const {
    field: { value: fieldValue, onChange: onFieldChange },
  } = useController({ control: form.control, defaultValue, name, rules })

  const onChange = useEventCallback((value: NonNullable<CheckboxFieldValueT>) => {
    onFieldChange(value as PathValue<TFieldValues, TPath>)
  })

  return (
    <Label
      description={label}
      descriptionPosition="end"
      size="md"
    >
      <Checkbox
        size="md"
        value={fieldValue}
        onChange={onChange}
      />
    </Label>
  )
}
