import { checkNonNullable } from '@resnet/client-common/common/utils/nullable/non-nullable'
import { assert } from '@resnet/client-common/typescript/utils/assert'

import { CustomFieldTypeT } from '@resnet/client-api/api'

import { FieldTypeIdT } from '@resnet/client-web/shared/fields/constants/field-types'

import type { CustomFieldT } from '../../types/custom-field'
import { mapCustomFieldToFullKey } from '../../utils/map-custom-field-to-key'

export const createField: CustomFieldT['createField'] = ({ field, fields, fieldValuesKey }) => {
  const formField = fields.find((item) => item.id === field.id)

  assert(formField, checkNonNullable)

  const payload = formField.payload

  assert(payload, checkNonNullable)

  const dropdownPayload = payload[CustomFieldTypeT.DropdownT]

  assert(dropdownPayload, checkNonNullable)

  return {
    id: mapCustomFieldToFullKey(field, fieldValuesKey),
    name: field.name,
    type: FieldTypeIdT.Dropdown,
  }
}
