import { useEffect, useState } from 'react'

import { createEmitter } from '@resnet/client-common/common/utils/emitter/create-emitter'
import { subscribeEvent } from '@resnet/client-common/common/utils/event/subscribe-event'
import { parse } from '@resnet/client-common/common/utils/json/parse'

import type { StateModelT } from '../types/state-model'
import { setStateReducer } from '../utils/set-state-reducer'

import { useEventCallback } from './use-event-callback'

// works like https://developer.mozilla.org/en-US/docs/Web/API/Window/storage_event but on the same page
const localStorageEmitter = createEmitter<{ key: string }>()

export const useLocalStorageState = <StateT>(key: string, defaultValue?: StateT): StateModelT<null | StateT> => {
  const defaultState: null | StateT = defaultValue ?? null

  const [state, setStateActual] = useState(() => parse<StateT>(localStorage.getItem(key)) ?? defaultState)

  const setState = useEventCallback((action: React.SetStateAction<typeof state>) => {
    const nextState = setStateReducer(state, action)

    localStorage.setItem(key, JSON.stringify(nextState))

    localStorageEmitter.dispatch({ key })
  })

  useEffect(
    () =>
      subscribeEvent(window, 'storage', (event: StorageEvent) => {
        if (event.key !== key) {
          return
        }

        setStateActual(parse<StateT>(localStorage.getItem(key)))
      }),
    [key],
  )

  useEffect(
    () =>
      localStorageEmitter.subscribe((event) => {
        if (event.key !== key) {
          return
        }

        setStateActual(parse<StateT>(localStorage.getItem(key)))
      }),
    [key],
  )

  return [state, setState]
}
