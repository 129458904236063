import { useMemo } from 'react'

import { useEventCallback } from '@resnet/client-common/react/hooks/use-event-callback'
import { createHookContainer } from '@resnet/client-common/react/utils/create-hook-container'

import type { MediaT } from '@resnet/client-shared/shared/gdl/types/media'

import type { AbstractOptionT } from '@resnet/client-shared-web/shared/gdl/types/abstract-option'

import { defaultNullOptionLabel, defaultNullOptionMedia, nullOption } from '../../select-dropdown/constants/null-option'
import { checkIsNullOption, checkIsNullOptionId } from '../../select-dropdown/utils/check-is-null-option'

export type UseSelectMultipleDropdownWithNullOptionPropsT<OptionT extends AbstractOptionT> = {
  enabled?: boolean
  getOptionLabel?: (option: OptionT) => React.ReactNode
  getOptionMedia?: (option: OptionT) => undefined | MediaT
  mergeNullWithEmpty?: boolean
  nullOptionLabel?: React.ReactNode
  nullOptionMedia?: MediaT
  onChange?: (value: (null | OptionT['id'])[], option?: OptionT) => void
  options?: OptionT[]
  value: null | (null | OptionT['id'])[]
}

const getOptionLabelDefault = <OptionT extends AbstractOptionT>(option: OptionT) => option.id

const optionsDefault: never[] = []

// is used in some cases, when there is an option to select empty value (ie null)
export const useSelectMultipleDropdownWithNullOption = <OptionT extends AbstractOptionT>({
  enabled = true,
  getOptionLabel: getOptionLabelActual = getOptionLabelDefault,
  getOptionMedia: getOptionMediaActual,
  mergeNullWithEmpty = true,
  nullOptionLabel = defaultNullOptionLabel,
  nullOptionMedia = defaultNullOptionMedia,
  onChange: onChangeActual,
  options: optionsActual = optionsDefault,
  value: valueActual,
}: UseSelectMultipleDropdownWithNullOptionPropsT<OptionT>) => {
  const options = useMemo(() => {
    const nextOptions = [!enabled ? [] : [nullOption], optionsActual].flat()

    if (mergeNullWithEmpty) {
      return nextOptions.filter((option) => option.id !== null && option.id?.trim() !== '')
    }

    return nextOptions
  }, [enabled, mergeNullWithEmpty, optionsActual])

  const getOptionLabel = useMemo(() => {
    return (option: (typeof options)[number]) =>
      checkIsNullOption(option) ? nullOptionLabel : getOptionLabelActual(option)
  }, [nullOptionLabel, getOptionLabelActual])

  const getOptionMedia = useMemo(() => {
    return (option: (typeof options)[number]): undefined | MediaT =>
      checkIsNullOption(option) ? nullOptionMedia : getOptionMediaActual?.(option)
  }, [nullOptionMedia, getOptionMediaActual])

  const value = useMemo(
    () => (valueActual ?? []).map((optionId) => (optionId === null ? nullOption.id : optionId)),
    [valueActual],
  )

  const onChange = useEventCallback((value: (typeof options)[number]['id'][]) => {
    onChangeActual?.(value.map((optionId) => (checkIsNullOptionId(optionId) ? null : optionId)))
  })

  return { getOptionLabel, getOptionMedia, onChange, options, value }
}

export const SelectMultipleDropdownWithNullOptionContainer = createHookContainer(
  useSelectMultipleDropdownWithNullOption,
)
