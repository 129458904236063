import { Box } from '@mui/material'
import type { FieldPathByValue, FieldValues, UseFormReturn } from 'react-hook-form'

import type { CustomFieldPayloadAttachmentT } from '@resnet/client-api/api'
import { CustomFieldTypeT, type CustomFieldFragmentT } from '@resnet/client-api/api'

import { SwitchField } from '@resnet/client-shared-web/shared/form/components/common/switch-field'
import { toPx } from '@resnet/client-shared-web/shared/gdl/utils/to-px'

export type AttachmentCustomFieldPayloadValueT = CustomFieldFragmentT

export type AttachmentCustomFieldPayloadPropsT<
  TFieldValues extends FieldValues,
  TPath extends FieldPathByValue<TFieldValues, AttachmentCustomFieldPayloadValueT>,
> = {
  form: UseFormReturn<TFieldValues>
  name: TPath
  isDraft: boolean
}

export const AttachmentCustomFieldPayload = <
  TFieldValues extends FieldValues,
  TPath extends FieldPathByValue<TFieldValues, AttachmentCustomFieldPayloadValueT>,
>({
  form,
  name,
  isDraft,
}: AttachmentCustomFieldPayloadPropsT<TFieldValues, TPath>) => {
  type AttachmentPayloadPathT = FieldPathByValue<TFieldValues, undefined | null | CustomFieldPayloadAttachmentT>

  const attachmentPayloadName = `${name}.payload.${CustomFieldTypeT.AttachmentT}` as AttachmentPayloadPathT

  type RequiredPathT = FieldPathByValue<TFieldValues, undefined | null | CustomFieldPayloadAttachmentT['required']>

  const requiredName = `${attachmentPayloadName}.required` as RequiredPathT

  type MultiplePathT = FieldPathByValue<TFieldValues, undefined | null | CustomFieldPayloadAttachmentT['multiple']>

  const multipleName = `${attachmentPayloadName}.multiple` as MultiplePathT

  const renderRequiredField = () => {
    return (
      <SwitchField
        form={form}
        label="Required"
        name={requiredName}
      />
    )
  }

  const renderMultipleField = () => {
    return (
      <SwitchField
        disabled={!isDraft}
        disabledTooltipTitle="Multiple cannot be changed after creation"
        form={form}
        label="Multiple"
        name={multipleName}
      />
    )
  }

  const renderSwitchFields = () => {
    return (
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: toPx(8) }}>
        {renderRequiredField()}
        {renderMultipleField()}
      </Box>
    )
  }

  return <>{renderSwitchFields()}</>
}
