import { renderDateFieldPreviewData } from '@resnet/client-shared-web/shared/gdl/components/field-preview'

import type { RegularCellPropsT } from '../regular-cell'
import { RegularCell } from '../regular-cell'

export type DateCellValueT = undefined | null | string

export type DateCellPropsT = RegularCellPropsT & {
  value: undefined | null | string
}

export const DateCell = ({ value, ...props }: DateCellPropsT) => {
  const renderContent = () => {
    if (value === undefined || value === null) {
      return '-'
    }

    return renderDateFieldPreviewData(value)
  }

  return <RegularCell {...props}>{renderContent()}</RegularCell>
}
