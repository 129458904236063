import type { BoxProps } from '@mui/material'
import { Box } from '@mui/material'

import type { ForwardableT } from '@resnet/client-common/react/types/forwardable'
import { foldElement } from '@resnet/client-common/react/utils/fold-element'
import { renderForwardable } from '@resnet/client-common/react/utils/render-forwardable'
import type { MergeT } from '@resnet/client-common/typescript/types/merge'

import { typographyPresets } from '@resnet/client-shared/shared/gdl/constants/typography-presets'

import { themeColors } from '@resnet/client-shared-web/shared/gdl/constants/theme-colors'
import { mapTypographyPresetToSx } from '@resnet/client-shared-web/shared/gdl/utils/map-typography-preset-to-sx'
import { toPx } from '@resnet/client-shared-web/shared/gdl/utils/to-px'

export type EmptyPanelIconPropsT = {
  children: ForwardableT<React.SVGProps<SVGSVGElement>>
}

export const EmptyPanelIcon = ({ children }: EmptyPanelIconPropsT): React.ReactElement => {
  const iconSize = 48

  return (
    <>
      {renderForwardable(children, {
        fill: themeColors.overBackgroundDefault,
        height: iconSize,
        width: iconSize,
      })}
    </>
  )
}

export type EmptyPanelTitlePropsT = BoxProps

export const EmptyPanelTitle = ({ sx, children, ...props }: EmptyPanelTitlePropsT): React.ReactElement => {
  return (
    <Box
      {...props}
      sx={[
        mapTypographyPresetToSx(typographyPresets.titleLarge),
        { color: themeColors.overBackgroundBold, textAlign: 'center' },
        sx ?? null,
      ].flat()}
    >
      {children}
    </Box>
  )
}

export type EmptyPanelDescriptionPropsT = BoxProps

export const EmptyPanelDescription = ({ sx, children, ...props }: EmptyPanelDescriptionPropsT): React.ReactElement => {
  return (
    <Box
      {...props}
      sx={[
        mapTypographyPresetToSx(typographyPresets.bodyMedium),
        { color: themeColors.overBackgroundDefault, textAlign: 'center' },
        sx ?? null,
      ].flat()}
    >
      {children}
    </Box>
  )
}

export type EmptyPanelLayoutPropsT = MergeT<
  BoxProps,
  {
    children?: {
      icon?: React.ReactNode
      title?: React.ReactNode
      description?: React.ReactNode
    }
  }
>

export const EmptyPanelLayout = ({ sx, children, ...props }: EmptyPanelLayoutPropsT): React.ReactElement => {
  const borderWidth = 1

  const renderIcon = (): React.ReactNode => {
    return foldElement(
      <Box sx={{ alignItems: 'center', display: 'flex', flexDirection: 'column' }}>{children?.icon}</Box>,
    )
  }

  const renderTitleAndDescription = () => {
    return foldElement(
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: toPx(8) }}>
        {children?.title}
        {children?.description}
      </Box>,
    )
  }

  return (
    <Box
      {...props}
      sx={[
        {
          backgroundColor: themeColors.surfaceNeutralDefault,
          borderColor: themeColors.borderDefault,
          borderRadius: toPx(16),
          borderStyle: 'solid',
          borderWidth: toPx(borderWidth),
          display: 'flex',
          flexDirection: 'column',
          flexGrow: 1,
          padding: toPx(16 - borderWidth),
        },
        sx ?? null,
      ].flat()}
    >
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: toPx(32), my: 'auto' }}>
        {renderIcon()}
        {renderTitleAndDescription()}
      </Box>
    </Box>
  )
}

export type EmptyPanelPropsT = MergeT<
  EmptyPanelLayoutPropsT,
  {
    children?: {
      icon?: ForwardableT<React.SVGProps<SVGSVGElement>>
      title?: React.ReactNode
      description?: React.ReactNode
    }
  }
>

export const EmptyPanel = ({ children, ...props }: EmptyPanelPropsT) => {
  const renderTitle = () => {
    const title = children?.title

    if (!title) {
      return null
    }

    return <EmptyPanelTitle>{title}</EmptyPanelTitle>
  }

  const renderDescription = () => {
    const description = children?.description

    if (!description) {
      return null
    }

    return <EmptyPanelDescription>{description}</EmptyPanelDescription>
  }

  const renderIcon = () => {
    const icon = children?.icon

    if (!icon) {
      return null
    }

    return <EmptyPanelIcon>{icon}</EmptyPanelIcon>
  }

  return (
    <EmptyPanelLayout {...props}>
      {{ description: renderDescription(), icon: renderIcon(), title: renderTitle() }}
    </EmptyPanelLayout>
  )
}
