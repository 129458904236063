import { Box } from '@mui/material'

import { ChildrenDivider } from '@resnet/client-common/react/components/children-transformer'

import { Divider } from '@resnet/client-shared-web/shared/gdl/components/divider'
import { toPx } from '@resnet/client-shared-web/shared/gdl/utils/to-px'

export const ObjectDetailsContent = ({ children }: { children: React.ReactNode }) => {
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', flexGrow: 1, gap: toPx(16) }}>
      <ChildrenDivider dividerNode={<Divider />}>{children}</ChildrenDivider>
    </Box>
  )
}
