import type { Draft } from 'immer'
import { produce } from 'immer'

import type { GetOrganizationQueryT, OrganizationFragmentT } from '@resnet/client-api/api'
import {
  useGetOrganizationQuery,
  useRemoveOrganizationUnusedSeatsMutation,
  useSetOrganizationSeatsMutation,
  useUpdateOrganizationMutation,
} from '@resnet/client-api/api'
import { createMutationHookMutationCacheHandlers } from '@resnet/client-api/services/query-client/utils/create-mutation-hook-mutation-cache-handlers'
import { createQueriesHandlers } from '@resnet/client-api/utils/create-queries-handlers'
import { findQueries } from '@resnet/client-api/utils/find-queries'
import { invalidateQuery } from '@resnet/client-api/utils/invalidate-query'
import { setQueryData } from '@resnet/client-api/utils/set-query-data'

export const createGetOrganizationQueriesHandlers = createQueriesHandlers(({ queryClient }) => {
  const findGetOrganizationQueries = () => {
    return findQueries(queryClient, useGetOrganizationQuery)
  }

  const updateOrganizationRecipe = ({
    data,
    input,
  }: {
    data: Draft<undefined | GetOrganizationQueryT>
    input: Partial<OrganizationFragmentT>
  }) => {
    if (!data) {
      return
    }

    const organization = data.getOrganization

    Object.assign(organization, input)
  }

  const createUpdateOrganizationHandlers = () => {
    return createMutationHookMutationCacheHandlers(useUpdateOrganizationMutation, {
      onError: () => {
        findGetOrganizationQueries().forEach(invalidateQuery(queryClient))
      },
      onMutate: ({ data: input }) => {
        findGetOrganizationQueries().forEach((query) => {
          setQueryData(queryClient)(query)(
            produce((data) => {
              updateOrganizationRecipe({ data, input })
            }),
          )
        })
      },
      onSuccess: (data) => {
        findGetOrganizationQueries().forEach((query) => {
          setQueryData(queryClient)(query)(() => ({
            getOrganization: data.updateOrganization,
          }))
        })
      },
    })
  }

  const createSetOrganizationSeatsHandlers = () => {
    return createMutationHookMutationCacheHandlers(useSetOrganizationSeatsMutation, {
      onError: () => {
        findGetOrganizationQueries().forEach(invalidateQuery(queryClient))
      },
      onMutate: ({ seats }) => {
        findGetOrganizationQueries().forEach((query) => {
          setQueryData(queryClient)(query)(
            produce((data) => {
              updateOrganizationRecipe({ data, input: { totalSeats: seats } })
            }),
          )
        })
      },
      onSuccess: () => {
        findGetOrganizationQueries().forEach(invalidateQuery(queryClient))
      },
    })
  }

  const createRemoveOrganizationUnusedSeatsHandlers = () => {
    return createMutationHookMutationCacheHandlers(useRemoveOrganizationUnusedSeatsMutation, {
      onError: () => {
        findGetOrganizationQueries().forEach(invalidateQuery(queryClient))
      },
      onSuccess: () => {
        findGetOrganizationQueries().forEach(invalidateQuery(queryClient))
      },
    })
  }

  return [
    createUpdateOrganizationHandlers(),
    createSetOrganizationSeatsHandlers(),
    createRemoveOrganizationUnusedSeatsHandlers(),
  ]
})
