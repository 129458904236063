import DocViewer, { DocViewerRenderers } from '@cyntler/react-doc-viewer'
import { Box, useEventCallback } from '@mui/material'
import type { MouseEvent as SyntheticMouseEvent } from 'react'
import { memo, useContext, useEffect, useState } from 'react'

import '@cyntler/react-doc-viewer/dist/index.css'

import 'react-pdf/dist/Page/AnnotationLayer.css'
import 'react-pdf/dist/Page/TextLayer.css'

import { subscribeEvent } from '@resnet/client-common/common/utils/event/subscribe-event'
import { assertedNonNullable } from '@resnet/client-common/common/utils/nullable/non-nullable'

import { ResourceRefTypesT, ResourceTypesT } from '@resnet/client-api/api'

import ChevronLeftSolidIcon from '@resnet/client-shared/assets/icons/chevron-left-solid.svg'
import ChevronRightSolidIcon from '@resnet/client-shared/assets/icons/chevron-right-solid.svg'
import DownloadSolidIcon from '@resnet/client-shared/assets/icons/download-solid.svg'
import LinkSolidIcon from '@resnet/client-shared/assets/icons/link-solid.svg'
import ZoomInSolidIcon from '@resnet/client-shared/assets/icons/zoom-in-solid.svg'
import ZoomOutSolidIcon from '@resnet/client-shared/assets/icons/zoom-out-solid.svg'
import { useTheme } from '@resnet/client-shared/shared/gdl/contexts/theme-context'
import { mapMimetypeToType } from '@resnet/client-shared/shared/resources/utils/map-mimetype-to-type'
import { useProfileId } from '@resnet/client-shared/shared/users/hooks/use-profile-id'

import type { ResourceT } from '@resnet/client-shared-web/shared/files/types/resource'
import { Button } from '@resnet/client-shared-web/shared/gdl/components/button'
import { createModalContainer, ModalContext } from '@resnet/client-shared-web/shared/gdl/components/modal'
import { createModalHook } from '@resnet/client-shared-web/shared/gdl/components/modal'
import { ModalContent } from '@resnet/client-shared-web/shared/gdl/components/modal'
import { toPx } from '@resnet/client-shared-web/shared/gdl/utils/to-px'
import { ImageResource } from '@resnet/client-shared-web/shared/resources/components/image-resource'
import { sketchSx } from '@resnet/client-shared-web/shared/sketch/styles/sketch-sx'

import { AttachmentDeleteConfirmationModalContainer } from '../attachment-delete-confirmation-modal'
import { VideoResource } from '../video-resource'

const useAltPressed = () => {
  const [isAltPressed, setIsAltPressed] = useState(false)

  useEffect(
    () =>
      subscribeEvent<KeyboardEvent>(document, 'keydown', (event) => {
        if (event.key !== 'Alt') {
          return
        }

        setIsAltPressed(true)
      }),
    [],
  )

  useEffect(
    () =>
      subscribeEvent<KeyboardEvent>(document, 'keyup', (event) => {
        if (event.key !== 'Alt') {
          return
        }

        setIsAltPressed(false)
      }),
    [],
  )

  return isAltPressed
}

type PreviewRendererPropsT = {
  attachment: ResourceT
  children: (props: { content?: React.ReactNode; controls?: React.ReactNode }) => React.ReactElement
}

const DefaultPreviewRenderer = ({ children }: PreviewRendererPropsT): React.ReactElement => {
  const content = (
    <Box
      alignItems="center"
      display="flex"
      height="100%"
      justifyContent="center"
      width="100%"
    >
      <Box
        sx={{
          fontSize: '32px',
          lineHeight: '32px',
        }}
      >
        Preview is not available
      </Box>
    </Box>
  )

  return children({ content })
}

// NOTE looks like consequent renders can lead to failing to render the document
const DocsViewerPreviewRenderer = memo(
  ({ attachment, children }: PreviewRendererPropsT): React.ReactElement => {
    const docs = [{ uri: attachment.url }]

    const content = (
      <DocViewer
        config={{
          header: {
            disableFileName: true,
            disableHeader: true,
          },
        }}
        documents={docs}
        pluginRenderers={DocViewerRenderers}
        style={{ overflow: 'scroll' }}
      />
    )

    return children({ content })
  },
  (prevProps, nextProps) => prevProps.attachment.url === nextProps.attachment.url,
)

const SCALE_FACTOR_STEP = 0.5
const MIN_SCALE_FACTOR = 0.5
const MAX_SCALE_FACTOR = 4

const ImagePreviewRenderer = ({ attachment, children }: PreviewRendererPropsT): React.ReactElement => {
  const theme = useTheme()

  const [scaleFactor, setScaleFactor] = useState(1)

  const isAltPressed = useAltPressed()

  const isZoomInDisabled = scaleFactor >= MAX_SCALE_FACTOR

  const zoomIn = useEventCallback(() => {
    setScaleFactor((current) => current + SCALE_FACTOR_STEP)
  })

  const onZoomInClick = useEventCallback((event: SyntheticMouseEvent<HTMLElement>) => {
    const target = event.currentTarget

    const { x, y } = target.getBoundingClientRect()

    requestAnimationFrame(() => {
      target.scroll({
        left: target.scrollLeft + ((target.scrollLeft + event.clientX - x) / scaleFactor) * SCALE_FACTOR_STEP,
        top: target.scrollTop + ((target.scrollTop + event.clientY - y) / scaleFactor) * SCALE_FACTOR_STEP,
      })
    })

    zoomIn()
  })

  const isZoomOutDisabled = scaleFactor <= MIN_SCALE_FACTOR

  const zoomOut = useEventCallback(() => {
    setScaleFactor((current) => current - SCALE_FACTOR_STEP)
  })

  const onZoomOutClick = useEventCallback((event: SyntheticMouseEvent<HTMLElement>) => {
    const target = event.currentTarget

    const { x, y } = target.getBoundingClientRect()

    requestAnimationFrame(() => {
      target.scroll({
        left: target.scrollLeft - ((target.scrollLeft + event.clientX - x) / scaleFactor) * SCALE_FACTOR_STEP,
        top: target.scrollTop - ((target.scrollTop + event.clientY - y) / scaleFactor) * SCALE_FACTOR_STEP,
      })
    })

    zoomOut()
  })

  const content = (
    <Box
      sx={[
        { display: 'flex', height: '100%', overflow: 'auto', width: '100%' },
        !isZoomInDisabled && !isAltPressed ? { cursor: 'zoom-in' } : !isZoomOutDisabled ? { cursor: 'zoom-out' } : {},
      ]}
      onClick={!isZoomInDisabled && !isAltPressed ? onZoomInClick : !isZoomOutDisabled ? onZoomOutClick : undefined}
    >
      <ImageResource
        resource={attachment}
        sx={[
          {
            flexShrink: 0,
            margin: 'auto',
            userSelect: 'none',
          },
          attachment.resourceType !== ResourceTypesT.SketchT ? null : sketchSx({ theme }),
        ]}
        width={`${100 * scaleFactor}%`}
      />
    </Box>
  )

  const controls = (
    <>
      <Button
        color="primary"
        disabled={isZoomInDisabled}
        icon={<ZoomInSolidIcon />}
        size="md"
        variant="contained"
        onClick={zoomIn}
      >
        Zoom In
      </Button>
      <Button
        color="primary"
        disabled={isZoomOutDisabled}
        icon={<ZoomOutSolidIcon />}
        size="md"
        variant="contained"
        onClick={zoomOut}
      >
        Zoom Out
      </Button>
    </>
  )

  return children({ content, controls })
}

const VideoPreviewRenderer = ({ attachment, children }: PreviewRendererPropsT): React.ReactElement => {
  const content = (
    <Box
      display="flex"
      height="100%"
      sx={{ overflow: 'auto' }}
      width="100%"
    >
      <VideoResource
        controls
        resource={attachment}
        style={{ width: '100%' }}
      />
    </Box>
  )

  return children({ content })
}

const PREVIEW_RENDERER_BY_TYPE: Record<
  ReturnType<typeof mapMimetypeToType>,
  React.FunctionComponent<PreviewRendererPropsT>
> = {
  image: ImagePreviewRenderer,
  pdf: DocsViewerPreviewRenderer,
  unknown: DefaultPreviewRenderer,
  video: VideoPreviewRenderer,
  word: DocsViewerPreviewRenderer,
}

export const AttachmentPreviewModal = ({
  attachment,
  hasNext = false,
  hasPrev = false,
  onOpenNext,
  onOpenPrev,
  isDeleteButtonVisible = true,
}: {
  attachment: ResourceT
  hasNext?: boolean
  hasPrev?: boolean
  onOpenNext?: () => void
  onOpenPrev?: () => void
  isDeleteButtonVisible?: boolean
}): React.ReactElement => {
  const { onClose } = assertedNonNullable(useContext(ModalContext))

  const { profileId } = useProfileId()

  const PreviewRenderer = PREVIEW_RENDERER_BY_TYPE[mapMimetypeToType(attachment.mimetype)]

  useEffect(
    () =>
      subscribeEvent<KeyboardEvent>(document, 'keydown', (event) => {
        switch (event.key) {
          case 'ArrowLeft': {
            if (!hasPrev) {
              break
            }

            onOpenPrev?.()
            break
          }
          case 'ArrowRight': {
            if (!hasNext) {
              break
            }

            onOpenNext?.()
            break
          }
        }
      }),
    [hasNext, hasPrev, onOpenNext, onOpenPrev],
  )

  const renderDeleteButton = () => {
    if (
      attachment.__typename === '@DraftResource' ||
      profileId !== attachment.userId ||
      attachment.refType !== ResourceRefTypesT.OriginT ||
      !isDeleteButtonVisible
    ) {
      return null
    }

    return (
      <AttachmentDeleteConfirmationModalContainer>
        {({ onOpen }) => (
          <Button
            color="danger"
            size="md"
            variant="outlined"
            onClick={() => {
              onOpen({
                attachment,
                onDeleteSuccess: () => {
                  onClose?.()
                },
              })
            }}
          >
            Delete
          </Button>
        )}
      </AttachmentDeleteConfirmationModalContainer>
    )
  }

  return (
    <PreviewRenderer attachment={attachment}>
      {({ content, controls }) => {
        const renderHeaderTitle = (): React.ReactNode => {
          return 'Preview'
        }

        const renderHeaderSubtitle = (): React.ReactNode => {
          return attachment.filename
        }

        const renderHeader = () => {
          return {
            subtitle: renderHeaderSubtitle(),
            title: renderHeaderTitle(),
          }
        }

        const renderContentPrev = (): React.ReactNode => {
          if (!hasPrev) {
            return null
          }

          return (
            <Box
              sx={{
                alignItems: 'center',
                bottom: 0,
                display: 'flex',
                left: 0,
                padding: toPx(8),
                pointerEvents: 'none',
                position: 'absolute',
                top: 0,
              }}
            >
              <Box sx={{ pointerEvents: 'auto' }}>
                <Button
                  color="default"
                  icon={<ChevronLeftSolidIcon />}
                  size="sm"
                  variant="contained"
                  onClick={onOpenPrev}
                />
              </Box>
            </Box>
          )
        }

        const renderContentNext = (): React.ReactNode => {
          if (!hasNext) {
            return null
          }

          return (
            <Box
              sx={{
                alignItems: 'center',
                bottom: 0,
                display: 'flex',
                padding: toPx(8),
                pointerEvents: 'none',
                position: 'absolute',
                right: 0,
                top: 0,
              }}
            >
              <Box sx={{ pointerEvents: 'auto' }}>
                <Button
                  color="default"
                  icon={<ChevronRightSolidIcon />}
                  size="sm"
                  variant="contained"
                  onClick={onOpenNext}
                />
              </Box>
            </Box>
          )
        }

        const renderContent = (): React.ReactNode => {
          return (
            <Box sx={{ flexGrow: 1, height: 0, position: 'relative' }}>
              {renderContentPrev()}
              {content}
              {renderContentNext()}
            </Box>
          )
        }

        const renderFooter = (): React.ReactNode => {
          return (
            <Box sx={{ display: 'flex', gap: '10px', justifyContent: 'center' }}>
              <Button
                color="primary"
                icon={<LinkSolidIcon />}
                size="md"
                variant="outlined"
                onClick={() => {
                  navigator.clipboard.writeText(attachment.url)
                }}
              >
                Copy Link
              </Button>
              {controls}
              <Button
                color="primary"
                download={attachment.filename}
                href={attachment.url}
                icon={<DownloadSolidIcon />}
                size="md"
                type="anchor"
                variant="outlined"
              >
                Download
              </Button>
              {renderDeleteButton()}
            </Box>
          )
        }

        return (
          <ModalContent sx={{ height: '100%', width: '100%' }}>
            {{
              content: renderContent(),
              footer: renderFooter(),
              header: renderHeader(),
            }}
          </ModalContent>
        )
      }}
    </PreviewRenderer>
  )
}

export const useAttachmentPreviewModal = createModalHook(
  (params: React.ComponentProps<typeof AttachmentPreviewModal>) => <AttachmentPreviewModal {...params} />,
)

export const AttachmentPreviewModalContainer = createModalContainer(useAttachmentPreviewModal)
