import { Drawer } from '@resnet/client-shared-web/shared/gdl/components/drawer'

import { FormObjectsDrawerContent } from '@resnet/client-web/shared/form-objects/components/form-objects-drawer-content/index.lazy'
import { FormObjectsDrawerContainer } from '@resnet/client-web/shared/form-objects/hooks/use-form-objects-drawer'
import { CreateIssueDrawerContent } from '@resnet/client-web/shared/issues/components/create-issue-drawer-content/index.lazy'
import { CreateIssueDrawerContainer } from '@resnet/client-web/shared/issues/hooks/use-create-issue-drawer'
import { NotificationsDrawerContent } from '@resnet/client-web/shared/notifications/components/notifications-drawer-content/index.lazy'
import { NotificationsDrawerContainer } from '@resnet/client-web/shared/notifications/hooks/use-notifications-drawer'
import { SettingsDrawerContent } from '@resnet/client-web/shared/settings/components/settings-drawer-content/index.lazy'
import { SettingsDrawerContainer } from '@resnet/client-web/shared/settings/hooks/use-settings-drawer'

export const AuthorizedDrawers = () => {
  const renderFormObjectsDrawer = () => {
    return (
      <FormObjectsDrawerContainer>
        {({ opened, onClose }) => (
          <Drawer
            isOpened={opened}
            onClose={onClose}
          >
            <FormObjectsDrawerContent />
          </Drawer>
        )}
      </FormObjectsDrawerContainer>
    )
  }

  const renderCreateIssueDrawer = () => {
    return (
      <CreateIssueDrawerContainer>
        {({ opened, onClose }) => (
          <Drawer
            isOpened={opened}
            onClose={onClose}
          >
            <CreateIssueDrawerContent onSuccess={onClose} />
          </Drawer>
        )}
      </CreateIssueDrawerContainer>
    )
  }

  const renderNotificationsDrawer = () => {
    return (
      <NotificationsDrawerContainer>
        {({ opened, onClose }) => (
          <Drawer
            isOpened={opened}
            onClose={onClose}
          >
            <NotificationsDrawerContent />
          </Drawer>
        )}
      </NotificationsDrawerContainer>
    )
  }

  const renderSettingsDrawer = () => {
    return (
      <SettingsDrawerContainer>
        {({ opened, onClose }) => (
          <Drawer
            isOpened={opened}
            onClose={onClose}
          >
            <SettingsDrawerContent />
          </Drawer>
        )}
      </SettingsDrawerContainer>
    )
  }

  return (
    <>
      {renderFormObjectsDrawer()}
      {renderCreateIssueDrawer()}
      {renderNotificationsDrawer()}
      {renderSettingsDrawer()}
    </>
  )
}
