import { Tooltip } from '@mui/material'

import { pluralize } from '@resnet/client-common/common/utils/string/pluralize'
import { forwardFunctionalComponentRef } from '@resnet/client-common/react/utils/forward-functional-component-ref'

import type { ButtonPropsT, ButtonRefT } from '../button'
import { Button } from '../button'

export type SelectMultipleButtonPropsT = {
  label: string
  itemIcon?: ButtonPropsT['icon']
  buttonIcon?: ButtonPropsT['icon']
  buttonSize: ButtonPropsT['size']
  items?: null | string[]
  onClick?: () => void
  itemName: string
  'data-testid'?: string
}

export type SelectMultipleButtonRefT = ButtonRefT

export const SelectMultipleButton = forwardFunctionalComponentRef(
  (
    { items, buttonSize, onClick, buttonIcon, itemIcon, label, itemName, ...props }: SelectMultipleButtonPropsT,
    ref: SelectMultipleButtonRefT,
  ) => {
    const renderContent = () => {
      if (!items || items.length === 0) {
        return label
      }

      if (items.length === 1) {
        return items[0]
      }

      return `${items.length} ${pluralize(items.length, itemName)}`
    }

    return (
      <Tooltip title={items?.join(', ')}>
        <Button
          {...props}
          color="primary"
          icon={!items || items.length === 0 ? buttonIcon : itemIcon}
          ref={ref}
          size={buttonSize}
          variant="outlined"
          onClick={onClick}
        >
          {renderContent()}
        </Button>
      </Tooltip>
    )
  },
)
