import { Box } from '@mui/material'

import { NonNullableContextContainer } from '@resnet/client-common/react/hooks/use-non-nullable-context'

import type { ApprovalTemplatePresetFragmentT } from '@resnet/client-api/api'

import DownloadSolidIcon from '@resnet/client-shared/assets/icons/download-solid.svg'
import SaveSolidIcon from '@resnet/client-shared/assets/icons/save-solid.svg'

import { Button } from '@resnet/client-shared-web/shared/gdl/components/button'
import { Popper, PopperContext } from '@resnet/client-shared-web/shared/gdl/components/popper'
import { toPx } from '@resnet/client-shared-web/shared/gdl/utils/to-px'

import { ApprovalTemplatePresetsPopover } from '@resnet/client-web/shared/presets/components/approval-template-presets-popover'
import { ApprovalTemplatePresetsSaveAsNewModalContainer } from '@resnet/client-web/shared/presets/components/approval-template-presets-save-as-new-modal'

import type { IssueApprovalLevelT } from '../issue-approval/types'

type IssueApprovalTemplatePresetControlsPropsT = {
  approvalLevels: IssueApprovalLevelT[]
  onApplyApprovalTemplatePreset: (approvalTemplatePreset: ApprovalTemplatePresetFragmentT) => void
}

export const IssueApprovalTemplatePresetControls = ({
  approvalLevels,
  onApplyApprovalTemplatePreset,
}: IssueApprovalTemplatePresetControlsPropsT) => {
  const renderAnchor = () => {
    return (
      <Box sx={{ display: 'flex', gap: toPx(16) }}>
        <NonNullableContextContainer Context={PopperContext}>
          {({ setAnchorEl, open }) => (
            <Button
              color="primary"
              icon={<DownloadSolidIcon />}
              ref={setAnchorEl}
              size="md"
              sx={{
                flexGrow: 1,
                width: 0,
              }}
              variant="outlined"
              onClick={open}
            >
              Load Approval Preset
            </Button>
          )}
        </NonNullableContextContainer>
        <ApprovalTemplatePresetsSaveAsNewModalContainer>
          {({ onOpen }) => (
            <Button
              color="default"
              icon={<SaveSolidIcon />}
              size="md"
              variant="contained"
              onClick={() => {
                onOpen({
                  approvalTemplatePresetLevels: approvalLevels.map((level) => ({
                    approvers: level.approvers.map((approver) => approver.id),
                    id: level.id,
                    requiredApprovals: level.requiredApprovals,
                  })),
                })
              }}
            />
          )}
        </ApprovalTemplatePresetsSaveAsNewModalContainer>
      </Box>
    )
  }

  const renderContent = (): React.ReactElement => {
    return <ApprovalTemplatePresetsPopover onSelect={onApplyApprovalTemplatePreset} />
  }

  return (
    <Popper placement="left">
      {{
        anchor: renderAnchor,
        content: renderContent,
      }}
    </Popper>
  )
}
