import { checkNonNullable } from '@resnet/client-common/common/utils/nullable/non-nullable'
import { assert } from '@resnet/client-common/typescript/utils/assert'

import { CustomFieldTypeT } from '@resnet/client-api/api'

import { FieldTypeIdT } from '@resnet/client-web/shared/fields/constants/field-types'

import type { CustomFieldT } from '../../types/custom-field'
import { mapCustomFieldToFullKey } from '../../utils/map-custom-field-to-key'

export const createField: CustomFieldT['createField'] = ({ field, fieldValuesKey }) => {
  const payload = field.payload

  assert(payload, checkNonNullable)

  const datePayload = payload[CustomFieldTypeT.DateT]

  assert(datePayload, checkNonNullable)

  const { addTimeEnabled } = datePayload

  return {
    id: mapCustomFieldToFullKey(field, fieldValuesKey),
    name: field.name,
    type: addTimeEnabled ? FieldTypeIdT.DateTime : FieldTypeIdT.Date,
  }
}
