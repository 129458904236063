import type { Theme } from '@emotion/react'
import type { SxProps } from '@mui/material'
import { Box } from '@mui/material'
import { useMemo } from 'react'

import { NonNullableContextContainer } from '@resnet/client-common/react/hooks/use-non-nullable-context'
import type { MergeT } from '@resnet/client-common/typescript/types/merge'

import type { MediaT } from '@resnet/client-shared/shared/gdl/types/media'

import { DropdownInput, DropdownInputMedia } from '@resnet/client-shared-web/shared/gdl/components/dropdown-input'
import { Popover } from '@resnet/client-shared-web/shared/gdl/components/popover'
import type { PopperPropsT } from '@resnet/client-shared-web/shared/gdl/components/popper'
import { Popper, PopperContext } from '@resnet/client-shared-web/shared/gdl/components/popper'
import { SelectDropdown } from '@resnet/client-shared-web/shared/gdl/components/select-dropdown'
import { StaticOptionsDropdownText } from '@resnet/client-shared-web/shared/gdl/components/static-options-dropdown-text'
import type { AbstractSimpleOptionT } from '@resnet/client-shared-web/shared/gdl/types/abstract-option'
import { getSimpleOptionLabel } from '@resnet/client-shared-web/shared/gdl/utils/get-simple-option-label'

export type SimpleStaticOptionsDropdownPropsT<OptionT extends AbstractSimpleOptionT> = MergeT<
  Omit<PopperPropsT, 'children'>,
  {
    tooltip?: React.ReactNode
    isBusy?: boolean
    disabled?: boolean
    disabledInputTooltipTitle?: string
    disablePortal?: boolean
    dropdownHeader?: React.ReactNode
    getOptionDisabled?: (option: OptionT) => boolean
    getOptionLabel?: (option: OptionT) => string
    getOptionMedia?: (option: OptionT) => undefined | MediaT
    getOptionTooltip?: (option: OptionT) => undefined | string
    hasError?: boolean
    onChange: (value: undefined | null | OptionT['id']) => void
    options: OptionT[]
    placement?: React.ComponentProps<typeof Popper>['placement']
    value: undefined | null | OptionT['id']
    anchorSx?: SxProps<Theme>
    size?: 'sm' | 'md'
    placeholder?: string
  }
>

export const SimpleStaticOptionsDropdown = <OptionT extends AbstractSimpleOptionT>({
  tooltip,
  anchorSx,
  disabled,
  disabledInputTooltipTitle,
  disablePortal,
  dropdownHeader,
  getOptionDisabled,
  getOptionLabel = getSimpleOptionLabel,
  getOptionMedia: getOptionMediaActual,
  getOptionTooltip,
  hasError,
  onChange,
  options,
  placement,
  popperRef,
  value,
  size,
  isBusy,
  placeholder = 'Not Selected',
}: SimpleStaticOptionsDropdownPropsT<OptionT>) => {
  const optionsById = useMemo(() => new Map(options.map((option) => [option.id, option])), [options])

  const getOptionMedia = (option: OptionT) => {
    if (getOptionMediaActual) {
      return getOptionMediaActual(option)
    }

    return option.media
  }

  const renderAnchor = (): React.ReactElement => {
    const renderText = () => (
      <StaticOptionsDropdownText
        getOptionLabel={getOptionLabel}
        options={options}
        placeholder={placeholder}
        size={size}
        value={value}
      />
    )

    const renderMedia = () => {
      if (!value) {
        return null
      }

      const option = optionsById.get(value)

      if (!option) {
        return null
      }

      const media = getOptionMedia(option)

      if (!media) {
        return null
      }

      return (
        <DropdownInputMedia
          media={media}
          size={size}
          sx={{ alignItems: 'center', display: 'flex' }}
        />
      )
    }

    return (
      <NonNullableContextContainer Context={PopperContext}>
        {({ setAnchorEl, isOpened, open }) => (
          <DropdownInput
            disabled={disabled}
            disabledTooltipTitle={disabledInputTooltipTitle}
            hasError={hasError}
            isOpened={isOpened}
            ref={setAnchorEl}
            size={size}
            sx={anchorSx}
            tooltip={isOpened ? undefined : tooltip}
            onClick={open}
          >
            <Box sx={{ alignItems: 'center', display: 'flex', gap: '8px' }}>
              {renderMedia()}
              {renderText()}
            </Box>
          </DropdownInput>
        )}
      </NonNullableContextContainer>
    )
  }

  const renderContent = (): React.ReactElement => {
    return (
      <Popover sx={{ overflow: 'hidden' }}>
        <SelectDropdown
          getOptionDisabled={getOptionDisabled}
          getOptionLabel={getOptionLabel}
          getOptionMedia={getOptionMedia}
          getOptionTooltip={getOptionTooltip}
          header={dropdownHeader}
          isBusy={isBusy}
          options={options}
          value={value}
          onChange={onChange}
        />
      </Popover>
    )
  }

  return (
    <Popper
      disablePortal={disablePortal}
      placement={placement}
      popperRef={popperRef}
    >
      {{
        anchor: renderAnchor,
        content: renderContent,
      }}
    </Popper>
  )
}

export const createStaticSimpleOptionsDropdownComponent =
  <OptionT extends AbstractSimpleOptionT>({ options }: { options: OptionT[] }) =>
  (props: Omit<SimpleStaticOptionsDropdownPropsT<OptionT>, 'options'>) => (
    <SimpleStaticOptionsDropdown
      {...props}
      options={options}
    />
  )
