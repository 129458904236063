import { Box } from '@mui/material'
import type { FieldPathByValue, FieldValues, PathValue, RegisterOptions } from 'react-hook-form'
import { useController, type UseFormReturn } from 'react-hook-form'

import { useEventCallback } from '@resnet/client-common/react/hooks/use-event-callback'

import { getSpecialValuesFieldProps } from '@resnet/client-shared/shared/forms/utils/get-special-values-field-props'

import { Field } from '@resnet/client-shared-web/shared/gdl/components/field'
import { FieldErrorText } from '@resnet/client-shared-web/shared/gdl/components/field'
import { FieldLabel } from '@resnet/client-shared-web/shared/gdl/components/field'
import { NumberInput } from '@resnet/client-shared-web/shared/gdl/components/number-input'
import { toPx } from '@resnet/client-shared-web/shared/gdl/utils/to-px'

export type NumberFieldValueT = undefined | null | number

export type NumberFieldPropsT<
  TFieldValues extends FieldValues,
  TPath extends FieldPathByValue<TFieldValues, NumberFieldValueT>,
> = {
  defaultValue?: PathValue<TFieldValues, TPath>
  disabled?: boolean
  form: UseFormReturn<TFieldValues>
  headerRight?: React.ReactNode
  label?: string
  name: TPath
  placeholder?: string
  rules?: RegisterOptions<TFieldValues, TPath>
  type?: string
  autoComplete?: string
}

export const NumberField = <
  TFieldValues extends FieldValues,
  TPath extends FieldPathByValue<TFieldValues, NumberFieldValueT>,
>({
  defaultValue,
  disabled,
  form,
  headerRight,
  label,
  name,
  placeholder,
  rules,
  type,
  autoComplete,
}: NumberFieldPropsT<TFieldValues, TPath>) => {
  const {
    field: { onChange: onChangeActual, ...field },
    field: fieldActual,
    fieldState: { error, invalid },
  } = useController({ control: form.control, defaultValue, name, rules })

  const onChange = useEventCallback((value: null | number) => {
    onChangeActual(value as PathValue<TFieldValues, TPath>)
  })

  const renderHeader = () => {
    if (!label && !headerRight) {
      return null
    }

    return (
      <Box sx={{ alignItems: 'center', display: 'flex', gap: toPx(16) }}>
        <FieldLabel>{label}</FieldLabel>
        {headerRight}
      </Box>
    )
  }

  const renderInput = () => {
    const specialValuesFieldProps = getSpecialValuesFieldProps(fieldActual)

    return (
      <NumberInput
        {...field}
        autoComplete={autoComplete}
        disabled={disabled}
        hasError={invalid}
        placeholder={placeholder}
        type={type}
        onChange={onChange}
        {...specialValuesFieldProps}
      />
    )
  }

  const renderFooter = () => {
    if (!error) {
      return null
    }

    return <FieldErrorText>{error.message as string}</FieldErrorText>
  }

  return (
    <Field sx={{ flexGrow: 1, width: 0 }}>
      {renderHeader()}
      {renderInput()}
      {renderFooter()}
    </Field>
  )
}
