import { transform } from '@resnet/client-common/common/utils/object/transform'
import type { MergeAllT } from '@resnet/client-common/typescript/types/merge-all'

import type { EntityTypeIdsT, SearchEntitiesQueryVariablesT } from '@resnet/client-api/api'

import { entityTypeOptions } from '@resnet/client-shared/shared/entities/constants/entity-type-options'
import { EntityOptionContainer } from '@resnet/client-shared/shared/entities/hooks/use-entity-option'
import { mapEntityToMedia } from '@resnet/client-shared/shared/entities/utils/map-entity-to-media'
import { mapEntityToTitle } from '@resnet/client-shared/shared/entities/utils/map-entity-to-title'

import type { SelectPropsT } from '@resnet/client-shared-web/shared/common/factories/create-select'
import { createSelect } from '@resnet/client-shared-web/shared/common/factories/create-select'
import type { SelectMultiplePropsT } from '@resnet/client-shared-web/shared/common/factories/create-select-multiple'
import { createSelectMultiple } from '@resnet/client-shared-web/shared/common/factories/create-select-multiple'
import { withCategories } from '@resnet/client-shared-web/shared/common/hocs/with-categories'

import { EntitiesSelectDropdownContainer } from '@resnet/client-web/shared/entities/hooks/use-entities-select-dropdown-azeroth'

const withActiveCategory =
  ({ activeCategoryId }: { activeCategoryId: EntityTypeIdsT }) =>
  (input: SearchEntitiesQueryVariablesT): SearchEntitiesQueryVariablesT =>
    transform(input, {
      filter: (filter) =>
        transform(filter ?? {}, {
          entityTypeId: () => ({
            eq: activeCategoryId,
          }),
        }),
    })

export const EntitySelectWithoutCategories = createSelect({
  OptionContainer: (props: React.ComponentProps<typeof EntityOptionContainer>) => <EntityOptionContainer {...props} />,
  SelectDropdownContainer: (props: React.ComponentProps<typeof EntitiesSelectDropdownContainer>) => (
    <EntitiesSelectDropdownContainer {...props} />
  ),
  getOptionLabel: (option) => mapEntityToTitle(option),
  getOptionMedia: (option) => mapEntityToMedia(option),
})

export type EntitySelectPropsT = MergeAllT<[SelectPropsT<object>, { categoryIds?: EntityTypeIdsT[] }]>

export const EntitySelect = withCategories({
  Component: EntitySelectWithoutCategories,
  getCategoryOptions: () => entityTypeOptions,
  withActiveCategory,
}) as React.ComponentType<EntitySelectPropsT>

export const EntitySelectMultipleWithoutCategories = createSelectMultiple({
  OptionContainer: (props: React.ComponentProps<typeof EntityOptionContainer>) => <EntityOptionContainer {...props} />,
  SelectDropdownContainer: (props: React.ComponentProps<typeof EntitiesSelectDropdownContainer>) => (
    <EntitiesSelectDropdownContainer {...props} />
  ),
  getOptionLabel: (option) => mapEntityToTitle(option),
  getOptionMedia: (option) => mapEntityToMedia(option),
})

export type EntitySelectMultiplePropsT = MergeAllT<[SelectMultiplePropsT<object>, { categoryIds?: EntityTypeIdsT[] }]>

export const EntitySelectMultiple = withCategories({
  Component: EntitySelectMultipleWithoutCategories,
  getCategoryOptions: () => entityTypeOptions,
  withActiveCategory,
}) as React.ComponentType<EntitySelectMultiplePropsT>
