import type { InfiniteData } from '@tanstack/react-query'
import type { Draft } from 'immer'

export type GenericEntityT = { id: string; customFields?: Record<string, unknown> }

export const listEntityQueryDeleteRecipe = <DataT, EntityT extends GenericEntityT>({
  data,
  id,
  mapDataToEntities,
}: {
  data: Draft<undefined | DataT>
  id: string
  mapDataToEntities: (data: Draft<DataT>) => Draft<EntityT[]>
}) => {
  if (!data) {
    return
  }

  const entities = mapDataToEntities(data)

  const entityIndex = entities.findIndex((entity) => entity.id === id)

  if (entityIndex === -1) {
    return
  }

  entities.splice(entityIndex, 1)
}

export const infiniteListEntityQueryDeleteRecipe = <DataT, EntityT extends GenericEntityT>({
  data,
  id,
  mapDataToEntities,
}: {
  data: Draft<undefined | InfiniteData<DataT>>
  id: string
  mapDataToEntities: (data: Draft<DataT>) => Draft<EntityT[]>
}) => {
  if (!data) {
    return
  }

  data.pages.forEach((data) => {
    listEntityQueryDeleteRecipe({ data, id, mapDataToEntities })
  })
}
