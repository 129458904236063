import type { ControllerRenderProps } from 'react-hook-form'

import type { AnyTypeT } from '@resnet/client-common/typescript/types/any-type'

import { specialValues } from '@resnet/client-shared/shared/forms/constants/special-values'

export const getSpecialValuesFieldProps = (field: ControllerRenderProps<AnyTypeT, AnyTypeT>) => {
  switch (field.value) {
    case specialValues.mixed:
      return {
        placeholder: 'Multiple Values',
        value: null,
      }
    default: {
      return null
    }
  }
}

export const getSpecialValuesMultipleFieldProps = (field: ControllerRenderProps<AnyTypeT, AnyTypeT>) => {
  switch (field.value) {
    case specialValues.mixed:
      return {
        placeholder: 'Multiple Values',
        value: [],
      }
    default: {
      return null
    }
  }
}
