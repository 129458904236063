import type { QueryClient } from '@tanstack/react-query'

import { updateById } from '@resnet/client-common/common/utils/array/update-by-id'
import { transform } from '@resnet/client-common/common/utils/object/transform'

import {
  useCreateCompanyApprovalTemplatePresetMutation,
  useCreateMyApprovalTemplatePresetMutation,
  useDeleteCompanyApprovalTemplatePresetMutation,
  useDeleteMyApprovalTemplatePresetMutation,
  useListCompanyApprovalTemplatePresetsQuery,
  useListMyApprovalTemplatePresetsQuery,
  useUpdateCompanyApprovalTemplatePresetMutation,
  useUpdateMyApprovalTemplatePresetMutation,
} from '@resnet/client-api/api'
import type { MutationCacheHandlersT } from '@resnet/client-api/services/query-client/types/mutation-cache-handlers'
import { createMutationHookMutationCacheHandlers } from '@resnet/client-api/services/query-client/utils/create-mutation-hook-mutation-cache-handlers'
import { findQueries } from '@resnet/client-api/utils/find-queries'
import { invalidateQuery } from '@resnet/client-api/utils/invalidate-query'
import { setQueryData } from '@resnet/client-api/utils/set-query-data'

export const listMyApprovalTemplatePresetsQueriesHandlers = ({
  queryClient,
}: {
  queryClient: QueryClient
}): MutationCacheHandlersT[] => {
  const findListMyApprovalTemplatePresetsQueries = () => findQueries(queryClient, useListMyApprovalTemplatePresetsQuery)

  const createMyApprovalTemplatePresetHandlers = () => {
    return createMutationHookMutationCacheHandlers(useCreateMyApprovalTemplatePresetMutation, {
      onSuccess: () => {
        findListMyApprovalTemplatePresetsQueries().forEach(invalidateQuery(queryClient))
      },
    })
  }

  const updateMyApprovalTemplatePresetHandlers = () => {
    return createMutationHookMutationCacheHandlers(useUpdateMyApprovalTemplatePresetMutation, {
      onError: () => {
        findListMyApprovalTemplatePresetsQueries().forEach(invalidateQuery(queryClient))
      },
      onMutate: ({ id, levels }) => {
        findListMyApprovalTemplatePresetsQueries().forEach((query) =>
          setQueryData(queryClient)(query)((data) => {
            if (!data) {
              return data
            }

            return transform(data, {
              listMyApprovalTemplatePresets: (listMyApprovalTemplatePresets) =>
                transform(listMyApprovalTemplatePresets, {
                  items: (presets) =>
                    updateById(presets, id, (preset) => ({
                      ...preset,
                      items: [levels].flat(),
                    })),
                }),
            })
          }),
        )
      },
      onSuccess: () => {
        findListMyApprovalTemplatePresetsQueries().forEach(invalidateQuery(queryClient))
      },
    })
  }

  const deleteMyApprovalTemplatePresetHandlers = () => {
    return createMutationHookMutationCacheHandlers(useDeleteMyApprovalTemplatePresetMutation, {
      onError: () => {
        findListMyApprovalTemplatePresetsQueries().forEach(invalidateQuery(queryClient))
      },
      onMutate: ({ id }) => {
        findListMyApprovalTemplatePresetsQueries().forEach((query) =>
          setQueryData(queryClient)(query)((data) => {
            if (!data) {
              return data
            }

            return transform(data, {
              listMyApprovalTemplatePresets: (listMyApprovalTemplatePresets) =>
                transform(listMyApprovalTemplatePresets, {
                  items: (items) => items.filter((widgetPreset) => widgetPreset.id !== id),
                }),
            })
          }),
        )
      },
      onSuccess: () => {
        findListMyApprovalTemplatePresetsQueries().forEach(invalidateQuery(queryClient))
      },
    })
  }

  return [
    createMyApprovalTemplatePresetHandlers(),
    deleteMyApprovalTemplatePresetHandlers(),
    updateMyApprovalTemplatePresetHandlers(),
  ]
}

export const listCompanyApprovalTemplatePresetsQueriesHandlers = ({
  queryClient,
}: {
  queryClient: QueryClient
}): MutationCacheHandlersT[] => {
  const findListCompanyApprovalTemplatePresetsQueries = () =>
    findQueries(queryClient, useListCompanyApprovalTemplatePresetsQuery)

  const createCompanyApprovalTemplatePresetHandlers = () => {
    return createMutationHookMutationCacheHandlers(useCreateCompanyApprovalTemplatePresetMutation, {
      onSuccess: () => {
        findListCompanyApprovalTemplatePresetsQueries().forEach(invalidateQuery(queryClient))
      },
    })
  }

  const updateCompanyApprovalTemplatePresetHandlers = () => {
    return createMutationHookMutationCacheHandlers(useUpdateCompanyApprovalTemplatePresetMutation, {
      onError: () => {
        findListCompanyApprovalTemplatePresetsQueries().forEach(invalidateQuery(queryClient))
      },
      onMutate: ({ id, levels }) => {
        findListCompanyApprovalTemplatePresetsQueries().forEach((query) =>
          setQueryData(queryClient)(query)((data) => {
            if (!data) {
              return data
            }

            return transform(data, {
              listCompanyApprovalTemplatePresets: (listCompanyApprovalTemplatePresets) =>
                transform(listCompanyApprovalTemplatePresets, {
                  items: (presets) =>
                    updateById(presets, id, (preset) => ({
                      ...preset,
                      items: [levels].flat(),
                    })),
                }),
            })
          }),
        )
      },
      onSuccess: () => {
        findListCompanyApprovalTemplatePresetsQueries().forEach(invalidateQuery(queryClient))
      },
    })
  }

  const deleteCompanyApprovalTemplatePresetHandlers = () => {
    return createMutationHookMutationCacheHandlers(useDeleteCompanyApprovalTemplatePresetMutation, {
      onError: () => {
        findListCompanyApprovalTemplatePresetsQueries().forEach(invalidateQuery(queryClient))
      },
      onMutate: ({ id }) => {
        findListCompanyApprovalTemplatePresetsQueries().forEach((query) =>
          setQueryData(queryClient)(query)((data) => {
            if (!data) {
              return data
            }

            return transform(data, {
              listCompanyApprovalTemplatePresets: (listCompanyApprovalTemplatePresets) =>
                transform(listCompanyApprovalTemplatePresets, {
                  items: (items) => items.filter((widgetPreset) => widgetPreset.id !== id),
                }),
            })
          }),
        )
      },
      onSuccess: () => {
        findListCompanyApprovalTemplatePresetsQueries().forEach(invalidateQuery(queryClient))
      },
    })
  }

  return [
    createCompanyApprovalTemplatePresetHandlers(),
    deleteCompanyApprovalTemplatePresetHandlers(),
    updateCompanyApprovalTemplatePresetHandlers(),
  ]
}
