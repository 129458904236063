import { useEffect, useMemo } from 'react'

import { useEventCallback } from '@resnet/client-common/react/hooks/use-event-callback'
import { ChildrenDivider } from "@resnet/client-common/react/components/children-transformer";
import { Divider } from "@mui/material";
import {
  EntityTypeIdsT,
  useCreateIssuesMutation,
  useDeleteIssueMutation,
  useSetIssueApprovalLevelsMutation,
  useUpdateRelationsMutation,
} from '@resnet/client-api/api'

import { useSuccessOption } from '@resnet/client-shared/shared/common/hooks/use-success-option'
import { useEntityTypeOption } from '@resnet/client-shared/shared/entities/hooks/use-entity-type-option'
import { mapEntityTypeToCustomFields } from '@resnet/client-shared/shared/entities/utils/map-entity-type-to-custom-fields'
import { composeValidators } from '@resnet/client-shared/shared/forms/validators/compose-validators'
import { createValidateMaxLength } from '@resnet/client-shared/shared/forms/validators/text-length'
import { validateTextRequired } from '@resnet/client-shared/shared/forms/validators/text-required'
import { issuePriorityOptions } from '@resnet/client-shared/shared/issues/constants/issue-priority-options'
import { issueStatusOptions } from '@resnet/client-shared/shared/issues/constants/issue-status-options'
import { issueTypeOptions } from '@resnet/client-shared/shared/issues/constants/issue-type-options'

import { SaveFormFooter } from '@resnet/client-web/shared/common/components/save-form-footer'
import { SidebarScrollableContent } from '@resnet/client-shared-web/shared/common/components/sidebar'
import { CustomFieldsUserFormFields } from '@resnet/client-web/shared/custom-fields/components/custom-fields-user-form-fields'
import { useCustomFieldsForm } from '@resnet/client-web/shared/custom-fields/hooks/use-custom-fields-form'
import { checkFieldMatchesScope } from '@resnet/client-web/shared/custom-fields/utils/check-field-matches-scope'
import { mapFormCustomFieldValuesToResources } from '@resnet/client-web/shared/custom-fields/utils/map-form-custom-field-values-to-resources'
import { entityCustomFieldValuesKey } from '@resnet/client-web/shared/entities/constants/entity-custom-field-values-key'
import { AssetsListField } from '@resnet/client-web/shared/form-dalaran/components/assets/assets-list-field'
import { AttachmentMultipleField } from '@resnet/client-shared-web/shared/form/components/common/attachment-multiple-field'
import { DateField } from '@resnet/client-shared-web/shared/form/components/common/date-field'
import { Form } from '@resnet/client-shared-web/shared/form/components/common/form'
import { SimpleStaticOptionsDropdownField } from '@resnet/client-shared-web/shared/form/components/common/simple-static-options-dropdown-field'
import { SwitchField } from '@resnet/client-shared-web/shared/form/components/common/switch-field'
import { TextField } from '@resnet/client-shared-web/shared/form/components/common/text-field'
import { EntityLabelsMultipleSelectField } from '@resnet/client-web/shared/form-dalaran/components/entity/entity-labels-select-multiple-field'
import { EntityPermitRecipientsSelectField } from '@resnet/client-web/shared/form-dalaran/components/entity/entity-permit-recipients-select-field'
import { EntityPermitTypeSelectField } from '@resnet/client-web/shared/form-dalaran/components/entity/entity-permit-type-select-fields'
import { EntityRelationsField } from '@resnet/client-web/shared/form-dalaran/components/entity/entity-relations-field'
import { IssueAffectedAssetsField } from '@resnet/client-web/shared/form-dalaran/components/issue/issue-affected-assets-field'
import { IssueApprovalField } from '@resnet/client-web/shared/form-dalaran/components/issue/issue-approval-field'
import { Box } from "@mui/material";
import { IssueCampaignSelectField } from '@resnet/client-web/shared/form-dalaran/components/issue/issue-campaign-field'
import { ResourceObjectRelationField } from '@resnet/client-web/shared/form-dalaran/components/resource-object/resource-object-relation-field'
import { UserSelectField } from '@resnet/client-web/shared/form-dalaran/components/users/user-select-field'
import type { FormPropsT } from '@resnet/client-web/shared/form-dalaran/types'
import { ObjectDetailsContent } from '@resnet/client-web/shared/object/components/object-details-content'
import { ObjectDetailsGroup } from '@resnet/client-web/shared/object/components/object-details-group'
import { ObjectDetailsRow } from '@resnet/client-web/shared/object/components/object-details-row'
import { mapFormValuesToUpdateRelationsMutationVariables } from '@resnet/client-web/shared/relations/utils/map-form-values-to-update-relations-mutation-variables'
import { withUpdateRelationsOperationsCountCheck } from '@resnet/client-web/shared/relations/utils/with-update-relations-operations-count-check'
import { useUpdateResources } from '@resnet/client-shared-web/shared/resources/hooks/use-update-resources'

import { useSnackbar } from "notistack";

import type { CreateIssueFormValuesT } from "@resnet/client-web/shared/issues/components/create-issue-drawer-content/types/create-issue-form-values";
import { createDefaultCreateIssueFormValues } from "@resnet/client-web/shared/issues/components/create-issue-drawer-content/utils/create-default-create-issue-form-values";
import { mapCreateIssueFormValuesToAttachments } from "@resnet/client-web/shared/issues/components/create-issue-drawer-content/utils/map-create-issue-form-values-to-attachments";

import { mapTypographyPresetToSx } from "@resnet/client-shared-web/shared/gdl/utils/map-typography-preset-to-sx";
import { mapCreateIssueFormValuesToSetIssueApprovalLevelsMutationVariables } from "@resnet/client-web/shared/issues/components/create-issue-drawer-content/utils/map-create-issue-form-values-to-set-issue-approval-levels-mutation-variables";
import { mapCreateIssueFormValuesToCreateIssuesMutationVariables } from '@resnet/client-web/shared/issues/components/create-issue-drawer-content/utils/map-create-issue-form-values-to-create-issues-mutation-variables'

import { TextOverflow } from "@resnet/client-shared-web/shared/gdl/components/text-overflow";
import { createIssueFormValuesStore } from '@resnet/client-web/shared/issues/components/create-issue-drawer-content/stores/create-issue-form-values-store'
import { themeColors } from '@resnet/client-shared-web/shared/gdl/constants/theme-colors';

import SimpleHeader from '../components/SimpleHeader';
import { toPx } from '@resnet/client-shared-web/shared/gdl/utils/to-px';
import { typographyPresets } from '@resnet/client-shared/shared/gdl/constants/typography-presets';
import { useHistory } from 'react-router-dom';
import { useTeamsData } from '../context/teams-context';
import { stripHtml } from '../../utils/strip-html';


export type CreateIssueDrawerContentPropsT = FormPropsT

export const OfficeCreateIssue = ({ onSuccess }: CreateIssueDrawerContentPropsT) => {
  const { mutateAsync: createIssues } = useCreateIssuesMutation()

  const { mutateAsync: deleteIssue } = useDeleteIssueMutation()

  const { mutateAsync: setApprovalLevels } = useSetIssueApprovalLevelsMutation()

  const { updateResources } = useUpdateResources()

  const { mutateAsync: updateRelations } = useUpdateRelationsMutation()

  const { enqueueSnackbar } = useSnackbar()

  const navigate = useHistory();

  const { message, subject, teamsUrl } = useTeamsData();

  const { option: entityType } = useSuccessOption({
    id: EntityTypeIdsT.IssueT,
    useOption: useEntityTypeOption,
  })

  const fieldsActual = useMemo(() => mapEntityTypeToCustomFields(entityType), [entityType])

  const defaultValues = useMemo(() => createDefaultCreateIssueFormValues({ fields: fieldsActual }), [fieldsActual])

  const { form, fields } = useCustomFieldsForm({
    defaultValues,
    fieldValuesKey: entityCustomFieldValuesKey,
    fields: fieldsActual,
    store: createIssueFormValuesStore,
  })

  useEffect(() => {
    form.setValue("description", stripHtml(message) + " - " + teamsUrl);
    form.setValue("name", subject);
  }, []);

  const defaultFields = useMemo(() => {
    return fields.filter((field) => checkFieldMatchesScope({ field }))
  }, [fields])

  const campaignId = form.watch('campaignId')

  const campaignScope = useMemo(() => {
    if (!campaignId) {
      return undefined
    }

    return {
      field: 'campaignId',
      value: campaignId,
    }
  }, [campaignId])

  const campaignFields = useMemo(() => {
    if (!campaignScope) {
      return []
    }

    return fields.filter((field) => checkFieldMatchesScope({ field, scope: campaignScope }))
  }, [campaignScope, fields])

  const onSubmit = useEventCallback(async (values: CreateIssueFormValuesT) => {
    try {
      const { createIssues: issues } = await createIssues(
        mapCreateIssueFormValuesToCreateIssuesMutationVariables({ fields, values }),
      )

      if (values.needsApproval) {
        try {
          await Promise.all(
            issues.map((issue) =>
              setApprovalLevels(
                mapCreateIssueFormValuesToSetIssueApprovalLevelsMutationVariables({ id: issue.id, values }),
              ),
            ),
          )
        } catch (error) {
          await Promise.all(issues.map((issue) => deleteIssue({ id: issue.id })))

          throw error
        }
      }

      const { attachments: nextAttachmentResources } = mapCreateIssueFormValuesToAttachments({
        values,
      })

      const { resources: nextCustomFieldsResources } = mapFormCustomFieldValuesToResources({
        fieldValues: values.customFields,
        fields,
      })

      try {
        await Promise.all(
          issues.map(async (issue) => {
            await Promise.all([
              withUpdateRelationsOperationsCountCheck(updateRelations)(
                mapFormValuesToUpdateRelationsMutationVariables({
                  entityId: issue.id,
                  values,
                }),
              ),
              updateResources({
                nextResources: [...nextAttachmentResources, ...nextCustomFieldsResources],
                originId: issue.id,
                originType: 'ENTITY' as const,
              }),
            ])
          }),
        )
      } catch (error) {
        enqueueSnackbar("An error occurred while creating the issue",{
          variant: 'error',
        });
      }

      enqueueSnackbar("Issue Created Successfully",{
        variant: 'success',
      });

      form.reset(defaultValues)

      navigate.push("/");

      onSuccess?.()
    } catch (error) {
      enqueueSnackbar("An error occurred while creating the issue",{
        variant: 'error',
      });
    }
  })

  const onReset = useEventCallback(() => {
    form.reset(defaultValues)
  })

  const renderHeaderTitle = () => {
    return 'Create Issue'
  }

  const renderAccessControlRow = () => {
    return (
      <ObjectDetailsRow>
        <EntityPermitTypeSelectField form={form} />
        <EntityPermitRecipientsSelectField form={form} />
      </ObjectDetailsRow>
    )
  }

  const renderNameAndLabelRow = () => {
    return (
      <ObjectDetailsRow>
        <TextField
          form={form}
          label="Name (Required)"
          name="name"
          rules={{
            validate: composeValidators(validateTextRequired, createValidateMaxLength(100)),
          }}
        />
        <EntityLabelsMultipleSelectField
          entityTypeId={EntityTypeIdsT.IssueT}
          form={form}
          placement="bottom-end"
        />
      </ObjectDetailsRow>
    )
  }

  const renderTypeAndCampaignRow = () => {
    return (
      <ObjectDetailsRow>
        <SimpleStaticOptionsDropdownField
          nonNullable
          form={form}
          label="Type"
          name="type"
          options={issueTypeOptions}
        />
        <IssueCampaignSelectField
          form={form}
          size="md"
        />
      </ObjectDetailsRow>
    )
  }

  const renderAfeRow = () => {
    return (
      <ObjectDetailsRow>
        <TextField
          form={form}
          label="AFE"
          name="afe"
          rules={{
            validate: composeValidators(createValidateMaxLength(100)),
          }}
        />
      </ObjectDetailsRow>
    )
  }

  const renderAssigneeRow = () => {
    return (
      <ObjectDetailsRow>
        <UserSelectField
          form={form}
          label="Assignee"
          name="assigneeId"
        />
      </ObjectDetailsRow>
    )
  }

  const renderPriorityAndStatusRow = () => {
    return (
      <ObjectDetailsRow>
        <SimpleStaticOptionsDropdownField
          nonNullable
          form={form}
          label="Priority"
          name="priority"
          options={issuePriorityOptions}
        />
        <SimpleStaticOptionsDropdownField
          nonNullable
          form={form}
          label="Status"
          name="status"
          options={issueStatusOptions}
          placement="bottom-end"
        />
      </ObjectDetailsRow>
    )
  }

  const renderCreateIssuesForRow = () => {
    return (
      <ObjectDetailsRow>
        <AssetsListField
          form={form}
          label="Create Issues For"
          name="assetIds"
        />
      </ObjectDetailsRow>
    )
  }

  const renderRequiresFieldDispatchRow = () => {
    return (
      <ObjectDetailsRow>
        <SwitchField
          form={form}
          label="Requires Field Dispatch"
          name="dispatched"
        />
      </ObjectDetailsRow>
    )
  }

  const renderCloneForIndividualAssetsRow = () => {
    return (
      <ObjectDetailsRow>
        <SwitchField
          form={form}
          label="Clone for Individual Assets"
          name="cloneForWells"
        />
      </ObjectDetailsRow>
    )
  }

  const renderAffectedAssetsRow = () => {
    return (
      <ObjectDetailsRow>
        <IssueAffectedAssetsField
          assetIdsName="assetIds"
          cloneForWellsName="cloneForWells"
          form={form}
          label="Affected Assets"
        />
      </ObjectDetailsRow>
    )
  }

  const renderAllocateResourcesRow = () => {
    return (
      <ObjectDetailsRow>
        <ResourceObjectRelationField
          form={form}
          label="Allocate Resources"
          name="resourceObjectId"
        />
      </ObjectDetailsRow>
    )
  }

  const renderRelationsRow = () => {
    return (
      <ObjectDetailsRow>
        <EntityRelationsField
          form={form}
          label="Relations"
          name="relations"
        />
      </ObjectDetailsRow>
    )
  }

  const renderNeedsApprovalRow = () => {
    return (
      <ObjectDetailsRow>
        <SwitchField
          form={form}
          label="Needs Approval"
          name="needsApproval"
        />
      </ObjectDetailsRow>
    )
  }

  const renderApprovalLevelsRow = () => {
    return (
      <ObjectDetailsRow>
        <IssueApprovalField
          form={form}
          issueStatusName="status"
          name="approval"
          needsApprovalName="needsApproval"
        />
      </ObjectDetailsRow>
    )
  }

  const renderAttachmentsRow = () => {
    return (
      <ObjectDetailsRow>
        <AttachmentMultipleField
          form={form}
          label="Attachments"
          name="attachments"
        />
      </ObjectDetailsRow>
    )
  }

  const renderEstimatedStartDateAndEstimatedEndDateRow = () => {
    return (
      <ObjectDetailsRow>
        <DateField
          form={form}
          label="Estimated Start Date"
          name="estimatedStartDate"
          rules={{
            validate: () => {
              const estimatedStartDate = form.getValues('estimatedStartDate')

              const estimatedEndDate = form.getValues('estimatedEndDate')

              if (estimatedStartDate && estimatedEndDate && estimatedStartDate > estimatedEndDate) {
                return 'Estimated Start Date shouldn\'t be after then Estimated End Date'
              }

              return true
            },
          }}
        />
        <DateField
          form={form}
          label="Estimated End Date"
          name="estimatedEndDate"
          rules={{
            validate: () => {
              const estimatedStartDate = form.getValues('estimatedStartDate')

              const estimatedEndDate = form.getValues('estimatedEndDate')

              if (estimatedStartDate && estimatedEndDate && estimatedStartDate > estimatedEndDate) {
                return 'Estimated End Date shouldn\'t be before then Estimated Start Date'
              }

              return true
            },
          }}
        />
      </ObjectDetailsRow>
    )
  }

  const renderActualStartDateAndActualEndDateRow = () => {
    return (
      <ObjectDetailsRow>
        <DateField
          form={form}
          label="Actual Start Date"
          name="actualStartDate"
          rules={{
            validate: () => {
              const actualStartDate = form.getValues('actualStartDate')

              const actualEndDate = form.getValues('actualEndDate')

              if (actualStartDate && actualEndDate && actualStartDate > actualEndDate) {
                return 'Actual Start Date shouldn\'t be after then Actual End Date'
              }

              return true
            },
          }}
        />
        <DateField
          form={form}
          label="Actual End Date"
          name="actualEndDate"
          rules={{
            validate: () => {
              const actualStartDate = form.getValues('actualStartDate')

              const actualEndDate = form.getValues('actualEndDate')

              if (actualStartDate && actualEndDate && actualStartDate > actualEndDate) {
                return 'Actual End Date shouldn\'t be before then Actual Start Date'
              }

              return true
            },
          }}
        />
      </ObjectDetailsRow>
    )
  }

  const renderDescriptionRow = () => {
    return (
      <ObjectDetailsRow>
        <TextField
          textArea
          form={form}
          label="Description"
          name="description"
          rules={{
            validate: createValidateMaxLength(1024),
          }}
        />
      </ObjectDetailsRow>
    )
  }

  const renderFlagRow = () => {
    return (
      <ObjectDetailsRow>
        <SwitchField
          form={form}
          label="Flag"
          name="flag"
        />
      </ObjectDetailsRow>
    )
  }

  const renderCustomFieldsGroup = () => {
    if (defaultFields.length === 0) {
      return null
    }

    return (
      <CustomFieldsUserFormFields
        fieldValuesName={entityCustomFieldValuesKey}
        fields={defaultFields}
        form={form}
      />
    )
  }

  const renderCampaignCustomFieldsGroup = () => {
    if (campaignFields.length === 0) {
      return null
    }

    return (
      <CustomFieldsUserFormFields
        fieldValuesName={entityCustomFieldValuesKey}
        fields={campaignFields}
        form={form}
        title="Campaign Custom Fields"
      />
    )
  }

  const renderScrollableArea = () => {
    return (
      <SidebarScrollableContent style={{height: '100%'}}>
        <ObjectDetailsContent>
          <ObjectDetailsGroup>
            {renderAccessControlRow()}
            {renderNameAndLabelRow()}
          </ObjectDetailsGroup>
          <ObjectDetailsGroup>
            {renderTypeAndCampaignRow()}
            {renderAfeRow()}
            {renderAssigneeRow()}
            {renderPriorityAndStatusRow()}
          </ObjectDetailsGroup>
          <ObjectDetailsGroup>{renderCreateIssuesForRow()}</ObjectDetailsGroup>
          <ObjectDetailsGroup>
            {renderRequiresFieldDispatchRow()}
            {renderCloneForIndividualAssetsRow()}
            {renderAffectedAssetsRow()}
          </ObjectDetailsGroup>
          <ObjectDetailsGroup>{renderAllocateResourcesRow()}</ObjectDetailsGroup>
          <ObjectDetailsGroup>{renderRelationsRow()}</ObjectDetailsGroup>
          <ObjectDetailsGroup>
            {renderNeedsApprovalRow()}
            {renderApprovalLevelsRow()}
          </ObjectDetailsGroup>
          <ObjectDetailsGroup>{renderAttachmentsRow()}</ObjectDetailsGroup>
          <ObjectDetailsGroup>
            {renderEstimatedStartDateAndEstimatedEndDateRow()}
            {renderActualStartDateAndActualEndDateRow()}
            {renderDescriptionRow()}
            {renderFlagRow()}
          </ObjectDetailsGroup>
          {renderCustomFieldsGroup()}
          {renderCampaignCustomFieldsGroup()}
        </ObjectDetailsContent>
      </SidebarScrollableContent>
    )
  }

  const renderFooter = () => {
    return (
      <SaveFormFooter
        form={form}
        onReset={onReset}
      />
    )
  }

  const renderContent = () => {
    return (
      <Form form={form} sx={{ flexGrow: 1, height: '100%', gap: toPx(16) }} onSubmit={onSubmit}>
        <ChildrenDivider  dividerNode={<Divider />}>
          {renderScrollableArea()}
          {renderFooter()}
        </ChildrenDivider>
      </Form>
    )
  }

  return (
    <Box>
      <Box
        sx={{
          position: "fixed",
          zIndex: 9,
          width: "100%",
          background: themeColors.background,
        }}
      >
        <SimpleHeader />
      </Box>
      <Box sx={{ padding: toPx(16), paddingTop: 5 }}>
        <ChildrenDivider dividerNode={<Divider />}>
          <Box
            sx={[
              {
                alignItems: "center",
                display: "flex",
                gap: toPx(8),
                padding: '16px 0',
                flexGrow: 1,
              },
            ].flat()}
          >
            <Box
              sx={[
                mapTypographyPresetToSx(typographyPresets.headlineMedium),
                {
                  color: themeColors.overBackgroundBold,
                  display: "flex",
                  flexGrow: 1,
                  width: 0,
                },
              ].flat()}
            >
              <TextOverflow>Create Issue</TextOverflow>
            </Box>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              flexGrow: 1,
              flex: 1,
              height: "100%",
            }}
          >
            {renderContent()}
          </Box>
        </ChildrenDivider>
      </Box>
    </Box>
  );
};
