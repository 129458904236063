import { pipeline } from '@resnet/client-common/common/utils/function/pipeline'

import type { UserFragmentT } from '@resnet/client-api/api'

export const mapUserToFirstName = (user: Pick<UserFragmentT, 'firstName'>): string =>
  pipeline(
    user.firstName,
    (x) => x ?? '',
    (x) => x.trim(),
  )
