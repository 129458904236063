import { Box } from '@mui/material'

import { ChildrenDivider } from '@resnet/client-common/react/components/children-transformer'
import { useNonNullableContext } from '@resnet/client-common/react/hooks/use-non-nullable-context'

import { Button } from '@resnet/client-shared-web/shared/gdl/components/button'
import { Divider } from '@resnet/client-shared-web/shared/gdl/components/divider'
import { ModalContent, ModalContext } from '@resnet/client-shared-web/shared/gdl/components/modal'
import { toPx } from '@resnet/client-shared-web/shared/gdl/utils/to-px'

export const PresetSaveAsNewModal = ({
  cancelActionLabel = 'Cancel',
  content,
  fields,
  isDestructive = false,
  isLoading,
  onCancel,
  onSubmit,
  primaryActionLabel = 'Save As New',
  title = 'Save as New',
}: {
  cancelActionLabel?: string
  content?: () => React.ReactNode
  fields: () => React.ReactNode
  isDestructive?: boolean
  isLoading: boolean
  onCancel?: () => void
  onSubmit?: React.EventHandler<React.FormEvent<HTMLFormElement>>
  primaryActionLabel?: string
  title?: string
}): React.ReactElement => {
  const { onClose } = useNonNullableContext(ModalContext)

  const renderHeaderTitle = (): React.ReactNode => {
    return title
  }

  const renderFields = (): React.ReactNode => {
    return <Box sx={{ display: 'flex', flexDirection: 'column', gap: toPx(16) }}>{fields()}</Box>
  }

  const renderSaveAsNewButton = (): React.ReactNode => {
    return (
      <Button
        color="primary"
        isLoading={isLoading}
        size="md"
        type="submit"
        variant="contained"
      >
        {primaryActionLabel}
      </Button>
    )
  }

  const renderCloseButton = (): React.ReactNode => {
    return (
      <Button
        color={!isDestructive ? 'primary' : 'danger'}
        size="md"
        variant="outlined"
        onClick={() => {
          onCancel?.()
          onClose?.()
        }}
      >
        {cancelActionLabel}
      </Button>
    )
  }

  const renderControls = (): React.ReactNode => {
    return (
      <Box sx={{ display: 'flex', gap: toPx(16) }}>
        {renderCloseButton()}
        <Box sx={{ flexGrow: 1, ml: toPx(-16) }} />
        {renderSaveAsNewButton()}
      </Box>
    )
  }

  const renderContent = (): React.ReactNode => {
    return (
      <Box
        component="form"
        sx={{ display: 'flex', flexDirection: 'column', flexGrow: 1, gap: toPx(16) }}
        onSubmit={(event) => {
          event.stopPropagation()
          onSubmit?.(event)
        }}
      >
        {content?.() ?? null}
        <ChildrenDivider dividerNode={<Divider />}>
          {renderFields()}
          {renderControls()}
        </ChildrenDivider>
      </Box>
    )
  }

  return (
    <ModalContent sx={{ maxWidth: toPx(512), width: '100%' }}>
      {{
        content: renderContent(),
        header: { title: renderHeaderTitle() },
      }}
    </ModalContent>
  )
}
