import type { MutationCacheHandlersT } from '../services/query-client/types/mutation-cache-handlers'
import type { QueryClient } from '../vendors/react-query'

// NOTE: Used just for type checking

export const createQueriesHandlers = (
  createHandlers: ({ queryClient }: { queryClient: QueryClient }) => MutationCacheHandlersT[],
) => {
  return ({ queryClient }: { queryClient: QueryClient }) => {
    return createHandlers({ queryClient })
  }
}
