import { Box } from "@mui/material";
import { Suspense, useContext } from "react";
import { stringifySearchParams } from "@resnet/client-common/network/utils/stringify-search-params";
import type { StateModelT } from "@resnet/client-common/react/types/state-model";
import { foldElement } from "@resnet/client-common/react/utils/fold-element";
import { forwardFunctionalComponentRef } from "@resnet/client-common/react/utils/forward-functional-component-ref";
import type { MergeAllT } from "@resnet/client-common/typescript/types/merge-all";
import { useResourcesQuery, type RegularPostFragmentT, ResourceTypesT } from "@resnet/client-api/api";
import { useAuth } from "@resnet/client-api/auth-api/auth-context";
import { mapUserToTitle } from "@resnet/client-shared/shared/users/utils/map-user-to-title";
import { MessageCardSkeleton } from "@resnet/client-web/shared/feed/components/message-card-skeleton";
import { themeColors } from "@resnet/client-shared-web/shared/gdl/constants/theme-colors";
import {
  MessageCardLayout,
  MessageCardLayoutContent,
  MessageCardLayoutContentSection,
  MessageCardLayoutPropsT,
} from "@resnet/client-web/shared/feed/components/message-card-layout";
import { toPx } from "@resnet/client-shared-web/shared/gdl/utils/to-px";
import { UserAvatar } from "@resnet/client-web/shared/users/components/user-avatar";
import { mapUserToPathname } from "@resnet/client-web/shared/users/utils/map-user-to-pathname";
import { MessageCardGallery } from "@resnet/client-web/shared/feed/components/message-card-gallery";
import { MessageCardOrigin } from "@resnet/client-web/shared/feed/components/message-card-origin";
import { MessageCardLink } from "@resnet/client-web/shared/feed/components/message-card-link";
import { FeedContext } from "@resnet/client-web/shared/feed/contexts/feed-context";
import { usePostOrigin } from "@resnet/client-web/shared/feed/hooks/use-post-origin";
import { MessageCardCreatedAt } from "@resnet/client-web/shared/feed/components/message-card-created-at";
import { MessageCardLikedBy } from "@resnet/client-web/shared/feed/components/message-card-liked-by";
import { MessageCardHeaderLayout } from "@resnet/client-web/shared/feed/components/message-card-header-layout";
import { MessageCardMeta } from "@resnet/client-web/shared/feed/components/message-card-meta";
import { MessageCardNameLink } from "@resnet/client-web/shared/feed/components/message-card-name";
import { useRegularPostUser } from "@resnet/client-web/shared/feed/hooks/use-regular-post-user";
import { PostLikeButtonContainer } from "@resnet/client-web/shared/feed/hooks/use-post-like-button";
import { MessageCardLikeButton } from "@resnet/client-web/shared/feed/components/message-card-like-button";

import { SlateContent } from '@resnet/client-web/shared/slate/components/slate-content'
import { MessageCardCollapsibleMessage } from "@resnet/client-web/shared/feed/components/message-card-collapsible-message";
import { getDomainFromTenant } from "../../utils/get-domain-from-tenant";

import { PostCommentButtonContainer } from "@resnet/client-web/shared/feed/hooks/use-post-comment-button";
import { PostCardCommentButton } from "@resnet/client-web/shared/feed/components/post-card-comment-button";
import { mapOriginToFeedPathname } from "@resnet/client-shared/shared/origins/utils/map-origin-to-feed-pathname";
import { mapOriginToPathname } from "@resnet/client-shared/shared/origins/utils/map-origin-to-pathname";

const emptyArray: never[] = [];

export type RegularPostCardContentPropsT = MergeAllT<
  [
    Omit<MessageCardLayoutPropsT, "children">,
    {
      post: RegularPostFragmentT;
      contentHeightModel?: StateModelT<null | number>;
    }
  ]
>;

export const OfficeRegularPostCardContent = forwardFunctionalComponentRef(
  ({ post, contentHeightModel, sx = null, ...props }: RegularPostCardContentPropsT, forwardedRef) => {
    const feed = useContext(FeedContext);

    const { tenant } = useAuth();

    const baseUrl = getDomainFromTenant(tenant ? tenant : "dev");

    const { id, pinned } = post;

    const isPinned = Boolean(pinned);

    const { origin } = usePostOrigin({ post });

    const { user } = useRegularPostUser({ post });

    const resourcesQuery = useResourcesQuery(
      {
        filter: { refId: { eq: id }, resourceType: { eq: ResourceTypesT.AttachmentT } },
      },
      { select: (data) => data.listResources.items }
    );

    const resources = resourcesQuery.data ?? emptyArray;

    const ref = forwardedRef;

    const renderLink = () => {
      const { id } = post;

      if (feed.type === "posts" && feed.isOriginScreen && feed.origin && feed.origin.id === origin.id) {
        return null;
      }

      const pathname = mapOriginToFeedPathname(origin);

      const search = stringifySearchParams({ post: id });

      const to = { pathname, search };

      return <MessageCardLink target="_blank" to={{ pathname: baseUrl + to.pathname + to.search }} />;
    };

    const renderHeaderAvatar = () => {
      return (
        <Box
          component="a"
          sx={[{ all: "unset" }, { cursor: "pointer", position: "relative" }]}
          target="_blank"
          //to="https://api.dev.resnet.ai/users/user/6287ba769dc0bd7baa656000"
          href={baseUrl + mapUserToPathname(user)}
        >
          <UserAvatar size="md" user={user} />
        </Box>
      );
    };

    const renderHeaderName = () => {
      return (
        <MessageCardNameLink sx={{ minWidth: 0 }} target="_blank" to={mapUserToPathname(user)}>
          {mapUserToTitle(user)}
        </MessageCardNameLink>
      );
    };

    const renderHeaderMetaCreatedAt = () => {
      return <MessageCardCreatedAt message={post} />;
    };

    const renderHeaderMetaLikedBy = () => {
      const { totalLikes } = post;

      if (!totalLikes) {
        return null;
      }

      return <MessageCardLikedBy message={post} />;
    };

    const renderHeaderMeta = () => {
      return (
        <MessageCardMeta>
          {renderHeaderMetaCreatedAt()}
          {renderHeaderMetaLikedBy()}
        </MessageCardMeta>
      );
    };

    const renderHeader = () => {
      return (
        <MessageCardHeaderLayout>
          {{
            avatar: renderHeaderAvatar(),
            meta: renderHeaderMeta(),
            name: renderHeaderName(),
          }}
        </MessageCardHeaderLayout>
      );
    };

    const renderMessage = () => {
      const { message } = post;

      if (!message) {
        return null;
      }

      return (
        <MessageCardCollapsibleMessage contentHeightModel={contentHeightModel}>
          {({ contentRef }) => {
            return <SlateContent ref={contentRef}>{message}</SlateContent>;
          }}
        </MessageCardCollapsibleMessage>
      );
    };

    const renderGallery = () => {
      if (resources.length === 0) {
        return null;
      }

      return <MessageCardGallery resources={resources} />;
    };

    const renderOrigin = () => {
      if (feed.type === "posts" && feed.isOriginScreen && feed.origin && feed.origin.id === origin.id) {
        return null;
      }

      return (
        <Box
          component="a"
          sx={[{ all: "unset" }, { cursor: "pointer", position: "relative" }]}
          target="_blank"
          href={baseUrl + mapOriginToPathname(origin)}
        >
          <MessageCardOrigin origin={origin} />
        </Box>
      );
    };

    const renderLikeButton = () => {
      return (
        <PostLikeButtonContainer post={post}>
          {(props) => <MessageCardLikeButton {...props} message={post} />}
        </PostLikeButtonContainer>
      );
    };

    // const renderCommentButton = () => {
    //   if (feed.type === "comments") {
    //     return null;
    //   }

    //   return (
    //     <PostCommentButtonContainer post={post}>
    //       {(props) => (
    //         <Box
    //           component="a"
    //           sx={[{ all: "unset" }, { cursor: "pointer", position: "relative" }]}
    //           target="_blank"
    //           href={baseUrl + mapOriginToPathname(origin)}
    //         >
    //           <PostCardCommentButton post={post} />
    //         </Box>
    //       )}
    //     </PostCommentButtonContainer>
    //   );
    // };

    const renderControls = () => {
      const gap = 8;

      return (
        <Box sx={{ alignItems: "center", display: "flex", gap: toPx(gap) }}>
          <Box sx={{ alignItems: "center", display: "flex", flexGrow: 1, gap: toPx(gap) }}>
            {renderLikeButton()}
            {/* {renderCommentButton()} */}
          </Box>
          <Box sx={{ alignItems: "center", display: "flex", gap: toPx(gap), ml: "auto" }}></Box>
        </Box>
      );
    };

    return (
      <MessageCardLayout
        {...props}
        data-testid="regular-post-card"
        ref={ref}
        sx={[
          !isPinned
            ? null
            : { backgroundColor: themeColors.surfacePrimaryDefault, borderColor: themeColors.basePrimary },
          sx,
        ].flat()}
      >
        {renderLink()}
        <MessageCardLayoutContent>
          {foldElement(
            <MessageCardLayoutContentSection>
              {renderHeader()}
              {renderMessage()}
              {renderGallery()}
              {renderOrigin()}
            </MessageCardLayoutContentSection>
          )}
          {foldElement(<MessageCardLayoutContentSection>{renderControls()}</MessageCardLayoutContentSection>)}
        </MessageCardLayoutContent>
      </MessageCardLayout>
    );
  }
);

export type RegularPostCardPropsT = RegularPostCardContentPropsT;

export const OfficeRegularPostCard = forwardFunctionalComponentRef((props: RegularPostCardPropsT, forwardedRef) => {
  return (
    <Suspense fallback={<MessageCardSkeleton {...props} ref={forwardedRef} />}>
      <OfficeRegularPostCardContent {...props} ref={forwardedRef} />
    </Suspense>
  );
});
