import { produce } from 'immer'

import type { ResourceObjectsQueryT } from '@resnet/client-api/api'
import {
  useArchiveResourceObjectMutation,
  useCreateResourceObjectMutation,
  useDeleteResourceObjectMutation,
  useInfiniteResourceObjectsQuery,
  useGetResourceObjectQuery,
  useResourceObjectsQuery,
  useUnarchiveResourceObjectMutation,
  useUpdateResourceObjectMutation,
  useUpdateRelationsMutation,
  EntityTypeIdsT,
  useUpdateEntityTypeMutation,
} from '@resnet/client-api/api'
import type { MutationCacheHandlersT } from '@resnet/client-api/services/query-client/types/mutation-cache-handlers'
import { createMutationHookMutationCacheHandlers } from '@resnet/client-api/services/query-client/utils/create-mutation-hook-mutation-cache-handlers'
import {
  getEntityQueryUpdateRecipe,
  infiniteListEntityQueryUpdateRecipe,
  listEntityQueryUpdateRecipe,
} from '@resnet/client-api/shared/entities/utils/update-entity-recipe'
import { findGetQueriesById, findInfiniteQueries, findQueries } from '@resnet/client-api/utils/find-queries'
import { invalidateQuery } from '@resnet/client-api/utils/invalidate-query'
import { setQueryData } from '@resnet/client-api/utils/set-query-data'
import type { QueryClient } from '@resnet/client-api/vendors/react-query'

export const resourceObjectQueriesHandlers = ({
  queryClient,
}: {
  queryClient: QueryClient
}): MutationCacheHandlersT[] => {
  const findResourceObjectQueries = () => {
    return findQueries(queryClient, useGetResourceObjectQuery)
  }

  const findResourceObjectQueriesById = ({ id }: { id: string }) =>
    findGetQueriesById(queryClient, useGetResourceObjectQuery, (data) => data.getResourceObject, { id })

  const deleteResourceObjectHandlers = () => {
    return createMutationHookMutationCacheHandlers(useDeleteResourceObjectMutation, {
      onSuccess: (data, { id }) => {
        findResourceObjectQueriesById({ id }).forEach(invalidateQuery(queryClient))
      },
    })
  }

  const updateResourceObjectHandlers = () => {
    return createMutationHookMutationCacheHandlers(useUpdateResourceObjectMutation, {
      onError: (error, { id }) => {
        findResourceObjectQueriesById({ id }).forEach(invalidateQuery(queryClient))
      },
      onMutate: ({ id, data: input }) => {
        findResourceObjectQueriesById({ id }).forEach((query) =>
          setQueryData(queryClient)(query)(
            produce((data) => {
              getEntityQueryUpdateRecipe({
                data,
                input,
                mapDataToEntity: (data) => data.getResourceObject,
              })
            }),
          ),
        )
      },
      onSuccess: (data, { id }) => {
        findResourceObjectQueriesById({ id }).forEach(invalidateQuery(queryClient))
      },
    })
  }

  const archiveResourceObjectHandlers = () => {
    return createMutationHookMutationCacheHandlers(useArchiveResourceObjectMutation, {
      onSuccess: (data, { id }) => {
        findResourceObjectQueriesById({ id }).forEach(invalidateQuery(queryClient))
      },
    })
  }

  const unarchiveResourceObjectHandlers = () => {
    return createMutationHookMutationCacheHandlers(useUnarchiveResourceObjectMutation, {
      onSuccess: (data, { id }) => {
        findResourceObjectQueriesById({ id }).forEach(invalidateQuery(queryClient))
      },
    })
  }

  const updateRelationsHandlers = () => {
    return createMutationHookMutationCacheHandlers(useUpdateRelationsMutation, {
      onSuccess: (data, { entityId }) => {
        findResourceObjectQueriesById({ id: entityId }).forEach(invalidateQuery(queryClient))
      },
    })
  }

  const updateEntityTypeHandlers = () => {
    return createMutationHookMutationCacheHandlers(useUpdateEntityTypeMutation, {
      onSuccess: (data, { id }) => {
        if (id !== EntityTypeIdsT.ResourceObjectT) {
          return
        }

        findResourceObjectQueries().forEach(invalidateQuery(queryClient))
      },
    })
  }

  return [
    deleteResourceObjectHandlers(),
    updateResourceObjectHandlers(),
    archiveResourceObjectHandlers(),
    unarchiveResourceObjectHandlers(),
    updateRelationsHandlers(),
    updateEntityTypeHandlers(),
  ]
}

export const resourceObjectsQueriesHandlers = ({
  queryClient,
}: {
  queryClient: QueryClient
}): MutationCacheHandlersT[] => {
  const findResourceObjectsQueries = () => findQueries(queryClient, useResourceObjectsQuery)

  const findInfiniteResourceObjectsQueries = () => findInfiniteQueries(queryClient, useInfiniteResourceObjectsQuery)

  const deleteResourceObject = (data: undefined | ResourceObjectsQueryT, { id }: { id: string }) => {
    if (!data) {
      return
    }

    const items = data.listResourceObjects.items

    const index = items.findIndex((item) => item.id === id)

    if (index === -1) {
      return
    }

    items.splice(index, 1)
  }

  const createResourceObjectHandlers = () => {
    return createMutationHookMutationCacheHandlers(useCreateResourceObjectMutation, {
      onSuccess: () => {
        findResourceObjectsQueries().forEach(invalidateQuery(queryClient))
        findInfiniteResourceObjectsQueries().forEach(invalidateQuery(queryClient))
      },
    })
  }

  const deleteResourceObjectHandlers = () => {
    return createMutationHookMutationCacheHandlers(useDeleteResourceObjectMutation, {
      onError: () => {
        findResourceObjectsQueries().forEach(invalidateQuery(queryClient))
        findInfiniteResourceObjectsQueries().forEach(invalidateQuery(queryClient))
      },
      onMutate: ({ id }) => {
        findResourceObjectsQueries().forEach((query) =>
          setQueryData(queryClient)(query)(produce((data) => deleteResourceObject(data, { id }))),
        )
        findInfiniteResourceObjectsQueries().forEach((query) =>
          setQueryData(queryClient)(query)(
            produce((data) => data?.pages.forEach((page) => deleteResourceObject(page, { id }))),
          ),
        )
      },
      onSuccess: () => {
        findResourceObjectsQueries().forEach(invalidateQuery(queryClient))
        findInfiniteResourceObjectsQueries().forEach(invalidateQuery(queryClient))
      },
    })
  }

  const updateResourceObjectHandlers = () => {
    return createMutationHookMutationCacheHandlers(useUpdateResourceObjectMutation, {
      onError: () => {
        findResourceObjectsQueries().forEach(invalidateQuery(queryClient))
        findInfiniteResourceObjectsQueries().forEach(invalidateQuery(queryClient))
      },
      onMutate: ({ id, data: input }) => {
        findResourceObjectsQueries().forEach((query) =>
          setQueryData(queryClient)(query)(
            produce((data) => {
              listEntityQueryUpdateRecipe({
                data,
                id,
                input,
                mapDataToEntities: (data) => data.listResourceObjects.items,
              })
            }),
          ),
        )
        findInfiniteResourceObjectsQueries().forEach((query) =>
          setQueryData(queryClient)(query)(
            produce((data) => {
              infiniteListEntityQueryUpdateRecipe({
                data,
                id,
                input,
                mapDataToEntities: (data) => data.listResourceObjects.items,
              })
            }),
          ),
        )
      },
      onSuccess: () => {
        findResourceObjectsQueries().forEach(invalidateQuery(queryClient))
        findInfiniteResourceObjectsQueries().forEach(invalidateQuery(queryClient))
      },
    })
  }

  const archiveResourceObjectHandlers = () => {
    return createMutationHookMutationCacheHandlers(useArchiveResourceObjectMutation, {
      onSuccess: () => {
        findResourceObjectsQueries().forEach(invalidateQuery(queryClient))
        findInfiniteResourceObjectsQueries().forEach(invalidateQuery(queryClient))
      },
    })
  }

  const unarchiveResourceObjectHandlers = () => {
    return createMutationHookMutationCacheHandlers(useUnarchiveResourceObjectMutation, {
      onSuccess: () => {
        findResourceObjectsQueries().forEach(invalidateQuery(queryClient))
        findInfiniteResourceObjectsQueries().forEach(invalidateQuery(queryClient))
      },
    })
  }

  const updateRelationsHandlers = () => {
    return createMutationHookMutationCacheHandlers(useUpdateRelationsMutation, {
      onSuccess: () => {
        findResourceObjectsQueries().forEach(invalidateQuery(queryClient))
        findInfiniteResourceObjectsQueries().forEach(invalidateQuery(queryClient))
      },
    })
  }

  const updateEntityTypeHandlers = () => {
    return createMutationHookMutationCacheHandlers(useUpdateEntityTypeMutation, {
      onSuccess: (data, { id }) => {
        if (id !== EntityTypeIdsT.ResourceObjectT) {
          return
        }

        findInfiniteResourceObjectsQueries().forEach(invalidateQuery(queryClient))
      },
    })
  }

  return [
    createResourceObjectHandlers(),
    deleteResourceObjectHandlers(),
    updateResourceObjectHandlers(),
    archiveResourceObjectHandlers(),
    unarchiveResourceObjectHandlers(),
    updateRelationsHandlers(),
    updateEntityTypeHandlers(),
  ]
}
