import type { BoxProps, SxProps } from '@mui/material'
import { Box } from '@mui/material'
import type { SystemStyleObject } from '@mui/system'
import type { LinkProps } from 'react-router-dom'
import { Link } from 'react-router-dom'

import { propToKey } from '@resnet/client-common/common/utils/object/prop-to-key'
import type { ForwardableT } from '@resnet/client-common/react/types/forwardable'
import { forwardFunctionalComponentRef } from '@resnet/client-common/react/utils/forward-functional-component-ref'
import { renderForwardable } from '@resnet/client-common/react/utils/render-forwardable'
import type { MergeAllT } from '@resnet/client-common/typescript/types/merge-all'
import { createConstrainer } from '@resnet/client-common/typescript/utils/create-constrainer'

import { typographyPresets } from '@resnet/client-shared/shared/gdl/constants/typography-presets'

import { TextOverflow } from '@resnet/client-shared-web/shared/gdl/components/text-overflow'
import { themeColors } from '@resnet/client-shared-web/shared/gdl/constants/theme-colors'

import { focusOutlineSx } from '../../sx-presets/focus-outline'
import { mapTypographyPresetToSx } from '../../utils/map-typography-preset-to-sx'
import { toPx } from '../../utils/to-px'
import { Spinner } from '../spinner'

const sizes = createConstrainer<{ id: string }[]>()([
  { iconSize: 16, id: 'sm' as const, py: 8, typographyPreset: typographyPresets.labelSmall },
  { iconSize: 20, id: 'md' as const, py: 10, typographyPreset: typographyPresets.labelMedium },
  { iconSize: 24, id: 'lg' as const, py: 16, typographyPreset: typographyPresets.labelLarge },
])

const sizesById = propToKey('id', sizes)

const buttonSizeSx = ({
  borderSize,
  isIconButton,
  size,
}: {
  borderSize: number
  isIconButton: boolean
  size: (typeof sizes)[number]['id']
}): SxProps => {
  const pxMultiplier = isIconButton ? 1 : 2

  const { py, typographyPreset } = sizesById[size]

  return [
    { px: toPx(py * pxMultiplier - borderSize), py: toPx(py - borderSize) },
    mapTypographyPresetToSx(typographyPreset),
  ]
}

export type ButtonBaseCommonPropsT = {
  borderSize?: number
  children?: React.ReactNode
  endIcon?: ForwardableT<React.SVGProps<SVGSVGElement>>
  icon?: ForwardableT<React.SVGProps<SVGSVGElement>>
  isLoading?: boolean
  size: (typeof sizes)[number]['id']
  sx?: SxProps
}

export type ButtonBaseBoxPropsT<D extends React.ElementType> = Omit<BoxProps<D>, keyof ButtonBaseCommonPropsT>

export type ButtonBasePropsT<D extends React.ElementType> = ButtonBaseCommonPropsT & ButtonBaseBoxPropsT<D>

export type ButtonBaseRefT = React.Ref<HTMLElement>

export const ButtonBase = forwardFunctionalComponentRef(
  <D extends React.ElementType>(
    { borderSize = 1, children, endIcon, icon, isLoading, size, sx = null, ...props }: ButtonBasePropsT<D>,
    ref: ButtonBaseRefT,
  ) => {
    const isIconButton = Boolean(icon && !children && !endIcon) || Boolean(!icon && !children && endIcon)

    const { iconSize } = sizesById[size]

    const renderIcon = () => {
      if (!icon) {
        return null
      }

      return renderForwardable(icon, { height: iconSize, width: iconSize })
    }

    const renderLabel = () => {
      if (!children) {
        return null
      }

      return <TextOverflow sx={{ flexShrink: 999, minWidth: 0 }}>{children}</TextOverflow>
    }

    const renderEndIcon = () => {
      if (!endIcon) {
        return null
      }

      return renderForwardable(endIcon, { height: iconSize, width: iconSize })
    }

    const renderContent = () => {
      return (
        <Box
          sx={[
            {
              alignItems: 'center',
              display: 'flex',
              flexGrow: 1,
              gap: toPx(8),
              justifyContent: 'center',
              minWidth: 0,
            },
            !isLoading ? null : { opacity: 0 },
          ]}
        >
          {renderIcon()}
          {renderLabel()}
          {renderEndIcon()}
        </Box>
      )
    }

    const renderSpinner = () => {
      if (!isLoading) {
        return null
      }

      return (
        <Box
          sx={{
            alignItems: 'center',
            display: 'flex',
            inset: '0',
            justifyContent: 'center',
            position: 'absolute',
          }}
        >
          <Spinner
            color="inherit"
            size={iconSize}
          />
        </Box>
      )
    }

    return (
      <Box
        {...props}
        ref={ref}
        sx={[
          {
            all: 'unset',
          },
          {
            alignItems: 'center',
            borderRadius: toPx(8),
            borderStyle: 'solid',
            borderWidth: toPx(borderSize),
            cursor: 'pointer',
            display: 'flex',
            minWidth: 0,
            position: 'relative',
            transition: 'background-color 0.2s ease, border-color 0.2s ease, color 0.2s ease',
          },
          buttonSizeSx({ borderSize, isIconButton, size }),
          sx,
        ].flat()}
      >
        {renderContent()}
        {renderSpinner()}
      </Box>
    )
  },
)

const colors = createConstrainer<{ id: string }[]>()([
  { id: 'default' as const },
  { id: 'primary' as const },
  { id: 'danger' as const },
])

const variants = createConstrainer<{ id: string }[]>()([
  { id: 'contained' as const },
  { id: 'outlined' as const },
  { id: 'text' as const },
])

const buttonContainedDefaultSx = (): SxProps => {
  return [
    {
      backgroundColor: themeColors.surfaceNeutralDefault,
      borderColor: themeColors.borderFaded,
      color: themeColors.overBackgroundBold,
    },
    {
      '&:hover': {
        backgroundColor: themeColors.surfaceNeutralHover,
        borderColor: themeColors.surfaceNeutralHover,
        color: themeColors.overBackgroundBold,
      },
    },
    {
      '&:active': {
        backgroundColor: themeColors.surfaceNeutralPressed,
        borderColor: themeColors.surfaceNeutralPressed,
        color: themeColors.overBackgroundBold,
      },
    },
  ]
}

const buttonContainedPrimarySx = (): SxProps => {
  return [
    {
      backgroundColor: themeColors.actionsPrimaryDefault,
      borderColor: themeColors.actionsPrimaryDefault,
      color: themeColors.actionsOverPrimaryDefault,
    },
    {
      '&:hover': {
        backgroundColor: themeColors.actionsPrimaryHover,
        borderColor: themeColors.actionsPrimaryHover,
        color: themeColors.actionsOverPrimaryDefault,
      },
    },
    {
      '&:active': {
        backgroundColor: themeColors.actionsPrimaryPressed,
        borderColor: themeColors.actionsPrimaryPressed,
        color: themeColors.actionsOverPrimaryDefault,
      },
    },
  ]
}

const buttonContainedDangerSx = (): SxProps => {
  return [
    {
      backgroundColor: themeColors.actionsDangerDefault,
      borderColor: themeColors.actionsDangerDefault,
      color: themeColors.actionsOverDangerDefault,
    },
    {
      '&:hover': {
        backgroundColor: themeColors.actionsDangerHover,
        borderColor: themeColors.actionsDangerHover,
        color: themeColors.actionsOverDangerDefault,
      },
    },
    {
      '&:active': {
        backgroundColor: themeColors.actionsDangerPressed,
        borderColor: themeColors.actionsDangerPressed,
        color: themeColors.actionsOverDangerDefault,
      },
    },
  ]
}

const buttonOutlinedDefaultSx = (): SxProps => {
  return [
    {
      backgroundColor: 'transparent',
      borderColor: themeColors.surfaceNeutralDefault,
      color: themeColors.surfaceNeutralDefault,
    },
    {
      '&:hover': {
        backgroundColor: themeColors.surfaceNeutralHover,
        borderColor: themeColors.surfaceNeutralHover,
        color: themeColors.overBackgroundBold,
      },
    },
    {
      '&:active': {
        backgroundColor: themeColors.surfaceNeutralPressed,
        borderColor: themeColors.surfaceNeutralPressed,
        color: themeColors.overBackgroundBold,
      },
    },
  ]
}

const buttonOutlinedPrimarySx = (): SxProps => {
  return [
    {
      backgroundColor: 'transparent',
      borderColor: themeColors.actionsPrimaryDefault,
      color: themeColors.actionsPrimaryDefault,
    },
    {
      '&:hover': {
        backgroundColor: themeColors.actionsPrimaryHover,
        borderColor: themeColors.actionsPrimaryHover,
        color: themeColors.actionsOverPrimaryDefault,
      },
    },
    {
      '&:active': {
        backgroundColor: themeColors.actionsPrimaryPressed,
        borderColor: themeColors.actionsPrimaryPressed,
        color: themeColors.actionsOverPrimaryDefault,
      },
    },
  ]
}

const buttonOutlinedDangerSx = (): SxProps => {
  return [
    {
      backgroundColor: 'transparent',
      borderColor: themeColors.actionsDangerDefault,
      color: themeColors.actionsDangerDefault,
    },
    {
      '&:hover': {
        backgroundColor: themeColors.actionsDangerHover,
        borderColor: themeColors.actionsDangerHover,
        color: themeColors.actionsOverDangerDefault,
      },
    },
    {
      '&:active': {
        backgroundColor: themeColors.actionsDangerPressed,
        borderColor: themeColors.actionsDangerPressed,
        color: themeColors.actionsOverDangerDefault,
      },
    },
  ]
}

const buttonTextDefaultSx = (): SxProps => {
  return [
    {
      backgroundColor: 'transparent',
      borderColor: 'transparent',
      color: themeColors.surfaceNeutralDefault,
    },
    {
      '&:hover': {
        backgroundColor: themeColors.surfaceNeutralHover,
        borderColor: themeColors.surfaceNeutralHover,
        color: themeColors.overBackgroundBold,
      },
    },
    {
      '&:active': {
        backgroundColor: themeColors.surfaceNeutralPressed,
        borderColor: themeColors.surfaceNeutralPressed,
      },
    },
  ]
}

const buttonTextPrimarySx = (): SxProps => {
  return [
    {
      backgroundColor: 'transparent',
      borderColor: 'transparent',
      color: themeColors.actionsPrimaryDefault,
    },
    {
      '&:hover': {
        backgroundColor: themeColors.actionsPrimaryHover,
        borderColor: themeColors.actionsPrimaryHover,
        color: themeColors.actionsOverPrimaryDefault,
      },
    },
    {
      '&:active': {
        backgroundColor: themeColors.actionsPrimaryPressed,
        borderColor: themeColors.actionsPrimaryPressed,
        color: themeColors.actionsOverPrimaryDefault,
      },
    },
  ]
}

const buttonTextDangerSx = (): SxProps => {
  return [
    {
      backgroundColor: 'transparent',
      borderColor: 'transparent',
      color: themeColors.actionsDangerDefault,
    },
    {
      '&:hover': {
        backgroundColor: themeColors.actionsDangerHover,
        borderColor: themeColors.actionsDangerHover,
        color: themeColors.actionsOverPrimaryDefault,
      },
    },
    {
      '&:active': {
        backgroundColor: themeColors.actionsDangerPressed,
        borderColor: themeColors.actionsDangerPressed,
        color: themeColors.actionsOverPrimaryDefault,
      },
    },
  ]
}

const buttonVariantColorSx = ({
  variant,
  color,
}: {
  variant: (typeof variants)[number]['id']
  color: (typeof colors)[number]['id']
}) => {
  switch (variant) {
    case 'contained': {
      switch (color) {
        case 'default': {
          return buttonContainedDefaultSx()
        }
        case 'primary': {
          return buttonContainedPrimarySx()
        }
        case 'danger': {
          return buttonContainedDangerSx()
        }
      }
      break
    }
    case 'outlined': {
      switch (color) {
        case 'default': {
          return buttonOutlinedDefaultSx()
        }
        case 'primary': {
          return buttonOutlinedPrimarySx()
        }
        case 'danger': {
          return buttonOutlinedDangerSx()
        }
      }
      break
    }
    case 'text': {
      switch (color) {
        case 'default': {
          return buttonTextDefaultSx()
        }
        case 'primary': {
          return buttonTextPrimarySx()
        }
        case 'danger': {
          return buttonTextDangerSx()
        }
      }
      break
    }
  }
}

const buttonDisabledSx = (): SystemStyleObject => {
  return {
    backgroundColor: themeColors.actionsDisabled,
    borderColor: themeColors.actionsDisabled,
    color: themeColors.actionsOverDisabled,
    cursor: 'not-allowed',
  }
}

export type ButtonCommonPropsT = ButtonBaseCommonPropsT & {
  variant: (typeof variants)[number]['id']
  color: (typeof colors)[number]['id']
  disabled?: boolean
}

export type ButtonPlaceholderPropsT = MergeAllT<[Omit<BoxProps<'div'>, 'ref'>, ButtonCommonPropsT]>

export type ButtonPlaceholderRefT = ButtonBaseRefT

export const ButtonPlaceholder = forwardFunctionalComponentRef(
  ({ color, disabled, sx = null, variant, ...props }: ButtonPlaceholderPropsT, ref: ButtonPlaceholderRefT) => {
    return (
      <ButtonBase
        {...props}
        ref={ref}
        sx={[
          buttonVariantColorSx({ color, variant }),
          {
            'input:focus-visible + &': focusOutlineSx,
          },
          !disabled ? null : { '&&': buttonDisabledSx() },
          sx,
        ].flat()}
      />
    )
  },
)

export type ButtonAnchorPropsT = MergeAllT<[Omit<BoxProps<'a'>, 'ref'>, ButtonCommonPropsT]>

export type ButtonAnchorRefT = ButtonBaseRefT

export const ButtonAnchor = forwardFunctionalComponentRef(
  ({ color, sx = null, variant, ...props }: ButtonAnchorPropsT, ref: ButtonAnchorRefT) => {
    return (
      <ButtonBase
        {...props}
        component="a"
        ref={ref}
        sx={[
          buttonVariantColorSx({ color, variant }),
          {
            '&:focus-visible': focusOutlineSx,
          },
          {
            '&:disabled': buttonDisabledSx(),
          },
          sx,
        ].flat()}
      />
    )
  },
)

export type ButtonLinkPropsT = MergeAllT<[Omit<BoxProps<'a'>, 'ref'>, LinkProps, ButtonCommonPropsT]>

export type ButtonLinkRefT = ButtonBaseRefT

export const ButtonLink = forwardFunctionalComponentRef(
  ({ color, sx = null, variant, ...props }: ButtonLinkPropsT, ref: ButtonLinkRefT) => {
    return (
      <ButtonBase
        {...props}
        component={Link}
        ref={ref}
        sx={[
          buttonVariantColorSx({ color, variant }),
          {
            '&:focus-visible': focusOutlineSx,
          },
          {
            '&:disabled': buttonDisabledSx(),
          },
          sx,
        ].flat()}
      />
    )
  },
)

export type ButtonButtonPropsT = MergeAllT<[Omit<BoxProps<'button'>, 'ref'>, ButtonCommonPropsT]>

export type ButtonButtonRefT = ButtonBaseRefT

export const ButtonButton = forwardFunctionalComponentRef(
  (
    { color, isLoading, onClick, sx = null, type = 'button', variant, ...props }: ButtonButtonPropsT,
    ref: ButtonButtonRefT,
  ) => {
    return (
      <ButtonBase
        {...props}
        component="button"
        isLoading={isLoading}
        ref={ref}
        sx={[
          buttonVariantColorSx({ color, variant }),
          {
            '&:focus-visible': focusOutlineSx,
          },
          {
            '&:disabled': buttonDisabledSx(),
          },
          sx,
        ].flat()}
        type={type}
        onClick={isLoading ? undefined : onClick}
      />
    )
  },
)

export type ButtonPropsT =
  | MergeAllT<[ButtonPlaceholderPropsT, { type: 'placeholder' }]>
  | MergeAllT<[ButtonAnchorPropsT, { type: 'anchor' }]>
  | MergeAllT<[ButtonLinkPropsT, { type: 'link' }]>
  | MergeAllT<[ButtonButtonPropsT, { type?: 'button' | 'submit' }]>

export type ButtonRefT = ButtonBaseRefT

export const Button = forwardFunctionalComponentRef((props: ButtonPropsT, ref: ButtonRefT) => {
  switch (props.type) {
    case 'placeholder': {
      const { type: _type, ...placeholderProps } = props

      return (
        <ButtonPlaceholder
          {...placeholderProps}
          ref={ref}
        />
      )
    }
    case 'anchor': {
      const { type: _type, ...anchorProps } = props

      return (
        <ButtonAnchor
          {...anchorProps}
          ref={ref}
        />
      )
    }
    case 'link': {
      const { type: _type, ...linkProps } = props

      return (
        <ButtonLink
          {...linkProps}
          ref={ref}
        />
      )
    }
    default: {
      const buttonProps = props

      return (
        <ButtonButton
          {...buttonProps}
          ref={ref}
        />
      )
    }
  }
})
