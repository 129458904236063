import { SingleLineText } from '@resnet/client-shared-web/shared/gdl/components/single-line-text'

import type { RegularCellPropsT } from '../regular-cell'
import { RegularCell } from '../regular-cell'

export type NumberCellValueT = undefined | null | number

export type NumberCellPropsT = RegularCellPropsT & {
  value: NumberCellValueT
}

export const NumberCell = ({ value, ...props }: NumberCellPropsT) => {
  const renderContent = () => {
    if (value === undefined || value === null) {
      return '-'
    }

    return value
  }

  return (
    <RegularCell {...props}>
      <SingleLineText>{renderContent()}</SingleLineText>
    </RegularCell>
  )
}
