import type { QueryClient } from '@tanstack/react-query'
import { produce } from 'immer'

import {
  useTeamQuery,
  useDeleteTeamMutation,
  useInfiniteTeamsQuery,
  useCreateTeamMutation,
  useUpdateTeamMutation,
} from '@resnet/client-api/api'
import type { MutationCacheHandlersT } from '@resnet/client-api/services/query-client/types/mutation-cache-handlers'
import { createMutationHookMutationCacheHandlers } from '@resnet/client-api/services/query-client/utils/create-mutation-hook-mutation-cache-handlers'
import { findGetQueriesById, findInfiniteQueries } from '@resnet/client-api/utils/find-queries'
import { invalidateQuery } from '@resnet/client-api/utils/invalidate-query'
import { setQueryData } from '@resnet/client-api/utils/set-query-data'

export const teamQueriesHandlers = ({ queryClient }: { queryClient: QueryClient }): MutationCacheHandlersT[] => {
  const findTeamQueries = ({ id }: { id: string }) =>
    findGetQueriesById(queryClient, useTeamQuery, (data) => data.getTeam, { id })

  const updateTeamHandlers = () => {
    return createMutationHookMutationCacheHandlers(useUpdateTeamMutation, {
      onError: (error, { id }) => {
        findTeamQueries({ id }).forEach(invalidateQuery(queryClient))
      },
      onMutate: ({ id, data }) => {
        findTeamQueries({ id }).forEach((query) => {
          setQueryData(queryClient)(query)(
            produce((draft) => {
              const team = draft?.getTeam

              if (!team) {
                return
              }

              Object.assign(team, data)
            }),
          )
        })
      },
      onSuccess: (data, { id }) => {
        findTeamQueries({ id }).forEach(invalidateQuery(queryClient))
      },
    })
  }

  const deleteTeamHandlers = () => {
    return createMutationHookMutationCacheHandlers(useDeleteTeamMutation, {
      onSuccess: (data, { id }) => {
        findTeamQueries({ id }).forEach(invalidateQuery(queryClient))
      },
    })
  }

  return [updateTeamHandlers(), deleteTeamHandlers()]
}

export const infiniteTeamsQueriesHandlers = ({
  queryClient,
}: {
  queryClient: QueryClient
}): MutationCacheHandlersT[] => {
  const findInfiniteTeamsQueries = () => findInfiniteQueries(queryClient, useInfiniteTeamsQuery)

  const createTeamHandlers = () => {
    return createMutationHookMutationCacheHandlers(useCreateTeamMutation, {
      onSuccess: () => {
        findInfiniteTeamsQueries().forEach(invalidateQuery(queryClient))
      },
    })
  }

  const updateTeamHandlers = () => {
    return createMutationHookMutationCacheHandlers(useUpdateTeamMutation, {
      onSuccess: () => {
        findInfiniteTeamsQueries().forEach(invalidateQuery(queryClient))
      },
    })
  }

  const deleteTeamHandlers = () => {
    return createMutationHookMutationCacheHandlers(useDeleteTeamMutation, {
      onSuccess: () => {
        findInfiniteTeamsQueries().forEach(invalidateQuery(queryClient))
      },
    })
  }

  return [createTeamHandlers(), updateTeamHandlers(), deleteTeamHandlers()]
}
