import { useMemo } from 'react'
import { type FieldPathByValue, type FieldValues, type UseFormReturn } from 'react-hook-form'

import { pipeline } from '@resnet/client-common/common/utils/function/pipeline'
import { checkNonNullable } from '@resnet/client-common/common/utils/nullable/non-nullable'
import { assert } from '@resnet/client-common/typescript/utils/assert'

import { CustomFieldTypeT, type CustomFieldFragmentT } from '@resnet/client-api/api'

import type {
  OptionGroupCustomFieldValueT,
  OptionGroupMultipleCustomFieldValueT,
} from '@resnet/client-shared/shared/custom-fields/presets/option-group/custom-field-value'
import { validateRequired } from '@resnet/client-shared/shared/forms/validators/any-required'

import { SimpleStaticOptionsDropdownField } from '@resnet/client-shared-web/shared/form/components/common/simple-static-options-dropdown-field'
import { StaticSimpleOptionsMultipleDropdownField } from '@resnet/client-shared-web/shared/form/components/common/simple-static-options-multiple-dropdown-field'

import { ObjectDetailsRow } from '@resnet/client-web/shared/object/components/object-details-row'

export type OptionGroupCustomFieldUserBulkFormFieldPropsT<
  TFieldValues extends FieldValues,
  TPath extends FieldPathByValue<TFieldValues, undefined | OptionGroupCustomFieldValueT>,
> = {
  field: CustomFieldFragmentT
  form: UseFormReturn<TFieldValues>
  name: TPath
}

export const OptionGroupCustomFieldUserBulkFormField = <
  TFieldValues extends FieldValues,
  TPath extends FieldPathByValue<TFieldValues, undefined | OptionGroupCustomFieldValueT>,
>({
  field,
  form,
  name,
}: OptionGroupCustomFieldUserBulkFormFieldPropsT<TFieldValues, TPath>) => {
  const payload = field.payload

  assert(payload, checkNonNullable)

  const dropdownPayload = payload[CustomFieldTypeT.OptionGroupT]

  assert(dropdownPayload, checkNonNullable)

  const { required, options, multiple } = dropdownPayload

  const rules = useMemo(() => {
    return {
      validate: !required ? undefined : validateRequired,
    }
  }, [required])

  const value = form.watch(name)

  const availableOptions = useMemo(
    () =>
      pipeline(
        options,
        (x) => x.filter((option) => !option.isMarkedAsDeleted || option.key === value),
        (x) =>
          x.map((option) => ({
            id: option.key,
            name: option.name,
          })),
      ),
    [options, value],
  )

  if (multiple) {
    return (
      <ObjectDetailsRow>
        <StaticSimpleOptionsMultipleDropdownField
          form={form}
          label={field.name}
          name={name as unknown as FieldPathByValue<TFieldValues, undefined | OptionGroupMultipleCustomFieldValueT>}
          options={availableOptions}
          rules={rules}
        />
      </ObjectDetailsRow>
    )
  }

  return (
    <ObjectDetailsRow>
      <SimpleStaticOptionsDropdownField
        form={form}
        label={field.name}
        name={name}
        options={availableOptions}
        rules={rules}
      />
    </ObjectDetailsRow>
  )
}
