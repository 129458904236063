import type { QueryClient } from '@tanstack/react-query'
import { produce } from 'immer'

import { assertedNonNullable } from '@resnet/client-common/common/utils/nullable/non-nullable'

import { useMyPhotosTakenQuery, useDeleteResourceMutation } from '@resnet/client-api/api'
import type { MutationCacheHandlersT } from '@resnet/client-api/services/query-client/types/mutation-cache-handlers'
import { createMutationHookMutationCacheHandlers } from '@resnet/client-api/services/query-client/utils/create-mutation-hook-mutation-cache-handlers'
import { findQueries } from '@resnet/client-api/utils/find-queries'
import { setQueryData } from '@resnet/client-api/utils/set-query-data'

export const gamificationMutationCacheHandlers = ({
  queryClient,
}: {
  queryClient: QueryClient
}): MutationCacheHandlersT[] => {
  const findMyPhotosTakenQueriesByItemId = ({ id }: { id: string }) => {
    return findQueries(queryClient, useMyPhotosTakenQuery, (query) => {
      return assertedNonNullable(query.state.data?.getMyPhotosTaken?.items).some((item) => item.id === id)
    })
  }

  const invalidateMyPhotosTakenQueriesByItemId = ({ id }: { id: string }) => {
    findMyPhotosTakenQueriesByItemId({ id }).forEach((query) => {
      queryClient.invalidateQueries(query.queryKey, { exact: true })
    })
  }

  const deleteItemFromMyPhotosTakenQueries = ({ id }: { id: string }) => {
    findMyPhotosTakenQueriesByItemId({ id }).forEach((query) => {
      setQueryData(queryClient)(query)(
        produce((draft) => {
          const items = assertedNonNullable(draft?.getMyPhotosTaken?.items)

          if (!items) {
            return
          }

          const index = items.findIndex((item) => item.id === id)

          if (index === -1) {
            return
          }

          items.splice(index, 1)
        }),
      )
    })
  }

  const myPhotosTakenQueriesDeleteResourceHandlers = () => {
    return createMutationHookMutationCacheHandlers(useDeleteResourceMutation, {
      onError: (error, { id }) => {
        invalidateMyPhotosTakenQueriesByItemId({ id })
      },
      onMutate: ({ id }) => {
        deleteItemFromMyPhotosTakenQueries({ id })
      },
      onSuccess: (data, { id }) => {
        invalidateMyPhotosTakenQueriesByItemId({ id })
      },
    })
  }

  return [myPhotosTakenQueriesDeleteResourceHandlers()]
}
