import type { GridColDef } from '@mui/x-data-grid'

import { checkNonNullable } from '@resnet/client-common/common/utils/nullable/non-nullable'
import { pluralize } from '@resnet/client-common/common/utils/string/pluralize'
import { assert } from '@resnet/client-common/typescript/utils/assert'

import { CustomFieldTypeT } from '@resnet/client-api/api'

import type { AttachmentCustomFieldValueT } from '@resnet/client-shared/shared/custom-fields/presets/attachment/custom-field-value'

import { Tag } from '@resnet/client-shared-web/shared/gdl/components/tag'
import { themeColors } from '@resnet/client-shared-web/shared/gdl/constants/theme-colors'

import type { ColumnWithCreateGanttColumnT } from '@resnet/client-web/shared/gantt/types/column-with-create-gantt-column'
import { createSimpleGanttColumn } from '@resnet/client-web/shared/gantt/utils/create-simple-gantt-column'
import { RegularCell } from '@resnet/client-web/shared/tables/components/regular-cell'
import { createColumn as createCommonColumn } from '@resnet/client-web/shared/tables/factories/create-column'
import type { ColumnWithEditFieldT } from '@resnet/client-web/shared/tables/types/column-with-edit-field'
import type { ColumnWithGroupingT } from '@resnet/client-web/shared/tables/types/column-with-grouping'
import type { ColumnWithHeaderMenuFilterT } from '@resnet/client-web/shared/tables/types/column-with-header-menu-filter'
import type { ColumnWithValueT } from '@resnet/client-web/shared/tables/types/column-with-value'

import type { CreateColumnPropsT, CustomFieldT } from '../../types/custom-field'
import { getFieldValue } from '../../utils/get-field-value'
import { mapCustomFieldToFullKey } from '../../utils/map-custom-field-to-key'

export const createColumn: CustomFieldT['createColumn'] = <
  RowT extends Record<string, unknown>,
  FieldValuesKeyT extends Extract<keyof RowT, string>,
>({
  field,
  fieldValuesKey,
  mapRowToPathname,
}: CreateColumnPropsT<RowT, FieldValuesKeyT>) => {
  type ColumnT = ColumnWithHeaderMenuFilterT<
    ColumnWithEditFieldT<ColumnWithGroupingT<ColumnWithCreateGanttColumnT<GridColDef<RowT>>>>
  >

  type ColumnWithValueAppliedT = ColumnWithValueT<ColumnT, undefined | AttachmentCustomFieldValueT>

  const payload = field.payload

  assert(payload, checkNonNullable)

  const attachmentPayload = payload[CustomFieldTypeT.AttachmentT]

  assert(attachmentPayload, checkNonNullable)

  const { multiple } = attachmentPayload

  const getValue = (row: Record<string, unknown>) =>
    getFieldValue<AttachmentCustomFieldValueT>(field, fieldValuesKey, row)

  const createGanttColumn: ColumnT['createGanttColumn'] = ({ viewSettings, column }) =>
    createSimpleGanttColumn<Record<string, unknown>, ColumnT>({
      column,
      data: ({ row, vido }) => {
        if (row.isGroup) {
          return null
        }

        const style = {
          color: themeColors.overBackgroundDefault,
          textDecoration: 'none',
          verticalAlign: 'baseline',
        }

        const value = getValue(row)

        if (value === undefined || value === null) {
          return vido.html`<div style=${vido.styleMap(style)}>-</div>`
        }

        const length = [value].flat().filter(checkNonNullable).length

        const text = `${length} ${pluralize(length, 'Attachment')}`

        return vido.html`<div style=${vido.styleMap(style)}>${text}</div>`
      },
      isHTML: false,
      sortable: ({ row }) => {
        const value = getValue(row)

        if (value === undefined || value === null) {
          return -Infinity
        }

        const length = [value].flat().filter(checkNonNullable).length

        return length
      },
      viewSettings,
      width: 100,
    })

  const columnField = mapCustomFieldToFullKey(field, fieldValuesKey)

  return createCommonColumn<ColumnWithValueAppliedT>({
    createGanttColumn,
    field: columnField,
    filterOptionId: columnField,
    groupRowRenderCell: (rowNode) => {
      const { groupingKey } = rowNode

      assert(groupingKey, checkNonNullable)

      return <RegularCell>{groupingKey}</RegularCell>
    },
    groupable: multiple ? false : true,
    groupingValueGetter: ({ row }) => {
      const value = getValue(row)

      if (value === undefined) {
        return undefined
      }

      const length = [value].flat().filter(checkNonNullable).length

      const text = `${length} ${pluralize(length, 'Attachment')}`

      return text
    },
    headerName: field.name,
    renderCell: ({ row, value }) => {
      const renderContent = () => {
        if (value === undefined) {
          return '-'
        }

        const length = [value].flat().filter(checkNonNullable).length

        const text = `${length} ${pluralize(length, 'Attachment')}`

        return <Tag uppercase>{text}</Tag>
      }

      return <RegularCell to={mapRowToPathname?.(row)}>{renderContent()}</RegularCell>
    },
    valueGetter: ({ row }) => getValue(row),
  })
}
