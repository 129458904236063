import type { AnyTypeT } from '@resnet/client-common/typescript/types/any-type'

import { checkIsSpecialValue } from '../utils/check-is-special-value'

export const validateListRequired = (value?: null | AnyTypeT[]) => {
  if (checkIsSpecialValue(value)) {
    return true
  }

  if (value == null || value.length === 0) {
    return 'This field is required'
  }

  return true
}
