import { Box } from '@mui/material'
import type { Path, PathValue, UseControllerProps, UseFormReturn } from 'react-hook-form'
import { useController } from 'react-hook-form'

import type { AnyTypeT } from '@resnet/client-common/typescript/types/any-type'

import type { entityTypeOptions } from '@resnet/client-shared/shared/entities/constants/entity-type-options'

import { Field } from '@resnet/client-shared-web/shared/gdl/components/field'
import { FieldErrorText } from '@resnet/client-shared-web/shared/gdl/components/field'
import { FieldLabel } from '@resnet/client-shared-web/shared/gdl/components/field'
import { toPx } from '@resnet/client-shared-web/shared/gdl/utils/to-px'

import { EntityTypeMultipleDropdown } from '@resnet/client-web/shared/entities/selects/entity-type-select-multiple'

export type EntityTypeSelectFieldPropsT<
  FieldValuesT extends Record<string, AnyTypeT>,
  FieldNameT extends Path<FieldValuesT>,
> = {
  defaultValue?: PathValue<FieldValuesT, FieldNameT>
  form: UseFormReturn<FieldValuesT>
  headerRight?: React.ReactNode
  name: FieldNameT
  label: string
  rules?: UseControllerProps<FieldValuesT, FieldNameT>['rules']
  exclude?: typeof entityTypeOptions
}

export const EntityTypeSelectMultipleField = <
  FieldValuesT extends Record<string, AnyTypeT>,
  FieldNameT extends Path<FieldValuesT>,
>({
  form,
  name,
  label,
  rules,
  defaultValue,
  headerRight,
  exclude,
}: EntityTypeSelectFieldPropsT<FieldValuesT, FieldNameT>) => {
  const {
    field,
    fieldState: { error, invalid },
  } = useController({ control: form.control, defaultValue, name, rules })

  const renderHeader = () => {
    return (
      <Box sx={{ alignItems: 'center', display: 'flex', gap: toPx(16) }}>
        <FieldLabel>{label}</FieldLabel>
        {headerRight}
      </Box>
    )
  }

  const renderInput = () => {
    return (
      <EntityTypeMultipleDropdown
        exclude={exclude}
        hasError={invalid}
        value={field.value}
        onChange={(value: PathValue<FieldValuesT, FieldNameT>) => {
          field.onChange(value)
        }}
      />
    )
  }

  const renderFooter = () => {
    if (!error) {
      return null
    }

    return <FieldErrorText>{error.message as string}</FieldErrorText>
  }

  return (
    <Field sx={{ flexGrow: 1, width: 0 }}>
      {renderHeader()}
      {renderInput()}
      {renderFooter()}
    </Field>
  )
}
