import { useMemo } from 'react'

import { checkNonUndefined } from '@resnet/client-common/common/utils/nullable/non-nullable'
import { createHookContainer } from '@resnet/client-common/react/utils/create-hook-container'
import type { SuccessSimpleQueryT } from '@resnet/client-common/react-query/types/query'
import { assert } from '@resnet/client-common/typescript/utils/assert'

import type { AbstractOptionT } from '../types/abstract-option'
import type { OptionContainerT } from '../types/option-container'

export const createStaticOptionContainer = <OptionT extends AbstractOptionT>({ options }: { options: OptionT[] }) => {
  const OptionContainer = createHookContainer(({ id }) => {
    const option = useMemo<OptionT>(() => {
      const option = options.find((option) => option.id === id)

      assert(option, checkNonUndefined)

      return option
    }, [id])

    const optionQuery = useMemo<SuccessSimpleQueryT<OptionT>>(() => {
      return {
        data: option,
        isSuccess: true,
      }
    }, [option])

    return {
      optionQuery,
    }
  }) as OptionContainerT<OptionT>

  return OptionContainer
}
