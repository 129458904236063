import type { BoxProps } from '@mui/material'
import { Box } from '@mui/material'

import { ChildrenDivider } from '@resnet/client-common/react/components/children-transformer'
import { forwardFunctionalComponentRef } from '@resnet/client-common/react/utils/forward-functional-component-ref'

import { Divider } from '@resnet/client-shared-web/shared/gdl/components/divider'
import { themeColors } from '@resnet/client-shared-web/shared/gdl/constants/theme-colors'
import { toPx } from '@resnet/client-shared-web/shared/gdl/utils/to-px'

export type MessageCardLayoutPropsT = BoxProps

export const MessageCardLayout = forwardFunctionalComponentRef(
  ({ sx = null, children, ...props }: MessageCardLayoutPropsT, ref) => {
    const borderWidth = 1

    return (
      <Box
        {...props}
        ref={ref}
        sx={[
          {
            backgroundColor: themeColors.surfaceNeutralDefault,
            borderColor: themeColors.borderDefault,
            borderRadius: toPx(8),
            borderStyle: 'solid',
            borderWidth: toPx(borderWidth),
            display: 'flex',
            flexDirection: 'column',
            gap: toPx(8),
            padding: toPx(16 - borderWidth),
            position: 'relative',
          },
          sx,
        ].flat()}
      >
        {children}
      </Box>
    )
  },
)

export type MessageCardLayoutContentPropsT = BoxProps

export const MessageCardLayoutContent = forwardFunctionalComponentRef(
  ({ sx = null, children, ...props }: MessageCardLayoutPropsT, ref) => {
    return (
      <Box
        {...props}
        ref={ref}
        sx={[
          {
            display: 'flex',
            flexDirection: 'column',
            gap: toPx(8),
          },
          sx,
        ].flat()}
      >
        <ChildrenDivider dividerNode={<Divider />}>{children}</ChildrenDivider>
      </Box>
    )
  },
)

export type MessageCardLayoutContentSectionPropsT = BoxProps

export const MessageCardLayoutContentSection = forwardFunctionalComponentRef(
  ({ sx = null, children, ...props }: MessageCardLayoutPropsT, ref) => {
    return (
      <Box
        {...props}
        ref={ref}
        sx={[
          {
            display: 'flex',
            flexDirection: 'column',
            gap: toPx(8),
          },
          sx,
        ].flat()}
      >
        {children}
      </Box>
    )
  },
)
