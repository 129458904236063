import type { BoxProps } from '@mui/material'
import { Box } from '@mui/material'
import { forwardRef } from 'react'

import { mapValues } from '@resnet/client-common/common/utils/object/map-values'
import type { ForwardableT } from '@resnet/client-common/react/types/forwardable'
import { renderForwardable } from '@resnet/client-common/react/utils/render-forwardable'
import type { MergeAllT } from '@resnet/client-common/typescript/types/merge-all'

import { propsBySize, propsByStatus } from '@resnet/client-shared/shared/gdl/constants/tag-props'

import { themeColors } from '@resnet/client-shared-web/shared/gdl/constants/theme-colors'

import { mapBorderWidthToValue } from '../../utils/map-border-width-to-value'
import { mapPaddingToValue } from '../../utils/map-padding-to-value'
import { toPx } from '../../utils/to-px'
import { TextOverflow } from '../text-overflow'

export type TagPropsT = MergeAllT<
  [
    BoxProps,
    {
      endIcon?: ForwardableT<React.SVGProps<SVGSVGElement>>
      icon?: ForwardableT<React.SVGProps<SVGSVGElement>>
      size?: keyof typeof propsBySize
      status?: keyof typeof propsByStatus
      uppercase?: boolean
      variant?: boolean
      children?: React.ReactNode
      customColors?: {
        backgroundColor?: string
        color?: string
        borderColor?: string
      }
    },
  ]
>

export const Tag = forwardRef(
  (
    {
      endIcon,
      icon,
      size = 'md',
      status = 'default',
      uppercase = false,
      variant = false,
      sx = null,
      children,
      customColors,
      ...props
    }: TagPropsT,
    ref,
  ) => {
    const { fontSize, fontWeight, iconOffset, iconSize, letterSpacing, paddingHorizontal, paddingVertical } =
      propsBySize[size]

    const { backgroundColor, borderColor, color } = {
      ...mapValues((color) => themeColors[color], propsByStatus[status]),
      ...customColors,
    }

    const renderIcon = () => {
      if (!icon) {
        return null
      }

      return renderForwardable(icon, {
        fill: color,
        height: toPx(iconSize),
        width: toPx(iconSize),
      })
    }

    const renderLabel = () => {
      if (!children) {
        return null
      }

      return (
        <TextOverflow
          sx={{
            color,
            flexShrink: 1,
            fontSize: toPx(fontSize),
            fontWeight,
            letterSpacing,
            lineHeight: toPx(iconSize),
            textTransform: uppercase ? 'uppercase' : 'none',
          }}
        >
          {children}
        </TextOverflow>
      )
    }

    const renderEndIcon = () => {
      if (!endIcon) {
        return null
      }

      return renderForwardable(endIcon, {
        fill: color,
        height: toPx(iconSize),
        width: toPx(iconSize),
      })
    }

    const borderWidth = 1

    const padding = { bottom: paddingVertical, left: paddingHorizontal, right: paddingHorizontal, top: paddingVertical }

    return (
      <Box
        {...props}
        ref={ref}
        sx={[
          {
            alignItems: 'center',
            backgroundColor: variant ? themeColors.surfaceVariantDefault : backgroundColor,
            borderColor,
            borderRadius: toPx(999),
            borderStyle: 'solid',
            borderWidth: mapBorderWidthToValue({ borderWidth }),
            display: 'inline-flex',
            gap: toPx(iconOffset),
            justifyContent: 'center',
            overflow: 'hidden',
            padding: mapPaddingToValue({ borderWidth, padding }),
            position: 'relative',
          },
          sx,
        ].flat()}
      >
        {renderIcon()}
        {renderLabel()}
        {renderEndIcon()}
      </Box>
    )
  },
)
