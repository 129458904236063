import { useRef, useEffect, useMemo } from 'react'
import type { UseControllerReturn } from 'react-hook-form'
import { useController } from 'react-hook-form'

import { useEventCallback } from '@resnet/client-common/react/hooks/use-event-callback'
import { useNonNullableContext } from '@resnet/client-common/react/hooks/use-non-nullable-context'

import { DateSelectDropdown } from '@resnet/client-shared-web/shared/gdl/components/date-select-dropdown'
import type { PopperRefValueT } from '@resnet/client-shared-web/shared/gdl/components/popper'

import { CellContext } from '../../contexts/cell-context'
import { EditableTableContext } from '../../contexts/editable-table'
import type { DateCellValueT } from '../date-cell'

export type EditDateCellPropsT = {
  name: string
}

export const EditDateCell = ({ name }: EditDateCellPropsT) => {
  const { useUpdateRowForm } = useNonNullableContext(EditableTableContext)

  const { api, row, field } = useNonNullableContext(CellContext)

  const { form, onSubmit } = useUpdateRowForm({ row })

  const {
    field: { value: valueActual, onChange: onChangeActual },
  } = useController({ control: form.control, name }) as UseControllerReturn<Record<string, DateCellValueT>, string>

  const value = useMemo(
    () => (valueActual === undefined || valueActual === null ? valueActual : new Date(valueActual)),
    [valueActual],
  )

  const popperRef = useRef<null | PopperRefValueT>(null)

  const onChange = useEventCallback(async (nextValue: null | Date) => {
    onChangeActual(nextValue === null ? nextValue : nextValue.toISOString())

    await onSubmit(form.getValues())
  })

  const onClear = useEventCallback(async () => {
    onChangeActual(null)

    await onSubmit(form.getValues())
  })

  const onClose = useEventCallback(() => {
    if (api.getCellMode(row.id, field) === 'edit') {
      api.stopCellEditMode({ field, id: row.id, ignoreModifications: true })
    }
  })

  useEffect(() => {
    popperRef.current?.open()
  }, [])

  return (
    <DateSelectDropdown
      popperRef={popperRef}
      size="sm"
      sx={{ flexGrow: 1 }}
      value={value}
      onChange={onChange}
      onClear={onClear}
      onClose={onClose}
    />
  )
}
