import { pipeline } from '@resnet/client-common/common/utils/function/pipeline'

import type { MediaT } from '@resnet/client-shared/shared/gdl/types/media'

import type { AbstractOptionT } from '@resnet/client-shared-web/shared/gdl/types/abstract-option'

import { DropdownInputPlaceholder, DropdownInputText } from '../dropdown-input'

export type StaticOptionsMultipleDropdownTextPropsT<OptionT extends AbstractOptionT> = {
  value?: OptionT['id'][]
  options: OptionT[]
  getOptionLabel?: (option: OptionT) => string
  getOptionMedia?: (option: OptionT) => MediaT
  multipleOptionSuffix?: string
  placeholder?: string
}

export const StaticOptionsMultipleDropdownText = <OptionT extends AbstractOptionT>({
  value,
  options,
  getOptionLabel,
  placeholder = 'Not Selected',
  multipleOptionSuffix = 'selected',
}: StaticOptionsMultipleDropdownTextPropsT<OptionT>): React.ReactElement => {
  if (!value || value.length === 0) {
    return <DropdownInputPlaceholder>{placeholder}</DropdownInputPlaceholder>
  }

  if (value.length === 1) {
    const selectedOption = pipeline(options, (options) => options.find((option) => value.includes(option.id)))

    if (!selectedOption) {
      return <DropdownInputPlaceholder>Option not found</DropdownInputPlaceholder>
    }

    return <DropdownInputText>{!getOptionLabel ? value : getOptionLabel(selectedOption)}</DropdownInputText>
  }

  return (
    <DropdownInputText>
      {value.length} {multipleOptionSuffix}
    </DropdownInputText>
  )
}
