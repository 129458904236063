import { Box } from '@mui/material'

import { foldElement } from '@resnet/client-common/react/utils/fold-element'

import { toPx } from '../../utils/to-px'

// This component is suitable when we have two groups of controls with same gap in one row

export type ControlsPropsT = {
  renderLeftItems?: () => React.ReactNode
  renderRightItems?: () => React.ReactNode
  gap: number
}

export const Controls = ({ renderLeftItems, renderRightItems, gap }: ControlsPropsT) => {
  const renderLeft = () => {
    return foldElement(
      <Box
        sx={{
          alignItems: 'center',
          display: 'flex',
          flexGrow: 1,
          gap: toPx(gap),
          minWidth: 0,
        }}
      >
        {renderLeftItems?.()}
      </Box>,
    )
  }

  const renderRight = () => {
    return foldElement(
      <Box
        sx={{
          alignItems: 'center',
          display: 'flex',
          gap: toPx(gap),
          minWidth: 0,
          ml: 'auto',
        }}
      >
        {renderRightItems?.()}
      </Box>,
    )
  }

  return (
    <Box
      sx={{
        alignItems: 'center',
        display: 'flex',
        gap: toPx(gap),
      }}
    >
      {renderLeft()}
      {renderRight()}
    </Box>
  )
}
