import type { BoxProps } from '@mui/material'
import { Box } from '@mui/material'

import { forwardFunctionalComponentRef } from '@resnet/client-common/react/utils/forward-functional-component-ref'
import type { MergeAllT } from '@resnet/client-common/typescript/types/merge-all'

import CalendarSolidIcon from '@resnet/client-shared/assets/icons/calendar-solid.svg'
import { typographyPresets } from '@resnet/client-shared/shared/gdl/constants/typography-presets'

import { TextSkeleton } from '@resnet/client-shared-web/shared/async/components/text-skeleton'
import { TextOverflow } from '@resnet/client-shared-web/shared/gdl/components/text-overflow'
import { themeColors } from '@resnet/client-shared-web/shared/gdl/constants/theme-colors'

import { mapTypographyPresetToSx } from '../../utils/map-typography-preset-to-sx'
import { toPx } from '../../utils/to-px'
import { sizeOptionsById } from '../text-input'

export type DateInputTextPropsT = MergeAllT<
  [
    BoxProps<'button'>,
    {
      hasError?: boolean
      size?: 'sm' | 'md'
    },
  ]
>

export type DateInputInputRefT = React.Ref<HTMLElement>

export const DateInputInput = forwardFunctionalComponentRef(
  (
    { children, disabled, hasError, onClick, size = 'md', sx = null, ...props }: DateInputTextPropsT,
    ref: DateInputInputRefT,
  ): React.ReactElement => {
    const { startSlotSize } = sizeOptionsById[size]

    const borderRadius = 8

    const borderWidth = 1

    const renderContent = (): React.ReactNode => {
      return <Box sx={{ display: 'flex', flexGrow: 1, flexShrink: 999, minWidth: 0 }}>{children}</Box>
    }

    const renderIcon = (): React.ReactNode => {
      const iconSize = startSlotSize

      return (
        <CalendarSolidIcon
          fill={themeColors.overBackgroundFaded}
          height={iconSize}
          width={iconSize}
        />
      )
    }

    return (
      <Box
        {...props}
        component="button"
        ref={ref}
        sx={[
          { all: 'unset' },
          {
            alignItems: 'center',
            backgroundColor: themeColors.surfaceNeutralDefault,
            borderColor: themeColors.borderFaded,
            borderRadius: toPx(borderRadius),
            borderStyle: 'solid',
            borderWidth: toPx(borderWidth),
            cursor: 'pointer',
            display: 'flex',
            gap: toPx(8),
            position: 'relative',
            px: toPx(16 - borderWidth),
            py: toPx(8 - borderWidth),
            transition: 'background-color 200ms ease',
          },
          {
            '&:hover': disabled
              ? {}
              : {
                  backgroundColor: themeColors.surfaceNeutralHover,
                },
          },
          {
            '&:active': {
              backgroundColor: themeColors.surfaceNeutralPressed,
            },
          },
          {
            '&:focus-visible': {
              outlineColor: themeColors.borderFocus,
              outlineStyle: 'solid',
              outlineWidth: toPx(2),
            },
          },
          !hasError ? null : { borderColor: themeColors.feedbackCritical },
          !disabled ? null : { cursor: 'not-allowed', opacity: 0.64 },
          sx,
        ].flat()}
        type="button"
        onClick={onClick}
      >
        {renderIcon()}
        {renderContent()}
      </Box>
    )
  },
)

export type DateInputInputTextPropsT = MergeAllT<
  [
    Omit<BoxProps<'div'>, 'ref'>,
    {
      size?: 'sm' | 'md'
    },
  ]
>

export const DateInputInputText = ({ children, size = 'md', sx = null, ...props }: DateInputInputTextPropsT) => {
  const { typographyPreset } = sizeOptionsById[size]

  return (
    <Box
      {...props}
      sx={[
        mapTypographyPresetToSx(typographyPreset),
        {
          color: themeColors.overBackgroundDefault,
          display: 'flex',
          minWidth: 0,
        },
        sx,
      ].flat()}
    >
      <TextOverflow>{children}</TextOverflow>
    </Box>
  )
}

export const DateInputInputTextSkeleton = (
  props: Omit<React.ComponentProps<typeof TextSkeleton>, 'typographyPreset'>,
): React.ReactElement => {
  return (
    <TextSkeleton
      {...props}
      contentSx={{ width: '70%' }}
      typographyPreset={typographyPresets.bodyMedium}
    />
  )
}

export const DateInputInputPlaceholder = ({
  sx = null,
  children,
  ...props
}: React.ComponentProps<typeof DateInputInputText>): React.ReactElement => {
  return (
    <DateInputInputText
      {...props}
      sx={[
        {
          color: themeColors.overBackgroundFaded,
        },
        sx,
      ].flat()}
    >
      {children}
    </DateInputInputText>
  )
}
