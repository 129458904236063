import { Box } from '@mui/material'
import { useEffect, useState } from 'react'
import type { NonUndefined, UseControllerReturn } from 'react-hook-form'
import { useController } from 'react-hook-form'

import { useEventCallback } from '@resnet/client-common/react/hooks/use-event-callback'
import { useNonNullableContext } from '@resnet/client-common/react/hooks/use-non-nullable-context'

import { NumberInput } from '@resnet/client-shared-web/shared/gdl/components/number-input'

import { CellContext } from '../../contexts/cell-context'
import { EditableTableContext } from '../../contexts/editable-table'
import type { NumberCellValueT } from '../number-cell'

export type EditNumberCellPropsT = {
  name: string
}

export const EditNumberCell = ({ name }: EditNumberCellPropsT) => {
  const { useUpdateRowForm } = useNonNullableContext(EditableTableContext)

  const { api, row, field } = useNonNullableContext(CellContext)

  const { form, onSubmit } = useUpdateRowForm({ row })

  const {
    field: { value: valueActual, onChange: onChangeActual },
  } = useController({ control: form.control, name }) as UseControllerReturn<
    Record<string, NonUndefined<NumberCellValueT>>,
    string
  >

  const inputRef = useEventCallback((element: null | HTMLInputElement) => {
    element?.focus()
  })

  const [value, setValue] = useState(valueActual)

  const onChange = useEventCallback((value: null | number) => {
    setValue(value)
  })

  const onBlur = useEventCallback(() => {
    if (api.getCellMode(row.id, field) === 'edit') {
      api.stopCellEditMode({ field, id: row.id, ignoreModifications: true })
    }
  })

  const onKeyDown = useEventCallback((event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key !== 'Escape') {
      return
    }

    setValue(valueActual)
  })

  const onUnmount = useEventCallback(async () => {
    if (value === valueActual) {
      return
    }

    onChangeActual(value)

    await onSubmit(form.getValues())
  })

  useEffect(
    () => () => {
      onUnmount()
    },
    [onUnmount],
  )

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', flexGrow: 1, minWidth: 0 }}>
      <NumberInput
        ref={inputRef}
        size="sm"
        value={value}
        onBlur={onBlur}
        onChange={onChange}
        onKeyDown={onKeyDown}
      />
    </Box>
  )
}
