import { assertedNonNullable } from '@resnet/client-common/common/utils/nullable/non-nullable'
import type { HTTPClientT } from '@resnet/client-common/network/utils/create-http-client'
import { createHTTPClient } from '@resnet/client-common/network/utils/create-http-client'

let authHttpClient: null | HTTPClientT = null

export const getAuthApiHttpClient = (): HTTPClientT => {
  return assertedNonNullable(authHttpClient)
}

export const setAuthApiHttpClient = (baseUrl: string): void => {
  authHttpClient = createHTTPClient(baseUrl)
}
