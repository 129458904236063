import { Tooltip, Box } from "@mui/material";
import React from "react";
import { Button } from "@resnet/client-shared-web/shared/gdl/components/button";
import GoToSolid from "@resnet/client-shared/assets/icons/go-to-solid.svg";
import HomeSolid from "@resnet/client-shared/assets/icons/house-solid.svg";
import { themeColors } from "@resnet/client-shared-web/shared/gdl/constants/theme-colors";
import { useHistory } from "react-router-dom";
import { toPx } from "@resnet/client-shared-web/shared/gdl/utils/to-px";
import { useSignOut } from "@resnet/client-api/auth-api/auth-context";

const SimpleHeader = (): React.ReactElement => {
  const navigate = useHistory();
  const signOut = useSignOut();

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        borderBottomWidth: 1,
        borderBottomColor: themeColors.borderDefault,
        borderBottomStyle: "solid",
        padding: "8px 16px",
      }}
    >
      <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
        <Box sx={{ height: "100" }}>
          <Tooltip title="Open in ResNet Cloud">
            <Button
              color="default"
              onClick={() => navigate.push("/taskpane.html")}
              icon={<HomeSolid />}
              size="sm"
              target="_blank"
              type="anchor"
              variant="contained"
            />
          </Tooltip>
        </Box>
      </Box>
      <Box sx={{ display: "flex", flexDirection: "row", gap: toPx(8) }}>
        <Tooltip title="Logout">
          <Button
            color="default"
            onClick={() => signOut()}
            icon={<GoToSolid />}
            size="sm"
            target="_blank"
            type="anchor"
            variant="contained"
          />
        </Tooltip>
      </Box>
    </Box>
  );
};

export default SimpleHeader;
