import { UserOptionContainer } from '@resnet/client-shared/shared/users/hooks/use-user-option'
import { mapUserToMedia } from '@resnet/client-shared/shared/users/utils/map-user-to-media-boston'
import { mapUserToTitle } from '@resnet/client-shared/shared/users/utils/map-user-to-title'

import { createSelect } from '@resnet/client-shared-web/shared/common/factories/create-select'
import { createSelectMultiple } from '@resnet/client-shared-web/shared/common/factories/create-select-multiple'

import { UsersSelectDropdownContainer } from '../../hooks/use-users-select-dropdown-vienna'

export const UserSelect = createSelect({
  OptionContainer: UserOptionContainer,
  SelectDropdownContainer: UsersSelectDropdownContainer,
  getOptionLabel: (option) => mapUserToTitle(option),
  getOptionMedia: (option) => mapUserToMedia(option),
})

export const UserSelectMultiple = createSelectMultiple({
  OptionContainer: UserOptionContainer,
  SelectDropdownContainer: UsersSelectDropdownContainer,
  getOptionLabel: (option) => mapUserToTitle(option),
  getOptionMedia: (option) => mapUserToMedia(option),
})
