import { checkNonNullable } from '@resnet/client-common/common/utils/nullable/non-nullable'
import { assert } from '@resnet/client-common/typescript/utils/assert'

import { CustomFieldTypeT, type CustomFieldFragmentT } from '@resnet/client-api/api'

import type { FormulaCustomFieldValueT } from '@resnet/client-shared/shared/custom-fields/presets/formula/custom-field-value'

import { FieldPreview } from '@resnet/client-shared-web/shared/gdl/components/field-preview'

import { ObjectDetailsRow } from '@resnet/client-web/shared/object/components/object-details-row'

export type FormulaFormUserFormPreviewPropsT = {
  field: CustomFieldFragmentT
  value: undefined | FormulaCustomFieldValueT
}

export const FormulaFormUserFormPreview = ({ field, value }: FormulaFormUserFormPreviewPropsT) => {
  const payload = field.payload

  assert(payload, checkNonNullable)

  const formulaPayload = payload[CustomFieldTypeT.FormulaT]

  assert(formulaPayload, checkNonNullable)

  const { isHalfWidth } = formulaPayload

  const getSizeSx = () => {
    if (isHalfWidth) {
      return null
    }

    return {
      gridColumn: 'span 2',
    }
  }

  return (
    <ObjectDetailsRow sx={getSizeSx()}>
      <FieldPreview
        label={field.name}
        value={value}
      />
    </ObjectDetailsRow>
  )
}
