import type { UserFragmentT } from '@resnet/client-api/api'

import { mapUserToFirstLastName } from './map-user-to-first-last-name'
import { mapUserToUsername } from './map-user-to-username'

export const mapUserToTitle = <UserT extends Pick<UserFragmentT, 'firstName' | 'lastName' | 'nickname' | 'email'>>(
  user: UserT,
) => {
  const firstLastName = mapUserToFirstLastName(user)

  if (firstLastName) {
    return firstLastName
  }

  const username = mapUserToUsername(user)

  return username
}
