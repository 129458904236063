import type { SxProps } from '@mui/material'
import type { UseQueryOptions } from '@tanstack/react-query'
import { matchPath, useLocation } from 'react-router-dom'

import { pipeline } from '@resnet/client-common/common/utils/function/pipeline'

import type { GetEntityQueryT, MinimalEntityFragmentT } from '@resnet/client-api/api'

import { EntityOptionContainer } from '@resnet/client-shared/shared/entities/hooks/use-entity-option'
import {
  mapEntityToEntityTypeId,
  mapEntityToEntityTypeIdTitle,
} from '@resnet/client-shared/shared/entities/utils/map-entity-to-entity-type-id'
import { mapEntityToIcon } from '@resnet/client-shared/shared/entities/utils/map-entity-to-icon'
import { mapEntityToPathname } from '@resnet/client-shared/shared/entities/utils/map-entity-to-pathname'
import { mapEntityToTitle } from '@resnet/client-shared/shared/entities/utils/map-entity-to-title'
import { typographyPresets } from '@resnet/client-shared/shared/gdl/constants/typography-presets'

import { IconAvatar } from '@resnet/client-shared-web/shared/gdl/components/avatar'
import { SingleLineText } from '@resnet/client-shared-web/shared/gdl/components/single-line-text'
import { themeColors } from '@resnet/client-shared-web/shared/gdl/constants/theme-colors'
import { mapTypographyPresetToSx } from '@resnet/client-shared-web/shared/gdl/utils/map-typography-preset-to-sx'
import { toPx } from '@resnet/client-shared-web/shared/gdl/utils/to-px'

import { OptionCell } from '../option-cell'
import { RegularCell } from '../regular-cell'

export type EntityCellContentPropsT = {
  entity: MinimalEntityFragmentT
  sx?: SxProps
}

export const EntityCellContent = ({ entity, sx = null }: EntityCellContentPropsT) => {
  const { pathname } = useLocation()

  const entityPathnameActual = mapEntityToPathname(entity)

  const entityPathname = matchPath(pathname, entityPathnameActual) ? undefined : entityPathnameActual

  const renderMedia = () => {
    return (
      <IconAvatar
        icon={mapEntityToIcon(entity)}
        size="xs"
      />
    )
  }

  const renderTitle = () => {
    return (
      <SingleLineText
        sx={[
          mapTypographyPresetToSx(typographyPresets.bodySmall),
          {
            color: themeColors.overBackgroundDefault,
            flexGrow: 1,
          },
          !entityPathname
            ? null
            : {
                color: themeColors.basePrimary,
                textDecoration: 'underline',
              },
        ]}
      >
        {mapEntityToTitle(entity)}
      </SingleLineText>
    )
  }

  return (
    <RegularCell
      sx={[{ gap: toPx(8) }, sx].flat()}
      to={entityPathname}
    >
      {renderMedia()}
      {renderTitle()}
    </RegularCell>
  )
}

export type EntityCellPropsT<EntityT extends MinimalEntityFragmentT> = {
  onSuccess?: () => void
  options?: UseQueryOptions<GetEntityQueryT, unknown, EntityT>
  sx?: SxProps
  value: undefined | null | string
}

export const EntityCell = <EntityT extends MinimalEntityFragmentT>({
  onSuccess,
  options,
  sx,
  value,
}: EntityCellPropsT<EntityT>) => {
  return (
    <OptionCell
      withMedia
      OptionContainer={EntityOptionContainer<EntityT>}
      options={options}
      renderOption={({ option: entity }) => (
        <EntityCellContent
          entity={entity}
          sx={sx}
        />
      )}
      value={value}
      onSuccess={onSuccess}
    />
  )
}

export const EntityTypeCellContent = ({ entity, sx = null }: { entity: MinimalEntityFragmentT; sx?: SxProps }) => {
  return (
    <RegularCell
      sx={[
        mapTypographyPresetToSx(typographyPresets.bodySmall),
        {
          color: themeColors.overBackgroundDefault,
        },
        sx,
      ].flat()}
    >
      {pipeline(entity, mapEntityToEntityTypeId, mapEntityToEntityTypeIdTitle)}
    </RegularCell>
  )
}

export type EntityTypeCellPropsT = { value: undefined | null | string; sx?: SxProps }

export const EntityTypeCell = ({ value, sx }: EntityTypeCellPropsT) => {
  return (
    <OptionCell
      withMedia
      OptionContainer={EntityOptionContainer<MinimalEntityFragmentT>}
      renderOption={({ option: entity }) => (
        <EntityTypeCellContent
          entity={entity}
          sx={sx}
        />
      )}
      value={value}
    />
  )
}
