import type { SxProps } from '@mui/material'
import type { UseQueryOptions } from '@tanstack/react-query'
import { useEffect } from 'react'

import { ErrorBoundary } from '@resnet/client-common/react/components/error-boundary'

import type { AbstractOptionT } from '@resnet/client-shared-web/shared/gdl/types/abstract-option'
import type { OptionContainerT } from '@resnet/client-shared-web/shared/gdl/types/option-container'

import { EmptyCell } from '../empty-cell'
import { NotFoundCell } from '../not-found-cell'
import { SkeletonCell } from '../skeleton-cell'

export type SuccessOptionContainerPropsT = {
  children: React.ReactNode
  onSuccess?: () => void
}

export const SuccessOptionContainer = ({ children, onSuccess }: SuccessOptionContainerPropsT) => {
  useEffect(() => {
    onSuccess?.()
  }, [onSuccess])

  return children
}

export type OptionCellValueT = undefined | null | string

export type OptionCellPropsT<OptionT extends AbstractOptionT> = {
  OptionContainer: OptionContainerT<OptionT>
  options?: UseQueryOptions<unknown, unknown, OptionT>
  renderOption: (props: { option: OptionT }) => React.ReactNode
  sx?: SxProps
  value: undefined | null | OptionT['id']
  withMedia?: boolean
  onSuccess?: () => void
}

export const OptionCell = <OptionT extends AbstractOptionT>({
  OptionContainer,
  options,
  renderOption,
  sx,
  value,
  withMedia,
  onSuccess,
}: OptionCellPropsT<OptionT>) => {
  if (!value) {
    return <EmptyCell sx={sx} />
  }

  return (
    <ErrorBoundary fallback={() => <NotFoundCell sx={sx} />}>
      <OptionContainer
        id={value}
        options={options}
      >
        {({ optionQuery }) => {
          if (!optionQuery.isSuccess) {
            return (
              <SkeletonCell
                sx={sx}
                withMedia={withMedia}
              />
            )
          }

          const option = optionQuery.data

          return <SuccessOptionContainer onSuccess={onSuccess}>{renderOption({ option })}</SuccessOptionContainer>
        }}
      </OptionContainer>
    </ErrorBoundary>
  )
}
