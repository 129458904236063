import { useIsAuthorized } from "@resnet/client-api/auth-api/auth-context";
import LoginScreen from "../screens/LoginScreen";

import { Route, Switch } from "react-router-dom";
import { AuthorizedDrawers } from "@resnet/client-web/screens/root-screen/components/authorized-drawers";

import Issue from "../screens/Entity";
import { Suspense } from "react";
import { OfficeCreateIssue } from "./OfficeCreateIssue";
import Home from "../screens/Home";

const Root = (): React.ReactElement => {
  const isAuthorized = useIsAuthorized();
  if (!isAuthorized) {
    return (
      <Suspense fallback={<div>Loading...</div>}>
        <Switch>
          <Route exact path="/">
            <LoginScreen />
          </Route>
          <Route>
            <LoginScreen />
          </Route>
        </Switch>
      </Suspense>
    );
  }

  return (
    <Suspense fallback={<div>Loading...</div>}>
      <Switch>
        <Route exact path="/">
          <Home />
        </Route>
        <Route exact path="/shareTo">
          <Home />
        </Route>
        <Route path="/createIssue">
          <OfficeCreateIssue />
        </Route>
        <Route path="/entity/:id">
          <Issue />
        </Route>
        <Route>
          <Home />
        </Route>
      </Switch>
      <AuthorizedDrawers />
    </Suspense>
  );
};

export default Root;
