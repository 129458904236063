import { Box } from '@mui/material'
import type { FieldPathByValue, FieldValues, PathValue, UseFormReturn } from 'react-hook-form'

import type { CustomFieldPayloadCheckboxT } from '@resnet/client-api/api'
import { CustomFieldTypeT, type CustomFieldFragmentT } from '@resnet/client-api/api'

import { BooleanDropdownField } from '@resnet/client-shared-web/shared/form/components/common/boolean-dropdown-field'

export type CheckboxCustomFieldPayloadValueT = CustomFieldFragmentT

export type CheckboxCustomFieldPayloadPropsT<
  TFieldValues extends FieldValues,
  TPath extends FieldPathByValue<TFieldValues, CheckboxCustomFieldPayloadValueT>,
> = {
  form: UseFormReturn<TFieldValues>
  name: TPath
}

export const CheckboxCustomFieldPayload = <
  TFieldValues extends FieldValues,
  TPath extends FieldPathByValue<TFieldValues, CheckboxCustomFieldPayloadValueT>,
>({
  form,
  name,
}: CheckboxCustomFieldPayloadPropsT<TFieldValues, TPath>) => {
  type CheckboxPayloadPathT = FieldPathByValue<TFieldValues, undefined | null | CustomFieldPayloadCheckboxT>

  const checkboxPayloadName = `${name}.payload.${CustomFieldTypeT.CheckboxT}` as CheckboxPayloadPathT

  type DefaultValuePathT = FieldPathByValue<TFieldValues, undefined | CustomFieldPayloadCheckboxT['defaultValue']>

  const defaultValueName = `${checkboxPayloadName}.defaultValue` as DefaultValuePathT

  const renderDefaultValueField = () => {
    return (
      <Box sx={{ display: 'flex' }}>
        <BooleanDropdownField
          defaultValue={false as PathValue<TFieldValues, DefaultValuePathT>}
          form={form}
          label="Default Value"
          name={defaultValueName}
        />
      </Box>
    )
  }

  return <>{renderDefaultValueField()}</>
}
