export const layoutHeaderHeight = 72

export const layoutScreenVerticalPadding = 24

export const layoutScreenHorizontalPadding = 24

export const layoutSidebarWidth = 480

export const layoutSidebarVerticalPadding = layoutScreenVerticalPadding

export const layoutSidebarHorizontalPadding = 16

export const layoutSidebarBorderLeft = 1
