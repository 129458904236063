import type { BoxProps } from '@mui/material'
import { Box } from '@mui/material'

import { ChildrenDivider } from '@resnet/client-common/react/components/children-transformer'

import { typographyPresets } from '@resnet/client-shared/shared/gdl/constants/typography-presets'

import { Controls } from '@resnet/client-shared-web/shared/gdl/components/controls'
import { Divider } from '@resnet/client-shared-web/shared/gdl/components/divider'
import { TextOverflow } from '@resnet/client-shared-web/shared/gdl/components/text-overflow'
import { themeColors } from '@resnet/client-shared-web/shared/gdl/constants/theme-colors'
import { mapTypographyPresetToSx } from '@resnet/client-shared-web/shared/gdl/utils/map-typography-preset-to-sx'
import { toPx } from '@resnet/client-shared-web/shared/gdl/utils/to-px'
import {
  layoutSidebarBorderLeft,
  layoutSidebarHorizontalPadding,
  layoutSidebarVerticalPadding,
  layoutSidebarWidth,
} from '@resnet/client-shared-web/shared/layout/constants/common'

export type SidebarPropsT = BoxProps

export const Sidebar = ({ sx = null, children, ...props }: SidebarPropsT) => {
  return (
    <Box
      {...props}
      sx={[
        {
          backgroundColor: themeColors.background,
          borderBottomWidth: toPx(0),
          borderColor: themeColors.borderBold,
          borderLeftWidth: toPx(layoutSidebarBorderLeft),
          borderRightWidth: toPx(0),
          borderStyle: 'solid',
          borderTopWidth: toPx(0),
          display: 'flex',
          flexDirection: 'column',
          overflow: 'auto',
          pb: toPx(layoutSidebarVerticalPadding),
          pl: toPx(layoutSidebarHorizontalPadding - layoutSidebarBorderLeft),
          pr: toPx(layoutSidebarHorizontalPadding),
          pt: toPx(layoutSidebarVerticalPadding),
          width: toPx(layoutSidebarWidth),
        },
        sx,
      ].flat()}
    >
      <Box sx={{ display: 'flex', flexDirection: 'column', flexGrow: 1, gap: toPx(16) }}>
        <ChildrenDivider dividerNode={<Divider />}>{children}</ChildrenDivider>
      </Box>
    </Box>
  )
}

export type SidebarHeaderPropsT = BoxProps

export const SidebarHeader = ({ sx = null, children, ...props }: SidebarHeaderPropsT) => {
  return (
    <Box
      {...props}
      sx={[{ alignItems: 'center', display: 'flex', gap: toPx(8) }, sx].flat()}
    >
      {children}
    </Box>
  )
}

export type SidebarTitlePropsT = BoxProps

export const SidebarTitle = ({ sx = null, children, ...props }: SidebarTitlePropsT) => {
  return (
    <Box
      {...props}
      sx={[
        mapTypographyPresetToSx(typographyPresets.headlineMedium),
        {
          color: themeColors.overBackgroundBold,
          display: 'flex',
          flexGrow: 1,
          width: 0,
        },
        sx,
      ].flat()}
    >
      <TextOverflow>{children}</TextOverflow>
    </Box>
  )
}

export type SidebarScrollableContentPropsT = BoxProps

export const SidebarScrollableContent = ({ sx = null, children, ...props }: SidebarScrollableContentPropsT) => {
  return (
    <Box
      {...props}
      sx={[
        {
          display: 'flex',
          flexDirection: 'column',
          flexGrow: 1,
          height: 0,
          ml: toPx(-(layoutSidebarHorizontalPadding - layoutSidebarBorderLeft)),
          mr: toPx(-layoutSidebarHorizontalPadding),
          overflow: 'auto',
          pl: toPx(layoutSidebarHorizontalPadding - layoutSidebarBorderLeft),
          pr: toPx(layoutSidebarHorizontalPadding),
        },
        sx,
      ].flat()}
    >
      {children}
    </Box>
  )
}

export const SidebarFooter = ({ children }: { children: { left?: React.ReactNode; right?: React.ReactNode } }) => {
  return (
    <Controls
      gap={16}
      renderLeftItems={() => children.left}
      renderRightItems={() => children.right}
    />
  )
}
