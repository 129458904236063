import { Box, useEventCallback } from '@mui/material'
import { useState } from 'react'

import UploadSolidIcon from '@resnet/client-shared/assets/icons/upload-solid.svg'

import { FileInput } from '@resnet/client-shared-web/shared/files/components/file-input'
import { ButtonBase } from '@resnet/client-shared-web/shared/gdl/components/button'
import { themeColors } from '@resnet/client-shared-web/shared/gdl/constants/theme-colors'

import { focusOutlineSx } from '../../sx-presets/focus-outline'

export const DropFileArea = ({
  multiple = true,
  onInput,
  placeholder = 'Drag and drop files to attach or browse...',
}: {
  placeholder?: string
  multiple?: boolean
  onInput?: ({ files }: { files: File[] }) => void
}): React.ReactElement => {
  const [isDragEntered, setIsDragEntered] = useState<boolean>(false)

  const onDragEnter = useEventCallback((event: React.DragEvent) => {
    event.preventDefault()
    event.stopPropagation()

    setIsDragEntered(true)
  })

  const onDragOver = useEventCallback((event: React.DragEvent) => {
    event.preventDefault()
    event.stopPropagation()
  })

  const onDragLeave = useEventCallback((event: React.DragEvent) => {
    event.preventDefault()
    event.stopPropagation()

    setIsDragEntered(false)
  })

  const onDrop = useEventCallback((event: React.DragEvent) => {
    event.preventDefault()
    event.stopPropagation()

    const { files } = event.dataTransfer

    onInput?.({ files: Array.from(files) as File[] })

    setIsDragEntered(false)
  })

  const onFileInputChange = useEventCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    const { files } = event.currentTarget

    if (!files) {
      return
    }

    onInput?.({ files: Array.from(files) as File[] })
  })

  return (
    <Box
      component="label"
      sx={{ cursor: 'pointer', display: 'flex', flexDirection: 'column' }}
      onDragEnter={onDragEnter}
      onDragLeave={onDragLeave}
      onDragOver={onDragOver}
      onDrop={onDrop}
    >
      <FileInput
        multiple={multiple}
        onChange={onFileInputChange}
      />
      <ButtonBase
        icon={<UploadSolidIcon />}
        size="md"
        sx={[
          {
            backgroundColor: themeColors.surfaceNeutralDefault,
            borderColor: themeColors.borderDefault,
            borderStyle: 'dashed',
            color: themeColors.overBackgroundDefault,
            pointerEvents: 'none',
          },
          !isDragEntered
            ? null
            : {
                borderColor: themeColors.basePrimary,
              },
          { 'input:focus-visible + &': focusOutlineSx },
        ]}
      >
        {isDragEntered ? 'Drop files' : placeholder}
      </ButtonBase>
    </Box>
  )
}
