import type { TagPropsT } from '@resnet/client-shared-web/shared/gdl/components/tag'
import { Tag } from '@resnet/client-shared-web/shared/gdl/components/tag'

export type NotFoundCellPropsT = Omit<TagPropsT, 'children' | 'uppercase' | 'status'>

export const NotFoundCell = (props: NotFoundCellPropsT) => {
  return (
    <Tag
      {...props}
      uppercase
      status="warning"
    >
      Not Found
    </Tag>
  )
}
