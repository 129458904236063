import type { Draft } from 'immer'
import { produce } from 'immer'

import { omitUndefinedValues } from '@resnet/client-common/common/utils/object/omit-undefined-values'

import type { InputUpdateUserFormT, GetUserFormQueryT, ListUserFormsQueryT } from '@resnet/client-api/api'
import {
  useCreateUserFormMutation,
  useDeleteUserFormMutation,
  useInfiniteListUserFormsQuery,
  useGetUserFormQuery,
  useListUserFormsQuery,
  useUpdateUserFormMutation,
  useArchiveUserFormMutation,
  useUnarchiveUserFormMutation,
  useRequestToFillUserFormMutation,
  useGetFormQuery,
  useUpdateFormMutation,
} from '@resnet/client-api/api'
import type { MutationCacheHandlersT } from '@resnet/client-api/services/query-client/types/mutation-cache-handlers'
import { createMutationHookMutationCacheHandlers } from '@resnet/client-api/services/query-client/utils/create-mutation-hook-mutation-cache-handlers'
import { findGetQueriesById, findInfiniteQueries, findQueries } from '@resnet/client-api/utils/find-queries'
import { invalidateQuery } from '@resnet/client-api/utils/invalidate-query'
import { matchesIdFilter } from '@resnet/client-api/utils/matches-id-filter'
import { setQueryData } from '@resnet/client-api/utils/set-query-data'
import type { QueryClient } from '@resnet/client-api/vendors/react-query'

export const getUserFormQueriesHandlers = ({ queryClient }: { queryClient: QueryClient }): MutationCacheHandlersT[] => {
  const findUserFormQueriesById = ({ id }: { id: string }) =>
    findGetQueriesById(queryClient, useGetUserFormQuery, (data) => data.getUserForm, { id })

  const findFormObjectQueriesById = ({ id }: { id: string }) =>
    findGetQueriesById(queryClient, useGetFormQuery, (data) => data.getForm, { id })

  const findUserFormQueriesByFormObjectId = ({ formObjectId }: { formObjectId?: string } = {}) =>
    findQueries(queryClient, useGetUserFormQuery, (query) => {
      const data = query.state.data

      if (formObjectId && data?.getUserForm.formId !== formObjectId) {
        return false
      }

      return true
    })

  const updateUserForm = ({
    data,
    input,
  }: {
    data: Draft<undefined | GetUserFormQueryT>
    input: InputUpdateUserFormT
  }) => {
    if (!data) {
      return
    }

    const item = data.getUserForm

    const { formData, ...staticFields } = input ?? {}

    Object.assign(item, omitUndefinedValues(staticFields))

    const itemFormData = item.formData ?? {}

    item.formData = itemFormData

    Object.assign(itemFormData, omitUndefinedValues(formData))
  }

  const deleteUserFormHandlers = () => {
    return createMutationHookMutationCacheHandlers(useDeleteUserFormMutation, {
      onSuccess: (data, { id }) => {
        findUserFormQueriesById({ id }).forEach(invalidateQuery(queryClient))
        findFormObjectQueriesById({ id: data.deleteUserForm.formId }).forEach(invalidateQuery(queryClient))
      },
    })
  }

  const updateUserFormHandlers = () => {
    return createMutationHookMutationCacheHandlers(useUpdateUserFormMutation, {
      onError: (error, { id }) => {
        findUserFormQueriesById({ id }).forEach(invalidateQuery(queryClient))
      },
      onMutate: ({ id, data: input }) => {
        findUserFormQueriesById({ id }).forEach((query) =>
          setQueryData(queryClient)(query)(produce((data) => updateUserForm({ data, input }))),
        )
      },
      onSuccess: (data, { id }) => {
        findUserFormQueriesById({ id }).forEach(invalidateQuery(queryClient))
        findFormObjectQueriesById({ id: data.updateUserForm.formId }).forEach(invalidateQuery(queryClient))
      },
    })
  }

  const archiveUserFormHandlers = () => {
    return createMutationHookMutationCacheHandlers(useArchiveUserFormMutation, {
      onSuccess: (data, { id }) => {
        findUserFormQueriesById({ id }).forEach(invalidateQuery(queryClient))
      },
    })
  }

  const unarchiveUserFormHandlers = () => {
    return createMutationHookMutationCacheHandlers(useUnarchiveUserFormMutation, {
      onSuccess: (data, { id }) => {
        findUserFormQueriesById({ id }).forEach(invalidateQuery(queryClient))
      },
    })
  }

  const updateFormHandlers = () => {
    return createMutationHookMutationCacheHandlers(useUpdateFormMutation, {
      onSuccess: (data, { id: formObjectId }) => {
        findUserFormQueriesByFormObjectId({ formObjectId }).forEach(invalidateQuery(queryClient))
      },
    })
  }

  return [
    deleteUserFormHandlers(),
    updateUserFormHandlers(),
    archiveUserFormHandlers(),
    unarchiveUserFormHandlers(),
    updateFormHandlers(),
  ]
}

export const listUserFormsQueriesHandlers = ({
  queryClient,
}: {
  queryClient: QueryClient
}): MutationCacheHandlersT[] => {
  const findUserFormsQueries = ({ formObjectId }: { formObjectId?: string } = {}) =>
    findQueries(queryClient, useListUserFormsQuery, (query) => {
      const variables = query.queryKey[1]

      if (formObjectId && !matchesIdFilter(formObjectId, variables?.filter?.formId)) {
        return false
      }

      return true
    })

  const findInfiniteUserFormsQueries = ({ formObjectId }: { formObjectId?: string } = {}) =>
    findInfiniteQueries(queryClient, useInfiniteListUserFormsQuery, (query) => {
      const variables = query.queryKey[1]

      if (formObjectId && !matchesIdFilter(formObjectId, variables?.filter?.formId)) {
        return false
      }

      return true
    })

  const findFormObjectQueriesById = ({ id }: { id: string }) =>
    findGetQueriesById(queryClient, useGetFormQuery, (data) => data.getForm, { id })

  const updateUserForm = ({
    data,
    id,
    input,
  }: {
    data: Draft<undefined | ListUserFormsQueryT>
    id: string
    input: InputUpdateUserFormT
  }) => {
    if (!data) {
      return
    }

    const items = data.listUserForms.items

    const item = items.find((item) => item.id === id)

    if (!item) {
      return
    }

    const { formData, ...staticFields } = input ?? {}

    Object.assign(item, omitUndefinedValues(staticFields))

    const itemFormData = item.formData ?? {}

    item.formData = itemFormData

    Object.assign(itemFormData, omitUndefinedValues(formData))
  }

  const deleteUserForm = ({ data, id }: { data: Draft<undefined | ListUserFormsQueryT>; id: string }) => {
    if (!data) {
      return
    }

    const items = data.listUserForms.items

    const index = items.findIndex((item) => item.id === id)

    if (index === -1) {
      return
    }

    items.splice(index, 1)
  }

  const createUserFormHandlers = () => {
    return createMutationHookMutationCacheHandlers(useCreateUserFormMutation, {
      onSuccess: (data) => {
        findUserFormsQueries().forEach(invalidateQuery(queryClient))
        findInfiniteUserFormsQueries().forEach(invalidateQuery(queryClient))
        setTimeout(() => {
          findFormObjectQueriesById({ id: data.createUserForm.formId }).forEach(invalidateQuery(queryClient))
          //NOTE wait for attachments counters to update
        }, 3000)
      },
    })
  }

  const deleteUserFormHandlers = () => {
    return createMutationHookMutationCacheHandlers(useDeleteUserFormMutation, {
      onError: () => {
        findUserFormsQueries().forEach(invalidateQuery(queryClient))
        findInfiniteUserFormsQueries().forEach(invalidateQuery(queryClient))
      },
      onMutate: ({ id }) => {
        findUserFormsQueries().forEach((query) =>
          setQueryData(queryClient)(query)(produce((data) => deleteUserForm({ data, id }))),
        )
        findInfiniteUserFormsQueries().forEach((query) =>
          setQueryData(queryClient)(query)(
            produce((infiniteData) => infiniteData?.pages.forEach((data) => deleteUserForm({ data, id }))),
          ),
        )
      },
      onSuccess: (data) => {
        findUserFormsQueries().forEach(invalidateQuery(queryClient))
        findInfiniteUserFormsQueries().forEach(invalidateQuery(queryClient))
        findFormObjectQueriesById({ id: data.deleteUserForm.formId }).forEach(invalidateQuery(queryClient))
      },
    })
  }

  const updateUserFormHandlers = () => {
    return createMutationHookMutationCacheHandlers(useUpdateUserFormMutation, {
      onError: () => {
        findUserFormsQueries().forEach(invalidateQuery(queryClient))
        findInfiniteUserFormsQueries().forEach(invalidateQuery(queryClient))
      },
      onMutate: ({ id, data: input }) => {
        findUserFormsQueries().forEach((query) =>
          setQueryData(queryClient)(query)(produce((data) => updateUserForm({ data, id, input }))),
        )
        findInfiniteUserFormsQueries().forEach((query) =>
          setQueryData(queryClient)(query)(
            produce((infiniteData) => infiniteData?.pages.forEach((data) => updateUserForm({ data, id, input }))),
          ),
        )
      },
      onSuccess: (data) => {
        findUserFormsQueries().forEach(invalidateQuery(queryClient))
        findInfiniteUserFormsQueries().forEach(invalidateQuery(queryClient))
        findFormObjectQueriesById({ id: data.updateUserForm.formId }).forEach(invalidateQuery(queryClient))
      },
    })
  }

  const archiveUserFormHandlers = () => {
    return createMutationHookMutationCacheHandlers(useArchiveUserFormMutation, {
      onSuccess: () => {
        findUserFormsQueries().forEach(invalidateQuery(queryClient))
        findInfiniteUserFormsQueries().forEach(invalidateQuery(queryClient))
      },
    })
  }

  const unarchiveUserFormHandlers = () => {
    return createMutationHookMutationCacheHandlers(useUnarchiveUserFormMutation, {
      onSuccess: () => {
        findUserFormsQueries().forEach(invalidateQuery(queryClient))
        findInfiniteUserFormsQueries().forEach(invalidateQuery(queryClient))
      },
    })
  }

  const requestToFillUserFormHandlers = () => {
    return createMutationHookMutationCacheHandlers(useRequestToFillUserFormMutation, {
      onSuccess: (data, { formId: formObjectId }) => {
        findUserFormsQueries({ formObjectId }).forEach(invalidateQuery(queryClient))
        findInfiniteUserFormsQueries({ formObjectId }).forEach(invalidateQuery(queryClient))
      },
    })
  }

  const updateFormHandlers = () => {
    return createMutationHookMutationCacheHandlers(useUpdateFormMutation, {
      onSuccess: (data, { id: formObjectId }) => {
        findUserFormsQueries({ formObjectId }).forEach(invalidateQuery(queryClient))
        findInfiniteUserFormsQueries({ formObjectId }).forEach(invalidateQuery(queryClient))
      },
    })
  }

  return [
    createUserFormHandlers(),
    deleteUserFormHandlers(),
    updateUserFormHandlers(),
    archiveUserFormHandlers(),
    unarchiveUserFormHandlers(),
    requestToFillUserFormHandlers(),
    updateFormHandlers(),
  ]
}
