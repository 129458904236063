import { produce } from 'immer'

import {
  useMyDailyChallengeQuery,
  useClaimDailyChallengeRewardMutation,
  useAdminNextChallengesQuery,
  useAdminChallengeSortOrderMutation,
} from '@resnet/client-api/api'
import type { MutationCacheHandlersT } from '@resnet/client-api/services/query-client/types/mutation-cache-handlers'
import { createMutationHookMutationCacheHandlers } from '@resnet/client-api/services/query-client/utils/create-mutation-hook-mutation-cache-handlers'
import { findQueries } from '@resnet/client-api/utils/find-queries'
import { invalidateQuery } from '@resnet/client-api/utils/invalidate-query'
import { setQueryData } from '@resnet/client-api/utils/set-query-data'
import type { QueryClient } from '@resnet/client-api/vendors/react-query'

export const myDailyChallengeMutationCacheHandlers = ({
  queryClient,
}: {
  queryClient: QueryClient
}): MutationCacheHandlersT[] => {
  const findMyDailyChallengeQueries = () => findQueries(queryClient, useMyDailyChallengeQuery)

  const claimDailyChallengeRewardHandlers = () => {
    return createMutationHookMutationCacheHandlers(useClaimDailyChallengeRewardMutation, {
      onSuccess: () => {
        findMyDailyChallengeQueries().forEach(invalidateQuery(queryClient))
      },
    })
  }

  return [claimDailyChallengeRewardHandlers()]
}

export const adminNextChallengesMutationCacheHandlers = ({
  queryClient,
}: {
  queryClient: QueryClient
}): MutationCacheHandlersT[] => {
  const findAdminNextChallengesQueries = () => findQueries(queryClient, useAdminNextChallengesQuery)

  const adminChallengeSortOrderHandlers = () => {
    return createMutationHookMutationCacheHandlers(useAdminChallengeSortOrderMutation, {
      onError: () => {
        findAdminNextChallengesQueries().forEach(invalidateQuery(queryClient))
      },
      onMutate: ({ challengeId, position }) => {
        findAdminNextChallengesQueries().forEach((query) =>
          setQueryData(queryClient)(query)(
            produce((draft) => {
              if (!draft?.adminListNextChallenges) {
                return
              }

              const challengeIndex = draft.adminListNextChallenges.findIndex((item) => item.id === challengeId)

              const [challenge] = draft.adminListNextChallenges.splice(challengeIndex, 1)

              draft.adminListNextChallenges.splice(position, 0, challenge)
            }),
          ),
        )
      },
      onSuccess: () => {
        findAdminNextChallengesQueries().forEach(invalidateQuery(queryClient))
      },
    })
  }

  return [adminChallengeSortOrderHandlers()]
}
