import { flattenRoutes } from '../utils/flatten-routes'

import { adminPanelScreenRoutes } from './admin-panel-screen-routes'
import { automationScreenRoutes } from './automation-screen-routes'
import { batteriesScreenRoutes } from './batteries-screen-routes'
import { campaignsScreenRoutes } from './campaigns-screen-routes'
import { credentialsScreenRoutes } from './credentials-screen-routes'
import { formObjectsScreenRoutes } from './form-objects-screen-routes'
import { gamificationScreenRoutes } from './gamification-screen-routes'
import { groupsScreenRoutes } from './groups-screen-routes'
import { insightsScreenRoutes } from './insights-screen-routes'
import { issuesScreenRoutes } from './issues-screen-routes'
import { resourceObjectsScreenRoutes } from './resource-objects-screen-routes'
import { routesScreenRoutes } from './routes-screen-routes'
import { sitesScreenRoutes } from './sites-screen-routes'
import { ufoScreenRoutes } from './ufo-screen-routes'
import { usersScreenRoutes } from './users-screen-routes'
import { wellsScreenRoutes } from './wells-screen-routes'

export const rootScreenRoutes = flattenRoutes({
  acceptInvite: '/accept-invite',
  activity: '/activity',
  adminPanel: ['/admin-panel', adminPanelScreenRoutes],
  analytics: '/analytics',
  automation: ['/automation', automationScreenRoutes],
  batteries: ['/batteries', batteriesScreenRoutes],
  campaigns: ['/campaigns', campaignsScreenRoutes],
  changePassword: '/change-password',
  contacts: '/contacts',
  createAccount: '/create-account',
  credentials: ['/credentials', credentialsScreenRoutes],
  data: '/data',
  fieldManagement: '/field-management',
  formObjects: ['/forms', formObjectsScreenRoutes],
  gamification: ['/gamification', gamificationScreenRoutes],
  groups: ['/groups', groupsScreenRoutes],
  home: '/',
  insights: ['/insights', insightsScreenRoutes],
  intelligentControl: '/intelligent-control',
  issues: ['/issues', issuesScreenRoutes],
  login: '/login',
  recoverPassword: '/recover-password',
  reporting: '/reporting',
  resourceObjects: ['/resource', resourceObjectsScreenRoutes],
  routes: ['/routes', routesScreenRoutes],
  search: '/search/:category?',
  sites: ['/sites', sitesScreenRoutes],
  tokenAuth: '/token-auth',
  ufo: ['/ufo', ufoScreenRoutes],
  users: ['/users', usersScreenRoutes],
  wells: ['/wells', wellsScreenRoutes],
})
