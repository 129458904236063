import type { Popper as MUIPopper } from '@mui/material'
import Sketch from '@uiw/react-color-sketch'
import type { SwatchPresetColor } from '@uiw/react-color-swatch'
import { useRef } from 'react'

import { useEventCallback } from '@resnet/client-common/react/hooks/use-event-callback'
import { useLocalStorageState } from '@resnet/client-common/react/hooks/use-local-storage-state'
import { NonNullableContextContainer } from '@resnet/client-common/react/hooks/use-non-nullable-context'

import ColorPickerSolidIcon from '@resnet/client-shared/assets/icons/color-picker-solid.svg'

import { Popover } from '@resnet/client-shared-web/shared/gdl/components/popover'
import { Popper, PopperContext } from '@resnet/client-shared-web/shared/gdl/components/popper'

import { themeColors } from '../../constants/theme-colors'
import { ColorSelectorButton } from '../color-selector-button'

export type ColorPickerButtonPropsT = {
  value?: null | string
  onChange: (value: string) => void
  selected?: boolean
  disablePortal?: boolean
  placement?: React.ComponentProps<typeof MUIPopper>['placement']
}

const mapColorToSwatch = (color: string): SwatchPresetColor => {
  return {
    color,
    title: color,
  }
}

export const ColorPickerButton = ({
  value,
  onChange,
  selected,
  ...popperProps
}: ColorPickerButtonPropsT): React.ReactNode => {
  const [swatches, setSwatches] = useLocalStorageState<string[]>('swatch-colors')
  const lastSelectedColorRef = useRef<null | string>(null)

  const renderAnchor = (): React.ReactElement => {
    return (
      <NonNullableContextContainer Context={PopperContext}>
        {({ setAnchorEl, open }) => (
          <ColorSelectorButton
            color={value}
            icon={ColorPickerSolidIcon}
            ref={setAnchorEl}
            selected={selected}
            onClick={open}
          />
        )}
      </NonNullableContextContainer>
    )
  }

  const onPickerClose = useEventCallback(() => {
    const { current: lastSelectedColor } = lastSelectedColorRef

    if (!lastSelectedColor) {
      return
    }

    if (swatches?.includes(lastSelectedColor)) {
      lastSelectedColorRef.current = null

      return
    }

    setSwatches((currentValue) => {
      return [lastSelectedColor, ...(currentValue ?? []).slice(0, 15)]
    })

    lastSelectedColorRef.current = null
  })

  const renderContent = (): React.ReactElement => {
    return (
      <Popover
        sx={[
          {
            padding: '0px',
            width: 'auto',
          },
          {
            '& .w-color-editable-input': {
              '--editable-input-box-shadow': `${themeColors.borderDefault} 0px 0px 0px 1px inset !important`,
              '--editable-input-color': `${themeColors.overBackgroundDefault} !important`,
              '--editable-input-label-color': `${themeColors.overBackgroundFaded} !important`,
            },
          },
        ]}
      >
        <Sketch
          disableAlpha
          color={value ?? undefined}
          presetColors={swatches?.map(mapColorToSwatch) ?? false}
          style={
            {
              '--sketch-background': 'transparent',
              '--sketch-box-shadow': 'none',
              '--sketch-swatch-border-top': '0px',
            } as React.CSSProperties
          }
          onChange={(color) => {
            lastSelectedColorRef.current = color.hex
            onChange(color.hex)
          }}
        />
      </Popover>
    )
  }

  return (
    <Popper
      {...popperProps}
      onClose={onPickerClose}
    >
      {{
        anchor: renderAnchor,
        content: renderContent,
      }}
    </Popper>
  )
}
