import type { InfiniteData } from '@tanstack/react-query'
import type { Draft } from 'immer'

import { omitUndefinedValues } from '@resnet/client-common/common/utils/object/omit-undefined-values'

export type GenericEntityT = Record<string, unknown> & { id: string; customFields?: Record<string, unknown> }

export type GenericInputT = Record<string, unknown> & { customFields?: Record<string, unknown> }

export const updateEntityRecipe = <EntityT extends GenericEntityT, InputT extends GenericInputT>({
  entity,
  input,
}: {
  entity: Draft<EntityT>
  input: undefined | null | InputT
}) => {
  const { customFields, ...staticFields } = input ?? {}

  Object.assign(entity, omitUndefinedValues(staticFields))

  const itemCustomFields = (entity.customFields ?? {}) as NonNullable<Draft<EntityT['customFields']>>

  entity.customFields = itemCustomFields

  Object.assign(itemCustomFields, omitUndefinedValues(customFields))
}

export const getEntityQueryUpdateRecipe = <DataT, EntityT extends GenericEntityT, InputT extends GenericInputT>({
  data,
  input,
  mapDataToEntity,
}: {
  data: Draft<undefined | DataT>
  input: undefined | null | InputT
  mapDataToEntity: (data: Draft<DataT>) => Draft<EntityT>
}) => {
  if (!data) {
    return
  }

  const entity = mapDataToEntity(data)

  updateEntityRecipe({ entity, input })
}

export const listEntityQueryUpdateRecipe = <DataT, EntityT extends GenericEntityT, InputT extends GenericInputT>({
  data,
  id,
  input,
  mapDataToEntities,
}: {
  data: Draft<undefined | DataT>
  id: string
  input: undefined | null | InputT
  mapDataToEntities: (data: Draft<DataT>) => Draft<EntityT[]>
}) => {
  if (!data) {
    return
  }

  const entities = mapDataToEntities(data)

  const entity = entities.find((entity) => entity.id === id)

  if (!entity) {
    return
  }

  updateEntityRecipe({ entity, input })
}

export const infiniteListEntityQueryUpdateRecipe = <
  DataT,
  EntityT extends GenericEntityT,
  InputT extends GenericInputT,
>({
  data,
  id,
  input,
  mapDataToEntities,
}: {
  data: Draft<undefined | InfiniteData<DataT>>
  id: string
  input: undefined | null | InputT
  mapDataToEntities: (data: Draft<DataT>) => Draft<EntityT[]>
}) => {
  if (!data) {
    return
  }

  data.pages.forEach((data) => {
    listEntityQueryUpdateRecipe({ data, id, input, mapDataToEntities })
  })
}
