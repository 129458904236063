import type { SxProps } from '@mui/material'
import type { UseQueryOptions } from '@tanstack/react-query'

import type { UserFragmentT, UserQueryT } from '@resnet/client-api/api'

import { typographyPresets } from '@resnet/client-shared/shared/gdl/constants/typography-presets'
import { UserOptionContainer } from '@resnet/client-shared/shared/users/hooks/use-user-option'
import { mapUserToTitle } from '@resnet/client-shared/shared/users/utils/map-user-to-title'

import { SingleLineText } from '@resnet/client-shared-web/shared/gdl/components/single-line-text'
import { themeColors } from '@resnet/client-shared-web/shared/gdl/constants/theme-colors'
import { mapTypographyPresetToSx } from '@resnet/client-shared-web/shared/gdl/utils/map-typography-preset-to-sx'
import { toPx } from '@resnet/client-shared-web/shared/gdl/utils/to-px'

import { UserAvatar } from '@resnet/client-web/shared/users/components/user-avatar'
import { mapUserToPathname } from '@resnet/client-web/shared/users/utils/map-user-to-pathname'

import { OptionCell } from '../option-cell'
import { RegularCell } from '../regular-cell'

export type UserCellContentPropsT = {
  user: UserFragmentT
  sx?: SxProps
}

export const UserCellContent = ({ user, sx = null }: UserCellContentPropsT) => {
  const userPathname = mapUserToPathname(user)

  const renderMedia = () => {
    return (
      <UserAvatar
        size="xs"
        user={user}
      />
    )
  }

  const renderTitle = () => {
    return (
      <SingleLineText
        sx={[
          mapTypographyPresetToSx(typographyPresets.bodySmall),
          {
            color: themeColors.overBackgroundDefault,
            flexGrow: 1,
          },
          {
            color: themeColors.basePrimary,
            textDecoration: 'underline',
          },
        ]}
      >
        {mapUserToTitle(user)}
      </SingleLineText>
    )
  }

  return (
    <RegularCell
      sx={[{ gap: toPx(8) }, sx].flat()}
      to={userPathname}
    >
      {renderMedia()}
      {renderTitle()}
    </RegularCell>
  )
}

export type UserCellPropsT = {
  onSuccess?: () => void
  options?: UseQueryOptions<UserQueryT, unknown, UserFragmentT>
  sx?: SxProps
  value: undefined | null | string
}

export const UserCell = ({ onSuccess, options, sx, value }: UserCellPropsT) => {
  return (
    <OptionCell
      withMedia
      OptionContainer={UserOptionContainer}
      options={options}
      renderOption={({ option: user }) => (
        <UserCellContent
          sx={sx}
          user={user}
        />
      )}
      value={value}
      onSuccess={onSuccess}
    />
  )
}
