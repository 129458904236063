import { Box } from '@mui/material'

import type { BatteryFragmentT } from '@resnet/client-api/api'

import { mapBatteryToCoordinates } from '@resnet/client-shared/shared/batteries/utils/map-battery-to-coordinates'
import { mapBatteryToReadableId } from '@resnet/client-shared/shared/batteries/utils/map-battery-to-readable-id'
import { mapBatteryToTitle } from '@resnet/client-shared/shared/batteries/utils/map-battery-to-title'
import { typographyPresets } from '@resnet/client-shared/shared/gdl/constants/typography-presets'

import { RectangularSkeleton } from '@resnet/client-shared-web/shared/async/components/rectangular-skeleton'
import { TextSkeleton } from '@resnet/client-shared-web/shared/async/components/text-skeleton'
import { AvatarSkeleton } from '@resnet/client-shared-web/shared/gdl/components/avatar'
import { themeColors } from '@resnet/client-shared-web/shared/gdl/constants/theme-colors'
import { mapTypographyPresetToSx } from '@resnet/client-shared-web/shared/gdl/utils/map-typography-preset-to-sx'
import { toPx } from '@resnet/client-shared-web/shared/gdl/utils/to-px'
import { mapCoordinatesToMapResource } from '@resnet/client-shared-web/shared/resources/utils/map-coordinates-to-map-resource'

import { AssetRating } from '@resnet/client-web/shared/assets/components/asset-rating'

import { AnyCard, EntityCardAvatar } from '../any-card'

export const getBatteryCardSize = () => 108

export const BatteryCardSkeleton = () => {
  const renderAvatar = () => {
    return <AvatarSkeleton size="lg" />
  }

  const renderFirstSectionAside = () => {
    return <Box sx={{ display: 'flex', flexDirection: 'column' }}>{renderAvatar()}</Box>
  }

  const renderName = () => {
    return (
      <TextSkeleton
        contentSx={{ width: '50%' }}
        typographyPreset={typographyPresets.titleMedium}
      />
    )
  }

  const renderMeta = () => {
    return (
      <TextSkeleton
        contentSx={{ width: '70%' }}
        typographyPreset={typographyPresets.bodySmall}
      />
    )
  }

  const renderFirstSectionMain = () => {
    return (
      <Box sx={{ display: 'flex', flexDirection: 'column', flexGrow: 1, width: 0 }}>
        {renderName()}
        {renderMeta()}
      </Box>
    )
  }

  const renderFirstSection = () => {
    return (
      <Box
        sx={{
          display: 'flex',
          gap: toPx(8),
        }}
      >
        {renderFirstSectionAside()}
        {renderFirstSectionMain()}
      </Box>
    )
  }

  const renderSecondSectionAside = () => {
    return <Box sx={{ width: toPx(44) }} />
  }

  const renderHealthLevel = () => {
    return <RectangularSkeleton sx={{ height: toPx(16), width: toPx(96) }} />
  }

  const renderSecondSectionMain = () => {
    return <Box sx={{ display: 'flex', flexDirection: 'column', flexGrow: 1, width: 0 }}>{renderHealthLevel()}</Box>
  }

  const renderSecondSection = () => {
    return (
      <Box
        sx={{
          display: 'flex',
          gap: toPx(8),
        }}
      >
        {renderSecondSectionAside()}
        {renderSecondSectionMain()}
      </Box>
    )
  }

  return (
    <AnyCard>
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: toPx(16) }}>
        {renderFirstSection()}
        {renderSecondSection()}
      </Box>
    </AnyCard>
  )
}

export const BatteryCard = ({
  battery,
  isActive,
  onClick,
}: {
  battery: BatteryFragmentT
  isActive?: boolean
  onClick?: () => void
}) => {
  const renderAvatar = () => {
    return (
      <EntityCardAvatar
        entity={battery}
        mapEntityToDefaultAvatar={({ entity, themeProps }) =>
          mapCoordinatesToMapResource({ coordinates: mapBatteryToCoordinates({ battery: entity }), themeProps })}
        size="lg"
      />
    )
  }

  const renderFirstSectionAside = () => {
    return <Box sx={{ display: 'flex', flexDirection: 'column' }}>{renderAvatar()}</Box>
  }

  const renderName = () => {
    return (
      <Box
        sx={[
          mapTypographyPresetToSx(typographyPresets.titleMedium),
          { color: themeColors.overBackgroundBold, overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' },
        ]}
      >
        {mapBatteryToTitle(battery)}
      </Box>
    )
  }

  const renderMeta = () => {
    return (
      <Box
        sx={[
          mapTypographyPresetToSx(typographyPresets.bodySmall),
          {
            color: themeColors.overBackgroundMuted,
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
          },
        ]}
      >
        {mapBatteryToReadableId(battery)}
      </Box>
    )
  }

  const renderFirstSectionMain = () => {
    return (
      <Box sx={{ display: 'flex', flexDirection: 'column', flexGrow: 1, width: 0 }}>
        {renderName()}
        {renderMeta()}
      </Box>
    )
  }

  const renderFirstSection = () => {
    return (
      <Box
        sx={{
          display: 'flex',
          gap: toPx(8),
        }}
      >
        {renderFirstSectionAside()}
        {renderFirstSectionMain()}
      </Box>
    )
  }

  const renderSecondSectionAside = () => {
    return <Box sx={{ width: toPx(44) }} />
  }

  const renderHealthLevel = () => {
    return (
      <AssetRating
        iconSize={16}
        sx={{ position: 'relative' }}
        value={battery.healthLevel ?? 0}
      />
    )
  }

  const renderSecondSectionMain = () => {
    return <Box sx={{ display: 'flex', flexDirection: 'column', flexGrow: 1, width: 0 }}>{renderHealthLevel()}</Box>
  }

  const renderSecondSection = () => {
    return (
      <Box
        sx={{
          display: 'flex',
          gap: toPx(8),
        }}
      >
        {renderSecondSectionAside()}
        {renderSecondSectionMain()}
      </Box>
    )
  }

  return (
    <AnyCard
      isActive={isActive}
      onClick={onClick}
    >
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: toPx(16) }}>
        {renderFirstSection()}
        {renderSecondSection()}
      </Box>
    </AnyCard>
  )
}
