export const createRenderColumnAsReactElement =
  <RowT extends Record<string, unknown>>({
    columnId,
    rowElementsRef,
    getValue,
  }: {
    columnId: string
    rowElementsRef: React.MutableRefObject<Record<string, Element | null>>
    getValue: (row: RowT) => unknown
  }) =>
  ({ row }: { row: RowT }) => {
    if (row.isGroup || getValue(row) == null) {
      return '-'
    }

    const key = columnId + '_@_' + row.id

    const rowElement = rowElementsRef.current[key]?.children[0].cloneNode(true) as Element

    return !rowElement ? '-' : rowElement
  }
