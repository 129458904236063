import type { BoxProps } from '@mui/material'
import { Box, Tooltip } from '@mui/material'
import type { LinkProps } from 'react-router-dom'

import { createDate } from '@resnet/client-common/date/utils/create-date'
import { fullDateFormatter } from '@resnet/client-common/format/utils/date/full-date-formatter'
import { fullDateTimeFormatter } from '@resnet/client-common/format/utils/date/full-date-time-formatter'
import { ErrorBoundary } from '@resnet/client-common/react/components/error-boundary'
import { useHasOverflow } from '@resnet/client-common/react/hooks/use-has-overflow'
import type { HookContainerT } from '@resnet/client-common/react/utils/create-hook-container'
import type { MergeAllT } from '@resnet/client-common/typescript/types/merge-all'

import type { UseQueryResult } from '@resnet/client-api/vendors/react-query'

import { typographyPresets } from '@resnet/client-shared/shared/gdl/constants/typography-presets'

import { TextSkeleton } from '@resnet/client-shared-web/shared/async/components/text-skeleton'

import { themeColors } from '../../constants/theme-colors'
import { mapTypographyPresetToSx } from '../../utils/map-typography-preset-to-sx'
import { toPx } from '../../utils/to-px'
import { LabelPreview } from '../label'
import { Linkable } from '../linkable'
import { SwitchPreview } from '../switch'

export type FieldPreviewValueContainerT<DataT extends unknown> = HookContainerT<
  { id: string },
  { optionQuery: UseQueryResult<DataT> }
>

export type FieldPreviewLayoutPropsT = BoxProps

export const FieldPreviewLayout = ({ sx = null, children, ...props }: FieldPreviewLayoutPropsT) => {
  return (
    <Box
      {...props}
      sx={[{ display: 'flex', flexDirection: 'column', gap: toPx(4) }, sx].flat()}
    >
      {children}
    </Box>
  )
}

export type FieldPreviewLabelPropsT = BoxProps

export const FieldPreviewLabel = ({ sx = null, children, ...props }: FieldPreviewLabelPropsT) => {
  return (
    <Box
      {...props}
      sx={[
        mapTypographyPresetToSx(typographyPresets.overlineSmall),
        { color: themeColors.overBackgroundFaded, textTransform: 'uppercase' },
        sx,
      ].flat()}
    >
      {children}
    </Box>
  )
}

export const FieldPreviewLabelSkeleton = () => {
  return (
    <TextSkeleton
      contentSx={{ width: '30%' }}
      typographyPreset={typographyPresets.overlineSmall}
    />
  )
}

export type FieldPreviewValueContentPropsT = MergeAllT<
  [
    Omit<BoxProps, 'ref'>,
    {
      isMarkedAsDeleted?: undefined | null | boolean
      multiline?: boolean
      to?: LinkProps['to']
    },
  ]
>

export const FieldPreviewValueContent = ({
  children,
  isMarkedAsDeleted,
  multiline,
  sx = null,
  ...props
}: FieldPreviewValueContentPropsT) => {
  const { hasOverflow, elementRef } = useHasOverflow({ enabled: !multiline })

  const renderContent = () => {
    if (children === undefined || children === null || (typeof children === 'string' && children.length === 0)) {
      return '-'
    }

    return children
  }

  const renderRoot = () => {
    const getDefaultSx = ({ isLink }: { isLink?: boolean } = {}) => {
      return [
        mapTypographyPresetToSx(typographyPresets.bodyMedium),
        {
          color: !isLink ? themeColors.overBackgroundDefault : themeColors.basePrimary,
          overflow: 'hidden',
          overflowWrap: 'break-word',
        },
      ]
    }

    const getMultilineSx = () => {
      if (multiline) {
        return null
      }

      return {
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
      }
    }

    const getMarkedAsDeletedSx = () => {
      if (isMarkedAsDeleted) {
        return { textDecoration: 'line-through' }
      }

      return null
    }

    const getSx = ({ isLink }: { isLink?: boolean } = {}) => {
      return [getDefaultSx({ isLink }), getMultilineSx(), getMarkedAsDeletedSx(), sx].flat()
    }

    return (
      <Linkable
        {...props}
        ref={elementRef}
        sx={({ isLink }) => getSx({ isLink })}
      >
        {renderContent()}
      </Linkable>
    )
  }

  return (
    <Tooltip
      placement="bottom-start"
      title={!hasOverflow ? '' : renderContent()}
    >
      {renderRoot()}
    </Tooltip>
  )
}

export const FieldPreviewValueContentSkeleton = () => {
  return (
    <TextSkeleton
      contentSx={{ width: '70%' }}
      typographyPreset={typographyPresets.bodyMedium}
    />
  )
}

const mapDataToLinkDefault = (_data: unknown) => {
  return undefined
}

const renderDataDefault = (data: unknown) => {
  if (!(typeof data === 'number' || typeof data === 'string')) {
    return null
  }

  return data
}

export type FieldPreviewValuePropsT<ValueT extends unknown, DataT extends unknown = ValueT> = {
  Container?: FieldPreviewValueContainerT<DataT>
  isMarkedAsDeleted?: undefined | null | boolean
  mapDataToLinkTo?: (data: DataT) => undefined | LinkProps['to']
  multiline?: boolean
  renderData?: (data: DataT) => React.ReactNode
  value: ValueT
}

export const FieldPreviewValue = <ValueT extends unknown, DataT extends unknown = ValueT>({
  Container,
  isMarkedAsDeleted,
  mapDataToLinkTo = mapDataToLinkDefault,
  multiline,
  renderData = renderDataDefault,
  value,
}: FieldPreviewValuePropsT<ValueT, DataT>) => {
  const renderIdle = () => {
    return <FieldPreviewValueContent>{null}</FieldPreviewValueContent>
  }

  const renderLoading = () => {
    return <FieldPreviewValueContentSkeleton />
  }

  const renderSuccess = (data: DataT) => {
    return (
      <FieldPreviewValueContent
        isMarkedAsDeleted={isMarkedAsDeleted}
        multiline={multiline}
        to={mapDataToLinkTo(data)}
      >
        {renderData(data)}
      </FieldPreviewValueContent>
    )
  }

  const renderContainer = () => {
    // if value is not defined we display idle content
    if (value === undefined || value === null) {
      return renderIdle()
    }

    // if container is not defined we assume that value is data
    if (!Container) {
      return renderSuccess(value as unknown as DataT)
    }

    // if container is defined but value is not an id we can't fetch data and therefore display idle content
    // this is the case when value is nullable like assignee id in issue
    if (typeof value !== 'string') {
      return renderIdle()
    }

    return (
      <Container id={value}>
        {({ optionQuery }) => {
          // if data is not loaded yet we display loading content
          if (!optionQuery.isSuccess) {
            return renderLoading()
          }

          return renderSuccess(optionQuery.data)
        }}
      </Container>
    )
  }

  return (
    <ErrorBoundary fallback={() => <FieldPreviewValueContent>Not Found</FieldPreviewValueContent>}>
      {renderContainer()}
    </ErrorBoundary>
  )
}

export type FieldPreviewPropsT<ValueT extends unknown, DataT extends unknown> = MergeAllT<
  [
    FieldPreviewValuePropsT<ValueT, DataT>,
    {
      label: React.ReactNode
    },
  ]
>

export const FieldPreview = <ValueT extends unknown, DataT extends unknown = ValueT>({
  label,
  ...props
}: FieldPreviewPropsT<ValueT, DataT>) => {
  return (
    <FieldPreviewLayout>
      <FieldPreviewLabel>{label}</FieldPreviewLabel>
      <FieldPreviewValue {...props} />
    </FieldPreviewLayout>
  )
}

export const FieldPreviewSkeleton = () => {
  return (
    <FieldPreviewLayout>
      <FieldPreviewLabelSkeleton />
      <FieldPreviewValueContentSkeleton />
    </FieldPreviewLayout>
  )
}

export const renderDateFieldPreviewData = (valueActual: number | string | Date) => {
  const date = createDate(valueActual)

  const formattedDate = fullDateFormatter.format(date)

  return formattedDate
}

export const renderDateTimeFieldPreviewData = (valueActual: number | string | Date) => {
  const date = createDate(valueActual)

  const formattedDate = fullDateTimeFormatter.format(date)

  return formattedDate
}

export type SwitchFieldPreviewPropsT = {
  label: React.ReactNode
  value: undefined | null | boolean
  isMarkedAsDeleted?: undefined | null | boolean
}

export const SwitchFieldPreview = ({ label, value, isMarkedAsDeleted }: SwitchFieldPreviewPropsT) => {
  return (
    <LabelPreview
      description={label}
      isMarkedAsDeleted={isMarkedAsDeleted}
      size="md"
    >
      <SwitchPreview
        size="md"
        value={value ?? false}
      />
    </LabelPreview>
  )
}
