import { pipeline } from '@resnet/client-common/common/utils/function/pipeline'

import type { UserFragmentT } from '@resnet/client-api/api'

export const mapUserToLastName = (user: Pick<UserFragmentT, 'lastName'>): string =>
  pipeline(
    user.lastName,
    (x) => x ?? '',
    (x) => x.trim(),
  )
