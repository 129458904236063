import type { SxProps } from '@mui/material'

import { NonNullableContextContainer } from '@resnet/client-common/react/hooks/use-non-nullable-context'
import type { HookContainerT } from '@resnet/client-common/react/utils/create-hook-container'
import type { MergeAllT } from '@resnet/client-common/typescript/types/merge-all'

import type { MediaT } from '@resnet/client-shared/shared/gdl/types/media'

import type { DropdownInputPropsT } from '@resnet/client-shared-web/shared/gdl/components/dropdown-input'
import { DropdownInput } from '@resnet/client-shared-web/shared/gdl/components/dropdown-input'
import { Popover } from '@resnet/client-shared-web/shared/gdl/components/popover'
import type { PopperPropsT } from '@resnet/client-shared-web/shared/gdl/components/popper'
import { Popper, PopperContext } from '@resnet/client-shared-web/shared/gdl/components/popper'
import type { SelectDropdownPropsT } from '@resnet/client-shared-web/shared/gdl/components/select-dropdown'
import { SelectDropdown } from '@resnet/client-shared-web/shared/gdl/components/select-dropdown'
import { SelectSearch } from '@resnet/client-shared-web/shared/gdl/components/select-dropdown/components/select-search'
import type { AbstractOptionT } from '@resnet/client-shared-web/shared/gdl/types/abstract-option'

import { DropdownText } from '../../gdl/components/dropdown-text'
import type { OptionContainerT } from '../../gdl/types/option-container'

export type SelectDropdownContainerT<QueryVariablesT extends object, OptionT extends AbstractOptionT> = HookContainerT<
  {
    exclude?: string[]
    withQueryVariables?: (input: QueryVariablesT) => QueryVariablesT
  },
  {
    dropdownProps: {
      isLoading: boolean
      optionHeight?: number
      options: OptionT[]
    }
    searchProps: {
      onChange: (event: React.ChangeEvent<HTMLInputElement>) => void
      onClear: () => void
      value: string
    }
  }
>

export type CreateSelectDropdownPropsT<QueryVariablesT extends object, OptionT extends AbstractOptionT> = {
  SelectDropdownContainer: SelectDropdownContainerT<QueryVariablesT, OptionT>
  getOptionDescription?: (option: OptionT) => string
  getOptionLabel?: (option: OptionT) => string
  getOptionMedia?: (option: OptionT) => MediaT
  getOptionVisible?: (option: OptionT, value: undefined | null | OptionT['id']) => boolean
} & Pick<SelectDropdownPropsT<OptionT>, 'skeletonProps' | 'header' | 'optionHeight'>

export type SelectDropdownWrapperPropsT<QueryVariablesT extends object> = {
  dropdownHeader?: React.ReactNode
  exclude?: string[]
  isBusy?: boolean
  nonNullable?: boolean
  onChange?: (value: undefined | null | string) => void
  value: undefined | null | string
  withQueryVariables?: (input: QueryVariablesT) => QueryVariablesT
}

export const createSelectDropdown = <QueryVariablesT extends object, OptionT extends AbstractOptionT>({
  getOptionLabel,
  getOptionDescription,
  SelectDropdownContainer,
  getOptionMedia,
  ...props
}: CreateSelectDropdownPropsT<QueryVariablesT, OptionT>) => {
  const SelectDropdownWrapper = ({
    dropdownHeader,
    exclude,
    isBusy,
    nonNullable,
    onChange,
    value,
    withQueryVariables,
  }: SelectDropdownWrapperPropsT<QueryVariablesT>) => {
    return (
      <Popover sx={{ overflow: 'hidden' }}>
        <SelectDropdownContainer
          exclude={exclude}
          withQueryVariables={withQueryVariables}
        >
          {({ searchProps, dropdownProps }) => (
            <SelectDropdown
              {...dropdownProps}
              {...props}
              getOptionDescription={getOptionDescription}
              getOptionLabel={getOptionLabel}
              getOptionMedia={getOptionMedia}
              header={dropdownHeader}
              isBusy={isBusy}
              nonNullable={nonNullable}
              search={!searchProps ? null : <SelectSearch {...searchProps} />}
              value={value}
              onChange={onChange}
            />
          )}
        </SelectDropdownContainer>
      </Popover>
    )
  }

  return SelectDropdownWrapper
}

export type CreateSelectPropsT<QueryVariablesT extends object, OptionT extends AbstractOptionT> = {
  OptionContainer?: OptionContainerT<OptionT>
  SelectDropdownContainer: SelectDropdownContainerT<QueryVariablesT, OptionT>
  getOptionDescription?: (option: OptionT) => string
  getOptionLabel?: (option: OptionT) => string
  getOptionMedia?: (option: OptionT) => MediaT
  getOptionVisible?: (option: OptionT, value: undefined | null | OptionT['id']) => boolean
} & Pick<SelectDropdownPropsT<OptionT>, 'skeletonProps' | 'header' | 'optionHeight'> & {
    tooltip?: React.ReactNode
    disabledTooltipTitle?: string
  }

export type SelectPropsT<QueryVariablesT extends object> = MergeAllT<
  [
    Omit<PopperPropsT, 'children'>,
    {
      placeholder?: string
      disabled?: boolean
      disablePortal?: boolean
      dropdownHeader?: React.ReactNode
      exclude?: string[]
      hasError?: boolean
      isBusy?: boolean
      nonNullable?: boolean
      onChange?: (value: undefined | null | string) => void
      onClose?: () => void
      onCurrentValueQueryError?: () => void
      placement?: React.ComponentProps<typeof Popper>['placement']
      size?: DropdownInputPropsT['size']
      sx?: SxProps
      value: undefined | null | string
      withQueryVariables?: (input: QueryVariablesT) => QueryVariablesT
      renderAnchor?: () => React.ReactElement
    },
  ]
>

export const createSelect = <QueryVariablesT extends object, OptionT extends AbstractOptionT>({
  getOptionLabel,
  getOptionDescription,
  OptionContainer,
  SelectDropdownContainer,
  getOptionMedia,
  tooltip,
  disabledTooltipTitle,
  ...props
}: CreateSelectPropsT<QueryVariablesT, OptionT>) => {
  const SelectDropdownWrapper = createSelectDropdown({
    ...props,
    SelectDropdownContainer,
    getOptionDescription,
    getOptionLabel,
    getOptionMedia,
  })

  const Select = ({
    disabled,
    disablePortal,
    dropdownHeader,
    exclude,
    hasError,
    isBusy,
    nonNullable,
    onChange,
    onClose,
    onCurrentValueQueryError,
    placement,
    popperRef,
    size,
    sx,
    value,
    withQueryVariables,
    placeholder,
    renderAnchor: renderAnchorActual,
  }: SelectPropsT<QueryVariablesT>) => {
    const renderAnchor = () => {
      if (renderAnchorActual) {
        return renderAnchorActual()
      }

      return (
        <NonNullableContextContainer Context={PopperContext}>
          {({ setAnchorEl, isOpened, open }) => (
            <DropdownInput
              disabled={disabled}
              disabledTooltipTitle={disabledTooltipTitle}
              hasError={hasError}
              isOpened={isOpened}
              ref={setAnchorEl}
              size={size}
              sx={sx}
              tooltip={isOpened ? undefined : tooltip}
              onClick={open}
            >
              <DropdownText
                OptionContainer={OptionContainer}
                getOptionLabel={getOptionLabel}
                getOptionMedia={getOptionMedia}
                placeholder={placeholder}
                size={size}
                value={value}
                onCurrentValueQueryError={onCurrentValueQueryError}
              />
            </DropdownInput>
          )}
        </NonNullableContextContainer>
      )
    }

    const renderContent = () => {
      return (
        <SelectDropdownWrapper
          dropdownHeader={dropdownHeader}
          exclude={exclude}
          isBusy={isBusy}
          nonNullable={nonNullable}
          value={value}
          withQueryVariables={withQueryVariables}
          onChange={onChange}
        />
      )
    }

    return (
      <Popper
        disablePortal={disablePortal}
        placement={placement}
        popperRef={popperRef}
        onClose={onClose}
      >
        {{
          anchor: renderAnchor,
          content: renderContent,
        }}
      </Popper>
    )
  }

  return Select
}
