import { cloneElement } from 'react'

export type WrapPropsT = {
  children: React.ReactNode
  with: undefined | null | false | React.ReactElement | ((children: React.ReactNode) => React.ReactNode)
}

export const Wrap = ({ children, with: withWrapper }: WrapPropsT) => {
  if (!withWrapper) {
    return <>{children}</>
  }

  if (typeof withWrapper === 'function') {
    return <>{withWrapper(children)}</>
  }

  return cloneElement(withWrapper, undefined, children)
}
