import { Box } from '@mui/material'
import type { FieldPathByValue, FieldValues, Path, PathValue, UseControllerProps, UseFormReturn } from 'react-hook-form'
import { useController } from 'react-hook-form'

import type { AnyTypeT } from '@resnet/client-common/typescript/types/any-type'

import { getSpecialValuesFieldProps } from '@resnet/client-shared/shared/forms/utils/get-special-values-field-props'

import { Field, FieldErrorText } from '@resnet/client-shared-web/shared/gdl/components/field'
import { FieldLabel } from '@resnet/client-shared-web/shared/gdl/components/field'
import { toPx } from '@resnet/client-shared-web/shared/gdl/utils/to-px'

import { UserSelect } from '@resnet/client-web/shared/users/components/user-dropdown'

export type UserSelectValueT = undefined | null | string

type UserSelectFieldPropsT<FieldValuesT extends Record<string, AnyTypeT>, FieldNameT extends Path<FieldValuesT>> = {
  defaultValue?: PathValue<FieldValuesT, FieldNameT>
  form: UseFormReturn<FieldValuesT>
  headerRight?: React.ReactNode
  name: FieldNameT
  label: string
  rules?: UseControllerProps<FieldValuesT, FieldNameT>['rules']
}

export const UserSelectField = <
  TFieldValues extends FieldValues,
  TPath extends FieldPathByValue<TFieldValues, UserSelectValueT>,
>({
  form,
  name,
  label,
  rules,
  defaultValue,
  headerRight,
}: UserSelectFieldPropsT<TFieldValues, TPath>) => {
  const {
    field,
    fieldState: { error, invalid },
  } = useController({ control: form.control, defaultValue, name, rules })

  const renderHeader = () => {
    return (
      <Box sx={{ alignItems: 'center', display: 'flex', gap: toPx(16) }}>
        <FieldLabel>{label}</FieldLabel>
        {headerRight}
      </Box>
    )
  }

  const renderInput = () => {
    const specialValuesFieldProps = getSpecialValuesFieldProps(field)

    return (
      <UserSelect
        hasError={invalid}
        {...field}
        onChange={(value: PathValue<TFieldValues, TPath>) => {
          field.onChange(value)
        }}
        {...specialValuesFieldProps}
      />
    )
  }

  const renderFooter = () => {
    if (!error) {
      return null
    }

    return <FieldErrorText>{error.message as string}</FieldErrorText>
  }

  return (
    <Field sx={{ flexGrow: 1, width: 0 }}>
      {renderHeader()}
      {renderInput()}
      {renderFooter()}
    </Field>
  )
}
