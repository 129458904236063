import { FieldTypeIdT } from '@resnet/client-web/shared/fields/constants/field-types'

import type { CustomFieldT } from '../../types/custom-field'
import { mapCustomFieldToFullKey } from '../../utils/map-custom-field-to-key'

export const createField: CustomFieldT['createField'] = ({ field, fieldValuesKey }) => {
  return {
    id: mapCustomFieldToFullKey(field, fieldValuesKey),
    name: field.name,
    type: FieldTypeIdT.Checkbox,
  }
}
