import type { FieldError } from 'react-hook-form'

import { useEventCallback } from '@resnet/client-common/react/hooks/use-event-callback'
import { forwardFunctionalComponentRef } from '@resnet/client-common/react/utils/forward-functional-component-ref'

import { Field, FieldLabel, FieldErrorText } from '@resnet/client-shared-web/shared/gdl/components/field'
import { TextInput } from '@resnet/client-shared-web/shared/gdl/components/text-input'

export const PresetNameField = forwardFunctionalComponentRef(
  (
    {
      value,
      error,
      onChange: onChangeProp,
      onBlur,
      placeholder = 'Enter name',
      label,
    }: {
      label: string
      placeholder?: string
      value: string
      error?: FieldError
      onChange: (value: string) => void
      onBlur?: (event: React.FocusEvent<HTMLInputElement>) => void
    },
    ref: React.Ref<HTMLInputElement>,
  ) => {
    const onChange = useEventCallback((event: React.ChangeEvent<HTMLInputElement>) => {
      onChangeProp(event.target.value)
    })

    return (
      <Field>
        <FieldLabel>{label}</FieldLabel>
        <TextInput
          hasError={Boolean(error)}
          placeholder={placeholder}
          ref={ref}
          value={value}
          onBlur={onBlur}
          onChange={onChange}
        />
        {!error ? null : <FieldErrorText>{error.message}</FieldErrorText>}
      </Field>
    )
  },
)
