import { checkNonNullable } from '@resnet/client-common/common/utils/nullable/non-nullable'
import { assert } from '@resnet/client-common/typescript/utils/assert'

import { CustomFieldTypeT, type CustomFieldFragmentT } from '@resnet/client-api/api'

import type { TextCustomFieldValueT } from '@resnet/client-shared/shared/custom-fields/presets/text/custom-field-value'

import { FieldPreview } from '@resnet/client-shared-web/shared/gdl/components/field-preview'

import { ObjectDetailsRow } from '@resnet/client-web/shared/object/components/object-details-row'

export type TextFormUserFormPreviewPropsT = {
  field: CustomFieldFragmentT
  value: undefined | TextCustomFieldValueT
}

export const TextFormUserFormPreview = ({ field, value }: TextFormUserFormPreviewPropsT) => {
  const payload = field.payload

  assert(payload, checkNonNullable)

  const textPayload = payload[CustomFieldTypeT.TextT]

  assert(textPayload, checkNonNullable)

  const { multiline, isHalfWidth } = textPayload

  const getSizeSx = () => {
    if (isHalfWidth && !multiline) {
      return null
    }

    return {
      gridColumn: 'span 2',
    }
  }

  return (
    <ObjectDetailsRow sx={getSizeSx()}>
      <FieldPreview
        label={field.name}
        multiline={field.payload?.[CustomFieldTypeT.TextT]?.multiline}
        value={value}
      />
    </ObjectDetailsRow>
  )
}
