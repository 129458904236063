import { identity } from 'ramda'
import type { FieldNamesMarkedBoolean } from 'react-hook-form'

import { pipeline } from '@resnet/client-common/common/utils/function/pipeline'
import { assertedNonUndefined } from '@resnet/client-common/common/utils/nullable/non-nullable'

import { returnUndefinedIfNotIsDirty } from './return-undefined-if-not-is-dirty'

export const ignoreDirtyFields = Symbol('ignoreDirtyFields')

export type GenericInputT = Record<string, unknown>

export type GenericOutputT = Record<string, unknown>

export type GenericSpecT<InputT extends GenericInputT, OutputT extends GenericOutputT> = {
  [K in keyof InputT & keyof OutputT]: (value: Exclude<InputT[K], undefined>, key: K) => OutputT[K]
}

export const mapFieldValuesToRemoteValues = <InputT extends GenericInputT, OutputT extends GenericOutputT>(
  input: InputT,
  dirtyFields: FieldNamesMarkedBoolean<InputT> | typeof ignoreDirtyFields,
  spec: GenericSpecT<InputT, OutputT>,
) => {
  const output = {} as GenericOutputT

  for (const key of Object.keys(spec)) {
    output[key] = pipeline(
      input[key] as InputT[string],
      assertedNonUndefined,
      (x) => spec[key](x, key),
      dirtyFields === ignoreDirtyFields ? identity : returnUndefinedIfNotIsDirty(dirtyFields)(key),
    )
  }

  return output as OutputT
}
