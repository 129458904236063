import type { SxProps } from '@mui/material'
import { Box, Tooltip } from '@mui/material'

import { roundToPrecision } from '@resnet/client-common/math/utils/to-precision'

import HeartEmptySolidIcon from '@resnet/client-shared/assets/icons/heart-empty-solid.svg'
import HeartFilledSolidIcon from '@resnet/client-shared/assets/icons/heart-filled-solid.svg'
import { systemColors } from '@resnet/client-shared/shared/gdl/constants/system-colors'

import { themeColors } from '@resnet/client-shared-web/shared/gdl/constants/theme-colors'
import { toPx } from '@resnet/client-shared-web/shared/gdl/utils/to-px'

const clip = ({ left = 0, right = 0 }: { left?: number; right?: number }): string =>
  `polygon(${left}% 0, ${(1 - right) * 100}% 0, ${(1 - right) * 100}% 100%, ${left}% 100%)`

const iconsCount = 5

export const AssetRating = ({
  value,
  iconSize = 20,
  sx = null,
}: {
  value: number
  iconSize?: number
  sx?: SxProps
}): React.ReactElement => {
  return (
    <Box sx={[{ display: 'flex' }, sx].flat()}>
      <Tooltip
        placement="right"
        title={`${roundToPrecision(value * 100, 0.01)}%`}
      >
        <Box sx={{ display: 'flex', gap: toPx(4) }}>
          {Array.from({ length: iconsCount }, (_, index) => {
            const progress = Math.max(0, Math.min(value * iconsCount - index, 1))

            return (
              <Box
                key={index}
                sx={{
                  '& > *': {
                    gridArea: '1 / 1 / 2 / 2',
                  },
                  display: 'grid',
                  gridTemplateColumns: '1fr',
                  gridTemplateRows: '1fr',
                }}
              >
                <HeartEmptySolidIcon
                  fill={themeColors.overBackgroundFaded}
                  height={iconSize}
                  width={iconSize}
                />
                <Box
                  component={HeartFilledSolidIcon}
                  fill={systemColors.critical60}
                  height={iconSize}
                  sx={{ clipPath: clip({ right: 1 - progress }) }}
                  width={iconSize}
                />
              </Box>
            )
          })}
        </Box>
      </Tooltip>
    </Box>
  )
}
