import { omit } from 'ramda'

export const omitUndefinedValues = <ObjectT extends Record<string, unknown>>(input: undefined | null | ObjectT) => {
  if (!input) {
    return input
  }

  const keys = Object.keys(input).filter((key) => input[key] === undefined)

  if (keys.length === 0) {
    return input
  }

  return omit(keys, input)
}
