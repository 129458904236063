import { pipeline } from '@resnet/client-common/common/utils/function/pipeline'
import { getObjectProp } from '@resnet/client-common/common/utils/object/get-object-prop'

import type { CustomFieldFragmentT } from '@resnet/client-api/api'

import type { ResourceT } from '@resnet/client-shared-web/shared/files/types/resource'

import { customFieldsById } from '../constants/custom-fields'
import type { CustomFieldT } from '../types/custom-field'
import type { FormCustomFieldValuesT } from '../types/form-custom-field-values'

import { mapCustomFieldToKey } from './map-custom-field-to-key'

export const mapFormCustomFieldValuesToResources = ({
  fields,
  fieldValues,
}: {
  fields: CustomFieldFragmentT[]
  fieldValues: undefined | null | FormCustomFieldValuesT
}) => {
  const resources: ResourceT[] = pipeline(fields, (x) =>
    x.flatMap((field) => {
      const { type } = field

      const key = mapCustomFieldToKey(field)

      const customFieldTypeProps = customFieldsById[type] as CustomFieldT

      const mapFormCustomFieldValueToResources = getObjectProp(
        customFieldTypeProps,
        'mapFormCustomFieldValueToResources',
      )

      const getResources = () => {
        const valueActual = fieldValues?.[key]

        if (!mapFormCustomFieldValueToResources) {
          return []
        }

        return mapFormCustomFieldValueToResources({ field, value: valueActual })
      }

      return getResources()
    }),
  )

  return { resources }
}
