import type { BoxProps } from '@mui/material'
import { Box } from '@mui/material'

import { toPx } from '@resnet/client-shared-web/shared/gdl/utils/to-px'

export type ObjectDetailsRowPropsT = BoxProps

export const ObjectDetailsRow = ({ sx = null, children, ...props }: ObjectDetailsRowPropsT) => {
  return (
    <Box
      {...props}
      sx={[
        {
          '& > *': {
            flexGrow: 1,
            width: 0,
          },
          display: 'flex',
          gap: toPx(16),
        },
        sx,
      ].flat()}
    >
      {children}
    </Box>
  )
}
