import type { Path, PathValue } from 'react-hook-form'
import { useController, type UseControllerProps, type UseFormReturn } from 'react-hook-form'

import { useEventCallback } from '@resnet/client-common/react/hooks/use-event-callback'
import type { AnyTypeT } from '@resnet/client-common/typescript/types/any-type'

import { getSpecialValuesFieldProps } from '@resnet/client-shared/shared/forms/utils/get-special-values-field-props'

import { SimpleStaticOptionsDropdown } from '@resnet/client-shared-web/shared/common/components/simple-static-options-dropdown'
import { Field, FieldErrorText, FieldLabel } from '@resnet/client-shared-web/shared/gdl/components/field'
import type { PopperPropsT } from '@resnet/client-shared-web/shared/gdl/components/popper'
import type { AbstractSimpleOptionT } from '@resnet/client-shared-web/shared/gdl/types/abstract-option'

export type SimpleStaticOptionsDropdownFieldPropsT<
  FieldValuesT extends Record<string, AnyTypeT>,
  FieldNameT extends Path<FieldValuesT>,
  OptionT extends AbstractSimpleOptionT,
> = {
  defaultValue?: PathValue<FieldValuesT, FieldNameT>
  disabled?: boolean
  disabledInputTooltipTitle?: string
  emptyIsNullValue?: boolean
  form: UseFormReturn<FieldValuesT>
  getOptionDisabled?: (option: OptionT) => boolean
  label?: string
  name: FieldNameT
  nonNullable?: boolean
  onChange?: (value: PathValue<FieldValuesT, FieldNameT>) => void
  options: OptionT[]
  placement?: PopperPropsT['placement']
  rules?: UseControllerProps<FieldValuesT, FieldNameT>['rules']
}

export const SimpleStaticOptionsDropdownField = <
  FieldValuesT extends Record<string, AnyTypeT>,
  FieldNameT extends Path<FieldValuesT>,
  OptionT extends AbstractSimpleOptionT,
>({
  defaultValue,
  disabled,
  disabledInputTooltipTitle,
  form,
  getOptionDisabled,
  label,
  name,
  nonNullable,
  onChange: onChangeActual,
  options,
  placement,
  rules,
}: SimpleStaticOptionsDropdownFieldPropsT<FieldValuesT, FieldNameT, OptionT>) => {
  const {
    field: { value, onChange: onFieldChange, onBlur },
    field,
    fieldState: { error },
  } = useController({ control: form.control, defaultValue, name, rules })

  const onChange = useEventCallback((value: PathValue<FieldValuesT, FieldNameT>) => {
    if (nonNullable && !value) {
      return
    }

    onFieldChange(value)

    onChangeActual?.(value)

    onBlur()
  })

  const renderHeader = () => {
    if (!label) {
      return null
    }

    return <FieldLabel>{label}</FieldLabel>
  }

  const renderInput = () => {
    const specialValuesFieldProps = getSpecialValuesFieldProps(field)

    return (
      <SimpleStaticOptionsDropdown
        disabled={disabled}
        disabledInputTooltipTitle={disabledInputTooltipTitle}
        getOptionDisabled={getOptionDisabled}
        hasError={Boolean(error)}
        options={options}
        placement={placement}
        value={value}
        onChange={onChange}
        {...specialValuesFieldProps}
      />
    )
  }

  const renderError = () => {
    if (!error) {
      return null
    }

    return <FieldErrorText>{error.message}</FieldErrorText>
  }

  return (
    <Field sx={{ flexGrow: 1, width: 0 }}>
      {renderHeader()}
      {renderInput()}
      {renderError()}
    </Field>
  )
}
