import type { SxProps } from '@mui/material'

export const visuallyHiddenStyles: SxProps = {
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: '1px',
  left: 0,
  overflow: 'hidden',
  position: 'absolute',
  top: 0,
  whiteSpace: 'nowrap',
  width: '1px',
}

export const placeContentCenterStyles: SxProps = {
  alignItems: 'center',
  display: 'flex',
  justifyContent: 'center',
}

export const stretchRowItemStyles: SxProps = { flexGrow: 1, width: 0 }

export const stretchColumnItemStyles: SxProps = { flexGrow: 1, height: 0 }

export const childrenNoShrinkStyles: SxProps = { '& > *': { flexShrink: 0 } }

export const childrenProportionalMaxWidthStyles: SxProps = Object.assign(
  {},
  ...Array.from({ length: 2 }, (_item, index) => ({
    [`& > *:first-of-type:nth-last-of-type(${index + 2}), & > *:first-of-type:nth-last-of-type(${index + 2}) ~ *`]: {
      maxWidth: `${100 / (index + 2)}%`,
    },
  })),
)

export const childrenProportionalFlexBasisStyles: SxProps = Object.assign(
  {},
  ...Array.from({ length: 2 }, (_item, index) => ({
    [`& > *:first-of-type:nth-last-of-type(${index + 2}), & > *:first-of-type:nth-last-of-type(${index + 2}) ~ *`]: {
      flexBasis: `${100 / (index + 2)}%`,
      width: 0,
    },
  })),
)

export const listResetStyles: SxProps = {
  listStyle: 'none',
  margin: 0,
  padding: 0,
}

export const lineClampStyles = (count: number): SxProps => ({
  display: '-webkit-box',
  overflow: 'hidden',
  webkitBoxOrient: 'vertical',
  webkitLineClamp: String(count),
})

export const textFillColor = (color: string): SxProps => {
  return {
    WebkitTextFillColor: 'transparent',
    background: color,
    backgroundClip: 'text',
  }
}
