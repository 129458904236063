import { Box } from '@mui/material'
import { memo } from 'react'
import { Link } from 'react-router-dom'

import { fullDateTimeFormatter } from '@resnet/client-common/format/utils/date/full-date-time-formatter'

import type { ApprovalLevelT, ApproverT, UserQueryVariablesT } from '@resnet/client-api/api'
import { useUserQuery } from '@resnet/client-api/api'
import { ApproveStatusT } from '@resnet/client-api/api'

import MinusSolidIcon from '@resnet/client-shared/assets/icons/minus-solid.svg'
import { typographyPresets } from '@resnet/client-shared/shared/gdl/constants/typography-presets'
import { mapApprovalStatusToLabel } from '@resnet/client-shared/shared/issues/utils/map-approval-status-to-label'
import { mapUserToMedia } from '@resnet/client-shared/shared/users/utils/map-user-to-media-boston'
import { mapUserToTitle } from '@resnet/client-shared/shared/users/utils/map-user-to-title'

import { LoadableAvatar } from '@resnet/client-shared-web/shared/gdl/components/avatar'
import { Button } from '@resnet/client-shared-web/shared/gdl/components/button'
import { ListItemContentLayout, ListItemName } from '@resnet/client-shared-web/shared/gdl/components/list'
import { themeColors } from '@resnet/client-shared-web/shared/gdl/constants/theme-colors'
import { mapTypographyPresetToSx } from '@resnet/client-shared-web/shared/gdl/utils/map-typography-preset-to-sx'

import { mapUserToPathname } from '@resnet/client-web/shared/users/utils/map-user-to-pathname'

import { IssueApprovalTag } from '../issue-approval-tag'

export const Approver = memo(
  ({
    approver,
    onRemove,
    level,
    isAwaiting,
    isPreview,
  }: {
    onRemove: (userId: string) => void
    approver: ApproverT
    level: ApprovalLevelT & { isDraft?: boolean }
    isAwaiting: boolean
    isPreview?: boolean
  }) => {
    const userQuery = useUserQuery({ id: approver.id } as UserQueryVariablesT, {
      select: (data) => data.getUser,
    })

    if (!userQuery.data) {
      return null
    }

    const name = mapUserToTitle(userQuery.data)

    const media = mapUserToMedia(userQuery.data)

    // NOTE this mimics 4th status which does not exists on BE, but is present in UI
    const levelApprovalStatusLabel =
      isAwaiting && approver.status === ApproveStatusT.PendingT
        ? { name: 'Awaiting approval' }
        : mapApprovalStatusToLabel(approver)

    const renderStatus = (): React.ReactNode => {
      return level.isDraft ? null : (
        <IssueApprovalTag
          {...levelApprovalStatusLabel}
          size="sm"
        />
      )
    }

    const renderName = (): React.ReactNode => {
      return (
        <Box sx={{ alignItems: 'center', display: 'flex' }}>
          <ListItemName
            component={Link}
            sx={{
              flexGrow: 1,
              textDecoration: 'none',
              width: 0,
            }}
            // @ts-expect-error to is a valid prop for Link
            to={mapUserToPathname(userQuery.data)}
          >
            {name}
          </ListItemName>
          {renderStatus()}
        </Box>
      )
    }

    const renderAction = (): React.ReactNode => {
      return isPreview ? null : (
        <Button
          color="default"
          icon={<MinusSolidIcon />}
          size="md"
          sx={{ alignSelf: 'flex-start' }}
          variant="contained"
          onClick={() => onRemove(approver.id)}
        />
      )
    }

    const renderAvatar = (): React.ReactNode => {
      return (
        <LoadableAvatar
          size="md"
          {...media}
        />
      )
    }

    const renderDate = (): React.ReactNode => {
      return approver.status === (ApproveStatusT.PendingT || !approver.updatedAt) ? null : (
        <Box
          sx={[
            mapTypographyPresetToSx(typographyPresets.captionRegular),
            {
              color: themeColors.overBackgroundBold,
            },
          ].flat()}
        >
          {`on ${fullDateTimeFormatter.format(new Date(approver.updatedAt))}`}
        </Box>
      )
    }

    return (
      <ListItemContentLayout>
        {{ action: renderAction(), description: renderDate(), media: renderAvatar(), name: renderName() }}
      </ListItemContentLayout>
    )
  },
)
