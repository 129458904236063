import { useCallback } from 'react'
import { useSlate } from 'slate-react'

import type { CustomBlockFormatsT } from '../../types/common'
import { isBlockActive, toggleBlock } from '../../utils/common'

type FormatBlockButtonContainerPropsT = {
  format: CustomBlockFormatsT
  children: (props: { isActive: boolean; onClick: React.MouseEventHandler<HTMLButtonElement> }) => React.ReactElement
}

export const FormatBlockButtonContainer = ({
  format,
  children,
}: FormatBlockButtonContainerPropsT): React.ReactElement => {
  const editor = useSlate()
  const isActive = isBlockActive(editor, format)

  const onClick = useCallback(
    (event: React.MouseEvent) => {
      event.preventDefault()
      toggleBlock(editor, format)
    },
    [editor, format],
  )

  return children({ isActive, onClick })
}
