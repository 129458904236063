import { useMapFieldModel } from '@resnet/client-common/react/hooks/use-map-field-model'
import { NonNullableContextContainer } from '@resnet/client-common/react/hooks/use-non-nullable-context'

import { FilterModeT } from '@resnet/client-api/api'

import { DropdownInput } from '@resnet/client-shared-web/shared/gdl/components/dropdown-input'
import { MultipleDropdownText } from '@resnet/client-shared-web/shared/gdl/components/multiple-dropdown-text'
import { Popover } from '@resnet/client-shared-web/shared/gdl/components/popover'
import type { PopperPropsT } from '@resnet/client-shared-web/shared/gdl/components/popper'
import { Popper, PopperContext } from '@resnet/client-shared-web/shared/gdl/components/popper'
import { SelectSearch } from '@resnet/client-shared-web/shared/gdl/components/select-dropdown/components/select-search'
import { SelectMultipleDropdown } from '@resnet/client-shared-web/shared/gdl/components/select-multiple-dropdown'
import { SelectMultipleDropdownWithNullOptionContainer } from '@resnet/client-shared-web/shared/gdl/components/select-multiple-dropdown/hooks/use-select-multiple-dropdown-with-null-option'
import type { AbstractOptionT } from '@resnet/client-shared-web/shared/gdl/types/abstract-option'
import type { OptionContainerT } from '@resnet/client-shared-web/shared/gdl/types/option-container'
import type { SelectDropdownContainerT } from '@resnet/client-shared-web/shared/gdl/types/select-dropdown-container'

import { useIncludeExcludeFilterSelectMultipleDropdown } from '@resnet/client-web/shared/presets/hooks/use-include-exclude-filter-select-multiple-dropdown'
import type { IncludeExcludeFilterT } from '@resnet/client-web/shared/presets/types/filters'

export type MultipleFilterPropsT<
  OptionT extends AbstractOptionT,
  ItemT extends null | OptionT['id'] = OptionT['id'],
> = {
  disablePortal?: boolean
  getOptionLabel: (option: OptionT) => React.ReactNode
  nullOptionEnabled?: boolean
  nullOptionLabel?: React.ReactNode
  onChange: (value: undefined | null | IncludeExcludeFilterT<ItemT>) => void
  OptionContainer: OptionContainerT<OptionT>
  placement?: PopperPropsT['placement']
  popperRef?: PopperPropsT['popperRef']
  SelectDropdownContainer: SelectDropdownContainerT
  value: undefined | null | IncludeExcludeFilterT<ItemT>
}

export const MultipleFilter = <OptionT extends AbstractOptionT, ItemT extends null | OptionT['id'] = OptionT['id']>({
  disablePortal,
  getOptionLabel,
  nullOptionEnabled,
  nullOptionLabel,
  onChange: onChangeActual,
  OptionContainer,
  placement,
  popperRef,
  SelectDropdownContainer,
  value: valueActual,
}: MultipleFilterPropsT<OptionT, ItemT>) => {
  const [value, onChange] = useMapFieldModel(
    [valueActual, onChangeActual],
    (inputValue) => inputValue ?? { items: [], mode: FilterModeT.IncludeT },
    (outputValue) =>
      outputValue.mode === FilterModeT.IncludeT && outputValue.items.length === 0 ? undefined : outputValue,
  )

  const includeExcludeFilterProps = useIncludeExcludeFilterSelectMultipleDropdown({
    onChange,
    value,
  })

  const renderAnchor = () => {
    return (
      <NonNullableContextContainer Context={PopperContext}>
        {({ setAnchorEl, isOpened, open }) => (
          <DropdownInput
            isOpened={isOpened}
            ref={setAnchorEl}
            onClick={open}
          >
            <MultipleDropdownText
              OptionContainer={OptionContainer}
              getOptionLabel={getOptionLabel}
              mode={includeExcludeFilterProps.mode}
              nullOptionLabel={nullOptionLabel}
              value={includeExcludeFilterProps.value}
            />
          </DropdownInput>
        )}
      </NonNullableContextContainer>
    )
  }

  const renderContent = () => {
    return (
      <Popover>
        <SelectDropdownContainer>
          {({ searchProps, dropdownProps }) => (
            <SelectMultipleDropdownWithNullOptionContainer
              {...includeExcludeFilterProps}
              {...dropdownProps}
              enabled={nullOptionEnabled}
              nullOptionLabel={nullOptionLabel}
            >
              {(dropdownPropsWithNullOption) => (
                <SelectMultipleDropdown
                  {...includeExcludeFilterProps}
                  {...dropdownProps}
                  {...dropdownPropsWithNullOption}
                  search={<SelectSearch {...searchProps} />}
                />
              )}
            </SelectMultipleDropdownWithNullOptionContainer>
          )}
        </SelectDropdownContainer>
      </Popover>
    )
  }

  return (
    <Popper
      disablePortal={disablePortal}
      placement={placement}
      popperRef={popperRef}
    >
      {{
        anchor: renderAnchor,
        content: renderContent,
      }}
    </Popper>
  )
}
