import { ResourceObjectOptionContainer } from '@resnet/client-shared/shared/resource-objects/hooks/use-resource-object-option'
import { mapResourceObjectToTitle } from '@resnet/client-shared/shared/resource-objects/utils/map-resource-object-to-title'

import { createSelect } from '@resnet/client-shared-web/shared/common/factories/create-select'

import { ResourceObjectSelectDropdownContainer } from '../../hooks/use-resource-object-select-dropdown'

export const ResourceObjectSelect = createSelect({
  OptionContainer: ResourceObjectOptionContainer,
  SelectDropdownContainer: ResourceObjectSelectDropdownContainer,
  getOptionLabel: mapResourceObjectToTitle,
})
