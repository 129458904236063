type ValidatorT<ValueT> = (value: ValueT) => string | boolean | Promise<string | boolean>

export const composeValidators = <ValueT>(...validators: ValidatorT<ValueT>[]) => {
  return async (value: ValueT) => {
    let result: string | boolean = true

    for (const validator of validators) {
      result = await validator(value)

      if (result !== true) {
        break
      }
    }

    return result
  }
}
