import { Box } from "@mui/material";
import React from "react";
import Header from "../components/Header";
import { themeColors } from "@resnet/client-shared-web/shared/gdl/constants/theme-colors";
import { useLocation } from "react-router-dom";
import { useTeamsData } from "../context/teams-context";
import {
  Search,
  useSearchHandlers,
} from "@resnet/client-shared-web/shared/gdl/components/search";
import { toPx } from "@resnet/client-shared-web/shared/gdl/utils/to-px";
import { useForm } from "react-hook-form";
import { gasColors } from "@resnet/client-shared/shared/gdl/constants/gas-colors";
import { SimpleStaticOptionsDropdownField } from "@resnet/client-shared-web/shared/form/components/common/simple-static-options-dropdown-field";
import HomeIssues from "./Home/components/HomeIssues";
import HomeWells from "./Home/components/HomeWells";
import HomeBatteries from "./Home/components/HomeBatteries";
import HomeSites from "./Home/components/HomeSites";

type HomeScreenValuesT = {
  name: string;
  id: string;
};

const Home = (): React.ReactElement => {
  const { updateMessage, updateSubject, updateTeamsUrl, updateAttachments } =
    useTeamsData();
  const location = useLocation();

  const [searchActual, setSearch] = React.useState("");
  const [activeEntity, setActiveEntity] = React.useState("issues");
  const { onChange: onSearchChange, onClear: onSearchClear } =
    useSearchHandlers({ setSearch });

  const form = useForm<HomeScreenValuesT>({
      defaultValues: {
        name: "issues",
        id: "issues",
      },
    });

    const entities = [
      {
        buttonColor: "primary" as const,
        fill: gasColors.blueGray400,
        id: "issues" as const,
        name: "Issues",
      },
      {
        buttonColor: "primary" as const,
        fill: gasColors.blueA700,
        id: "wells" as const,
        name: "Wells",
      },
      {
        buttonColor: "primary" as const,
        fill: gasColors.yellowA700,
        id: "batteries" as const,
        name: "Batteries",
      },
      {
        buttonColor: "primary" as const,
        fill: gasColors.purpleA700,
        id: "sites" as const,
        name: "Sites",
      },
    ];

  const decodeURLParams = (url: string): Record<string, string> => {
    const params = new URLSearchParams(url);
    const decodedParams: Record<string, string> = {};
    for (const [key, value] of params) {
      decodedParams[key] = decodeURIComponent(value);
    }
    return decodedParams;
  };

  const handleURL = (url: string | undefined | null): void => {
    if (url === "" || url === undefined || url === null) {
      return;
    } else {
      const decodedParams = decodeURLParams(url);
      updateMessage(decodedParams.message);
      updateSubject(decodedParams.subject || "");
      updateTeamsUrl(decodedParams.teamsUrl || "");
      const attachmentsArray = JSON.parse(decodedParams.attachments || "[]");
      updateAttachments(attachmentsArray);
    }
  };

  // Call handleURL whenever location changes
  React.useEffect(() => {
    handleURL(location.search);
  }, [location.search]);


  const renderSearch = (): React.ReactNode => {
    return (
      <Search
        placeholder="Search Issues"
        sx={{ flexGrow: 1 }}
        value={searchActual}
        onChange={onSearchChange}
        onClear={onSearchClear}
      />
    );
  };

  return (
    <Box>
      <Box
        sx={{
          position: "fixed",
          zIndex: 9,
          width: "100%",
          background: themeColors.background,
        }}
      >
        <Header />

        <Box sx={{ display: "flex", gap: toPx(16), padding: "8px 16px" }}>
          <SimpleStaticOptionsDropdownField
            nonNullable
            form={form}
            label="Attach Email to:"
            name="name"
            onChange={(value) => {
              console.log(value);
              setActiveEntity(value);
            }}
            options={entities}
            placement="bottom-end"
          />
        </Box>

        <Box sx={{ padding: "8px 16px"}}>{renderSearch()}</Box>
      </Box>
      {activeEntity === "wells" ? (
        <HomeWells search={searchActual} />
      ) : null}
      {activeEntity === "issues" ? (
        <HomeIssues search={searchActual} />
      ) : null}
      {activeEntity === "batteries" ? (
        <HomeBatteries search={searchActual} />
      ) : null}
      {activeEntity === "sites" ? (
        <HomeSites search={searchActual} />
      ): null}
    </Box>
  );
};

export default Home;
