import type { BoxProps } from '@mui/material'
import { Box, Tooltip } from '@mui/material'

import { forwardFunctionalComponentRef } from '@resnet/client-common/react/utils/forward-functional-component-ref'
import type { MergeAllT } from '@resnet/client-common/typescript/types/merge-all'

export type SingleLineTextPropsT = MergeAllT<
  [
    Omit<BoxProps, 'ref'>,
    {
      showTooltip?: boolean
    },
  ]
>

export type SingleLineTextRefT = React.Ref<HTMLElement>

export const SingleLineText = forwardFunctionalComponentRef(
  ({ showTooltip = false, sx = null, children, ...props }: SingleLineTextPropsT, ref: SingleLineTextRefT) => {
    return (
      <Tooltip title={!showTooltip ? '' : children}>
        <Box
          {...props}
          ref={ref}
          sx={[{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }, sx].flat()}
        >
          {children}
        </Box>
      </Tooltip>
    )
  },
)
