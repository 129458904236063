import { Box } from '@mui/material'
import { useSnackbar } from 'notistack'
import { Controller, useForm } from 'react-hook-form'

import { mapErrorToMessage } from '@resnet/client-common/network/utils/map-error-to-message'
import { useAsyncEventCallback } from '@resnet/client-common/react/hooks/use-async-event-callback'
import { useNonNullableContext } from '@resnet/client-common/react/hooks/use-non-nullable-context'
import { createConstrainer } from '@resnet/client-common/typescript/utils/create-constrainer'

import type { ApprovalTemplatePresetFragmentT, InputApprovalTemplateLevelT } from '@resnet/client-api/api'
import {
  PresetTypeT,
  useCreateCompanyApprovalTemplatePresetMutation,
  useCreateMyApprovalTemplatePresetMutation,
} from '@resnet/client-api/api'

import { useProfile } from '@resnet/client-shared/shared/users/hooks/use-profile'
import { checkHasAdminRights } from '@resnet/client-shared/shared/users/utils/check-has-admin-rights'

import {
  ModalContext,
  createModalContainer,
  createModalHook,
} from '@resnet/client-shared-web/shared/gdl/components/modal'
import { toPx } from '@resnet/client-shared-web/shared/gdl/utils/to-px'

import { PresetNameField } from '../preset-name-field'
import type { SaveAsOptionT } from '../preset-save-as-field'
import { PresetSaveAsField } from '../preset-save-as-field'
import { PresetSaveAsNewModal } from '../preset-save-as-new-modal'

const saveAsOptions = createConstrainer<SaveAsOptionT[]>()([
  { id: PresetTypeT.UserT, name: 'My Preset' },
  { id: PresetTypeT.CompanyT, name: 'Company Preset' },
])

type CreateApprovalTemplatePresetStateT = {
  approvalTemplatePresetLevels: InputApprovalTemplateLevelT[]
  name: string
  saveAs: (typeof saveAsOptions)[number]['id']
}

export const ApprovalTemplatePresetsSaveAsNewModal = ({
  approvalTemplatePresetLevels,
  onSubmitSuccess,
}: {
  approvalTemplatePresetLevels: InputApprovalTemplateLevelT[]
  onSubmitSuccess?: (props: { approvalTemplatePreset: ApprovalTemplatePresetFragmentT }) => void
}): React.ReactElement => {
  const { enqueueSnackbar } = useSnackbar()

  const { mutateAsync: createMyApprovalTemplatePreset } = useCreateMyApprovalTemplatePresetMutation()

  const { mutateAsync: createCompanyApprovalTemplatePreset } = useCreateCompanyApprovalTemplatePresetMutation()

  const { profile } = useProfile()

  const { onClose } = useNonNullableContext(ModalContext)

  const { handleSubmit, control } = useForm<CreateApprovalTemplatePresetStateT>({
    defaultValues: {
      approvalTemplatePresetLevels,
      name: '',
      saveAs: PresetTypeT.UserT,
    },
  })

  const [isCreatePending, onCreate] = useAsyncEventCallback(async (data: CreateApprovalTemplatePresetStateT) => {
    try {
      const { name, saveAs, approvalTemplatePresetLevels } = data

      const variables = { levels: approvalTemplatePresetLevels, name: name.trim() }

      const approvalTemplatePreset = await (async () => {
        switch (saveAs) {
          case PresetTypeT.UserT: {
            const response = await createMyApprovalTemplatePreset(variables)

            return response.createMyApprovalTemplatePreset
          }
          case PresetTypeT.CompanyT: {
            const response = await createCompanyApprovalTemplatePreset(variables)

            return response.createCompanyApprovalTemplatePreset
          }
        }
      })()

      enqueueSnackbar(`"${name}" approval template has been saved`, {
        variant: 'success',
      })

      onSubmitSuccess?.({ approvalTemplatePreset })

      onClose?.()
    } catch (error) {
      enqueueSnackbar(mapErrorToMessage(error), {
        variant: 'error',
      })
    }
  })

  const renderNameField = (): React.ReactNode => {
    return (
      <Controller
        control={control}
        name="name"
        render={({ field: { ref, value, onChange, onBlur }, formState }) => {
          const error = formState.errors.name

          return (
            <PresetNameField
              error={error}
              label="Approval Preset Name"
              ref={ref}
              value={value}
              onBlur={onBlur}
              onChange={onChange}
            />
          )
        }}
        rules={{
          validate: (value) => {
            if (value.trim().length === 0) {
              return 'This field is required'
            }

            return true
          },
        }}
      />
    )
  }

  const renderSaveAsField = (): React.ReactNode => {
    if (!checkHasAdminRights(profile)) {
      return null
    }

    return (
      <Controller
        control={control}
        name="saveAs"
        render={({ field: { value, onChange } }) => (
          <PresetSaveAsField
            options={saveAsOptions}
            value={value}
            onChange={onChange}
          />
        )}
      />
    )
  }

  const renderFields = (): React.ReactNode => {
    return (
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: toPx(16) }}>
        {renderNameField()}
        {renderSaveAsField()}
      </Box>
    )
  }

  return (
    <PresetSaveAsNewModal
      fields={renderFields}
      isLoading={isCreatePending}
      title="Save Approval Preset"
      onSubmit={handleSubmit(onCreate)}
    />
  )
}

export const ApprovalTemplatePresetsSaveAsNewModalContainer = createModalContainer(
  createModalHook((params: React.ComponentProps<typeof ApprovalTemplatePresetsSaveAsNewModal>) => (
    <ApprovalTemplatePresetsSaveAsNewModal {...params} />
  )),
)
