import { produce } from 'immer'

import {
  useCreateInsightMutation,
  useDeleteInsightMutation,
  useFollowMutation,
  useInfiniteListInsightsQuery,
  useGetInsightQuery,
  useListInsightsQuery,
  useUnfollowMutation,
  useUpdateFollowersMutation,
  useUpdateInsightMutation,
  useUpdateRelationsMutation,
  useArchiveInsightMutation,
  useUnarchiveInsightMutation,
  useUpdateEntityTypeMutation,
  EntityTypeIdsT,
} from '@resnet/client-api/api'
import type { MutationCacheHandlersT } from '@resnet/client-api/services/query-client/types/mutation-cache-handlers'
import { createMutationHookMutationCacheHandlers } from '@resnet/client-api/services/query-client/utils/create-mutation-hook-mutation-cache-handlers'
import {
  infiniteListEntityQueryDeleteRecipe,
  listEntityQueryDeleteRecipe,
} from '@resnet/client-api/shared/entities/utils/delete-entity-recipe'
import {
  getEntityQueryUpdateRecipe,
  infiniteListEntityQueryUpdateRecipe,
  listEntityQueryUpdateRecipe,
} from '@resnet/client-api/shared/entities/utils/update-entity-recipe'
import { findGetQueriesById, findInfiniteQueries, findQueries } from '@resnet/client-api/utils/find-queries'
import { invalidateQuery } from '@resnet/client-api/utils/invalidate-query'
import { setQueryData } from '@resnet/client-api/utils/set-query-data'
import type { QueryClient } from '@resnet/client-api/vendors/react-query'

export const insightQueriesHandlers = ({ queryClient }: { queryClient: QueryClient }): MutationCacheHandlersT[] => {
  const findInsightQueries = () => {
    return findQueries(queryClient, useGetInsightQuery)
  }

  const findInsightQueriesById = ({ id }: { id: string }) =>
    findGetQueriesById(queryClient, useGetInsightQuery, (data) => data.getInsight, { id })

  const updateInsightHandlers = () => {
    return createMutationHookMutationCacheHandlers(useUpdateInsightMutation, {
      onError: (error, { id }) => {
        findInsightQueriesById({ id }).forEach(invalidateQuery(queryClient))
      },
      onMutate: ({ id, data: input }) => {
        findInsightQueriesById({ id }).forEach((query) => {
          setQueryData(queryClient)(query)(
            produce((data) => {
              getEntityQueryUpdateRecipe({
                data,
                input,
                mapDataToEntity: (data) => data.getInsight,
              })
            }),
          )
        })
      },
      onSuccess: (data, { id }) => {
        findInsightQueriesById({ id }).forEach(invalidateQuery(queryClient))
      },
    })
  }

  const followHandlers = () => {
    return createMutationHookMutationCacheHandlers(useFollowMutation, {
      onSuccess: (data, { entityId }) => {
        findInsightQueriesById({ id: entityId }).forEach(invalidateQuery(queryClient))
      },
    })
  }

  const unfollowHandlers = () => {
    return createMutationHookMutationCacheHandlers(useUnfollowMutation, {
      onSuccess: (data, { entityId }) => {
        findInsightQueriesById({ id: entityId }).forEach(invalidateQuery(queryClient))
      },
    })
  }

  const updateFollowersHandlers = () => {
    return createMutationHookMutationCacheHandlers(useUpdateFollowersMutation, {
      onSuccess: (data, { entityId }) => {
        findInsightQueriesById({ id: entityId }).forEach(invalidateQuery(queryClient))
      },
    })
  }

  const deleteInsightHandlers = () => {
    return createMutationHookMutationCacheHandlers(useDeleteInsightMutation, {
      onSuccess: (data, { id }) => {
        findInsightQueriesById({ id }).forEach(invalidateQuery(queryClient))
      },
    })
  }

  const updateRelationsHandlers = () => {
    return createMutationHookMutationCacheHandlers(useUpdateRelationsMutation, {
      onSuccess: (data, { entityId }) => {
        findInsightQueriesById({ id: entityId }).forEach(invalidateQuery(queryClient))
      },
    })
  }

  const archiveInsightHandlers = () => {
    return createMutationHookMutationCacheHandlers(useArchiveInsightMutation, {
      onError: (error, { id }) => {
        findInsightQueriesById({ id }).forEach(invalidateQuery(queryClient))
      },
      onMutate: ({ id }) => {
        findInsightQueriesById({ id }).forEach((query) => {
          setQueryData(queryClient)(query)(
            produce((data) => {
              getEntityQueryUpdateRecipe({
                data,
                input: { archived: { at: new Date().toISOString() } },
                mapDataToEntity: (data) => data.getInsight,
              })
            }),
          )
        })
      },
      onSuccess: (data, { id }) => {
        findInsightQueriesById({ id }).forEach(invalidateQuery(queryClient))
      },
    })
  }

  const unarchiveInsightHandlers = () => {
    return createMutationHookMutationCacheHandlers(useUnarchiveInsightMutation, {
      onError: (error, { id }) => {
        findInsightQueriesById({ id }).forEach(invalidateQuery(queryClient))
      },
      onMutate: ({ id }) => {
        findInsightQueriesById({ id }).forEach((query) => {
          setQueryData(queryClient)(query)(
            produce((draft) => {
              const insight = draft?.getInsight

              if (!insight) {
                return
              }

              delete insight.archived
            }),
          )
        })
      },
      onSuccess: (data, { id }) => {
        findInsightQueriesById({ id }).forEach(invalidateQuery(queryClient))
      },
    })
  }

  const updateEntityTypeHandlers = () => {
    return createMutationHookMutationCacheHandlers(useUpdateEntityTypeMutation, {
      onSuccess: (data, { id }) => {
        if (id !== EntityTypeIdsT.InsightT) {
          return
        }

        findInsightQueries().forEach(invalidateQuery(queryClient))
      },
    })
  }

  return [
    updateInsightHandlers(),
    followHandlers(),
    unfollowHandlers(),
    updateFollowersHandlers(),
    deleteInsightHandlers(),
    updateRelationsHandlers(),
    archiveInsightHandlers(),
    unarchiveInsightHandlers(),
    updateEntityTypeHandlers(),
  ]
}

export const insightsQueriesHandlers = ({ queryClient }: { queryClient: QueryClient }): MutationCacheHandlersT[] => {
  const findInsightsQueries = () => findQueries(queryClient, useListInsightsQuery)

  const findInfiniteInsightsQueries = () => findInfiniteQueries(queryClient, useInfiniteListInsightsQuery)

  const findInsightsQueriesById = ({ id }: { id: string }) =>
    findQueries(queryClient, useListInsightsQuery, (query) => {
      const data = query.state.data

      if (!data) {
        return false
      }

      const items = data.listInsights.items

      return items.some((item) => item.id === id)
    })

  const findInfiniteInsightsQueriesById = ({ id }: { id: string }) =>
    findInfiniteQueries(queryClient, useInfiniteListInsightsQuery, (query) => {
      const infiniteData = query.state.data

      if (!infiniteData) {
        return false
      }

      return infiniteData.pages.some((data) => {
        const items = data.listInsights.items

        return items.some((item) => item.id === id)
      })
    })

  const createInsightHandlers = () => {
    return createMutationHookMutationCacheHandlers(useCreateInsightMutation, {
      onSuccess: () => {
        findInsightsQueries().forEach(invalidateQuery(queryClient))
        findInfiniteInsightsQueries().forEach(invalidateQuery(queryClient))
      },
    })
  }

  const deleteInsightHandlers = () => {
    return createMutationHookMutationCacheHandlers(useDeleteInsightMutation, {
      onError: (error, { id }) => {
        findInsightsQueriesById({ id }).forEach(invalidateQuery(queryClient))
        findInfiniteInsightsQueriesById({ id }).forEach(invalidateQuery(queryClient))
      },
      onMutate: ({ id }) => {
        findInsightsQueriesById({ id }).forEach((query) => {
          setQueryData(queryClient)(query)(
            produce((data) => {
              listEntityQueryDeleteRecipe({
                data,
                id,
                mapDataToEntities: (data) => data.listInsights.items,
              })
            }),
          )
        })

        findInfiniteInsightsQueriesById({ id }).forEach((query) => {
          setQueryData(queryClient)(query)(
            produce((data) => {
              infiniteListEntityQueryDeleteRecipe({
                data,
                id,
                mapDataToEntities: (data) => data.listInsights.items,
              })
            }),
          )
        })
      },
      onSuccess: (error, { id }) => {
        findInsightsQueriesById({ id }).forEach(invalidateQuery(queryClient))
        findInfiniteInsightsQueriesById({ id }).forEach(invalidateQuery(queryClient))
      },
    })
  }

  const updateInsightHandlers = () => {
    return createMutationHookMutationCacheHandlers(useUpdateInsightMutation, {
      onError: (error, { id }) => {
        findInsightsQueriesById({ id }).forEach(invalidateQuery(queryClient))
        findInfiniteInsightsQueriesById({ id }).forEach(invalidateQuery(queryClient))
      },
      onMutate: ({ id, data: input }) => {
        findInsightsQueriesById({ id }).forEach((query) => {
          setQueryData(queryClient)(query)(
            produce((data) => {
              listEntityQueryUpdateRecipe({
                data,
                id,
                input,
                mapDataToEntities: (data) => data.listInsights.items,
              })
            }),
          )
        })

        findInfiniteInsightsQueriesById({ id }).forEach((query) => {
          setQueryData(queryClient)(query)(
            produce((data) => {
              infiniteListEntityQueryUpdateRecipe({
                data,
                id,
                input,
                mapDataToEntities: (data) => data.listInsights.items,
              })
            }),
          )
        })
      },
      onSuccess: (data, { id }) => {
        findInsightsQueriesById({ id }).forEach(invalidateQuery(queryClient))
        findInfiniteInsightsQueriesById({ id }).forEach(invalidateQuery(queryClient))
      },
    })
  }

  const followHandlers = () => {
    return createMutationHookMutationCacheHandlers(useFollowMutation, {
      onSuccess: (data, { entityId: id }) => {
        findInsightsQueriesById({ id }).forEach(invalidateQuery(queryClient))
        findInfiniteInsightsQueriesById({ id }).forEach(invalidateQuery(queryClient))
      },
    })
  }

  const unfollowHandlers = () => {
    return createMutationHookMutationCacheHandlers(useUnfollowMutation, {
      onSuccess: (data, { entityId: id }) => {
        findInsightsQueriesById({ id }).forEach(invalidateQuery(queryClient))
        findInfiniteInsightsQueriesById({ id }).forEach(invalidateQuery(queryClient))
      },
    })
  }

  const updateRelationsHandlers = () => {
    return createMutationHookMutationCacheHandlers(useUpdateRelationsMutation, {
      onSuccess: (data, { entityId }) => {
        findInsightsQueriesById({ id: entityId }).forEach(invalidateQuery(queryClient))
        findInfiniteInsightsQueriesById({ id: entityId }).forEach(invalidateQuery(queryClient))
      },
    })
  }

  const archiveInsightHandlers = () => {
    return createMutationHookMutationCacheHandlers(useArchiveInsightMutation, {
      onSuccess: (data, { id }) => {
        findInsightsQueriesById({ id }).forEach(invalidateQuery(queryClient))
        findInfiniteInsightsQueriesById({ id }).forEach(invalidateQuery(queryClient))
      },
    })
  }

  const unarchiveInsightHandlers = () => {
    return createMutationHookMutationCacheHandlers(useUnarchiveInsightMutation, {
      onSuccess: (data, { id }) => {
        findInsightsQueriesById({ id }).forEach(invalidateQuery(queryClient))
        findInfiniteInsightsQueriesById({ id }).forEach(invalidateQuery(queryClient))
      },
    })
  }

  const updateEntityTypeHandlers = () => {
    return createMutationHookMutationCacheHandlers(useUpdateEntityTypeMutation, {
      onSuccess: (data, { id }) => {
        if (id !== EntityTypeIdsT.InsightT) {
          return
        }

        findInfiniteInsightsQueries().forEach(invalidateQuery(queryClient))
      },
    })
  }

  return [
    createInsightHandlers(),
    deleteInsightHandlers(),
    updateInsightHandlers(),
    followHandlers(),
    unfollowHandlers(),
    updateRelationsHandlers(),
    archiveInsightHandlers(),
    unarchiveInsightHandlers(),
    updateEntityTypeHandlers(),
  ]
}
