import { Box } from '@mui/material'
import { memo, useRef } from 'react'

import { useEventCallback } from '@resnet/client-common/react/hooks/use-event-callback'
import { NonNullableContextContainer } from '@resnet/client-common/react/hooks/use-non-nullable-context'

import PlusSolidIcon from '@resnet/client-shared/assets/icons/plus-solid.svg'

import { SimpleStaticNonEmptyOptionsDropdown } from '@resnet/client-shared-web/shared/common/components/simple-static-non-empty-options-dropdown'
import { Button } from '@resnet/client-shared-web/shared/gdl/components/button'
import { List, ListItem } from '@resnet/client-shared-web/shared/gdl/components/list'
import { PopperContext } from '@resnet/client-shared-web/shared/gdl/components/popper'

import { UserWithGroupsSelectMultipleDropdown } from '@resnet/client-web/shared/users/selects/user-with-groups-select-multiple-field'

import type { IssueApprovalLevelT } from '../issue-approval/types'
import { Approver } from '../issue-approval-approver'

type IssueApprovalLevelAddApproverButtonPropsT = {
  allSelectedUserIds: string[]
  onChange: (userIds: string[]) => void
  containerRef: React.RefObject<HTMLDivElement>
}

const IssueApprovalLevelAddApproverButton = ({
  containerRef,
  allSelectedUserIds,
  onChange,
}: IssueApprovalLevelAddApproverButtonPropsT) => {
  const renderAnchor = () => {
    return (
      <NonNullableContextContainer Context={PopperContext}>
        {({ setAnchorEl, open }) => {
          setAnchorEl(containerRef.current)

          return (
            <Button
              color="primary"
              icon={<PlusSolidIcon />}
              size="md"
              sx={{ alignSelf: 'flex-start' }}
              variant="text"
              onClick={open}
            >
              Add Approvers
            </Button>
          )
        }}
      </NonNullableContextContainer>
    )
  }

  return (
    <UserWithGroupsSelectMultipleDropdown
      placement="left-start"
      renderAnchor={renderAnchor}
      value={allSelectedUserIds}
      onChange={onChange}
    />
  )
}

type IssueApprovalLevelPropsT = {
  onSelectApprovers: (index: number, userIds: (null | string)[]) => void
  index: number
  isPreview: boolean
  value: IssueApprovalLevelT[]
  onChangeApproversRequired: (index: number, requiredApprovals: number) => void
  isAwaiting: boolean
  level: IssueApprovalLevelT
  onRemoveApprover: (index: number, userId: string) => void
}

const approversRequiredOptions = Array.from({ length: 5 }, (_, index) => ({
  id: String(index + 1),
  name: `Needs to be approved by ${index + 1} people`,
}))

export const IssueApprovalLevel = memo(
  ({
    onSelectApprovers: onSelectApproversActual,
    index,
    isPreview,
    value,
    onChangeApproversRequired,
    level,
    isAwaiting,
    onRemoveApprover,
  }: IssueApprovalLevelPropsT) => {
    const onSelectApprovers = useEventCallback((value: (null | string)[]) => {
      onSelectApproversActual(index, value)
    })

    const containerRef = useRef<HTMLDivElement>(null)

    return (
      <Box
        ref={containerRef}
        sx={{ display: 'flex', flexDirection: 'column', gap: '8px' }}
      >
        {isPreview ? null : (
          <SimpleStaticNonEmptyOptionsDropdown
            options={approversRequiredOptions}
            value={String(value[index].requiredApprovals)}
            onChange={(value) => onChangeApproversRequired(index, Number(value))}
          />
        )}
        <List>
          {level.approvers.map((approver) => (
            <ListItem key={approver.id}>
              <Approver
                approver={approver}
                isAwaiting={isAwaiting}
                isPreview={isPreview}
                level={level}
                onRemove={(userId) => onRemoveApprover(index, userId)}
              />
            </ListItem>
          ))}
        </List>
        {isPreview ? null : (
          <IssueApprovalLevelAddApproverButton
            allSelectedUserIds={level.approvers.map((approver) => approver.id)}
            containerRef={containerRef}
            onChange={onSelectApprovers}
          />
        )}
      </Box>
    )
  },
)
