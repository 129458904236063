import { produce } from 'immer'

import type { InputUpdateFormTypeT, GetFormTypeQueryT, ListFormTypesQueryT } from '@resnet/client-api/api'
import {
  useCreateFormTypeMutation,
  useDeleteFormTypeMutation,
  useInfiniteListFormTypesQuery,
  useGetFormTypeQuery,
  useListFormTypesQuery,
  useUpdateFormTypeMutation,
} from '@resnet/client-api/api'
import type { MutationCacheHandlersT } from '@resnet/client-api/services/query-client/types/mutation-cache-handlers'
import { createMutationHookMutationCacheHandlers } from '@resnet/client-api/services/query-client/utils/create-mutation-hook-mutation-cache-handlers'
import { findGetQueriesById, findInfiniteQueries, findQueries } from '@resnet/client-api/utils/find-queries'
import { invalidateQuery } from '@resnet/client-api/utils/invalidate-query'
import { setQueryData } from '@resnet/client-api/utils/set-query-data'
import type { QueryClient } from '@resnet/client-api/vendors/react-query'

export const formObjectTypeQueriesHandlers = ({
  queryClient,
}: {
  queryClient: QueryClient
}): MutationCacheHandlersT[] => {
  const findFormObjectTypeQueriesById = ({ id }: { id: string }) =>
    findGetQueriesById(queryClient, useGetFormTypeQuery, (data) => data.getFormType, { id })

  const updateFormObjectType = (data: undefined | GetFormTypeQueryT, { diff }: { diff: InputUpdateFormTypeT }) => {
    if (!data) {
      return
    }

    const item = data.getFormType

    Object.assign(item, diff)
  }

  const deleteFormObjectTypeHandlers = () => {
    return createMutationHookMutationCacheHandlers(useDeleteFormTypeMutation, {
      onSuccess: (data, { id }) => {
        findFormObjectTypeQueriesById({ id }).forEach(invalidateQuery(queryClient))
      },
    })
  }

  const updateFormObjectTypeHandlers = () => {
    return createMutationHookMutationCacheHandlers(useUpdateFormTypeMutation, {
      onError: (error, { id }) => {
        findFormObjectTypeQueriesById({ id }).forEach(invalidateQuery(queryClient))
      },
      onMutate: ({ id, data: diff }) => {
        findFormObjectTypeQueriesById({ id }).forEach((query) =>
          setQueryData(queryClient)(query)(produce((data) => updateFormObjectType(data, { diff }))),
        )
      },
      onSuccess: (data, { id }) => {
        findFormObjectTypeQueriesById({ id }).forEach(invalidateQuery(queryClient))
      },
    })
  }

  return [deleteFormObjectTypeHandlers(), updateFormObjectTypeHandlers()]
}

export const formObjectTypesQueriesHandlers = ({
  queryClient,
}: {
  queryClient: QueryClient
}): MutationCacheHandlersT[] => {
  const findFormObjectTypesQueries = () => findQueries(queryClient, useListFormTypesQuery)

  const findInfiniteFormObjectTypesQueries = () => findInfiniteQueries(queryClient, useInfiniteListFormTypesQuery)

  const updateFormObjectType = (
    data: undefined | ListFormTypesQueryT,
    { id, diff }: { id: string; diff: InputUpdateFormTypeT },
  ) => {
    if (!data) {
      return
    }

    const items = data.listFormTypes.items

    const item = items.find((item) => item.id === id)

    if (!item) {
      return
    }

    Object.assign(item, diff)
  }

  const deleteFormObjectType = (data: undefined | ListFormTypesQueryT, { id }: { id: string }) => {
    if (!data) {
      return
    }

    const items = data.listFormTypes.items

    const index = items.findIndex((item) => item.id === id)

    if (index === -1) {
      return
    }

    items.splice(index, 1)
  }

  const createFormObjectTypeHandlers = () => {
    return createMutationHookMutationCacheHandlers(useCreateFormTypeMutation, {
      onSuccess: () => {
        findFormObjectTypesQueries().forEach(invalidateQuery(queryClient))
        findInfiniteFormObjectTypesQueries().forEach(invalidateQuery(queryClient))
      },
    })
  }

  const deleteFormObjectTypeHandlers = () => {
    return createMutationHookMutationCacheHandlers(useDeleteFormTypeMutation, {
      onError: () => {
        findFormObjectTypesQueries().forEach(invalidateQuery(queryClient))
        findInfiniteFormObjectTypesQueries().forEach(invalidateQuery(queryClient))
      },
      onMutate: ({ id }) => {
        findFormObjectTypesQueries().forEach((query) =>
          setQueryData(queryClient)(query)(produce((data) => deleteFormObjectType(data, { id }))),
        )
        findInfiniteFormObjectTypesQueries().forEach((query) =>
          setQueryData(queryClient)(query)(
            produce((data) => data?.pages.forEach((page) => deleteFormObjectType(page, { id }))),
          ),
        )
      },
      onSuccess: () => {
        findFormObjectTypesQueries().forEach(invalidateQuery(queryClient))
        findInfiniteFormObjectTypesQueries().forEach(invalidateQuery(queryClient))
      },
    })
  }

  const updateFormObjectTypeHandlers = () => {
    return createMutationHookMutationCacheHandlers(useUpdateFormTypeMutation, {
      onError: () => {
        findFormObjectTypesQueries().forEach(invalidateQuery(queryClient))
        findInfiniteFormObjectTypesQueries().forEach(invalidateQuery(queryClient))
      },
      onMutate: ({ id, data: diff }) => {
        findFormObjectTypesQueries().forEach((query) =>
          setQueryData(queryClient)(query)(produce((data) => updateFormObjectType(data, { diff, id }))),
        )
        findInfiniteFormObjectTypesQueries().forEach((query) =>
          setQueryData(queryClient)(query)(
            produce((data) => data?.pages.forEach((page) => updateFormObjectType(page, { diff, id }))),
          ),
        )
      },
      onSuccess: () => {
        findFormObjectTypesQueries().forEach(invalidateQuery(queryClient))
        findInfiniteFormObjectTypesQueries().forEach(invalidateQuery(queryClient))
      },
    })
  }

  return [createFormObjectTypeHandlers(), deleteFormObjectTypeHandlers(), updateFormObjectTypeHandlers()]
}
