import React, { ReactNode, createContext, useContext, useState } from "react";

type Attachment = {
  id: string;
  name: string;
  // Add any other relevant properties here
};

interface TeamsContextType {
  message: string;
  teamsUrl: string;
  subject: string;
  attachments: Attachment[];
  updateMessage: (newMessage: string) => void;
  updateSubject: (newSubject: string) => void;
  updateTeamsUrl: (newTeamsUrl: string) => void;
  updateAttachments: (newAttachments: Attachment[]) => void;  // Corrected to Attachment[]
}

interface TeamsProviderProps {
  children: ReactNode;
}

const TeamsContext = createContext<TeamsContextType>({
  message: "",
  subject: "",
  teamsUrl: "",
  attachments: [],
  updateMessage: () => {},
  updateSubject: () => {},
  updateTeamsUrl: () => {},
  updateAttachments: () => {},  // Default as no-op function
});

export const TeamsProvider: React.FC<TeamsProviderProps> = ({ children }) => {
  const [message, setMessage] = useState("");
  const [subject, setSubject] = useState("");
  const [teamsUrl, setTeamsUrl] = useState("");
  const [attachments, setAttachments] = useState<Attachment[]>([]);

  const updateMessage = (newMessage: string) => {
    setMessage(newMessage);
  };

  const updateSubject = (newSubject: string) => {
    setSubject(newSubject);
  };

  const updateTeamsUrl = (newTeamsUrl: string) => {
    setTeamsUrl(newTeamsUrl);
  };

  const updateAttachments = (newAttachments: Attachment[]) => {
    setAttachments(newAttachments);
  };

  return (
    <TeamsContext.Provider
      value={{
        message,
        subject,
        teamsUrl,
        attachments,
        updateMessage,
        updateSubject,
        updateTeamsUrl,
        updateAttachments,
      }}
    >
      {children}
    </TeamsContext.Provider>
  );
};

export const useTeamsData = () => {
  const context = useContext(TeamsContext);
  if (!context) {
    throw new Error("useTeamsData must be used within a TeamsProvider");
  }
  return context;
};
