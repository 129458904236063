import { useMemo, useState } from 'react'

import { assertedNonNullable } from '@resnet/client-common/common/utils/nullable/non-nullable'
import { fold } from '@resnet/client-common/common/utils/object/fold'
import { useDebounced } from '@resnet/client-common/react/hooks/use-debounced'
import { createHookContainer } from '@resnet/client-common/react/utils/create-hook-container'

import { useInfiniteSearchUsersQuery } from '@resnet/client-api/api'
import type { UserFragmentT, UserRolesT } from '@resnet/client-api/api'
import { defaultLimit } from '@resnet/client-api/constants/variables'

import UserGroupSolidIcon from '@resnet/client-shared/assets/icons/user-group-solid.svg'
import { userRoleOptionById, userRoleOptions } from '@resnet/client-shared/shared/users/constants/user-role-options'
import { mapUserToMedia } from '@resnet/client-shared/shared/users/utils/map-user-to-media-boston'
import { mapUserToTitle } from '@resnet/client-shared/shared/users/utils/map-user-to-title'

import { useSearchHandlers } from '@resnet/client-shared-web/shared/gdl/components/search'

type RoleOptionT = {
  __typename: 'Role'
  name: string
  id: UserRolesT
}

export type EntityPermitRecipientOptionT = UserFragmentT | RoleOptionT

export const mapRolesToOptions = (roles: UserRolesT[]): RoleOptionT[] =>
  roles.map((role) => ({ __typename: 'Role' as const, id: role, name: userRoleOptionById[role].name }))

export const mapOptionsToEntityPermitRecipients = (options: string[]): { users: string[]; roles: UserRolesT[] } => {
  return options.reduce(
    (acc, option) => {
      if (userRoleOptionById[option as UserRolesT] != null) {
        acc.roles.push(option as UserRolesT)

        return acc
      }

      acc.users.push(option)

      return acc
    },
    {
      roles: [],
      users: [],
    } as { users: string[]; roles: UserRolesT[] },
  )
}

const rolesOptions = mapRolesToOptions(userRoleOptions.map((option) => option.id))

const getOptionLabel = (option: EntityPermitRecipientOptionT) => {
  switch (option.__typename) {
    case 'User': {
      return mapUserToTitle(option)
    }
    case 'Role': {
      return option.name
    }
  }
}

const getOptionMedia = (option: EntityPermitRecipientOptionT) => {
  switch (option.__typename) {
    case 'User': {
      return mapUserToMedia(option)
    }
    case 'Role': {
      return {
        icon: UserGroupSolidIcon,
        size: 'md',
        type: 'iconAvatar',
      } as const
    }
  }
}

export const useEntityPermitRecipientsSelectDropdown = () => {
  const [searchActual, setSearch] = useState('')

  const search = useDebounced(searchActual)

  const usersQuery = useInfiniteSearchUsersQuery(
    {
      limit: defaultLimit,
      search,
    },
    {
      getNextPageParam: (lastPage, allPages) =>
        fold({
          offset: lastPage.searchUsers.items.length === 0 ? undefined : allPages.length * defaultLimit,
        }),
      keepPreviousData: true,
    },
  )

  const usersQueryFetchNextPage = usersQuery.fetchNextPage

  const users = useMemo(
    () => usersQuery.data?.pages.flatMap((page) => assertedNonNullable(page.searchUsers?.items)),
    [usersQuery.data],
  )

  const options = useMemo(
    () => [...rolesOptions.filter((role) => role.name.toLowerCase().includes(search.toLowerCase())), ...(users ?? [])],
    [search, users],
  )

  const { onChange: onSearchChange, onClear: onSearchClear } = useSearchHandlers({ setSearch })

  const bottomBoundary = useMemo(() => {
    if (usersQuery.isFetchingNextPage || !usersQuery.hasNextPage) {
      return
    }

    return { onObserve: usersQueryFetchNextPage }
  }, [usersQuery.hasNextPage, usersQuery.isFetchingNextPage, usersQueryFetchNextPage])

  const dropdownProps = {
    bottomBoundary,
    getOptionLabel,
    getOptionMedia,
    isLoading: usersQuery.isFetching,
    options,
    skeletonProps: { media: { type: 'avatar' as const } },
  }

  const searchProps = {
    onChange: onSearchChange,
    onClear: onSearchClear,
    value: searchActual,
  }

  return {
    dropdownProps,
    searchProps,
  }
}

export const EntityPermitRecipientsSelectDropdownContainer = createHookContainer(
  useEntityPermitRecipientsSelectDropdown,
)
