import { Box } from '@mui/material'
import type { GridColDef } from '@mui/x-data-grid'
import { ObjectId } from 'bson'
import type { FieldPathByValue, FieldValues, UseFormReturn } from 'react-hook-form'

import { checkNonNullable } from '@resnet/client-common/common/utils/nullable/non-nullable'
import { assert } from '@resnet/client-common/typescript/utils/assert'

import { CustomFieldTypeT } from '@resnet/client-api/api'

import type { UserPickerCustomFieldValueT } from '@resnet/client-shared/shared/custom-fields/presets/user-picker/custom-field-value'

import { Tag } from '@resnet/client-shared-web/shared/gdl/components/tag'

import type { ColumnWithCreateGanttColumnT } from '@resnet/client-web/shared/gantt/types/column-with-create-gantt-column'
import { createSimpleGanttColumn } from '@resnet/client-web/shared/gantt/utils/create-simple-gantt-column'
import { createRenderColumnAsReactElement } from '@resnet/client-web/shared/gantt/utils/render-column-as-react-element'
import { EditOptionCell } from '@resnet/client-web/shared/tables/components/edit-option-cell'
import { EditOptionMultipleCell } from '@resnet/client-web/shared/tables/components/edit-option-multiple-cell'
import { RegularCell } from '@resnet/client-web/shared/tables/components/regular-cell'
import { UserCell } from '@resnet/client-web/shared/tables/components/user-cell'
import { createColumn as createCommonColumn } from '@resnet/client-web/shared/tables/factories/create-column'
import type { ColumnWithEditFieldT } from '@resnet/client-web/shared/tables/types/column-with-edit-field'
import type { ColumnWithGroupingT } from '@resnet/client-web/shared/tables/types/column-with-grouping'
import type { ColumnWithHeaderMenuFilterT } from '@resnet/client-web/shared/tables/types/column-with-header-menu-filter'
import type { ColumnWithValueT } from '@resnet/client-web/shared/tables/types/column-with-value'
import { UserSelect, UserSelectMultiple } from '@resnet/client-web/shared/users/selects/user-select'

import type { CreateColumnPropsT, CustomFieldT } from '../../types/custom-field'
import { getFieldValue } from '../../utils/get-field-value'
import { mapCustomFieldToFullKey } from '../../utils/map-custom-field-to-key'

import { UserPickerCustomFieldUserFormField } from './components/user-picker-custom-field-user-form-field'

export const createColumn: CustomFieldT['createColumn'] = <
  RowT extends Record<string, unknown>,
  FieldValuesKeyT extends Extract<keyof RowT, string>,
>({
  field,
  fieldValuesKey,
}: CreateColumnPropsT<RowT, FieldValuesKeyT>) => {
  type ColumnT = ColumnWithHeaderMenuFilterT<
    ColumnWithEditFieldT<ColumnWithGroupingT<ColumnWithCreateGanttColumnT<GridColDef<RowT>>>>
  >

  type ColumnWithValueAppliedT = ColumnWithValueT<ColumnT, undefined | UserPickerCustomFieldValueT>

  const payload = field.payload

  assert(payload, checkNonNullable)

  const userPickerPayload = payload[CustomFieldTypeT.UserPickerT]

  assert(userPickerPayload, checkNonNullable)

  const { multiple } = userPickerPayload

  const getValue = (row: Record<string, unknown>) =>
    getFieldValue<UserPickerCustomFieldValueT>(field, fieldValuesKey, row)

  const createGanttColumn: ColumnT['createGanttColumn'] = ({ viewSettings, rowElementsRef, column }) => {
    const columnId = column.field

    const data = createRenderColumnAsReactElement<Record<string, unknown>>({ columnId, getValue, rowElementsRef })

    return createSimpleGanttColumn<Record<string, unknown>, ColumnT>({
      column,
      data,
      isReactElementRenderer: true,
      renderElement: ({ row, onSuccess }) => {
        const value = getValue(row)

        if (value === undefined) {
          return <RegularCell>-</RegularCell>
        }

        const [id, ...rest] = [value].filter(checkNonNullable).flat()

        return (
          <Box sx={{ alignItems: 'center', display: 'flex', gap: '4px' }}>
            <UserCell
              value={id}
              onSuccess={onSuccess}
            />
            {rest.length < 1 ? null : <Tag>+{rest.length}</Tag>}
          </Box>
        )
      },
      sortable: ({ row }) => {
        const value = getValue(row)

        if (value === undefined) {
          return '-'
        }

        const [id] = [value].filter(checkNonNullable).flat()

        if (id === undefined) {
          return '-'
        }

        return id
      },
      viewSettings,
      width: 174,
    })
  }

  const columnField = mapCustomFieldToFullKey(field, fieldValuesKey)

  return createCommonColumn<ColumnWithValueAppliedT>({
    createGanttColumn,
    editable: true,
    field: columnField,
    filterOptionId: columnField,
    groupRowRenderCell: (rowNode) => {
      const { groupingKey } = rowNode

      assert(groupingKey, checkNonNullable)

      const id = typeof groupingKey !== 'string' || !ObjectId.isValid(groupingKey) ? undefined : groupingKey

      return (
        <UserCell
          options={{ refetchOnWindowFocus: false }}
          sx={{ flexGrow: 1 }}
          value={id}
          onSuccess={rowNode.onSuccess}
        />
      )
    },
    groupable: multiple ? false : true,
    groupingValueGetter: ({ row }) => {
      const value = getValue(row)

      if (value === undefined) {
        return undefined
      }

      if (Array.isArray(value)) {
        throw new Error('grouping is not available for arrays')
      }

      return value
    },
    headerName: field.name,
    renderCell: ({ value }) => {
      if (value === undefined) {
        return <RegularCell>-</RegularCell>
      }

      const [id, ...rest] = [value].flat()

      return (
        <Box sx={{ alignItems: 'center', display: 'flex', flexGrow: 1, gap: '4px', minWidth: 0 }}>
          <UserCell value={id} />
          {rest.length < 1 ? null : <Tag sx={{ flexShrink: 0 }}>+{rest.length}</Tag>}
        </Box>
      )
    },
    renderEditCell: () => {
      if (multiple) {
        return (
          <EditOptionMultipleCell
            name={columnField}
            selectMultiple={(props) => <UserSelectMultiple {...props} />}
          />
        )
      }

      return (
        <EditOptionCell
          name={columnField}
          select={(props) => <UserSelect {...props} />}
        />
      )
    },
    renderField: ({ form }) => {
      return (
        <Box sx={{ display: 'flex', flexDirection: 'column', flexGrow: 1 }}>
          <UserPickerCustomFieldUserFormField
            field={field}
            form={form as UseFormReturn<FieldValues>}
            name={columnField as FieldPathByValue<RowT, undefined | UserPickerCustomFieldValueT>}
          />
        </Box>
      )
    },
    valueGetter: ({ row }) => getValue(row),
  })
}
