import type { InputStringFilterT } from '@resnet/client-api/api'
import { FilterModeT } from '@resnet/client-api/api'

import type { AbstractOptionT } from '@resnet/client-shared-web/shared/gdl/types/abstract-option'
import type { OptionContainerT } from '@resnet/client-shared-web/shared/gdl/types/option-container'
import type { SelectDropdownContainerT } from '@resnet/client-shared-web/shared/gdl/types/select-dropdown-container'

import { MultipleFilter } from '@resnet/client-web/shared/filters/components/multiple-filter'
import { FilterRuleContainer } from '@resnet/client-web/shared/presets/hooks/use-filter-rule'
import { mapFilterModeToKey } from '@resnet/client-web/shared/presets/utils/map-filter-mode-to-key'

import type {
  FilterOptionT as GenericFilterOptionT,
  QueryVariablesOfFilterOptionT,
} from '../../../presets/types/filter-option'
import { mapStringFilterItemsToMergedEmpty } from '../../utils/map-string-filter-items-to-merged-empty'

export type CreateMultipleFilterOptionPropsT<
  FilterOptionT extends GenericFilterOptionT,
  OptionT extends AbstractOptionT,
> = {
  getOptionLabel: (option: OptionT) => React.ReactNode
  id: string
  name: string
  nullOptionEnabled?: boolean
  objectType: FilterOptionT['objectType']
  OptionContainer: OptionContainerT<OptionT>
  SelectDropdownContainer: SelectDropdownContainerT
  withFilter: (
    input: QueryVariablesOfFilterOptionT<FilterOptionT>,
    updater: (value: undefined | null | InputStringFilterT) => InputStringFilterT,
  ) => QueryVariablesOfFilterOptionT<FilterOptionT>
}

export const createMultipleFilterOption = <
  FilterOptionT extends GenericFilterOptionT,
  OptionT extends AbstractOptionT,
>({
  getOptionLabel,
  id,
  name,
  nullOptionEnabled,
  objectType,
  OptionContainer,
  SelectDropdownContainer,
  withFilter,
}: CreateMultipleFilterOptionPropsT<FilterOptionT, OptionT>) => {
  const filterOption: GenericFilterOptionT = {
    apply: (input, rule) => {
      const { string: payload } = rule

      if (!payload) {
        return input
      }

      const { mode, items } = payload

      if (mode === FilterModeT.IncludeT && items.length === 0) {
        return input
      }

      const updater = () => ({ [mapFilterModeToKey(mode)]: mapStringFilterItemsToMergedEmpty(items) })

      return withFilter(input as QueryVariablesOfFilterOptionT<FilterOptionT>, updater)
    },
    id,
    name,
    objectType,
    renderContent: ({ filterPresetRuleModel, popperRef }) => {
      return (
        <FilterRuleContainer
          filterPresetRuleModel={filterPresetRuleModel}
          filterType="string"
        >
          {({ filterPresetItemRulePayloadModel: [value, onChange] }) => {
            return (
              <MultipleFilter
                OptionContainer={OptionContainer}
                SelectDropdownContainer={SelectDropdownContainer}
                getOptionLabel={getOptionLabel}
                nullOptionEnabled={nullOptionEnabled}
                popperRef={popperRef}
                value={value}
                onChange={onChange}
              />
            )
          }}
        </FilterRuleContainer>
      )
    },
  }

  return filterOption as FilterOptionT
}
