import { checkNonNullable } from '@resnet/client-common/common/utils/nullable/non-nullable'
import { assert } from '@resnet/client-common/typescript/utils/assert'

import { CustomFieldTypeT, type CustomFieldFragmentT } from '@resnet/client-api/api'

import type { NumberCustomFieldValueT } from '@resnet/client-shared/shared/custom-fields/presets/number/custom-field-value'

import { FieldPreview } from '@resnet/client-shared-web/shared/gdl/components/field-preview'

import { ObjectDetailsRow } from '@resnet/client-web/shared/object/components/object-details-row'

export type NumberFormUserFormPreviewPropsT = {
  field: CustomFieldFragmentT
  value: undefined | NumberCustomFieldValueT
}

export const NumberFormUserFormPreview = ({ field, value }: NumberFormUserFormPreviewPropsT) => {
  const payload = field.payload

  assert(payload, checkNonNullable)

  const numberPayload = payload[CustomFieldTypeT.NumberT]

  assert(numberPayload, checkNonNullable)

  const { isHalfWidth } = numberPayload

  const getSizeSx = () => {
    if (isHalfWidth) {
      return null
    }

    return {
      gridColumn: 'span 2',
    }
  }

  return (
    <ObjectDetailsRow sx={getSizeSx()}>
      <FieldPreview
        label={field.name}
        value={value}
      />
    </ObjectDetailsRow>
  )
}
