import { Box, useEventCallback } from '@mui/material'
import { uniqBy } from 'ramda'
import { useEffect, useMemo } from 'react'
import type { DropResult } from 'react-beautiful-dnd'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'
import type { PathValue, UseControllerReturn } from 'react-hook-form'
import { useController, type FieldPathByValue, type FieldValues, type UseFormReturn } from 'react-hook-form'
import uuid4 from 'uuid4'

import { move } from '@resnet/client-common/common/utils/array/move'
import { pullById } from '@resnet/client-common/common/utils/array/pull-by-id'
import { push } from '@resnet/client-common/common/utils/array/push'
import { updateById } from '@resnet/client-common/common/utils/array/update-by-id'
import { pipeline } from '@resnet/client-common/common/utils/function/pipeline'
import { checkNonUndefined } from '@resnet/client-common/common/utils/nullable/non-nullable'
import { update } from '@resnet/client-common/common/utils/object/update'
import { ChildrenDivider } from '@resnet/client-common/react/components/children-transformer'
import { assert } from '@resnet/client-common/typescript/utils/assert'

import type {
  CustomFieldPayloadDiscriminatorOptionFragmentT,
  CustomFieldPayloadDiscriminatorT,
} from '@resnet/client-api/api'
import { CustomFieldTypeT, type CustomFieldFragmentT } from '@resnet/client-api/api'

import DragDotsSolidIcon from '@resnet/client-shared/assets/icons/drag-dots-solid.svg'
import MinusSolidIcon from '@resnet/client-shared/assets/icons/minus-solid.svg'
import PlusSolidIcon from '@resnet/client-shared/assets/icons/plus-solid.svg'
import { composeValidators } from '@resnet/client-shared/shared/forms/validators/compose-validators'
import { validateListRequired } from '@resnet/client-shared/shared/forms/validators/list-required'
import { createValidateMaxLength } from '@resnet/client-shared/shared/forms/validators/text-length'
import { validateTextRequired } from '@resnet/client-shared/shared/forms/validators/text-required'

import { SimpleStaticOptionsDropdownField } from '@resnet/client-shared-web/shared/form/components/common/simple-static-options-dropdown-field'
import type { StaticSimpleOptionsMultipleDropdownFieldValueT } from '@resnet/client-shared-web/shared/form/components/common/simple-static-options-multiple-dropdown-field'
import { StaticSimpleOptionsMultipleDropdownField } from '@resnet/client-shared-web/shared/form/components/common/simple-static-options-multiple-dropdown-field'
import type { SwitchFieldValueT } from '@resnet/client-shared-web/shared/form/components/common/switch-field'
import { SwitchField } from '@resnet/client-shared-web/shared/form/components/common/switch-field'
import { TextField } from '@resnet/client-shared-web/shared/form/components/common/text-field'
import { Button } from '@resnet/client-shared-web/shared/gdl/components/button'
import { Divider } from '@resnet/client-shared-web/shared/gdl/components/divider'
import { Field, FieldErrorText, FieldLabel } from '@resnet/client-shared-web/shared/gdl/components/field'
import { themeColors } from '@resnet/client-shared-web/shared/gdl/constants/theme-colors'
import type { AbstractSimpleOptionT } from '@resnet/client-shared-web/shared/gdl/types/abstract-option'
import { toPx } from '@resnet/client-shared-web/shared/gdl/utils/to-px'

import { EmptyPanel } from '@resnet/client-web/shared/common/components/empty-panel'
import type { DraftableT } from '@resnet/client-web/shared/draftable/types/draftable'
import { ObjectDetailsRow } from '@resnet/client-web/shared/object/components/object-details-row'

export const mapOptionToOptionIdFromKey = ({ options }: { options: { name: string; key: string }[] }) => {
  return pipeline(
    options,
    (x) =>
      x.map((option): AbstractSimpleOptionT => {
        return {
          id: option.key,
          name: option.name,
        }
      }),
    (x) => uniqBy((item) => item.id, x),
  )
}

const discriminatorOptionsGap = 8

const discriminatorOptionItemControlsHeight = 40

const DiscriminatorOptionItem = <TFieldValues extends FieldValues>({
  formFieldsName,
  id,
  form,
  name,
  index,
  onDeleteField,
}: {
  formFieldsName: FieldPathByValue<TFieldValues, CustomFieldFragmentT[]>
  id: string
  form: UseFormReturn<TFieldValues>
  name: FieldPathByValue<TFieldValues, NonNullable<CustomFieldPayloadDiscriminatorOptionFragmentT>>
  index: number
  onDeleteField?: ({ id }: { id: string }) => void
}) => {
  const { watch } = form

  type KeyPathT = FieldPathByValue<TFieldValues, NonNullable<CustomFieldPayloadDiscriminatorOptionFragmentT>['key']>

  const keyName = `${name}.key` as KeyPathT

  type NamePathT = FieldPathByValue<TFieldValues, NonNullable<CustomFieldPayloadDiscriminatorOptionFragmentT>['name']>

  const nameName = `${name}.name` as NamePathT

  type FieldsPathT = FieldPathByValue<
    TFieldValues,
    NonNullable<CustomFieldPayloadDiscriminatorOptionFragmentT>['fields']
  >

  const fieldsName = `${name}.fields` as FieldsPathT

  const formFields = watch(formFieldsName) as CustomFieldFragmentT[]

  const formFieldsWithoutDiscriminator = useMemo(() => {
    return formFields.filter((formField) => formField.type !== CustomFieldTypeT.DiscriminatorT)
  }, [formFields])

  const formFieldsWithoutDiscriminatorWithIdFromKey = useMemo(
    () => mapOptionToOptionIdFromKey({ options: formFieldsWithoutDiscriminator }),
    [formFieldsWithoutDiscriminator],
  )

  useEffect(() => {
    const fields = form.getValues(name).fields as string[]

    const formFieldsWithoutDiscriminatorMap = new Set(formFieldsWithoutDiscriminator.map((item) => item.key))

    const removedFields = fields.filter((field) => !formFieldsWithoutDiscriminatorMap.has(field))

    removedFields.forEach((field) => {
      form.setValue(fieldsName, fields.filter((item) => item !== field) as PathValue<TFieldValues, FieldsPathT>)
    })
  }, [formFieldsWithoutDiscriminator, form, name, onDeleteField, fieldsName])

  const onDelete = useEventCallback(() => {
    onDeleteField?.({ id })
  })

  return (
    <Draggable
      draggableId={id}
      index={index}
    >
      {(draggable) => {
        const renderDragHandle = () => {
          return (
            <Box
              sx={{
                alignItems: 'center',
                alignSelf: 'flex-start',
                display: 'flex',
                height: toPx(discriminatorOptionItemControlsHeight),
              }}
            >
              <Box
                {...draggable.dragHandleProps}
                sx={{ display: 'flex', flexDirection: 'column' }}
              >
                <DragDotsSolidIcon
                  fill={themeColors.overBackgroundBold}
                  height={16}
                  width={16}
                />
              </Box>
            </Box>
          )
        }

        const renderKey = () => {
          return (
            <ObjectDetailsRow>
              <TextField
                form={form}
                helperText="Key"
                name={keyName}
                rules={{
                  validate: composeValidators(validateTextRequired, createValidateMaxLength(60)),
                }}
              />
            </ObjectDetailsRow>
          )
        }

        const renderName = () => {
          return (
            <ObjectDetailsRow>
              <TextField
                form={form}
                helperText="Name"
                name={nameName}
                rules={{
                  validate: composeValidators(validateTextRequired, createValidateMaxLength(60)),
                }}
              />
            </ObjectDetailsRow>
          )
        }

        const renderFields = () => {
          return (
            <ObjectDetailsRow>
              <StaticSimpleOptionsMultipleDropdownField
                form={form}
                helperText="Fields"
                name={
                  fieldsName as unknown as FieldPathByValue<
                    TFieldValues,
                    StaticSimpleOptionsMultipleDropdownFieldValueT
                  >
                }
                options={formFieldsWithoutDiscriminatorWithIdFromKey}
              />
            </ObjectDetailsRow>
          )
        }

        const renderOptionFields = () => {
          return (
            <Box sx={{ display: 'flex', flexDirection: 'column', flexGrow: 1, gap: toPx(8), width: 0 }}>
              {renderKey()}
              {renderName()}
              {renderFields()}
            </Box>
          )
        }

        const renderDeleteButton = () => {
          return (
            <Box
              sx={{
                alignItems: 'center',
                alignSelf: 'flex-start',
                display: 'flex',
                height: toPx(discriminatorOptionItemControlsHeight),
              }}
            >
              <Button
                color="default"
                icon={<MinusSolidIcon />}
                size="sm"
                variant="contained"
                onClick={onDelete}
              />
            </Box>
          )
        }

        return (
          <Box
            {...draggable.draggableProps}
            ref={draggable.innerRef}
            sx={{ alignItems: 'center', display: 'flex', gap: toPx(8), marginTop: toPx(discriminatorOptionsGap) }}
          >
            {renderDragHandle()}
            {renderOptionFields()}
            {renderDeleteButton()}
          </Box>
        )
      }}
    </Draggable>
  )
}

const DiscriminatorOptionsField = <TFieldValues extends FieldValues>({
  form,
  formFieldsName,
  name,
  defaultValueName,
}: {
  form: UseFormReturn<TFieldValues>
  formFieldsName: FieldPathByValue<TFieldValues, CustomFieldFragmentT[]>
  defaultValueName: FieldPathByValue<TFieldValues, CustomFieldPayloadDiscriminatorT['defaultValue']>
  name: FieldPathByValue<TFieldValues, CustomFieldPayloadDiscriminatorT['options']>
}) => {
  const {
    field: { value: options, onChange },
    fieldState: { error },
  } = useController({
    control: form.control,
    name,
    rules: {
      validate: validateListRequired,
    },
  }) as unknown as UseControllerReturn<Record<string, DraftableT<CustomFieldPayloadDiscriminatorOptionFragmentT>[]>>

  const onAddOption = useEventCallback(() => {
    const options = form.getValues(name) as CustomFieldPayloadDiscriminatorOptionFragmentT[]

    const id = uuid4()

    const newOption: DraftableT<CustomFieldPayloadDiscriminatorOptionFragmentT> = {
      fields: [],
      id,
      isDraft: true,
      key: '',
      name: '',
    }

    const nextOptions = push(options, newOption)

    onChange(nextOptions)
  })

  const onDeleteOption = useEventCallback(({ id }: { id: string }) => {
    const options = form.getValues(name) as DraftableT<CustomFieldPayloadDiscriminatorOptionFragmentT>[]

    const option = options.find((item) => item.id === id)

    assert(option, checkNonUndefined)

    const defaultValue = form.getValues(defaultValueName)

    if (defaultValue === option.key) {
      const nextDefaultValue = undefined as typeof defaultValue

      form.setValue(defaultValueName, nextDefaultValue)
    }

    const nextOptions = option.isDraft
      ? pullById(options, id)
      : updateById(options, id, (option) => update(option, 'isMarkedAsDeleted', () => true))

    onChange(nextOptions)
  })

  const onDragEnd = useEventCallback(({ destination, source }: DropResult) => {
    if (!destination) {
      return
    }

    const options = form.getValues(name) as CustomFieldPayloadDiscriminatorOptionFragmentT[]

    const nextOptions = move(options, source.index, destination.index)

    onChange(nextOptions)
  })

  const renderHeader = () => {
    return <FieldLabel>Options</FieldLabel>
  }

  const renderContent = () => {
    if (options.filter((option) => !option.isMarkedAsDeleted).length === 0) {
      return (
        <EmptyPanel>
          {{
            title: 'No options',
          }}
        </EmptyPanel>
      )
    }

    return (
      <Box sx={{ display: 'flex', flexDirection: 'column' }}>
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="any">
            {(droppable) => (
              <Box
                ref={droppable.innerRef}
                sx={{ mt: toPx(-discriminatorOptionsGap) }}
              >
                {options.map(({ id: optionId, isMarkedAsDeleted }, optionIndex) => {
                  if (isMarkedAsDeleted) {
                    return null
                  }

                  type OptionPathT = FieldPathByValue<
                    TFieldValues,
                    NonNullable<CustomFieldPayloadDiscriminatorOptionFragmentT>
                  >

                  const optionName = `${name}.${optionIndex}` as OptionPathT

                  return (
                    <DiscriminatorOptionItem
                      form={form}
                      formFieldsName={formFieldsName}
                      id={optionId}
                      index={optionIndex}
                      key={optionId}
                      name={optionName}
                      onDeleteField={onDeleteOption}
                    />
                  )
                })}
                {droppable.placeholder}
              </Box>
            )}
          </Droppable>
        </DragDropContext>
      </Box>
    )
  }

  const renderAddNewOptionButton = () => {
    return (
      <Button
        color="primary"
        icon={<PlusSolidIcon />}
        size="md"
        variant="text"
        onClick={onAddOption}
      >
        Add Option
      </Button>
    )
  }

  const renderFooter = () => {
    if (!error || Array.isArray(error)) {
      return null
    }

    return <FieldErrorText>{error.message}</FieldErrorText>
  }

  return (
    <Field>
      {renderHeader()}
      {renderContent()}
      {renderAddNewOptionButton()}
      {renderFooter()}
    </Field>
  )
}

const DefaultValueField = <TFieldValues extends FieldValues>({
  form,
  name,
  optionsName,
}: {
  form: UseFormReturn<TFieldValues>
  name: FieldPathByValue<TFieldValues, CustomFieldPayloadDiscriminatorT['defaultValue']>
  optionsName: FieldPathByValue<TFieldValues, CustomFieldPayloadDiscriminatorT['options']>
}) => {
  const { watch } = form

  const options = watch(optionsName) as CustomFieldPayloadDiscriminatorT['options']

  const optionsWithIdFromKey = mapOptionToOptionIdFromKey({ options: options.filter((item) => item.key) })

  return (
    <SimpleStaticOptionsDropdownField
      form={form}
      label="Default Value"
      name={name}
      options={optionsWithIdFromKey}
    />
  )
}

export type DiscriminatorCustomFieldPayloadPropsT<TFieldValues extends FieldValues> = {
  form: UseFormReturn<TFieldValues>
  formFieldsName: FieldPathByValue<TFieldValues, CustomFieldFragmentT[]>
  name: FieldPathByValue<TFieldValues, CustomFieldFragmentT>
}

export const DiscriminatorCustomFieldPayload = <TFieldValues extends FieldValues>({
  form,
  formFieldsName,
  name,
}: DiscriminatorCustomFieldPayloadPropsT<TFieldValues>) => {
  type DiscriminatorPayloadPathT = FieldPathByValue<TFieldValues, undefined | null | CustomFieldPayloadDiscriminatorT>

  const discriminatorPayloadName = `${name}.payload.${CustomFieldTypeT.DiscriminatorT}` as DiscriminatorPayloadPathT

  type RequiredPathT = FieldPathByValue<TFieldValues, CustomFieldPayloadDiscriminatorT['required']>

  const requiredName = `${discriminatorPayloadName}.required` as RequiredPathT

  type IsHalfWidthPathT = FieldPathByValue<
    TFieldValues,
    undefined | null | CustomFieldPayloadDiscriminatorT['isHalfWidth']
  >

  const isHalfWidthName = `${discriminatorPayloadName}.isHalfWidth` as IsHalfWidthPathT

  type OptionsPathT = FieldPathByValue<TFieldValues, CustomFieldPayloadDiscriminatorT['options']>
  const optionsName = `${discriminatorPayloadName}.options` as OptionsPathT

  type DefaultValuePathT = FieldPathByValue<TFieldValues, CustomFieldPayloadDiscriminatorT['defaultValue']>

  const defaultValueName = `${discriminatorPayloadName}.defaultValue` as DefaultValuePathT

  const renderRequiredField = () => {
    return (
      <SwitchField
        form={form}
        label="Required"
        name={requiredName as unknown as FieldPathByValue<TFieldValues, SwitchFieldValueT>}
      />
    )
  }

  const renderIsHalfWidthField = () => {
    return (
      <SwitchField
        form={form}
        label="Half Width"
        name={isHalfWidthName}
      />
    )
  }

  const renderSwitchFields = () => {
    return (
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: toPx(8) }}>
        {renderRequiredField()}
        {renderIsHalfWidthField()}
      </Box>
    )
  }

  const renderOptionsField = () => {
    return (
      <DiscriminatorOptionsField
        defaultValueName={defaultValueName}
        form={form}
        formFieldsName={formFieldsName}
        name={optionsName}
      />
    )
  }

  const renderDefaultValueField = () => {
    return (
      <ObjectDetailsRow>
        <DefaultValueField
          form={form}
          name={defaultValueName}
          optionsName={optionsName}
        />
      </ObjectDetailsRow>
    )
  }

  return (
    <ChildrenDivider dividerNode={<Divider />}>
      {renderSwitchFields()}
      {renderOptionsField()}
      {renderDefaultValueField()}
    </ChildrenDivider>
  )
}
