export const createDate = (value: string | number | Date | unknown) => {
  if (typeof value === 'number' || typeof value === 'string') {
    return new Date(value)
  }

  if (value instanceof Date) {
    return value
  }

  throw new Error(`Invalid date value: ${value}`)
}
