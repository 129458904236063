import type { FieldPathByValue, FieldValues, PathValue } from 'react-hook-form'
import { useController, type UseControllerProps, type UseFormReturn } from 'react-hook-form'

import { propToKey } from '@resnet/client-common/common/utils/object/prop-to-key'
import { useEventCallback } from '@resnet/client-common/react/hooks/use-event-callback'

import { SimpleStaticOptionsDropdown } from '@resnet/client-shared-web/shared/common/components/simple-static-options-dropdown'
import { Field, FieldErrorText, FieldLabel } from '@resnet/client-shared-web/shared/gdl/components/field'

const falseBooleanOption = { id: 'false' as const, name: 'False', value: false }

const trueBooleanOption = { id: 'true' as const, name: 'True', value: true }

const booleanOptions = [falseBooleanOption, trueBooleanOption]

const booleanOptionsById = propToKey('id', booleanOptions)

type BooleanDropdownFieldValueT = boolean

export type BooleanDropdownFieldPropsT<
  TFieldValues extends FieldValues,
  TPath extends FieldPathByValue<TFieldValues, undefined | BooleanDropdownFieldValueT>,
> = {
  defaultValue?: PathValue<TFieldValues, TPath>
  form: UseFormReturn<TFieldValues>
  label?: string
  name: TPath
  rules?: UseControllerProps<TFieldValues, TPath>['rules']
}

export const BooleanDropdownField = <
  TFieldValues extends FieldValues,
  TPath extends FieldPathByValue<TFieldValues, undefined | BooleanDropdownFieldValueT>,
>({
  defaultValue,
  form,
  label,
  name,
  rules,
}: BooleanDropdownFieldPropsT<TFieldValues, TPath>) => {
  const {
    field: { value: fieldValue, onChange: onFieldChange },
    fieldState: { error },
  } = useController({ control: form.control, defaultValue, name, rules })

  const value = !fieldValue ? falseBooleanOption.id : trueBooleanOption.id

  const onChange = useEventCallback((optionId: undefined | null | (typeof booleanOptions)[number]['id']) => {
    if (!optionId) {
      return
    }

    const { value: fieldValue } = booleanOptionsById[optionId]

    onFieldChange(fieldValue as PathValue<TFieldValues, TPath>)
  })

  const renderHeader = () => {
    if (!label) {
      return null
    }

    return <FieldLabel>{label}</FieldLabel>
  }

  const renderInput = () => {
    return (
      <SimpleStaticOptionsDropdown
        options={booleanOptions}
        value={value}
        onChange={onChange}
      />
    )
  }

  const renderFooter = () => {
    if (!error) {
      return null
    }

    return <FieldErrorText>{error.message as string}</FieldErrorText>
  }

  return (
    <Field sx={{ flexGrow: 1, width: 0 }}>
      {renderHeader()}
      {renderInput()}
      {renderFooter()}
    </Field>
  )
}
