import { Box } from '@mui/material'
import type { GridColDef } from '@mui/x-data-grid'
import type { FieldPathByValue, UseFormReturn } from 'react-hook-form'

import { checkNonNullable } from '@resnet/client-common/common/utils/nullable/non-nullable'
import { assert } from '@resnet/client-common/typescript/utils/assert'

import { CustomFieldTypeT } from '@resnet/client-api/api'

import { mapPickerColorPresetToColor } from '@resnet/client-shared/shared/common/utils/map-picker-color-preset-to-color'
import type { DropdownCustomFieldValueT } from '@resnet/client-shared/shared/custom-fields/presets/dropdown/custom-field-value'
import { mapColorToOverBackgroundColor } from '@resnet/client-shared/shared/gdl/utils/map-color-to-over-background-color'

import { createSelect } from '@resnet/client-shared-web/shared/common/factories/create-select'
import { Tag } from '@resnet/client-shared-web/shared/gdl/components/tag'
import { createStaticOptionContainer } from '@resnet/client-shared-web/shared/gdl/factories/create-static-option-container'
import { createStaticOptionsSelectDropdownContainer } from '@resnet/client-shared-web/shared/gdl/factories/create-static-options-select-dropdown-container'

import type { ColumnWithCreateGanttColumnT } from '@resnet/client-web/shared/gantt/types/column-with-create-gantt-column'
import { createSimpleGanttColumn } from '@resnet/client-web/shared/gantt/utils/create-simple-gantt-column'
import { createRenderColumnAsReactElement } from '@resnet/client-web/shared/gantt/utils/render-column-as-react-element'
import { EditOptionCell } from '@resnet/client-web/shared/tables/components/edit-option-cell'
import { RegularCell } from '@resnet/client-web/shared/tables/components/regular-cell'
import { createColumn as createCommonColumn } from '@resnet/client-web/shared/tables/factories/create-column'
import type { ColumnWithEditFieldT } from '@resnet/client-web/shared/tables/types/column-with-edit-field'
import type { ColumnWithGroupingT } from '@resnet/client-web/shared/tables/types/column-with-grouping'
import type { ColumnWithHeaderMenuFilterT } from '@resnet/client-web/shared/tables/types/column-with-header-menu-filter'
import type { ColumnWithValueT } from '@resnet/client-web/shared/tables/types/column-with-value'

import type { CreateColumnPropsT, CustomFieldT } from '../../types/custom-field'
import { getFieldValue } from '../../utils/get-field-value'
import { mapCustomFieldToFullKey } from '../../utils/map-custom-field-to-key'

import { DropdownCustomFieldUserFormField } from './components/dropdown-custom-field-user-form-field'
import { mapOptionToOptionIdFromKey } from './utils/map-option-to-option-id-from-key'

export const createColumn: CustomFieldT['createColumn'] = <
  RowT extends Record<string, unknown>,
  FieldValuesKeyT extends Extract<keyof RowT, string>,
>({
  field,
  fieldValuesKey,
  mapRowToPathname,
}: CreateColumnPropsT<RowT, FieldValuesKeyT>) => {
  type ColumnT = ColumnWithHeaderMenuFilterT<
    ColumnWithEditFieldT<ColumnWithGroupingT<ColumnWithCreateGanttColumnT<GridColDef<RowT>>>>
  >

  type ColumnWithValueAppliedT = ColumnWithValueT<ColumnT, undefined | DropdownCustomFieldValueT>

  const payload = field.payload

  assert(payload, checkNonNullable)

  const dropdownPayload = payload[CustomFieldTypeT.DropdownT]

  assert(dropdownPayload, checkNonNullable)

  const { hasColor, options } = dropdownPayload

  const getValue = (row: Record<string, unknown>) =>
    getFieldValue<DropdownCustomFieldValueT>(field, fieldValuesKey, row)

  const createGanttColumn: ColumnT['createGanttColumn'] = ({ viewSettings, rowElementsRef, column }) => {
    const columnId = column.field

    const data = createRenderColumnAsReactElement<Record<string, unknown>>({ columnId, getValue, rowElementsRef })

    return createSimpleGanttColumn<Record<string, unknown>, ColumnT>({
      column,
      data,
      isReactElementRenderer: true,
      renderElement: ({ row }) => {
        const value = getValue(row)

        if (value === undefined) {
          return <RegularCell>-</RegularCell>
        }

        const option = dropdownPayload.options.find((item) => item.key === value)

        if (option === undefined) {
          return <RegularCell>-</RegularCell>
        }

        if (!dropdownPayload.hasColor) {
          return <RegularCell isMarkedAsDeleted={option.isMarkedAsDeleted}>{option.name}</RegularCell>
        }

        const backgroundColor = mapPickerColorPresetToColor(option?.color)

        const color = !backgroundColor ? undefined : mapColorToOverBackgroundColor(backgroundColor)

        return (
          <RegularCell isMarkedAsDeleted={option.isMarkedAsDeleted}>
            <Tag customColors={{ backgroundColor, color }}>{option.name}</Tag>
          </RegularCell>
        )
      },
      sortable: ({ row }) => {
        const value = getValue(row)

        if (value === undefined) {
          return '-'
        }

        const option = dropdownPayload.options.find((item) => item.key === value)

        if (option === undefined) {
          return '-'
        }

        return option.name
      },
      viewSettings,
      width: 174,
    })
  }

  const columnField = mapCustomFieldToFullKey(field, fieldValuesKey)

  const optionsWithIdFromKey = mapOptionToOptionIdFromKey({ hasColor, options })

  const OptionContainer = createStaticOptionContainer({
    options: optionsWithIdFromKey,
  })

  const SelectDropdownContainer = createStaticOptionsSelectDropdownContainer({
    options: optionsWithIdFromKey,
  })

  const Select = createSelect({
    OptionContainer: OptionContainer,
    SelectDropdownContainer: SelectDropdownContainer,
    getOptionLabel: (option) => option.name,
    getOptionVisible: (option, value) => !option.isMarkedAsDeleted || option.id === value,
  })

  return createCommonColumn<ColumnWithValueAppliedT>({
    createGanttColumn,
    editable: true,
    field: columnField,
    filterOptionId: columnField,
    groupRowRenderCell: (rowNode) => {
      const { groupingKey } = rowNode

      assert(groupingKey, checkNonNullable)

      const option = dropdownPayload.options.find((item) => item.key === groupingKey)

      if (option === undefined) {
        return <RegularCell>{groupingKey}</RegularCell>
      }

      if (!dropdownPayload.hasColor) {
        return <RegularCell isMarkedAsDeleted={option.isMarkedAsDeleted}>{option.name}</RegularCell>
      }

      const backgroundColor = mapPickerColorPresetToColor(option?.color)

      const color = !backgroundColor ? undefined : mapColorToOverBackgroundColor(backgroundColor)

      return (
        <RegularCell isMarkedAsDeleted={option.isMarkedAsDeleted}>
          <Tag customColors={{ backgroundColor, color }}>{option.name}</Tag>
        </RegularCell>
      )
    },
    groupable: true,
    groupingValueGetter: ({ row }) => {
      const value = getValue(row)

      if (value === undefined) {
        return undefined
      }

      return value
    },
    headerName: field.name,
    renderCell: ({ row, value }) => {
      const to = mapRowToPathname?.(row)

      if (value === undefined) {
        return <RegularCell to={to}>-</RegularCell>
      }

      const option = dropdownPayload.options.find((item) => item.key === value)

      if (option === undefined) {
        return <RegularCell to={to}>-</RegularCell>
      }

      if (!dropdownPayload.hasColor) {
        return (
          <RegularCell
            isMarkedAsDeleted={option.isMarkedAsDeleted}
            to={to}
          >
            {option.name}
          </RegularCell>
        )
      }

      const backgroundColor = mapPickerColorPresetToColor(option?.color)

      const color = !backgroundColor ? undefined : mapColorToOverBackgroundColor(backgroundColor)

      return (
        <RegularCell
          isMarkedAsDeleted={option.isMarkedAsDeleted}
          to={to}
        >
          <Tag customColors={{ backgroundColor, color }}>{option.name}</Tag>
        </RegularCell>
      )
    },
    renderEditCell: () => {
      return (
        <EditOptionCell
          name={columnField}
          select={(props) => <Select {...props} />}
        />
      )
    },
    renderField: ({ form }) => {
      return (
        <Box sx={{ display: 'flex', flexDirection: 'column', flexGrow: 1 }}>
          <DropdownCustomFieldUserFormField
            field={field}
            form={form as UseFormReturn<Record<string, unknown>>}
            name={columnField as FieldPathByValue<Record<string, unknown>, undefined | DropdownCustomFieldValueT>}
          />
        </Box>
      )
    },
    valueGetter: ({ row }) => getValue(row),
  })
}
