import { Box } from '@mui/material'
import { useMemo } from 'react'
import type { FieldPathByValue, FieldValues, PathValue, UseControllerProps } from 'react-hook-form'
import { useController, type UseFormReturn } from 'react-hook-form'

import { checkNonNullable } from '@resnet/client-common/common/utils/nullable/non-nullable'
import { useEventCallback } from '@resnet/client-common/react/hooks/use-event-callback'
import { setStateReducer } from '@resnet/client-common/react/utils/set-state-reducer'

import { getSpecialValuesFieldProps } from '@resnet/client-shared/shared/forms/utils/get-special-values-field-props'

import type { DateDropdown } from '@resnet/client-shared-web/shared/gdl/components/date-dropdown'
import { DateSelectDropdown } from '@resnet/client-shared-web/shared/gdl/components/date-select-dropdown'
import { Field, FieldErrorText } from '@resnet/client-shared-web/shared/gdl/components/field'
import { FieldLabel } from '@resnet/client-shared-web/shared/gdl/components/field'
import { toPx } from '@resnet/client-shared-web/shared/gdl/utils/to-px'

export type DateFieldValueT = undefined | null | string

export type DateFieldPropsT<
  TFieldValues extends FieldValues,
  TPath extends FieldPathByValue<TFieldValues, DateFieldValueT>,
> = {
  form: UseFormReturn<TFieldValues>
  name: TPath
  label: string
  rules?: UseControllerProps<TFieldValues, TPath>['rules']
  headerRight?: React.ReactNode
  showTime?: boolean
  defaultValue?: PathValue<TFieldValues, TPath>
} & Omit<React.ComponentProps<typeof DateDropdown>, 'value' | 'onChange'>

export const DateField = <
  TFieldValues extends FieldValues,
  TPath extends FieldPathByValue<TFieldValues, DateFieldValueT>,
>({
  form,
  name,
  label,
  rules,
  headerRight,
  showTime,
  defaultValue,
  ...dateDropdownProps
}: DateFieldPropsT<TFieldValues, TPath>) => {
  const {
    field: { value: valueActual, onChange: onChangeActual, ...field },
    field: fieldActual,
    fieldState: { error, invalid },
  } = useController({ control: form.control, defaultValue, name, rules })

  const specialValuesFieldProps = getSpecialValuesFieldProps(fieldActual)

  const value = useMemo(() => {
    if (specialValuesFieldProps) {
      return null
    }

    if (!checkNonNullable(valueActual)) {
      return null
    }

    return new Date(valueActual)
  }, [specialValuesFieldProps, valueActual])

  const onChange = useEventCallback((action: React.SetStateAction<null | Date>) => {
    const nextValue = setStateReducer(value, action)

    const nextValueActual = (nextValue === null ? null : nextValue.toISOString()) as PathValue<TFieldValues, TPath>

    onChangeActual(nextValueActual)
  })

  const onClear = useEventCallback(() => {
    onChangeActual(null as PathValue<TFieldValues, TPath>)
  })

  const renderHeader = () => {
    return (
      <Box sx={{ alignItems: 'center', display: 'flex', gap: toPx(16) }}>
        <FieldLabel>{label}</FieldLabel>
        {headerRight}
      </Box>
    )
  }

  const renderInput = () => {
    return (
      <DateSelectDropdown
        {...dateDropdownProps}
        {...field}
        hasError={invalid}
        showTime={showTime}
        value={value}
        onChange={onChange}
        onClear={onClear}
        onClose={field.onBlur}
        {...specialValuesFieldProps}
      />
    )
  }

  const renderFooter = () => {
    if (!error) {
      return null
    }

    return <FieldErrorText>{error.message as string}</FieldErrorText>
  }

  return (
    <Field sx={{ flexGrow: 1, width: 0 }}>
      {renderHeader()}
      {renderInput()}
      {renderFooter()}
    </Field>
  )
}
