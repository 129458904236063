import { NonNullableContextContainer } from '@resnet/client-common/react/hooks/use-non-nullable-context'
import type { MergeT } from '@resnet/client-common/typescript/types/merge'

import { FilterModeT } from '@resnet/client-api/api'

import { UserOptionContainer } from '@resnet/client-shared/shared/users/hooks/use-user-option'
import { mapUserToTitle } from '@resnet/client-shared/shared/users/utils/map-user-to-title'

import { DropdownInput } from '@resnet/client-shared-web/shared/gdl/components/dropdown-input'
import { MultipleDropdownText } from '@resnet/client-shared-web/shared/gdl/components/multiple-dropdown-text'
import { Popover } from '@resnet/client-shared-web/shared/gdl/components/popover'
import { Popper, PopperContext } from '@resnet/client-shared-web/shared/gdl/components/popper'
import type { PopperPropsT } from '@resnet/client-shared-web/shared/gdl/components/popper'
import { SelectSearch } from '@resnet/client-shared-web/shared/gdl/components/select-dropdown/components/select-search'
import { SelectMultipleDropdown } from '@resnet/client-shared-web/shared/gdl/components/select-multiple-dropdown'
import { SelectMultipleDropdownWithNullOptionContainer } from '@resnet/client-shared-web/shared/gdl/components/select-multiple-dropdown/hooks/use-select-multiple-dropdown-with-null-option'

import { UsersSelectDropdownContainer } from '../../hooks/use-users-select-dropdown-vienna'

export type UserDropdownMultiplePropsT = MergeT<
  Omit<PopperPropsT, 'children'>,
  {
    disablePortal?: boolean
    exclude?: string[]
    onClose?: () => void
    onChange: (value: (null | string)[]) => void
    placement?: React.ComponentProps<typeof Popper>['placement']
    value: string[]
    hasError?: boolean
    onCurrentValueQueryError?: () => void
    nullOptionLabel?: React.ReactNode
    nullOptionEnabled?: boolean
    placeholder?: string
    renderAnchor?: () => React.ReactElement
  }
>

export const UserDropdownMultiple = ({
  placeholder,
  disablePortal,
  hasError,
  nullOptionEnabled,
  nullOptionLabel,
  onChange: onChangeProp,
  onClose,
  onCurrentValueQueryError,
  placement,
  popperRef,
  value,
  exclude,
  renderAnchor: renderAnchorActual,
}: UserDropdownMultiplePropsT) => {
  const renderAnchor = (): React.ReactElement => {
    if (renderAnchorActual) {
      return renderAnchorActual()
    }

    return (
      <NonNullableContextContainer Context={PopperContext}>
        {({ setAnchorEl, isOpened, open }) => (
          <DropdownInput
            hasError={hasError}
            isOpened={isOpened}
            ref={setAnchorEl}
            onClick={open}
          >
            <MultipleDropdownText
              OptionContainer={UserOptionContainer}
              getOptionLabel={mapUserToTitle}
              mode={FilterModeT.IncludeT}
              placeholder={placeholder}
              value={value}
              onCurrentValueQueryError={onCurrentValueQueryError}
            />
          </DropdownInput>
        )}
      </NonNullableContextContainer>
    )
  }

  const renderContent = (): React.ReactElement => {
    return (
      <Popover>
        <UsersSelectDropdownContainer excludeIds={exclude}>
          {({ searchProps, dropdownProps }) => (
            <SelectMultipleDropdownWithNullOptionContainer
              {...dropdownProps}
              enabled={nullOptionEnabled}
              nullOptionLabel={nullOptionLabel}
              value={value}
              onChange={onChangeProp}
            >
              {(dropdownPropsWithNullOption) => (
                <SelectMultipleDropdown
                  {...dropdownProps}
                  {...dropdownPropsWithNullOption}
                  search={<SelectSearch {...searchProps} />}
                />
              )}
            </SelectMultipleDropdownWithNullOptionContainer>
          )}
        </UsersSelectDropdownContainer>
      </Popover>
    )
  }

  return (
    <Popper
      disablePortal={disablePortal}
      placement={placement}
      popperRef={popperRef}
      onClose={onClose}
    >
      {{
        anchor: renderAnchor,
        content: renderContent,
      }}
    </Popper>
  )
}
