import type { Client } from 'graphql-ws'
import { createClient } from 'graphql-ws'

import { assertedNonNullable } from '@resnet/client-common/common/utils/nullable/non-nullable'

let graphQlWsClient: null | Client = null

export const getGraphQlWsClient = (): Client => {
  return assertedNonNullable(graphQlWsClient)
}

export const setGraphQlWsClient = (baseUrl: string): void => {
  graphQlWsClient = createClient({
    url: baseUrl + '/graphql',
  })
}
